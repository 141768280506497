import React, { useState, useCallback, useEffect } from "react";
import { Row, Col, Button, Tooltip, Typography, Alert, Divider, Space } from "antd";
import moment from "moment";
import { AnDayPicker, AnTimePicker, AnCountSelect } from "components/an";

const ExperienceEventForm = React.memo((props) => {
    const { mode, date, event, onDelete, onSave, loadingDelete, loading, hasFlexibleHours } = props;

    const [recurrentForm, setRecurrentForm] = useState(false);
    const [recurrencyEndDate, setRecurrencyEndDate] = useState(
        moment(date || moment())
            .add(1, "d")
            .toDate()
    );
    const [recurrencyDays, setRecurrencyDays] = useState([]);
    const [time, setTime] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [capacity, setCapacity] = useState(event?.capacity);

    const handleOnSave = useCallback(() => {
        if (mode === "edit") {
            onSave(event.id, capacity);
        } else {
            if (hasFlexibleHours) {
                if (!recurrentForm) {
                    onSave(false, {
                        startTime,
                        endTime,
                        capacity,
                    });
                } else {
                    onSave(true, { startTime, endTime, capacity, recurrencyDays, recurrencyEndDate });
                }
            } else {
                if (!recurrentForm) {
                    onSave(false, { time, capacity });
                } else {
                    onSave(true, { time, capacity, recurrencyDays, recurrencyEndDate });
                }
            }
        }
    });

    const handleOnDelete = useCallback(() => {
        onDelete();
    });

    const handleSetCreateUniqueEventForm = useCallback(() => {
        setRecurrentForm(false);
    });

    const handleSetCreateRecurrentEventForm = useCallback(() => {
        setRecurrentForm(true);
    });

    const onDisabledCreateBtn = useCallback(() => {
        if (mode === "edit" && hasFlexibleHours) {
            return true;
        } else {
            if (hasFlexibleHours) {
                return event ? event.bookings === capacity : (!startTime && !endTime) || !capacity;
            } else {
                return event ? event.bookings === capacity : !time || !capacity;
            }
        }
    }, [event, capacity, startTime, endTime]);

    return (
        <div className="experience-event-form">
            {mode === "edit" ? (
                <div
                    style={{
                        marginBottom: 20,
                    }}
                >
                    <h3>Editar Evento</h3>
                </div>
            ) : (
                <>
                    <div className="create-mode-selector-container">
                        <Button
                            block
                            type="ghost"
                            shape="round"
                            onClick={handleSetCreateUniqueEventForm}
                            className={
                                !recurrentForm
                                    ? "create-form-mode-button create-form-mode-button--active"
                                    : "create-form-mode-button"
                            }
                        >
                            Evento único
                        </Button>

                        <Button
                            block
                            type="ghost"
                            shape="round"
                            onClick={handleSetCreateRecurrentEventForm}
                            className={
                                recurrentForm
                                    ? "create-form-mode-button create-form-mode-button--active"
                                    : "create-form-mode-button"
                            }
                        >
                            Evento recurrente
                        </Button>
                    </div>

                    <Divider />
                </>
            )}

            <Row align="middle" gutter={[{ xs: 0 }, { xs: 20, sm: 5 }]}>
                <Col xs={{ span: 24 }}>
                    <Row
                        align="middle"
                        gutter={[
                            { xs: 0, sm: 0 },
                            { xs: 10, sm: 20 },
                        ]}
                    >
                        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                            <Typography.Text className="form-item-label">Día:</Typography.Text>
                        </Col>

                        <Col flex="1">
                            <Row justify="end">
                                <Col flex={1}>
                                    <AnDayPicker
                                        className="an-input round an-min-width-180 an-max-height-40"
                                        format={"DD-MM-yyyy"}
                                        value={mode === "edit" ? event?.dayAndHour : date}
                                        onChange={(date) => {
                                            //
                                        }}
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {!!recurrentForm && (
                <>
                    <Row align="middle" gutter={[{ xs: 0 }, { xs: 20, sm: 5 }]}>
                        <Col xs={{ span: 24 }}>
                            <Row
                                align="middle"
                                gutter={[
                                    { xs: 0, sm: 0 },
                                    { xs: 10, sm: 20 },
                                ]}
                            >
                                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                                    <Typography.Text className="form-item-label">Recurrencia:</Typography.Text>
                                </Col>

                                <Col flex="1">
                                    <Row justify="end">
                                        <Col flex={1}>
                                            <RecurrencyInput
                                                value={recurrencyDays}
                                                options={["D", "L", "M", "X", "J", "V", "S"]}
                                                onClick={(day) => {
                                                    if (recurrencyDays.indexOf(day) !== -1) {
                                                        setRecurrencyDays((prev) => prev.filter((i) => i !== day));
                                                    } else {
                                                        setRecurrencyDays((p) => [].concat(p).concat(day));
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row align="middle" gutter={[{ xs: 0 }, { xs: 20, sm: 5 }]}>
                        <Col xs={{ span: 24 }}>
                            <Row
                                align="middle"
                                gutter={[
                                    { xs: 0, sm: 0 },
                                    { xs: 10, sm: 20 },
                                ]}
                            >
                                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                                    <Typography.Text className="form-item-label">Finalización:</Typography.Text>
                                </Col>

                                <Col flex="1">
                                    <Row justify="end">
                                        <Col flex={1}>
                                            <AnDayPicker
                                                className="an-input round an-min-width-180 an-max-height-40"
                                                placeholder="Seleccionar"
                                                format={"DD-MM-yyyy"}
                                                dayPickerProps={{
                                                    disabledDays: {
                                                        before: moment(date).add(1, "d").toDate(),
                                                    },
                                                    initialMonth: moment(date).add(1, "d").toDate(),
                                                }}
                                                value={recurrencyEndDate ? recurrencyEndDate : null}
                                                onChange={(date) => {
                                                    setRecurrencyEndDate(date);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            )}
            {hasFlexibleHours && mode !== "edit" ? (
                <>
                    <Row align="middle" gutter={[{ xs: 0 }, { xs: 20, sm: 5 }]}>
                        <Col xs={{ span: 24 }}>
                            <Row
                                align="middle"
                                gutter={[
                                    { xs: 0, sm: 0 },
                                    { xs: 10, sm: 20 },
                                ]}
                            >
                                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                                    <Typography.Text className="form-item-label">Horario Inicio:</Typography.Text>
                                </Col>

                                <Col flex="1">
                                    <Row justify="end">
                                        <Col flex={1}>
                                            <AnTimePicker
                                                className="an-input round an-min-width-180 an-max-height-40"
                                                placeholder="Seleccionar"
                                                format="HH:mm"
                                                value={startTime}
                                                onChange={(time) => {
                                                    setStartTime(time);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row align="middle" gutter={[{ xs: 0 }, { xs: 20, sm: 5 }]}>
                        <Col xs={{ span: 24 }}>
                            <Row
                                align="middle"
                                gutter={[
                                    { xs: 0, sm: 0 },
                                    { xs: 10, sm: 20 },
                                ]}
                            >
                                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                                    <Typography.Text className="form-item-label">Horario Fin:</Typography.Text>
                                </Col>

                                <Col flex="1">
                                    <Row justify="end">
                                        <Col flex={1}>
                                            <AnTimePicker
                                                className="an-input round an-min-width-180 an-max-height-40"
                                                placeholder="Seleccionar"
                                                format="HH:mm"
                                                value={endTime}
                                                onChange={(time) => {
                                                    setEndTime(time);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            ) : (
                <Row align="middle" gutter={[{ xs: 0 }, { xs: 20, sm: 5 }]}>
                    <Col xs={{ span: 24 }}>
                        <Row
                            align="middle"
                            gutter={[
                                { xs: 0, sm: 0 },
                                { xs: 10, sm: 20 },
                            ]}
                        >
                            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                                <Typography.Text className="form-item-label">Horario:</Typography.Text>
                            </Col>

                            <Col flex="1">
                                <Row justify="end">
                                    <Col flex={1}>
                                        {mode === "edit" ? (
                                            <AnTimePicker
                                                className="an-input round an-min-width-180 an-max-height-40"
                                                placeholder="Seleccionar"
                                                format="HH:mm"
                                                value={moment.utc(event?.dayAndHour)}
                                                disabled={true}
                                            />
                                        ) : (
                                            <AnTimePicker
                                                className="an-input round an-min-width-180 an-max-height-40"
                                                placeholder="Seleccionar"
                                                format="HH:mm"
                                                value={time}
                                                onChange={(time) => {
                                                    setTime(time);
                                                }}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}

            <Row align="middle" gutter={[{ xs: 0 }, { xs: 20, sm: 5 }]}>
                <Col xs={{ span: 24 }}>
                    <Row
                        align="middle"
                        gutter={[
                            { xs: 0, sm: 0 },
                            { xs: 10, sm: 20 },
                        ]}
                    >
                        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                            <Typography.Text className="form-item-label">Disponibilidad:</Typography.Text>
                        </Col>

                        <Col flex="1">
                            <Row justify="end">
                                <Col flex={1}>
                                    <AnCountSelect
                                        className="an-input an-count-select round an-max-height-40"
                                        value={capacity}
                                        minLimit={event?.bookings || 0}
                                        onChange={(capacity) => setCapacity(capacity)}
                                        disabled={hasFlexibleHours && mode === "edit"}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Divider />

            {mode === "edit" && (
                <>
                    <Alert
                        message="Atención"
                        description={`Este evento tiene ${event?.bookings || 0} reservas confirmadas`}
                        type="warning"
                        showIcon
                    />

                    <Divider />
                </>
            )}

            <Row justify="end">
                <Col>
                    <div>
                        <Space align="end">
                            {mode === "edit" && (
                                <>
                                    {!!event.bookings && event.bookings > 0 ? (
                                        <Tooltip title="No se puede eliminar ya que posee reservas confirmadas">
                                            <Button type="ghost" shape="round" disabled>
                                                Eliminar
                                            </Button>
                                        </Tooltip>
                                    ) : (
                                        <Button
                                            type="ghost"
                                            shape="round"
                                            loading={loadingDelete}
                                            onClick={handleOnDelete}
                                        >
                                            Eliminar
                                        </Button>
                                    )}
                                </>
                            )}

                            <Button
                                ghost
                                type="primary"
                                shape="round"
                                onClick={handleOnSave}
                                disabled={onDisabledCreateBtn()}
                                loading={loading}
                            >
                                {mode === "edit" ? "Guardar" : "Crear"}
                            </Button>
                        </Space>
                    </div>
                </Col>
            </Row>
        </div>
    );
});

export default ExperienceEventForm;

const RecurrencyInput = React.memo((props) => {
    const { options, value, onClick } = props;

    return (
        <div className="an-button-select">
            <div className="an-button-select-iwrap">
                {options.map((option) => (
                    <button
                        className={`an-button-select-button ${
                            value.indexOf(option) !== -1 ? " an-button-select-button--selected" : ""
                        }`}
                        onClick={() => {
                            onClick(option);
                        }}
                    >
                        {option}
                    </button>
                ))}
            </div>
        </div>
    );
});
