import { LanguageRestClient } from 'http/resources'
import axios from 'axios'

function getLanguages(params, criteria) {
    return dispatch => {
        dispatch({
            type: 'LANGUAGES_FETCH_LANGUAGES_START'
        });
        
        new LanguageRestClient().getLanguages(params, criteria)
            .then(response => {
                dispatch({
                    type: 'LANGUAGES_FETCH_LANGUAGES_SUCCESS',
                    payload: {
                        languages: response.data.items.map((language) => ({
                            id: language.id,
                            name: language.nombre,
                            inactive: language.inactivo
                        }))
                    }
                });
            })
            .catch(error => {
                if (error instanceof axios.Cancel) {
                    // Nothing to do ...
                } else {
                    dispatch({
                        type: 'LANGUAGES_FETCH_LANGUAGES_FAILURE',
                    });
                }
            })
    }
}

export const LanguagesActions = {
    getLanguages,
}