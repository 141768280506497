import RestClient from 'http/RestClient'

export default class ExperienceEventRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(
            undefined,
            useCredentials,
            refreshContext,
        )
    }

    getExperienceEvents(params) {
		const url = `${this.baseURL}/public/experiencias/${params.experienceId}/fechas`
        
        return this.instance.get(url);
    }
    
    createEvent(params) {
        const url = `${this.baseURL}/secured/experiencias/${params.experienceId}/fechas`

        return this.instance.post(url, {
            fecha: params.dayAndHour,
            capacidad: params.peopleQuantity
        });
    }

	createEventWithFlexibleHours(params) {
        const url = `${this.baseURL}/secured/experiencias/${params.experienceId}/fechas/horarioflexible`

        return this.instance.post(url, {
            fecha: params.dayAndHour,
            capacidad: params.peopleQuantity,
			horaInicio: params.startTime,
			horaFin: params.endTime,
			// TODO: el campo "intervalo" por ahora está hardcodeado en el backend
			// intervalo: ""
        });
    }

    createEvents(params) {
        const url = `${this.baseURL}/secured/experiencias/${params.experienceId}/fechas/recurrencia`

        return this.instance.post(url, {
            fechaInicio: params.startDate,
            fechaFinal: params.finishDate,
            capacidad: params.peopleQuantity,
            diasSemana: params.recurrenceDays
        });
    }

    createEventsFlexibleHours(params) {
        const url = `${this.baseURL}/secured/experiencias/${params.experienceId}/fechas/recurrencia/horarioflexible`

        return this.instance.post(url, {
			horaInicio: params.startTime,
			horaFin: params.endTime,

            fechaInicio: params.startDate,
            fechaFinal: params.finishDate,
            capacidad: params.peopleQuantity,
            diasSemana: params.recurrenceDays
        });
    }

    updateEvent(params) {
        const url = `${this.baseURL}/secured/experiencias/${params.experienceId}/fechas/${params.dateId}`

        return this.instance.patch(url, {
            capacidad: params.editPeopleQuantity
        })
    }

    removeEvent(params) {
        const url = `${this.baseURL}/secured/experiencias/${params.experienceId}/fechas/${params.dateId}`

        return this.instance.delete(url)
    }

    removeAllEvents(params) {
        const url = `${this.baseURL}/secured/experiencias/${params.experienceId}/fechas`

        return this.instance.delete(url)
    }
}
