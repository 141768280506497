const defaultState = {
    message: '',
    type: 'success',
    showLeftSide: false,
    showBottomDrawer: false,
    visibilityWindow: false
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {

        case 'UI_ALERT_SET_SUCCESS':
            return Object.assign({}, state, {
                type: 'success',
                message: action.payload.message
            });

        case 'UI_ALERT_SET_INFO':
            return Object.assign({}, state, {
                type: 'info',
                message: action.payload.message
            });

        case 'UI_ALERT_SET_WARNING':
            return Object.assign({}, state, {
                type: 'warning',
                message: action.payload.message
            });

        case 'UI_ALERT_SET_ERROR':
            return Object.assign({}, state, {
                type: 'error',
                message: action.payload.error
            });

        case 'UI_ALERT_SET_LIKED':
            return Object.assign({}, state, {
                type: 'liked',
            });

        case 'UI_ALERT_CLEAR_MESSAGE':
            return Object.assign({}, state, {
                message: ''
            });

        case 'UI_TOGGLE_LEFT_DRAWER':
            return Object.assign({}, state, {
                showLeftSide: action.payload.showLeftSide
            });

        case 'UI_TOGGLE_BOTTOM_DRAWER':
            return Object.assign({}, state, {
                showBottomDrawer: action.payload.showBottomDrawer
            });

        case 'UI_SET_VISIBILITY_WINDOWS':
            return Object.assign({}, state, {
                visibilityWindow: action.payload.visibilityWindow
            });

        default:
            return state;
    }
}
