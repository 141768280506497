
const defaultState = {
	loading: true,
	sendLoading: false,
	userInfo: null,
	changePassSuccess: false,
	changePassLoading: false
};


export default function reducer(state = defaultState, action) {
	switch (action.type) {

		case 'USER_FETCH_PROFILE_INFO_START':
			return Object.assign({}, state, {
                loading: true,
                userInfo: null,
			})

		case 'USER_FETCH_PROFILE_INFO_SUCCESS':
			return Object.assign({}, state, {
				loading: false,
				userInfo: action.payload.userInfo
			})

		case 'USER_FETCH_PROFILE_INFO_FAILURE':
			return Object.assign({}, state, {
				loading: false,
			})

		case 'USER_SEND_INVITATION_START':
			return Object.assign({}, state, {
				sendLoading: true,
			})

		case 'USER_SEND_INVITATION_SUCCESS':
			return Object.assign({}, state, {
				sendLoading: false,
			})

		case 'USER_SEND_INVITATION_FAILURE':
			return Object.assign({}, state, {
				sendLoading: false,
			})

		case 'USER_CHANGE_PASSWORD_START':
			return Object.assign({}, state, {
				changePassLoading: true,
				changePassSuccess: false
			})

		case 'USER_CHANGE_PASSWORD_SUCCESS':
			return Object.assign({}, state, {
				changePassLoading: false,
				changePassSuccess: true
			})

		case 'USER_CHANGE_PASSWORD_FAILURE':
			return Object.assign({}, state, {
				changePassLoading: false,
				changePassSuccess: false,
			})

		default:
			return state;
	}
}
