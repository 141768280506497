import React, { useCallback, useRef } from 'react'
import { ConversationItem } from 'components'

const EMPTY_LOADER = ["", "", "", "", "", ""]

const ConversationResultPagination = React.memo((props) => {
	const {
		user,
		loading,
		loadingMoreResults,
		hasMore,
		page,
		results = [],
		getMoreResults,
	} = props

	const observer = useRef()

	const lastItemRef = useCallback(node => {
		if (loading) return
		if (observer.current) observer.current.disconnect()
		observer.current = new IntersectionObserver(entries => {
			if (entries[0].isIntersecting && hasMore) {
				let nextPage = page + 1
				getMoreResults(user?.type, nextPage)
			}
		})
		if (node) observer.current.observe(node)
	}, [loading, page, hasMore, getMoreResults])

	return (
		<>
			{ loading && !(results.length > 1) ?
				EMPTY_LOADER.map((item, index) =>
					<ConversationItem
						key={index}
						loading={true} />)
				:
				results.map((result, index) => {
					if (results.length === index + 1) {
						return <ConversationItem
							ref={lastItemRef}
							key={result.id}
							loading={loading}
							details={result}
							user={user}
						/>
					}
					return (
						<ConversationItem
							key={index}
							loading={loading}
							user={user}
							details={result}
						/>
					)
				})
			}

			{loadingMoreResults && hasMore &&
				EMPTY_LOADER.map((item, index) =>
					<ConversationItem
						key={index}
						loading={true} />)}
		</>
	)
})

export default ConversationResultPagination