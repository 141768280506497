import JWT from 'services/jwt';

function initializeContext(impersonateToken) {
    return async dispatch => {
        // If we initialize context as impersonated user
        // we store the given token and remove the access token
        // that represent a normal session access token
        if (impersonateToken) {
            localStorage.setItem('impersonateToken', impersonateToken)
            localStorage.removeItem('accessToken')
        }

        const accessToken = impersonateToken || localStorage.getItem('accessToken')

        // Dispatch to initialize the current context
        // based on the token or null if it not exists
        // Also if it's an impersonate session we mark the context
        // as impersonated
        dispatch(
            setContext(
                accessToken ? JWT.parseToken(accessToken) : null, // The parsed token or null
                impersonateToken ? true : null // Impersonated session flag
            )
        )
    }
}

function setContext(context, impersonated) {
    return async (dispatch, getState) => {
        const {
            user,
            impersonated: currentImpersonatedState 
        } = getState().context

        if (!user) {
            dispatch({
                type: 'CONTEXT_SET',
                payload: {
                    user: context ? context : null,
                    impersonated: impersonated ? impersonated : currentImpersonatedState,
                }
            })
        }
    }
}

export const ContextActions = {
    initializeContext,
    setContext,
}