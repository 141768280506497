import React from 'react'
import { Input } from 'antd'

const AnTextArea = React.memo((props) => {
    const {
        placeholder,
        onChange,
        value,
        name,
        disabled,
        className,
    } = props

    return (
        <Input.TextArea
            className={className}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            disabled={disabled}

            { ...props }
        />
    )
})

export default AnTextArea