import React, { useCallback } from 'react'
import { Select, Spin, Space, Typography } from 'antd'

const AnSelect = React.memo((props) => {
    const {
        id,
        className,
        shape,
        value,
        optionFilterProp,
        onChange,
        fetchOptions,
        options,
        fetching,
        placeholder,
        onBlur,
    } = props

    const onSelect = useCallback((id, option) => {
        onChange({
            id,
            name: option.children,
        })
    }, [onChange])

    const onSearch = useCallback(name => {
        if (name.length > 2) {
            fetchOptions(name)
        } else if (name.length < 1) {
            fetchOptions()
        }
    }, [fetchOptions])

    const onFocus = useCallback(() => {
        fetchOptions()
    }, [fetchOptions])

    return (
        <div id={id} style={{ width: '100%' }}>
            <Select
                className={`${className} ${shape}`}
                showSearch
                placeholder={placeholder}
                optionFilterProp={optionFilterProp}
                onSearch={onSearch}
                onSelect={onSelect}
                onFocus={onFocus}
                onBlur={onBlur}
                notFoundContent={
                    fetching ? (
                        <Spin size="small" />
                    ) : (
                        <Typography.Text type='secondary'>No hay resultados</Typography.Text>
                    )
                }
                value={value?.id || null}
            >

                {!fetching && value?.id && options.length < 1 && (
                    <Select.Option value={value.id}>
                        <Space>
                            <Typography>
                                {value?.name || value?.id}
                            </Typography>

                            {(value?.inactive && (
                                <Typography.Text
                                    type="danger"
                                >
                                    ( Inactivo )
                                </Typography.Text>
                            ))}
                        </Space>
                    </Select.Option>   
                )}
                
                {options.map(result => (
                    <Select.Option key={result.id} value={result.id}>
                        <Space>
                            <Typography>
                                {result?.name}
                            </Typography>

                            {(result?.inactive && (
                                <Typography.Text
                                    type="danger"
                                >
                                    ( Inactivo )
                                </Typography.Text>
                            ))}
                        </Space>
                    </Select.Option>
                ))}
            </Select>
        </div>
    )
})

AnSelect.defaultProps = {
    className: 'an-select',
    placeholder: 'Seleccionar',
    optionFilterProp: 'children',
    options: [],
    fetchOptions: () => {}
}

export default AnSelect
