import React, { useState, useEffect } from 'react'
import { Form, Tooltip, Typography } from 'antd'
import { AnButtonGradient, AnInput, AnInputPhoneNumber, AnTextArea } from 'components/an'
import { withQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params'
import { ScrollToTop, Loader } from 'components';
import Animate from 'rc-animate'

const PostulationUserNewPostulate = React.memo((props) => {
    const {
        user,
        isMobile,
        image,
        history,
        sendNewPostulation,
        loadingPostulation,
        postulationSuccess,
        query,
        setQuery,
    } = props

    const [show, setShow] = useState(isMobile ? false : true)

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 500)
    }, [setShow])

    useEffect(() => {
        if (postulationSuccess) {
            history.push(`/postulation/user/confirm`)
        }
    }, [postulationSuccess, history])

    useEffect(() => {
        if (query.step !== "1" &&
            query.step !== "2") {
            setQuery({ step: "1" })
        }
    }, [query, setQuery])

    const onFinish = values => {
        sendNewPostulation(values, query.type)
    }

    return (
        <>
            <ScrollToTop behavior="auto" />

            <Loader loading={!show} />

            <Animate
                transitionName="zoom"
                transitionAppear
            >
                {(show && (
                    <div className="login-screen-iwrap">
                        <div className='login-img'>
                            <img
                                src={image}
                                alt='Login Spiderweb'
                            />
                        </div>

                        <div className='login-comp-container'>
                            <h1
                                style={{
                                    margin: 0
                                }}>Registro</h1>

                            <Typography.Text
                                style={{
                                    color: '#aaa',
                                    marginBottom: 15
                                }}
                            >
                                Solicitud para registro (Promotor o Proveedor)
                            </Typography.Text>

                            <div
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Form
                                    name="register"
                                    onFinish={onFinish}
                                    scrollToFirstError
                                    initialValues={{
                                        email: user.email || '',
                                        parentCode: query.parentCode
                                    }}
                                    size='large'
                                >
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                type: "email",
                                                message: "No es un correo válido"
                                            },
                                            {
                                                required: true,
                                                message: "Ingresa un correo válido"
                                            },
                                        ]}
                                    >
                                        <AnInput
                                            placeholder='Email'
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="emailRepeat"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Confirma el correo ingresado"
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (!value || getFieldValue("email") === value) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject(
                                                        "Los email que ingresaste no coinciden"
                                                    );
                                                }
                                            })
                                        ]}
                                    >
                                        <AnInput
                                            placeholder='Repite el email'
                                            autocomplete="off"
                                            noPaste
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="name"
                                        validateFirst
                                        rules={[
                                            {
                                                required: true,
                                                message: "Ingresá tu nombre"
                                            },
                                            {
                                                pattern: /^[A-Za-z\s]+$/,
                                                message: "Sólo caracteres alfabéticos"
                                            }
                                        ]}
                                    >
                                        <AnInput
                                            placeholder='Nombre'
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="surname"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Ingresá tu apellido'
                                            },
                                            {
                                                pattern: /^[A-Za-z\s]+$/,
                                                message: "Sólo caracteres alfabéticos"
                                            }
                                        ]}
                                    >
                                        <AnInput
                                            placeholder='Apellido'
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="phone"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'El teléfono es requerido',
                                            },
                                            () => ({
                                                validator(rule, value) {
                                                    if (!value) {
                                                        return Promise.reject()
                                                    }

                                                    if (value.toString().length === 10) {
                                                        return Promise.resolve()
                                                    }

                                                    return Promise.reject(
                                                        "Ingresá un número de teléfono válido"
                                                    );
                                                }
                                            })
                                        ]}
                                    >
                                        <AnInputPhoneNumber
                                            placeholder='Número de teléfono Ej: 11 2222 2222'
                                            shape='round'
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        noStyle
                                    >
                                        <Form.Item
                                            name="parentCode"
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                        >
                                            <AnInput
                                                placeholder='Código de Promotor ( Opcional )'
                                                suffix={
                                                    <Tooltip title="Código de Promotor o Nic del Promotor que te invitó (Opcional)">
                                                        <ion-icon
                                                            style={{
                                                                color: '#C3125A',
                                                                fontSize: 20
                                                            }}
                                                            name="information-circle-outline"></ion-icon>
                                                    </Tooltip>
                                                }
                                            />
                                        </Form.Item>

                                    </Form.Item>

                                    <Form.Item
                                        noStyle
                                    >
                                        <Form.Item
                                            name="moreInfo"
                                        >
                                            <AnTextArea
                                                className="an-textarea round"
                                                autoSize={{
                                                    minRows: 6
                                                }}
                                                placeholder='Queremos saber un poco más de vos..
                                                    ¿A que te dedicas?
                                                    ¿Tenes página web? 
                                                    ¿Usas Redes Sociales? ¿Cuales?'
                                            />
                                        </Form.Item>
                                    </Form.Item>

                                    <Form.Item
                                        noStyle
                                    >
                                        <AnButtonGradient
                                            block
                                            type="primary"
                                            shape='round'
                                            htmlType="submit"
                                            loading={loadingPostulation}
                                            style={{
                                                marginTop: 10
                                            }}
                                        >
                                            Enviar solicitud
                                            </AnButtonGradient>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                ))}
            </Animate>
        </>
    )
})

export default withQueryParams({
    type: withDefault(NumberParam, 1), // Option selected
    parentCode: withDefault(StringParam, ""), // Promotor's code when share
}, PostulationUserNewPostulate)