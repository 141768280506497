const defaultState = {
	loading: true,
	userBookingsList: [],
	pageCount: 0,
	totalItems: 0,
	page: 0,
	hasMore: true,
	loadingMoreResults: false,
};


export default function reducer(state = defaultState, action) {
	switch (action.type) {

		case 'USER_BOOKINGS_GET_BOOKINGS_START':
			return Object.assign({}, state, {
				loading: true,
			})

		case 'USER_BOOKINGS_GET_BOOKINGS_SUCCESS':
			return Object.assign({}, state, {
				loading: false,
				userBookingsList: [].concat(action.payload.userBookingsList),
				pageCount: action.payload.pageCount,
				totalItems: action.payload.totalItems,
				page: action.payload.page,
				hasMore: true,
			})

		case 'USER_BOOKINGS_GET_BOOKINGS_FAILURE':
			return Object.assign({}, state, {
				loading: true,
			})

		case 'USER_BOOKINGS_BOOKINGS_MORE_RESULTS_START':
			return Object.assign({}, state, {
				loadingMoreResults: true
			})

		case 'USER_BOOKINGS_BOOKINGS_MORE_RESULTS_SUCCESS':
			return Object.assign({}, state, {
				userBookingsList: state.userBookingsList
					.concat(action.payload.userBookingsList),
				pageCount: action.payload.pageCount,
				totalItems: action.payload.totalItems,
				page: action.payload.page,
				loadingMoreResults: false
			})

		case 'USER_BOOKINGS_BOOKINGS_MORE_RESULTS_HAS_NO_MORE':
			return Object.assign({}, state, {
				hasMore: false,
				loadingMoreResults: false
			})

		case 'USER_BOOKINGS_BOOKINGS_MORE_RESULTS_FAILURE':
			return Object.assign({}, state, {
				loadingMoreResults: false
			})

		default:
			return state;
	}
}