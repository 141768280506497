import React from 'react'
import { Link } from 'react-router-dom'
import { Typography } from 'antd'

const RequiredSessionMessage = React.memo((props) => {
    const {
        description,
        toButtonLabel,
        to
    } = props

    return (
        <div
            style={{
                padding: 15
            }}
        >
            <Typography style={{ marginBottom: 5 }}>{description}</Typography>
            
            <Link to={to}>
                {toButtonLabel}
            </Link>
        </div>
    )
})

RequiredSessionMessage.defaultProps = {
    description: 'Debes iniciar sesión para acceder a esta pantalla',
    toButtonLabel: 'Iniciar sesión',
    to: '/login'
}

export default RequiredSessionMessage

