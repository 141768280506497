const defaultState = {
	signInFail: false,
	loadingEmailLink: false,
	loadingList: {
		google: false,
		facebook: false,
		email: false
	},
	sendEmailSuccess: false,
	signInMailSuccess: false,
	actions: {
		resetPassword: {
			loading: false,
			success: false,
			error: false,
		},
	}
};

export default function reducer(state = defaultState, action) {
	switch (action.type) {
		case 'SEND_EMAIL_LOADING':
			return Object.assign({}, state, {
				loadingEmailLink: true,
				signInFail: false,
				sendEmailSuccess: false
			})

		case 'SEND_EMAIL_SUCCESS':
			return Object.assign({}, state, {
				loadingEmailLink: false,
				sendEmailSuccess: true,
				signInFail: false
			})

		case 'LOGIN_USER_SUCCESS':
			return Object.assign({}, state, {
				loadingList: {
					loadingList: action.payload.loadingList
				}
			})

		case 'LOGIN_USER_FAILURE':
			return Object.assign({}, state, {
				loadingEmailLink: false,
				sendEmailSuccess: false,
				loadingList: {
					loadingList: defaultState.loadingList
				}
			})

		case 'LOADING_SET_LOADING':
			return Object.assign({}, state, {
				loadingList: {
					...state.loadingList,
					...action.payload.loadingList
				}
			})

		case 'SIGN_IN_EMAIL_START':
			return Object.assign({}, state, {
				signInFail: false,
				signInMailSuccess: false,
				loadingEmailLink: true
			})

		case 'SIGN_IN_EMAIL_SUCCESS':
			return Object.assign({}, state, {
				signInFail: false,
				signInMailSuccess: true,
				loadingEmailLink: false
			})

		case 'SIGN_IN_EMAIL_FAILED':
			return Object.assign({}, state, {
				signInFail: true,
				signInMailSuccess: false,
				loadingEmailLink: false
			})

		case 'SESSION_SEND_EMAIL_RESET_PASSWORD_START':
			return Object.assign({}, state, {
				loadingEmailLink: true,
				sendEmailSuccess: false
			})

		case 'SESSION_SEND_EMAIL_RESET_PASSWORD_SUCCESS':
			return Object.assign({}, state, {
				loadingEmailLink: false,
				sendEmailSuccess: true
			})

		case 'SESSION_SEND_EMAIL_RESET_PASSWORD_FAILED':
			return Object.assign({}, state, {
				loadingEmailLink: false,
				sendEmailSuccess: false
			})

		case 'SESSION_RESET_PASSWORD_START':
			return Object.assign({}, state, {
				actions: Object.assign({}, state.actions, {
					resetPassword: {
						loading: true,
						error: false,
						success: false,
					}
				})
			})

		case 'SESSION_RESET_PASSWORD_SUCCESS':
			return Object.assign({}, state, {
				actions: Object.assign({}, state.actions, {
					resetPassword: {
						loading: false,
						error: false,
						success: true,
					}
				})
			})

		case 'SESSION_RESET_PASSWORD_FAILED':
			return Object.assign({}, state, {
				actions: Object.assign({}, state.actions, {
					resetPassword: {
						loading: false,
						error: true,
						success: false,
					}
				})
			})
			
		case 'SESSION_CLEAR_STATE':
			return Object.assign({}, state, {
				signInFail: false,
				loadingEmailLink: false,
				sendEmailSuccess: false,
				loadingList: {
					google: false,
					facebook: false,
					email: false
				},
			})

		default:
			return state;
	}
}
