import React from 'react'
import { connect } from 'react-redux'
import { UIActions } from 'redux/actions'
import { Drawer } from 'antd'


const BottomDrawer = React.memo((props) => {
	const {
		showBottomDrawer,
		toggleBottomDrawer,
		height = 256,
		children
	} = props

	return (
		<Drawer
			placement='bottom'
			closable={false}
			onClose={() => toggleBottomDrawer(false)}
			visible={showBottomDrawer}
			key='bottom'
			height={height}
			keyboard
		>
			{children}
		</Drawer>
	)
})


const mapStateToProps = (state) => ({
	showBottomDrawer: state.ui.showBottomDrawer
})

const mapDispatchToProps = (dispatch) => ({
	toggleBottomDrawer: (bool) => dispatch(UIActions.toggleBottomDrawer(bool)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BottomDrawer)