import React from 'react'
import ReactDOM from 'react-dom'
import Store from 'redux/index'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from "react-router-dom"
import { QueryParamProvider } from 'use-query-params'

import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <App />
        </QueryParamProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
