import React from 'react'
import logo from 'assets/images/logo.png'

const Splash = React.memo((props) => {
	return (
		<main
			style={{
				display: 'flex',
				alignItems: 'center',
				backgroundColor: '#fff',
				justifyContent: 'center',
				width: '100vw',
				height: '100vh'
			}}>
			<img
				src={logo}
				style={{
					width: '40%'
				}}
				alt='logo Spiderweb' />
		</main>
	)
})

export default Splash