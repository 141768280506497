import "intersection-observer";
import "firebase/auth";
import "firebase/storage";
import "moment/locale/es";
import "moment-duration-format";
import "react-aspect-ratio/aspect-ratio.css";
// -------

import React, { useEffect, useState } from "react";
import { Router } from "routes";
import { connect } from "react-redux";
import { ContextActions, MessagesActions, UIActions } from "redux/actions";
import firebase from "firebase/app";
import { Splash, Loader } from "components";
import { useImpersonate } from "hooks/useImpersonate";
import { Modal } from "antd";
import auth from "session/api";
import moment from "moment";

import "./App.less"; // Import all app styles
import { useInterval } from "hooks/useInterval";

/**
 * GOOGLE ANALYTICS
 *  */
import ReactGA from "react-ga4";
const TRACKING_ID = process.env.REACT_APP_TRACKING_ID; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

/**
 * GOOGLE ANALYTICS
 *  */

// Set the default locale for
// moment
moment.locale("es");

console.warn("env", process.env.NODE_ENV);

const App = React.memo((props) => {
  const {
    initializeContext,
    setContext,
    loading,
    impersonated,
    user,
    setVisibilityWindowUI,
    visibilityWindow,

    getMessagesBadge,
    clearBadge,
  } = props;

  window.onfocus = function () {
    setVisibilityWindowUI(true);
  };

  window.onblur = function () {
    setVisibilityWindowUI(false);
  };

  const { impersonateToken } = useImpersonate();

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  // Effect just to initialize our
  // context, this effect fires just
  // once, after context initialize, we remove
  // the listener
  useEffect(() => {
    const unsuscribe = firebase.auth().onAuthStateChanged(async () => {
      // When firebse auth state is ready, we can
      // initialize our context
      initializeContext(impersonateToken);

      // We can unsuscribe, we don't need the
      // listener anymore after the context
      // initialation
      unsuscribe();
    });
  }, [initializeContext]);

  // Effect just to start anonimous
  // session when user is null and
  // with loading on false that mean the initial context
  // was initialized
  useEffect(() => {
    if (!loading && !user) {
      auth.signInAnonymously();
    }
  }, [loading, user]);

  useEffect(() => {
    const unsuscribe = firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser?.isAnonymous) {
        // We set user context as anonimous
        // role
        setContext({
          sub: firebaseUser.uid,
          role: "Anonimous",
        });
      }
    });

    return () => unsuscribe();
  }, [setContext]);

  // This effect is just to listen when a impersonated
  // session starts and when it ends
  useEffect(() => {
    // We show modal message when user
    // impersonation starts
    if (impersonated) {
      // We check if there is a firebase session, if it exists
      // we delete it because impersonation sessions should not
      // have an active firebase session
      if (auth.currentUser()) {
        auth.signOut();
      }

      // Show the message
      setShowModal(true);
      setModalMessage(
        `Has iniciado sesión desde el administrador de Spiderweb impersonando al usuario ${(
          user.unique_name || ""
        ).toUpperCase()}.\n\nPara finalizar puedes cerrar la sesión del mismo modo que lo harías en una sesión normal`
      );
    }

    // We show modal message when user
    // impersonation ends
    if (impersonated === false) {
      // Show the message
      setShowModal(true);
      setModalMessage(
        "La sesión de impersonación ha finalizado.\n\nSi deseas seguir impersonando a este usuario debes iniciar una nueva sesión desde el administrador"
      );
    }
  }, [impersonated]);

  // Fetch badge first render
  useEffect(() => {
    if (user?.role !== "Anonimous") {
      getMessagesBadge();
    } else {
      clearBadge();
    }
  }, [getMessagesBadge, clearBadge, user]);

  // Polling messages badge every 30 seconds
  useInterval(() => {
    if (user?.role !== "Anonimous" && visibilityWindow) {
      getMessagesBadge();
    }
  }, 30000);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  if (loading) {
    return <Splash />;
  } else if (!user) {
    return <Loader loading={true} title="Aguarde unos instantes ..." />;
  } else {
    return (
      <>
        <Router impersonated={user && impersonated} />

        <Modal
          className="impersonated-session-modal-message"
          centered
          closable={false}
          title="Atención"
          visible={showModal}
          okText="Entendido"
          transitionName="zoom"
          cancelButtonProps={{
            style: { display: "none" },
          }}
          okButtonProps={{
            shape: "round",
          }}
          onOk={() => {
            setShowModal(false);
          }}
        >
          {modalMessage}
        </Modal>
      </>
    );
  }
});

const mapStateToProps = (state) => ({
  loading: state.context.loading,
  impersonated: state.context.impersonated,
  user: state.context.user,

  visibilityWindow: state.ui.visibilityWindow,
});

const mapDispatchToProps = (dispatch) => ({
  initializeContext: (impersonateToken) =>
    dispatch(ContextActions.initializeContext(impersonateToken)),
  setContext: (context) => dispatch(ContextActions.setContext(context)),
  //messages badge
  getMessagesBadge: () => dispatch(MessagesActions.getMessagesBadge()),
  clearBadge: () => dispatch(MessagesActions.clearBadge()),

  setVisibilityWindowUI: (bool) =>
    dispatch(UIActions.setVisibilityWindowUI(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
