import React from 'react'
import { Button } from 'antd'

const AnButton = React.memo((props) => {
	const {
		ghost,
		size,
		children,
        shape,
        block,
		style,
		type,
		icon,
		onClick,
		loading,
		disabled,
		className,
	} = props

	return (
		<Button
			type={type}
			size={size}
			icon={icon}
			ghost={ghost}
            shape={shape}
            block={block}
			onClick={onClick}
			loading={loading}
			disabled={disabled}
			className={className}
			style={{
				fontWeight: '500',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: 32,
				...style
			}}>
			{children}
		</Button>
	)
})

export default AnButton