import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import {
    AnButtonGradient,
    AnInput,
    AnButton,
    AnTextArea,
    AnLabelForm,
    AnTagSelect,
    AnSlider,
    AnSelect,
    AnSelectArray,
    AnCheckbox,
} from "components/an";

import { Form, Divider } from "antd";

import {
    UserExperiencesActions,
    ExperienceActions,
    CategoriesActions,
    LocalitiesActions,
    LanguagesActions,
} from "redux/actions";

import { ScrollToTop, Loader } from "components";

const EditStepOne = React.memo((props) => {
    const {
        saving,
        loading,
        createExperience,
        experience,
        updateExperienceDetails,
        updateDetailsFormFailed,

        isCreation,

        clearState,

        //
        getCategories,
        categories,
        fetchingCategories,

        //
        getLocalities,
        locations,
        fetchingLocations,

        //
        getLanguages,
        languages,
        fetchingLanguages,
    } = props;

    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        if (isCreation) {
            createExperience(values);
        } else {
            updateExperienceDetails(values);
        }
    };

    const onFinishFailed = () => {
        updateDetailsFormFailed();
    };

    useEffect(() => {
        if (experience.id) {
            form.setFieldsValue({
                id: experience.id,
                title: experience.title,
                category: experience.category,
                locationStreet: experience.direction?.street,
                locationStreetNumber: experience.direction?.number,
                locationFloor: experience.direction?.depto,
                locationLocality: {
                    id: experience.direction?.locate?.id,
                    name: experience.direction?.locate?.nombre,
                    inactive: experience.direction?.locate?.inactivo,
                },
                direction: experience.direction,
                price: experience.price,
                bookingType: experience.bookingType,
                externalUrl: experience.externalUrl,
                details: experience.details,
                detailsEn: experience.detailsEn,
                languages: experience.languages,
                peopleQuantity: experience.peopleQuantity,
                duration: experience.duration,
                tolerance: experience.tolerance,
                tags: experience.tags,
                timeZoneId: experience.timeZoneId,
                flexibleHours: experience.flexibleHours ? "flexibleHours" : null,
                bookingRequires: experience.bookingRequires,
            });
        }
    }, [experience]);

    return (
        <>
            <ScrollToTop />

            <Loader loading={loading && !isCreation} />

            {(!loading || isCreation) && (
                <div
                    style={{
                        padding: "20px 15px", // TODO - This must be a global class as container of sub main content
                        position: "relative",
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            right: "15px",
                            fontSize: "12px",
                            top: "8px",
                            color: "#a1a1a1",
                        }}
                    >
                        * Campo obligatorio
                    </div>

                    <Form
                        form={form}
                        className="an-form"
                        validateTrigger={["onBlur", "onChange"]}
                        size="large"
                        name="createExperience"
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        scrollToFirstError
                    >
                        <Form.Item>
                            <AnLabelForm label="Título *" />

                            <Form.Item
                                name="title"
                                noStyle
                                rules={[
                                    {
                                        required: true,
                                        message: "La experiencia debe tener título",
                                    },
                                ]}
                            >
                                <AnInput className="an-input round" placeholder="Título de la experiencia" />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <AnLabelForm
                                label="Categoría *"
                                tooltip
                                tooltipText="Defina una categoría que describa el tipo de experiencia"
                            />

                            <Form.Item
                                name="category"
                                valuePropName="value"
                                noStyle
                                rules={[
                                    {
                                        required: true,
                                        message: "La categoría es requerida",
                                    },
                                ]}
                            >
                                <AnSelect
                                    className="an-select round"
                                    placeholder="Seleccionar categoría ..."
                                    options={categories}
                                    fetching={fetchingCategories}
                                    fetchOptions={(name) => getCategories(null, { name })}
                                />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <AnLabelForm
                                label="Etiquetas"
                                tooltip
                                tooltipText="Defina las etiquetas que permitan establecer con mas precisión las busquedas que realizan los clientes"
                            />

                            <Form.Item name="tags" noStyle>
                                <AnTagSelect wrapperClassName="an-tag-select" className="an-select round" />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <AnLabelForm label="Ubicación (Localidad) *" />

                            <Form.Item
                                noStyle
                                name="locationLocality"
                                valuePropName="value"
                                rules={[
                                    {
                                        required: true,
                                        message: "La localidad es requerida",
                                    },
                                ]}
                            >
                                <AnSelect
                                    className="an-select round"
                                    placeholder="Seleccionar localidad ..."
                                    options={locations}
                                    fetching={fetchingLocations}
                                    fetchOptions={(name) => getLocalities(null, { name })}
                                />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <AnLabelForm label="Ubicación (Calle)" />

                            <Form.Item noStyle name="locationStreet">
                                <AnInput className="an-input round" placeholder="Ej: Ayacucho" />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <AnLabelForm label="Ubicación (Número)" />

                            <Form.Item noStyle name="locationStreetNumber">
                                <AnInput className="an-input round" placeholder="Ej: 4321" />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <AnLabelForm label="Ubicación (Departamento)" />

                            <Form.Item noStyle name="locationFloor">
                                <AnInput className="an-input round" placeholder="Ej: 6C - opcional" />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <AnLabelForm
                                label="Idioma hablado *"
                                tooltip
                                tooltipText="Defina los idiomas hablados en la experiencia"
                            />

                            <Form.Item
                                name="languages"
                                valuePropName="value"
                                noStyle
                                rules={[
                                    {
                                        required: true,
                                        message: "El idioma es requerido",
                                    },
                                ]}
                            >
                                <AnSelectArray
                                    wrapperClassName="an-tag-select"
                                    className="an-select round"
                                    placeholder="Seleccionar idioma ..."
                                    options={languages}
                                    fetching={fetchingLanguages}
                                    fetchOptions={(name) => getLanguages(null, { name })}
                                />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <AnLabelForm label="Precio *" tooltip tooltipText="Valor de la experiencia en ARS" />

                            <Form.Item
                                name="price"
                                noStyle
                                rules={[
                                    {
                                        required: true,
                                        message: "El precio es requerido",
                                    },
                                ]}
                            >
                                <AnInput
                                    className="an-input round"
                                    type="number"
                                    placeholder="Ej: 1080"
                                    prefix={<span>$</span>}
                                />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <AnLabelForm
                                label="Cantidad de personas *"
                                tooltip
                                tooltipText="Defina la cantidad de personas incluidas por reserva"
                            />

                            <Form.Item
                                name="peopleQuantity"
                                noStyle
                                rules={[
                                    {
                                        required: true,
                                        message: "La cantidad de personas es requerida",
                                    },
                                ]}
                            >
                                <AnInput className="an-input round" placeholder="0" />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <AnLabelForm
                                label="Duración *"
                                tooltip
                                tooltipText="Defina la duración aproximada de la experiencia"
                            />

                            <Form.Item
                                name="duration"
                                noStyle
                                style={{
                                    padding: 10,
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: "La duración es requerida",
                                    },
                                ]}
                            >
                                <AnSlider />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <AnLabelForm
                                label="Tolerancia *"
                                tooltip
                                tooltipText="Defina el tiempo mínimo con el que se podrá contratar la experiencia"
                            />

                            <Form.Item
                                name="tolerance"
                                noStyle
                                style={{
                                    padding: 10,
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: "La tolerancia es requerida",
                                    },
                                ]}
                            >
                                <AnSlider />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <AnLabelForm
                                label="Configuración de horarios"
                                tooltip
                                tooltipText="Defina si es posible crear eventos con horarios flexibles sobre esta experiencia"
                            />

                            <Form.Item
                                name="flexibleHours"
                                noStyle
                                style={{
                                    padding: 10,
                                }}
                            >
                                <AnCheckbox
                                    className="an-checkbox round"
                                    options={[{ id: "flexibleHours", name: "Horarios flexibles" }]}
                                />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <AnLabelForm
                                label="Campos requeridos de reserva"
                                tooltip
                                tooltipText="Al habilitar estos campos se solicitará al usuario que los complete, teniendo más información sobre la reserva"
                            />

                            <Form.Item name="bookingRequires" valuePropName="value" noStyle>
                                <AnCheckbox
                                    className="an-checkbox round"
                                    options={[
                                        { id: "requireWhereToPickup", name: "Requerir dónde recoger" },
                                        { id: "requireWhereToGo", name: "Requerir dónde llevar" },
                                        { id: "requireFlightNumber", name: "Requerir Número de Vuelo" },
                                    ]}
                                />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <AnLabelForm
                                label="Forma de Cobro *"
                                tooltip
                                tooltipText="La forma de cobro define los requisitos de pago al momento de realizar la reserva"
                            />

                            <Form.Item
                                name="bookingType"
                                valuePropName="value"
                                noStyle
                                rules={[
                                    {
                                        required: true,
                                        message: "La forma de cobro es requerida",
                                    },
                                ]}
                            >
                                <AnCheckbox
                                    className="an-checkbox round"
                                    options={[
                                        { id: "complete", name: "Requerir pago completo al reservar" },
                                        { id: "withPay", name: "Requerir una seña al reservar, y el resto en destino" },
                                        { id: "withoutPay", name: "Permitir pago total en destino" },
                                    ]}
                                />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <AnLabelForm
                                label="Enlace externo"
                                tooltip
                                tooltipText="El enlace externo se refiere a si la experiencia se visualiza sólo en el listado y al ver su detalle lo dirige a dicho enlace en lugar de en Spiderweb. En caso de existir enlace externo el manejo del calendario de eventos y la realización de la reserva se realizarán desde el sitio web externo"
                            />

                            <Form.Item name="externalUrl" valuePropName="value" noStyle>
                                <AnInput className="an-input round" placeholder="Ej: https://misitio.com" />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <AnLabelForm label="Descripción (Es) *" />

                            <Form.Item
                                noStyle
                                name="details"
                                rules={[
                                    {
                                        required: true,
                                        message: "La descripción en español es requerida",
                                    },
                                ]}
                            >
                                <AnTextArea
                                    className="an-textarea round"
                                    autoSize={{
                                        minRows: 2,
                                    }}
                                    placeholder="Agregar descripción ..."
                                />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <Form.Item>
                                <AnLabelForm label="Descripción (En)" />

                                <Form.Item noStyle name="detailsEn">
                                    <AnTextArea
                                        className="an-textarea round"
                                        autoSize={{
                                            minRows: 2,
                                        }}
                                        placeholder="Agregar Descripción..."
                                    />
                                </Form.Item>
                            </Form.Item>
                        </Form.Item>

                        <Form.Item name="id" noStyle>
                            <AnInput type="hidden" />
                        </Form.Item>

                        <Form.Item name="timeZoneId" noStyle>
                            <AnInput type="hidden" />
                        </Form.Item>

                        <Divider dashed />

                        <Form.Item shouldUpdate={true} noStyle>
                            {() => (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                    }}
                                >
                                    <AnButton
                                        ghost
                                        onClick={() => {
                                            history.push("/user/experiences");
                                        }}
                                        type="primary"
                                        shape="round"
                                        size="large"
                                        style={{
                                            width: "50%",
                                            marginRight: "5px",
                                        }}
                                    >
                                        Volver
                                    </AnButton>

                                    {console.warn("title", form.getFieldsError())}

                                    <AnButtonGradient
                                        htmlType="submit"
                                        type="primary"
                                        shape="round"
                                        size="large"
                                        loading={saving}
                                        style={{
                                            width: "50%",
                                            marginLeft: "5px",
                                        }}
                                    >
                                        {isCreation ? "Crear y seguir" : "Siguiente"}
                                    </AnButtonGradient>
                                </div>
                            )}
                        </Form.Item>
                    </Form>
                </div>
            )}
        </>
    );
});

const mapStateToProps = (state) => ({
    categories: state.categories.data,
    fetchingCategories: state.categories.loading,
    locations: state.localities.data,
    fetchingLocations: state.localities.loading,
    languages: state.languages.data,
    fetchingLanguages: state.languages.loading,
});

const mapDispatchToProps = (dispatch) => ({
    createExperience: (values) => dispatch(UserExperiencesActions.createExperience(values)),
    updateExperienceDetails: (values) => dispatch(UserExperiencesActions.updateExperienceDetails(values)),
    updateDetailsFormFailed: () => dispatch(UserExperiencesActions.updateDetailsFormFailed()),
    clearState: () => dispatch(ExperienceActions.reset()),

    // TODO
    getCategories: (params, criteria) => dispatch(CategoriesActions.getCategories(params, criteria)),
    getLocalities: (params, criteria) => dispatch(LocalitiesActions.getLocalities(params, criteria)),
    getLanguages: (params, criteria) => dispatch(LanguagesActions.getLanguages(params, criteria)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditStepOne);
