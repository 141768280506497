import React, { useEffect, useCallback } from 'react'
import { Tooltip, Typography } from 'antd'
import ContentLoader from 'react-content-loader'
import { connect } from 'react-redux'
import './style.css'
import { UserStatsActions } from 'redux/actions'
import { Link } from 'react-router-dom'
import { PageHeader } from 'components'
import { AnDayPicker } from 'components/an'

const UserPromotorStats = React.memo((props) => {
    const {
        user,
        loading,
        promotorStatsList,
        getPromotorStats,
        dateTo,
        setDateTo,
        dateFrom,
        setDateFrom,
    } = props

    useEffect(() => {
        if (user) {
            getPromotorStats(user.sub)
        }
    }, [])
    //handlers

    const handleDateFromSelect = useCallback((e) => {
        setDateFrom(new Date(e))
        getPromotorStats(user.sub)
    })

    const handleDateToSelect = useCallback((e) => {
        setDateTo(new Date(e))
        getPromotorStats(user.sub)
    })

    return (
        <>
            <div className="main">
                <PageHeader
                    title="Ingresos como Promotor"
                    subtitle="Detalle de tu actividad como Promotor"
                />

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        margin: '10px 15px 0px'
                    }}
                >
                    <AnDayPicker
                        className='stats-day-picker'
                        title='Seleccionar fecha "Desde"'
                        value={dateFrom}
                        withIcon={false}
                        onChange={handleDateFromSelect}
                        dayPickerProps={{
                            disabledDays: [
                                {
                                    after: new Date(dateTo)
                                }
                            ]
                        }}
                    />
                    -
                    <AnDayPicker
                        className='stats-day-picker'
                        title='Seleccionar fecha "Hasta"'
                        value={dateTo}
                        withIcon={false}
                        onChange={handleDateToSelect}
                        dayPickerProps={{
                            disabledDays: [
                                {
                                    before: new Date(dateFrom),
                                    after: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
                                }
                            ]
                        }}
                    />
                </div>
                <div
                    className='stats-container'>
                    {loading ?
                        (<ContentLoader
                            height={440}
                            style={{
                                width: '100%',
                            }}>
                            <rect x="5%" y="0" rx='8px' ry='8px' width="42.5%" height="100px" />
                            <rect x="52.5%" y="0" rx='8px' ry='8px' width="42.5%" height="100px" />
                            <rect x="5%" y="110px" rx='8px' ry='8px' width="42.5%" height="100px" />
                            <rect x="52.5%" y="110px" rx='8px' ry='8px' width="42.5%" height="100px" />
                            <rect x="5%" y="220px" rx='8px' ry='8px' width="42.5%" height="100px" />
                            <rect x="52.5%" y="220px" rx='8px' ry='8px' width="42.5%" height="100px" />
                            <rect x="5%" y="330px" rx='8px' ry='8px' width="42.5%" height="100px" />
                        </ContentLoader>)
                        :
                        <div className='user-promotor-report-info-container'>

                            <div className='user-promotor-report-info stat1'>
                                <Link
                                    to='/user/promotor/share/own'
                                >
                                    <h2>
                                        {typeof promotorStatsList?.cantVistasPromocionadasPropias === 'number' ?
                                            `${promotorStatsList?.cantVistasPromocionadasPropias}`
                                            :
                                            'No disponible'}
                                    </h2>
                                    <Typography.Text
                                        type='secondary'
                                        style={{
                                            color: '#fff'
                                        }}>
                                        Compartidas por vos
                                        </Typography.Text>

                                </Link>
                                <Tooltip title="Te mostramos la cantidad de potenciales clientes que abrieron Spiderweb desde un “link” generado por vos">
                                    <ion-icon
                                        style={{
                                            position: 'absolute',
                                            color: '#fff',
                                            top: 8,
                                            right: 8,
                                            fontSize: 20
                                        }}
                                        name="information-circle-outline"></ion-icon>
                                </Tooltip>
                            </div>

                            <div className='user-promotor-report-info stat2'>
                                <Link
                                    to='/user/promotor/share/red'
                                >
                                    <h2>
                                        {typeof promotorStatsList?.cantVistasPromocionadasTuRed === 'number' ?
                                            `${promotorStatsList?.cantVistasPromocionadasTuRed}`
                                            :
                                            'No disponible'}
                                    </h2>
                                    <Typography.Text
                                        type='secondary'
                                        style={{
                                            color: '#fff'
                                        }}>
                                        Compartidas por tu red
                                        </Typography.Text>

                                </Link>
                                <Tooltip title="Te mostramos la cantidad de potenciales clientes que abrieron Spiderweb desde un “link” generado por un promotor de tu red">
                                    <ion-icon
                                        style={{
                                            position: 'absolute',
                                            color: '#fff',
                                            top: 8,
                                            right: 8,
                                            fontSize: 20
                                        }}
                                        name="information-circle-outline"></ion-icon>
                                </Tooltip>
                            </div>

                            <div className='user-promotor-report-info stat3'>
                                <Link
                                    to='/user/promotor/bookings/own'>
                                    <h2>
                                        {typeof promotorStatsList?.cantReservasPropias === 'number' ?
                                            `${promotorStatsList?.cantReservasPropias}`
                                            :
                                            'No disponible'}
                                    </h2>
                                    <Typography.Text
                                        type='secondary'
                                        style={{
                                            color: '#fff'
                                        }}>
                                        Reservas generadas por vos
                                        </Typography.Text>
                                </Link>

                                <Tooltip title="Te mostramos la cantidad de reservas efectivamente realizadas por los clientes que abrieron Spiderweb desde un “link” generado por vos">
                                    <ion-icon
                                        style={{
                                            position: 'absolute',
                                            color: '#fff',
                                            top: 8,
                                            right: 8,
                                            fontSize: 20
                                        }}
                                        name="information-circle-outline"></ion-icon>
                                </Tooltip>
                            </div>

                            <div className='user-promotor-report-info stat4'>
                                <Link
                                    to='/user/promotor/bookings/red'>
                                    <h2>
                                        {typeof promotorStatsList?.cantReservasTuRed === 'number' ?
                                            `${promotorStatsList?.cantReservasTuRed}`
                                            :
                                            'No disponible'}
                                    </h2>
                                    <Typography.Text
                                        type='secondary'
                                        style={{
                                            color: '#fff'
                                        }}>
                                        Reservas generadas por tu red
                                        </Typography.Text>
                                </Link>

                                <Tooltip title="Te mostramos la cantidad de Reservas efectivamente realizadas por los clientes que abrieron Spiderweb desde un “link” generado por un promotor de tu red">
                                    <ion-icon
                                        style={{
                                            position: 'absolute',
                                            color: '#fff',
                                            top: 8,
                                            right: 8,
                                            fontSize: 20
                                        }}
                                        name="information-circle-outline"></ion-icon>
                                </Tooltip>
                            </div>

                            <div className='user-promotor-report-info stat5'>
                                <Link
                                    to='/user/promotor/commissions/own'>
                                    <h2>
                                        {typeof promotorStatsList?.totalComisionesPropias === 'number' ?
                                            `$${promotorStatsList?.totalComisionesPropias}`
                                            :
                                            'No disponible'}
                                    </h2>
                                    <Typography.Text
                                        type='secondary'
                                        style={{
                                            color: '#fff'
                                        }}>
                                        Comisiones generadas por vos
                                    </Typography.Text>

                                    <Tooltip title="Te mostramos las comisiones que generaste como promotor">
                                        <ion-icon
                                            style={{
                                                position: 'absolute',
                                                color: '#fff',
                                                top: 8,
                                                right: 8,
                                                fontSize: 20
                                            }}
                                            name="information-circle-outline"></ion-icon>
                                    </Tooltip>
                                </Link>
                            </div>

                            <div className='user-promotor-report-info stat6'>
                                <Link
                                    to='/user/promotor/commissions/red'>
                                    <h2>
                                        {typeof promotorStatsList?.totalComisionesTuRed === 'number' ?
                                            `$${promotorStatsList?.totalComisionesTuRed}`
                                            :
                                            'No disponible'}
                                    </h2>
                                    <Typography.Text
                                        type='secondary'
                                        style={{
                                            color: '#fff',
                                        }}>
                                        Comisiones generadas por tu red
                                    </Typography.Text>
                                </Link>

                                <Tooltip title="Te mostramos las comisiones que te generaron los promotores de tu red">
                                    <ion-icon
                                        style={{
                                            position: 'absolute',
                                            color: '#fff',
                                            top: 8,
                                            right: 8,
                                            fontSize: 20
                                        }}
                                        name="information-circle-outline"></ion-icon>
                                </Tooltip>
                            </div>

                            <div className='user-promotor-report-info stat7'>
                                <Link to='/user/wallet' >
                                    <h2>
                                        {typeof promotorStatsList?.totalBilletera === 'number' ?
                                            `$${promotorStatsList?.totalBilletera}`
                                            :
                                            'No disponible'}
                                    </h2>
                                    <Typography.Text
                                        type='secondary'
                                        style={{
                                            color: '#fff'
                                        }}>
                                        Billetera
                                    </Typography.Text>
                                </Link>

                                <Tooltip title="Dinero disponible en tu billetera">
                                    <ion-icon
                                        style={{
                                            position: 'absolute',
                                            color: '#fff',
                                            top: 8,
                                            right: 8,
                                            fontSize: 20
                                        }}
                                        name="information-circle-outline"></ion-icon>
                                </Tooltip>
                            </div>

                        </div>
                    }
                </div>
            </div>
        </>
    )

})

const mapStateToProps = (state) => ({
    user: state.context.user,
    promotorStatsList: state.userStats.promotorStatsList,
    loading: state.userStats.loading,
    dateFrom: state.userStats.dateFrom,
    dateTo: state.userStats.dateTo,

})

const mapDispatchToProps = (dispatch) => ({
    getPromotorStats: userId => dispatch(UserStatsActions.getPromotorStats(userId)),
    setDateFrom: dateFrom => dispatch(UserStatsActions.setDateFrom(dateFrom)),
    setDateTo: dateTo => dispatch(UserStatsActions.setDateTo(dateTo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPromotorStats);
