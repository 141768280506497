import React, { useCallback, useEffect, useState } from 'react'
import { SearchBar } from 'components'
import { ExperienceActions, FeedActions, FavoritesActions, CategoriesActions, LanguagesActions, TagsActions, LocalitiesActions, MessagesActions } from 'redux/actions'
import { connect } from 'react-redux'
import { withQueryParams, StringParam, NumberParam, ArrayParam, withDefault } from 'use-query-params';
import { FiltersModal, UbicationFiltersModal, ExperienceResultPagination, EmptyList, TagsFiltersModal } from 'components';
import { Alert } from 'antd'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'
import { AnButtonGradient } from 'components/an';
import { useMedia } from 'react-use'
import { useQuery } from 'hooks/useQuery';
import { setWithExpiry } from 'services/local-storage';

const FeedScreen = React.memo((props) => {
    const {
        user,

        loading,
        loadingMoreResults,
        error,
        experiences,
        page,
        hasMore,

        getExperiences,
        getMoreExperiences,

        actions,
        markExperienceAsViewed,

        likeExperience,
        unlikeExperience,

        query,
        setQuery,

        loadingCategories,
        getCategories,
        categoryOptions,

        loadingLanguages,
        getLanguages,
        languagesOptions,

        loadingTags,
        errorTags,
        getTags,
        tagsOptions,

        loadingLocalities,
        getLocalities,
        localitiesOptions,
        localitiesFavoritesOptions,

        currentLocality,
        currentTags,
    } = props

    const history = useHistory()
    const location = useLocation()
    let isDesktop = useMedia('(min-width: 768px)')

    const [filtersShow, setFiltersShow] = useState(false)
    const [ubicationFiltersShow, setUbicationFiltersShow] = useState(false)
    const [tagsFiltersShow, setTagsFiltersShow] = useState(false)
    const [hasFilters, seHasFilters] = useState(false)

    const queryRouter = useQuery(location.search)

    // If 'promoter-nic' is in the query parameters, save it to LocalStorage
    if (queryRouter.get('promoter-nic')) {
        setWithExpiry('promoter-nic', queryRouter.get('promoter-nic'), 86400000 * 30)
    }
    useEffect(() => {
        if (queryRouter.get('promoter-nic')) {
            markExperienceAsViewed(user?.role, null, queryRouter.get('promoter-nic'))
            queryRouter.delete('promoter-nic')
            history.replace({
            search: queryRouter.toString(),
            })
        }
    }, [])
    
    
    useEffect(() => {
        if (location.state?.blocked) {
            const unblock = history.block((to, action) => {
                if (action === 'POP' && to.pathname === location.state?.blockedPath) {
                    return false
                }

                return true
            })

            return () => {
                unblock()
            }
        }
    }, [location, history])

    useEffect(() => {
        if (user) {
            getExperiences(user, {
                name: query.name,
                fromDate: query.fromDate,
                toDate: query.toDate,
                categories: query.categories,
                peopleQuantity: query.peopleQuantity,
                languages: query.languages,
                duration: query.duration,
                maxPrice: query.maxPrice,
                tags: query.tags,
                localityId: query.localityId,
            })
        }
    }, [
        user,
        query.name,
        query.fromDate,
        query.toDate,
        query.categories,
        query.peopleQuantity,
        query.languages,
        query.duration,
        query.maxPrice,
        query.tags,
        query.localityId,
        getExperiences
    ])

    useEffect(() => {
        // query.localityId, query.name 
        // and query.fromDate aren't relevant for this badge

        if (query.categories
            || query.duration
            || query.languages
            || query.maxPrice
            || query.peopleQuantity
            || query.tags
            || query.toDate) {
            seHasFilters(true)
        } else {
            seHasFilters(false)
        }
    }, [query])

    const onRequestLikeExperience = useCallback((typeUser, experienceId) => {
        likeExperience(typeUser, experienceId)
    })

    const onRequestUnlikeExperience = useCallback((typeUser, experienceId) => {
        unlikeExperience(typeUser, experienceId)
    })

    const handleCloseErrorAlert = useCallback(() => {
        getExperiences(user, {
            name: query.name,
            fromDate: query.fromDate,
            toDate: query.toDate,
            categories: query.categories,
            peopleQuantity: query.peopleQuantity,
            languages: query.languages,
            duration: query.duration,
            maxPrice: query.maxPrice,
            tags: query.tags,
            localityId: query.localityId,
        }, true)
    }, [
        user,
        query.name,
        query.fromDate,
        query.toDate,
        query.categories,
        query.peopleQuantity,
        query.languages,
        query.duration,
        query.maxPrice,
        query.tags,
        query.localityId,
        getExperiences
    ])

    const handleOnSearchByText = useCallback((value) => {
        if (value.length > 2) {
            setQuery({
                name: value,
            })
        } else {
            setQuery({
                name: undefined,
            })
        }
    })

    const handleOnOpenFiltersModal = useCallback(() => {
        getCategories(null, { inactive: false })
        getLanguages(null, { inactive: false })
        getTags(null, { inactive: false })
    }, [getCategories, getLanguages, getTags])

    const handleOnOpenUbicationsFiltersModal = useCallback(() => {
        getLocalities(null, { favorite: true })
    }, [getLocalities])

    const handleOnOpenTagsFiltersModal = useCallback(() => {
        getTags(
            null,
            { inactive: false })
    }, [getLocalities])

    const handleOnApplySearchCriteria = useCallback((criteria) => {
        // Close the search modal
        setFiltersShow(false)

        // Apply filters to the current url
        // as query params

        setQuery((queries) => {
            return {
                fromDate: criteria.fromDate || undefined,
                toDate: criteria.toDate || undefined,
                categories: criteria.categories || undefined,
                peopleQuantity: criteria.peopleQuantity || undefined,
                languages: criteria.languages || undefined,
                duration: criteria.duration || undefined,
                maxPrice: criteria.maxPrice || undefined,
                tags: criteria.tags || undefined,
                localityId: queries.localityId || undefined,
            }
        })
    }, setFiltersShow, setQuery, query)

    const handleOnClickRemoveFilters = useCallback(() => {
        // Close the search modal
        setFiltersShow(false)

        // Apply filters to the current url
        // as query params
        setQuery({
            fromDate: undefined,
            toDate: undefined,
            categories: undefined,
            peopleQuantity: undefined,
            languages: undefined,
            duration: undefined,
            maxPrice: undefined,
            tags: undefined,
        })
    })

    const handleOnApplySearchLocationCriteria = useCallback((localityId) => {
        // Close the ubications modal
        setUbicationFiltersShow(false)

        // Apply filters to the current url
        // as query params
        setQuery({
            localityId: localityId && (localityId !== 'all') ? localityId : undefined,
        })
    }, setFiltersShow, setQuery)

    const handleOnApplySearchTagsCriteria = useCallback((tags) => {
        // Close the ubications modal
        setTagsFiltersShow(false)

        // Apply filters to the current url
        // as query params
        setQuery({
            tags: tags && !(tags.find((item) => { return item === 'all' })) ? tags : undefined
        })
    }, setFiltersShow, setQuery)

    let prevScrollpos = window.pageYOffset

    //With this function we control NavBar hiding in FeedScreen by id
    window.onscroll = function () {
        let feedFiltersRef = document.getElementById("feed-filters")
        let topBarRef = document.getElementById("top-bar")
        let currentScrollPos = window.pageYOffset
        if (topBarRef && feedFiltersRef && !isDesktop) {
            if (prevScrollpos > currentScrollPos) {
                //if user scroll to top
                feedFiltersRef.style.top = "58px"
                topBarRef.style.top = "0px"
            } else {
                //if user scroll to bottom
                topBarRef.style.top = "-120px"
                feedFiltersRef.style.top = "-120px"
            }
        }
        prevScrollpos = currentScrollPos
    }

    const FeedTopBar = () => {
        return (
            <>
                <div className='feed-screen-filters-buttons'                                >
                    <AnButtonGradient
                        type="link"
                        shape='round'
                        disabled={loading}
                        onClick={() => {
                            setUbicationFiltersShow(true)
                        }}
                        style={{
                            marginLeft: 10,
                        }}
                    >
                        {query.localityId && currentLocality ? currentLocality.nombre : 'Buscar en:'}
                    </AnButtonGradient>

                    <AnButtonGradient
                        type="link"
                        shape='round'
                        disabled={loading}
                        onClick={() => {
                            setTagsFiltersShow(true)
                        }}
                        style={{
                            marginRight: 10,
                        }}
                    >
                        {currentTags.length === 0 ? 'Tags' : `${currentTags[0].name} ${currentTags.length > 1 ? `y ${currentTags.length - 1} más..` : ''}`}
                    </AnButtonGradient>
                </div>
            </>)
    }


    return (
        <>
            <div className="main">
                {error ? (
                    <div className="list-error-container margin-gap">
                        <Alert
                            message="Ups!"
                            description="Ha ocurrido un error al intentar obtener las experiencias disponibles"
                            type="error"
                            showIcon
                            closeText="Reintentar"
                            onClose={handleCloseErrorAlert}
                        />
                    </div>
                ) : (
                        <>
                            {isDesktop ?
                                <>
                                    <SearchBar
                                        searchTextValue={query.name}
                                        onSearchByText={handleOnSearchByText}
                                        hasFilters={hasFilters}
                                        onClickSearchFilters={() => {
                                            setFiltersShow(true)
                                        }}
                                    />
                                    <FeedTopBar />
                                </>
                                :
                                <div
                                    className='feed-screen-top-bar sticky'
                                    id='feed-filters'
                                >
                                    <SearchBar
                                        searchTextValue={query.name}
                                        onSearchByText={handleOnSearchByText}
                                        hasFilters={hasFilters}
                                        onClickSearchFilters={() => {
                                            setFiltersShow(true)
                                        }}
                                    />
                                    <FeedTopBar />
                                </div>}
                            {experiences.length === 0 && !loading ? (
                                <EmptyList
                                    description={
                                        query.name
                                            || query.toDate
                                            || query.categories
                                            || query.peopleQuantity
                                            || query.languages
                                            || query.duration
                                            || query.maxPrice
                                            || query.tags
                                            || query.fromDate !== moment().format('YYYY-MM-DD')
                                            || query.localityId

                                            ? "No se encontraron experiencias para tus parámetros de búsqueda"
                                            : "En este momento no hay experiencias disponibles"
                                    }
                                    image={
                                        query.name
                                            || query.toDate
                                            || query.categories
                                            || query.peopleQuantity
                                            || query.languages
                                            || query.duration
                                            || query.maxPrice
                                            || query.tags
                                            || query.fromDate !== moment().format('YYYY-MM-DD')
                                            || query.localityId

                                            ? <ion-icon name="search-outline"></ion-icon>
                                            : <ion-icon name="images-outline"></ion-icon>
                                    }
                                />
                            ) : (
                                    <ExperienceResultPagination
                                        user={user}
                                        loading={loading}
                                        loadingMoreResults={loadingMoreResults}
                                        hasMore={hasMore}
                                        page={page}
                                        results={experiences}
                                        getMoreResults={getMoreExperiences}
                                        likeItemId={actions.like.id || actions.unlike.id}
                                        likeExperience={onRequestLikeExperience}
                                        unlikeExperience={onRequestUnlikeExperience}
                                        shareExperienceUrl={`${window.location.protocol}//${window.location.hostname}${window.location.port !== 80 && (window.location.port.length > 0) ? `:${window.location.port}` : ``}/experience/:id/share${user?.role === 'Usuario' ? `?promoter-nic=${user?.nic}` : ``}`}
                                        infinityScrollBottomMessage="Ya viste todas las experiencias"
                                    />
                                )}


                        </>
                    )}
            </div>

            {filtersShow && (
                <FiltersModal
                    loading={loadingCategories || loadingLanguages || loadingTags}
                    onOpen={handleOnOpenFiltersModal}
                    onClose={() => {
                        setFiltersShow(false)
                    }}
                    categoryOptions={categoryOptions}
                    languagesOptions={languagesOptions}
                    tagsOptions={tagsOptions}
                    fromDate={query.fromDate}
                    toDate={query.toDate}
                    categories={query.categories}
                    peopleQuantity={query.peopleQuantity}
                    languages={query.languages}
                    duration={query.duration}
                    maxPrice={query.maxPrice}
                    tags={query.tags}
                    onApplySearchCriteria={handleOnApplySearchCriteria}
                    onClickRemoveFilters={handleOnClickRemoveFilters}
                />
            )}

            {ubicationFiltersShow && (
                <UbicationFiltersModal
                    onApplySearchLocationCriteria={handleOnApplySearchLocationCriteria}
                    onOpen={handleOnOpenUbicationsFiltersModal}
                    loadingLocalities={loadingLocalities}
                    localitiesOptions={localitiesOptions}
                    localitiesFavoritesOptions={localitiesFavoritesOptions}
                    getLocalities={getLocalities}
                    currentLocality={currentLocality}
                />
            )}

            {tagsFiltersShow && (
                <TagsFiltersModal
                    onApplySearchTagsCriteria={handleOnApplySearchTagsCriteria}
                    onOpen={handleOnOpenTagsFiltersModal}
                    loading={loadingTags}
                    tagsOptions={
                        [...currentTags, ...tagsOptions]
                            .filter((tag, i, a) => a.findIndex(t => (t.id === tag.id)) === i)
                    }
                    currentTags={currentTags.map(tag => {
                        return tag.id
                    })}
                    errorTags={errorTags}
                />
            )}
        </>
    );
})

const mapStateToProps = (state) => ({
    user: state.context.user,
    loading: state.feed.loading,
    loadingMoreResults: state.feed.loadingMoreResults,
    error: state.feed.error,
    dataFingerprint: state.feed.dataFingerprint,
    experiences: state.feed.data,
    page: state.feed.page,
    pageCount: state.feed.pageCount,
    hasMore: state.feed.hasMore,
    actions: state.feed.experience.actions,
    currentLocality: state.feed.currentLocality,
    currentTags: state.feed.currentTags,

    categoryOptions: state.categories.data,
    loadingCategories: state.categories.loading,

    languagesOptions: state.languages.data,
    loadingLanguages: state.languages.loading,

    tagsOptions: state.tags.data,
    loadingTags: state.tags.loading,
    errorTags: state.tags.error,

    loadingLocalities: state.localities.loading,
    localitiesOptions: state.localities.data,
    localitiesFavoritesOptions: state.localities.favoritesData,
})

const mapDispatchToProps = (dispatch) => ({
    getExperiences: (user, criteria, ignoreFingerprint) => dispatch(FeedActions.getExperiences(user, criteria, ignoreFingerprint)),
    getMoreExperiences: (user, page) => dispatch(FeedActions.getMoreExperiences(user, page)),
    likeExperience: (typeUser, experienceId) => dispatch(FavoritesActions.likeExperience(typeUser, experienceId)),
    unlikeExperience: (typeUser, experienceId) => dispatch(FavoritesActions.unlikeExperience(typeUser, experienceId)),
    markExperienceAsViewed: (typeUser, experienceId, promotorNic) => dispatch(ExperienceActions.markExperienceAsViewed(typeUser, null, promotorNic)),
    getCategories: (params, criteria) => dispatch(CategoriesActions.getCategories(params, criteria)),
    getLanguages: (params, criteria) => dispatch(LanguagesActions.getLanguages(params, criteria)),
    getTags: (params, criteria) => dispatch(TagsActions.getTags(params, criteria)),
    getLocalities: (params, criteria) => dispatch(LocalitiesActions.getLocalities(params, criteria)),
})

export default connect(mapStateToProps, mapDispatchToProps)(
    withQueryParams({
        name: StringParam,
        fromDate: withDefault(StringParam, moment().format('YYYY-MM-DD')),
        toDate: StringParam,
        categories: ArrayParam,
        peopleQuantity: NumberParam,
        languages: ArrayParam,
        duration: NumberParam,
        maxPrice: NumberParam,
        tags: ArrayParam,
        localityId: StringParam,
        promoterNic: StringParam
    }, FeedScreen)
);