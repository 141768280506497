const defaultState = {
	loading: false,
	error: false,
	data: [],
	favoritesData: []
};

export default function reducer(state = defaultState, action) {
	switch (action.type) {

		case 'LOCALITIES_FETCH_LOCALITIES_START':
			return Object.assign({}, state, {
				loading: true,
				error: false,
				data: [],
			})

		case 'LOCALITIES_FETCH_LOCALITIES_FAVORITES_SUCCESS':
			return Object.assign({}, state, {
				loading: false,
				favoritesData: action.payload.localities
			})


		case 'LOCALITIES_FETCH_LOCALITIES_SUCCESS':
			return Object.assign({}, state, {
				loading: false,
				data: action.payload.localities
			})

		case 'LOCALITIES_FETCH_LOCALITIES_FAILURE':
			return Object.assign({}, state, {
				loading: false,
				error: true,
			})

		default:
			return state;
	}
}
