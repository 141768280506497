import { auth, providers, actionCodeSettings } from "config/firebase";

export default {
	signInWithGoogle: () => auth.signInWithPopup(providers.google),
	signInWithFacebook: () => auth.signInWithPopup(providers.facebook),
	sendSignInLinkToEmail: (email) => auth.sendSignInLinkToEmail(email, actionCodeSettings),
	signInWithEmailLink: (email, link) => auth.signInWithEmailLink(email, link),
	signUpWithEmailAndPass: (email, password) => auth.createUserWithEmailAndPassword(email, password),
	signInWithEmailAndPassword: (email, password) => auth.signInWithEmailAndPassword(email, password),
	signInAnonymously: () => auth.signInAnonymously(),
	signOut: () => auth.signOut(),
	deleteUser: () => auth.currentUser.delete(),
	onChange: (callback) => auth.onAuthStateChanged(callback),
	currentUser: () => auth.currentUser,
	updatePassword: (newPassword) => auth.currentUser.updatePassword(newPassword),
	reauthenticateWithCredential: (credential) => auth.currentUser.reauthenticateWithCredential(credential),
	sendPasswordResetEmail: (email) => auth.sendPasswordResetEmail(email),
	confirmPasswordReset: (code, newPassword) => auth.confirmPasswordReset(code, newPassword),
}