import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Divider, Modal, Alert, Button, Spin } from "antd";
import DayPicker from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import { Loader } from "components";

const AnCalendar = React.memo((props) => {
    const {
        loading,
        removingAllEvents,
        onDayClick,
        removeAllEvents,
        mode,
        events,
        selectedDays,
        selectedDay,
        removeAllEventsSuccess,
        removeAllEventsButtonLabel,
        removeAllEventsModalTitle,
        removeAllEventsModalDescription,
        removeAllEventsModalConfirmButtonLabel,
        removeAllEventsModalCancelButtonLabel,
        noEventsAlertTitle,
        noEventsAlertDescription,
        noEventsAlertType,
        calendarNotAvailable,
        calendarNotAvailableAlertTitle,
        calendarNotAvailableAlertDescription,
        calendarNotAvailableAlertType,
    } = props;

    const [showRemoveAllEventsConfirmModal, setShowRemoveAllEventsConfirmModal] = useState(false);

    const handleOnClickRemoveAllEvents = useCallback(() => {
        setShowRemoveAllEventsConfirmModal(true);
    });

    const handleConfirmRemoveAllEvents = useCallback(() => {
        removeAllEvents();
    });

    const handleCancelRemoveAllEvents = () => {
        setShowRemoveAllEventsConfirmModal(false);
    };

    useEffect(() => {
        if (removeAllEventsSuccess) {
            setShowRemoveAllEventsConfirmModal(false);
        }
    }, [removeAllEventsSuccess]);

    return (
        <>
            <Loader loading={loading} title="Obteniendo eventos ...">
                {!calendarNotAvailable ? (
                    <div
                        style={{
                            opacity: loading ? 0.4 : 1,
                        }}
                    >
                        <DayPicker
                            onDayClick={onDayClick}
                            localeUtils={MomentLocaleUtils}
                            fromMonth={new Date()}
                            showOutsideDays
                            disabledDays={
                                loading
                                    ? [
                                          {
                                              before: new Date(),
                                              after: new Date(),
                                          },
                                      ]
                                    : [
                                          {
                                              before: new Date(),
                                          },
                                      ]
                            }
                            modifiers={{
                                highlighted: new Date(selectedDay),
                            }}
                            navbarElement={(p) => (
                                <div className="DayPicker-NavBar">
                                    <Button
                                        className="DayPicker-NavButton DayPicker-NavButton--prev"
                                        onClick={() => p.onPreviousClick()}
                                    >
                                        <ion-icon name="chevron-back-outline"></ion-icon>
                                    </Button>

                                    <Button
                                        className="DayPicker-NavButton DayPicker-NavButton--next"
                                        onClick={() => {
                                            p.onNextClick();
                                        }}
                                    >
                                        <ion-icon name="chevron-forward-outline"></ion-icon>
                                    </Button>
                                </div>
                            )}
                            selectedDays={selectedDays}
                            {...props}
                        />

                        {!loading && events.length > 0 ? (
                            <>
                                {mode === "admin" && (
                                    <Row>
                                        <Col span={24}>
                                            <Button
                                                block
                                                type="ghost"
                                                shape="round"
                                                onClick={handleOnClickRemoveAllEvents}
                                                style={{
                                                    borderRadius: "3px",
                                                    margin: "3px",
                                                    background: "#f9f9f9",
                                                    color: "#c31459",
                                                    fontSize: "12px",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                {removeAllEventsButtonLabel}
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        ) : !loading && events.length === 0 ? (
                            <>
                                <Divider />

                                <Alert
                                    message={noEventsAlertTitle}
                                    description={noEventsAlertDescription}
                                    type={noEventsAlertType}
                                    showIcon
                                />
                            </>
                        ) : null}
                    </div>
                ) : (
                    <Alert
                        message={calendarNotAvailableAlertTitle}
                        description={calendarNotAvailableAlertDescription}
                        type={calendarNotAvailableAlertType}
                        showIcon
                    />
                )}
            </Loader>

            {mode === "admin" && (
                <Modal
                    centered
                    transitionName="zoom"
                    title={removeAllEventsModalTitle}
                    visible={showRemoveAllEventsConfirmModal}
                    cancelText={removeAllEventsModalCancelButtonLabel}
                    okText={removeAllEventsModalConfirmButtonLabel}
                    onOk={handleConfirmRemoveAllEvents}
                    onCancel={handleCancelRemoveAllEvents}
                    okButtonProps={{
                        loading: removingAllEvents,
                    }}
                >
                    <p>{removeAllEventsModalDescription}</p>
                </Modal>
            )}
        </>
    );
});

AnCalendar.defaultProps = {
    locale: "es",
    disabledDays: [],
    selectedDays: [],
    mode: "view",
    events: [],
    noEventsAlertTitle: "Calendario sin eventos",
    noEventsAlertDescription: "El calendario no posee eventos disponibles",
    noEventsAlertType: "warning",
    calendarNotAvailableAlertTitle: "Calendario no disponible",
    calendarNotAvailableAlertDescription: "El calendario no se encuentra disponible en este momento",
    calendarNotAvailableAlertType: "warning",
    removeAllEventsButtonLabel: "Eliminar todos los eventos",
    removeAllEventsModalTitle: "Advertencia",
    removeAllEventsModalCancelButtonLabel: "Cancelar",
    removeAllEventsModalConfirmButtonLabel: "Confirmar",
    removeAllEventsModalDescription: "En caso de confirmar se eliminarán todos los eventos de este calendario",
};

export default AnCalendar;
