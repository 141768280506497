import React from 'react'
import { Tooltip } from 'antd'

const AnLabelForm = React.memo((props) => {
    const {
        className,
        label,
        afterLabel,
        tooltip = false,
        tooltipText
    } = props;
    
    return (
        <div
            className={className ? `an-label ${className}` : 'an-label'}
        >
            <div className="label">
                <span>{label}</span>
                {afterLabel ? afterLabel : null}
            </div>
            
            {tooltip && (
                <Tooltip title={tooltipText}>
                    <ion-icon name="information-circle-outline"></ion-icon>
                </Tooltip>
            )}

        </div>
    )
})

export default AnLabelForm