import UserRestClient from 'http/resources/UserRestClient'
import ErrorManager from 'services/error-manager'
import axios from 'axios'

function getUserBookings(params, criteria) {
	return dispatch => {
		dispatch({
			type: "USER_BOOKINGS_GET_BOOKINGS_START",
		})

		new UserRestClient(true).getBookings(params, criteria)
			.then((res) => {
				dispatch({
					type: "USER_BOOKINGS_GET_BOOKINGS_SUCCESS",
					payload: {
						userBookingsList: res.data.items,
						pageCount: res.data.pageCount,
						totalItems: res.data.totalItems,
						page: res.data.page
					}
				})
			})
			.catch((error) => {
				if (error instanceof axios.Cancel) {
					// Nothing to do ...
				} else {
					dispatch({
						type: "USER_BOOKINGS_GET_BOOKINGS_FAILURE",
					})
					dispatch({
						type: 'UI_ALERT_SET_ERROR',
						payload: {
							error: ErrorManager.toMessage(error)
						}
					})
				}
			})

	}
}

function getMoreUserBookings(params, criteria) {

	return dispatch => {
		dispatch({
			type: "USER_BOOKINGS_BOOKINGS_MORE_RESULTS_START",
		})

		new UserRestClient(true).getBookings(params, criteria)
			.then((res) => {
				if (res.data.items.length < 1 ||
					res.data.page === (params.page - 1)) {
					dispatch({
						type: "USER_BOOKINGS_BOOKINGS_MORE_RESULTS_HAS_NO_MORE"
					})
				}
				dispatch({
					type: "USER_BOOKINGS_BOOKINGS_MORE_RESULTS_SUCCESS",
					payload: {
						userBookingsList: res.data.items,
						pageCount: res.data.pageCount,
						totalItems: res.data.totalItems,
						page: res.data.page
					}
				})
			})
			.catch((error) => {
				dispatch({
					type: "USER_BOOKINGS_BOOKINGS_MORE_RESULTS_FAILURE",
				})
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(error)
					}
				})
			})

	}
}


export const UserBookingsActions = {
	getUserBookings,
	getMoreUserBookings,
}