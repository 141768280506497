import { combineReducers } from 'redux'
import ui from './ui'
import context from './context'
import history from './history'
import session from './session'
import login from './login'
import register from './register'
import categories from './categories'
import localities from './localities'
import languages from './languages'
import tags from './tags'
import experience from './experience'
import favorites from './favorites'
import feed from './feed'
import experienceEvents from './experience-events'
import booking from './booking'
import payment from './payment'
import clientBookings from './client-bookings'
import userBookings from './user-bookings'
import userStats from './user-stats'
import messages from './messages'
import userExperiences from './user-experiences'
import userProfile from './user-profile'
import userGateway from './user-gateway'

const rootReducer = combineReducers({
	ui,
    context,
    history,
	session,
	login,
    register,
    categories,
    localities,
    languages,
    tags,
    experience,
    experienceEvents,
	favorites,
	feed,
    booking,
    payment,
	clientBookings,
	userBookings,
	userStats,
    messages,
	userExperiences,
    userProfile,
    userGateway,
})

export default rootReducer;