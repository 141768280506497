import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button, Avatar, Typography, Divider } from 'antd'
import { PageHeader, Loader, NewInvitationModal } from 'components'
import { UserOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { UserProfileActions, UserGatewayActions } from 'redux/actions'
import auth from 'session/api'
import { AnButton, AnInput } from 'components/an'
import { isMobile } from 'react-device-detect'

const UserProfileScreen = React.memo((props) => {
    const {
        user,
        loading,
        userInfo,
        getUserInfo,
        sendLoading,
        sendInvitation,
        confirmNewPassword,
        changePassSuccess,
        changePassLoading,
        getGatewayAuthorizationURL,
        unlinkGateway,
        actions,
    } = props

    const [newInvitationShow, setNewInvitationShow] = useState(false)
    const [canChangePassword, setCanChangePassword] = useState(false)
    const [passwords, setPasswords] = useState({
        actualPassword: '',
        newPassword: '',
        newPasswordConfirm: ''
    })

    useEffect(() => {
        getUserInfo(user.sub)
    }, [user])

    const handleOnChange = (e) => {
        const { name, value } = e.target
        setPasswords((state) => ({
            ...state,
            [name]: value
        }))
    }

    useEffect(() => {
        let user = auth.currentUser()
        if (user != null) {
            user.providerData.forEach((profile) => {
                if (profile.providerId === 'password') {
                    setCanChangePassword(true)
                }
            })
        }
    }, [])

    useEffect(() => {
        if (changePassSuccess) {
            setPasswords({
                actualPassword: '',
                newPassword: '',
                newPasswordConfirm: ''
            })
        }
    }, [changePassSuccess])

    useEffect(() => {
        if (actions.fetchGatewayAuthorizationURL.url) {
            const redirectURI = encodeURIComponent(process.env.REACT_APP_GATEWAY_REDIRECT_URL)

            const h = 600
            const w = 400
            const y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
            const x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);

            const popup = window.open(
                `${actions.fetchGatewayAuthorizationURL.url}&redirect_uri=${redirectURI}`,
                'mpauth',
                isMobile ? `` : `toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=${w}, height=${h}, top=${y}, left=${x}`
            )

            // Listen for messages
            window.addEventListener("message", function(event) {
                if(event.data === "success") {
                    popup.close();
                    getUserInfo(user.sub)
                }
            }, false);
        }
    }, [actions.fetchGatewayAuthorizationURL.url])

    useEffect(() => {
        if (actions.unlinkGateway.success) {
            getUserInfo(user.sub)
        }
    }, [actions.unlinkGateway.success, user, getUserInfo])

    const onConfirmPassChange = () => {
        if (passwords.newPassword === passwords.newPasswordConfirm) {
            confirmNewPassword(passwords.actualPassword, passwords.newPassword)
        }
    }

    return (
        <>
            <div className="main">
                <PageHeader
                    title="Mi Perfil"
                    subtitle="Datos de mi cuenta"
                />

                <Loader
                    loading={loading}
                />
                
                {userInfo && (
                    <>
                        <div className='profile-container'>
                            <div className='profile-information-container full'>
                                <Avatar
                                    size={64}
                                    icon={(
                                        <UserOutlined />
                                    )}
                                    src={user?.picture || ""}
                                />

                                <div className='profile-information-description'>

                                    <Typography.Text>
                                        {user?.unique_name}
                                    </Typography.Text>

                                    <Typography.Text
                                        type='secondary'
                                    >
                                        {userInfo?.email || 'Cargando..'}
                                    </Typography.Text>

                                    <Typography.Text
                                        type='secondary'
                                    >
                                        {user?.type}
                                    </Typography.Text>

                                </div>

                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                    }}
                                >

                                    <div className='profile-code-info-container'>
                                        <div>

                                            <Typography>
                                                Código promotor:
                                            </Typography>

                                            <Typography.Text
                                                type='secondary'
                                            >
                                                {userInfo?.codigo || 'Cargando..'}
                                            </Typography.Text>

                                        </div>

                                        <Button
                                            disabled={loading || !userInfo.codigo}
                                            type="primary"
                                            shape="round"
                                            icon={<PlusCircleOutlined />}
                                            onClick={() => {
                                                setNewInvitationShow(true)
                                            }}
                                        >
                                            Invitar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div
                            style={{
                                background: '#f9f9f9',
                                marginBottom: 15,
                            }}
                        >
                            <div className="page-header-container">
                                <h1 className="page-header-title">
                                    <Typography.Text>
                                        Medio de Cobro
                                    </Typography.Text>
                                    
                                    {userInfo.autorizadoGatewayPagos && (
                                        <ion-icon name="checkmark-outline" style={{ color: 'green', fontSize: 23, }}></ion-icon>
                                    )}
                                </h1>
                            </div>

                            <div>
                                <div
                                    style={{
                                        padding: 15,
                                    }}
                                >
                                    {userInfo.autorizadoGatewayPagos ? (
                                        <>
                                            <div
                                                style={{
                                                    marginBottom: 15,
                                                    paddingBottom: 15,
                                                    borderBottom: '1px dashed #f0f0f0',
                                                }}
                                            >
                                                <Typography.Text>
                                                    Su cuenta de Mercado pago se encuentra vinculada correctamente, las comisiones
                                                    obtenidas por reservas se transferirán a dicha cuenta
                                                </Typography.Text>
                                            </div>
                                            
                                            <Row
                                                align="center"
                                                justify="end"
                                            >
                                                <Col>
                                                    <AnButton
                                                        ghost
                                                        shape='round'
                                                        type='primary'
                                                        loading={actions.unlinkGateway.loading}
                                                        onClick={() => {
                                                            unlinkGateway(user?.sub)
                                                        }}
                                                    >
                                                        Desvincular
                                                    </AnButton>
                                                </Col>
                                            </Row>
                                        </>
                                    ) : (   
                                        <>
                                            <div
                                                style={{
                                                    marginBottom: 15,
                                                    paddingBottom: 15,
                                                    borderBottom: '1px dashed #f0f0f0',

                                                }}
                                            >
                                                <Typography.Text>
                                                    Su cuenta de Mercado pago no encuentra vinculada, una vez vinculada su cuenta,
                                                    las comisiones obtenidas se transferirán a dicha cuenta
                                                </Typography.Text>
                                            </div>
                                            
                                            <Row
                                                align="center"
                                                justify="end"
                                            >
                                                <Col>
                                                    <AnButton
                                                        ghost
                                                        shape='round'
                                                        type='primary'
                                                        block
                                                        loading={actions.fetchGatewayAuthorizationURL.loading}
                                                        onClick={() => {
                                                            getGatewayAuthorizationURL(user?.sub)
                                                        }}
                                                    >
                                                        Vincular
                                                    </AnButton>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        {canChangePassword && (
                            <div
                                style={{
                                    background: '#f9f9f9'
                                }}
                            >
                                <div className="page-header-container">
                                    <h1 className="page-header-title">
                                        Cambiar contraseña
                                    </h1>
                                </div>

                                <div
                                    style={{
                                        padding: 15,
                                    }}
                                >
                                    <div
                                        style={{
                                            marginBottom: 15,
                                            paddingBottom: 15,
                                            borderBottom: '1px dashed #f0f0f0',

                                        }}
                                    >
                                        <AnInput
                                            className="an-input round"
                                            placeholder='Contraseña actual'
                                            name='actualPassword'
                                            onChange={handleOnChange}
                                            value={passwords.actualPassword}
                                            type='password'
                                            style={{
                                                marginBottom: 10
                                            }}
                                        />

                                        <AnInput
                                            className="an-input round"
                                            placeholder='Contraseña nueva'
                                            name='newPassword'
                                            onChange={handleOnChange}
                                            value={passwords.newPassword}
                                            type='password'
                                            style={{
                                                marginBottom: 10
                                            }}
                                        />

                                        <AnInput
                                            className="an-input round"
                                            placeholder='Repetir contraseña nueva'
                                            name='newPasswordConfirm'
                                            onChange={handleOnChange}
                                            value={passwords.newPasswordConfirm}
                                            type='password'
                                            style={{
                                                marginBottom: 10
                                            }}
                                        />
                                    </div>

                                    <Row
                                        align="center"
                                        justify="end"
                                    >
                                        <Col>
                                            <AnButton
                                                ghost
                                                shape='round'
                                                type='primary'
                                                block
                                                onClick={onConfirmPassChange}
                                                disabled={changePassLoading ||
                                                    (passwords.newPassword !== passwords.newPasswordConfirm) ||
                                                    (passwords.newPassword.length == 0)
                                                }
                                                loading={changePassLoading}
                                            >
                                                Guardar cambio
                                            </AnButton>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        )}

                        {newInvitationShow && (
                            <NewInvitationModal
                                userInfo={userInfo}
                                user={user}
                                sendLoading={sendLoading}
                                sendInvitation={sendInvitation}
                                onClose={() => {
                                    setNewInvitationShow(false)
                                }}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    )
})

const mapStateToProps = (state) => ({
    user: state.context.user,
    loading: state.userProfile.loading,
    userInfo: state.userProfile.userInfo,
    sendLoading: state.userProfile.sendLoading,
    changePassSuccess: state.userProfile.changePassSuccess,
    changePassLoading: state.userProfile.changePassLoading,
    actions: state.userGateway.actions,
})

const mapDispatchToProps = (dispatch) => ({
    getUserInfo: (userId) => dispatch(UserProfileActions.getUserInfo(userId)),
    sendInvitation: (email, url) => dispatch(UserProfileActions.sendInvitation(email, url)),
    confirmNewPassword: (actualPassword, newPassword) => dispatch(UserProfileActions.confirmNewPassword(actualPassword, newPassword)),
    getGatewayAuthorizationURL: (id) => dispatch(UserGatewayActions.getGatewayAuthorizationURL(id)),
    unlinkGateway: (id) => dispatch(UserGatewayActions.unlinkGateway(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileScreen)
