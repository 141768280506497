import axios from 'axios'
import firebase from 'firebase/app'
import tokenProvider from 'axios-token-interceptor'
import Store from 'redux/index'
import { SessionActions } from 'redux/actions'
import JWT from 'services/jwt';

export default class RestClient {
	constructor(
		baseURL,
		secured
	) {
		// Use instaces values or fallback to
		// environment vars.
        this.baseURL = process.env.REACT_APP_BASE_API_URL
        this.secured = secured

		// Create the axios instance
		this.instance = axios.create({
			baseURL
		})

		if (secured) {
			/**
			 *  Use request interceptor to add credentials
			 *  to the request impersonateToken has priority
			 *  over access token. 
			 */

			this.instance.interceptors.request.use(tokenProvider({
				getToken: () => {
					const user = firebase.auth().currentUser

					if (localStorage.getItem('impersonateToken')) {
						return localStorage.getItem('impersonateToken')
					} else if (localStorage.getItem('accessToken')) {
						return localStorage.getItem('accessToken')
					}

					return undefined
				},
			}))

			this.instance.interceptors.request.use((config) => {
				if (localStorage.getItem('impersonateToken')) {
					config.headers['x-impersonated-request'] = 'impersonated'
				}

				return config
			})
		} else {
			/**
			 *  For non secured request we attach the anonimous
			 *  user token to trace the user
			 */

			this.instance.interceptors.request.use(tokenProvider({
				getToken: () => {
					const user = firebase.auth().currentUser

					if (user) {
						return user.getIdToken().then(result => result)
					}

					return undefined
				},
			}))
		}

		if (secured) {

			/**
			 *  Credentials may be out of date, add response
			 *  interceptor to perform refresh token flow and
			 *  repeat the last request
			 */

			this.instance.interceptors.response.use(undefined, (error) => {
				const { response } = error

				if (response
					&& response.status === 401
					&& response.config
					&& response.config.headers['x-retry-request'] !== 'retry'
					&& response.config.headers['x-impersonated-request'] !== 'impersonated') {
					return new Promise(async (resolve) => {
						// Update Token
						if (error.config && error.config.headers) {
							// Get firebase user
							const user = firebase.auth().currentUser
							const previousToken = localStorage.getItem('accessToken')
							const role = previousToken ? JWT.parseToken(previousToken).role : ''

							if (user) {
								// Get id token from the current user
								const token = await user.getIdToken(true)

								if (role === 'Usuario') {

									// Get new AccessToken from correct endpoint
									axios.get(`${this.baseURL}/public/identity/usuarios/token`, {
										headers: {
											authorization: `Bearer ${token}`
										}
									}).then((response) => {
										// Set accessToken in localStorage
										localStorage.setItem('accessToken', response.data.value)
										// Mark the retry request as retry
										error.config.headers['x-retry-request'] = 'retry'
										// Change the bearer to new token
										error.config.headers['Authorization'] = `Bearer ${token}`

										// Repeat the request with the
										// renewed acess token
										resolve(this.instance(error.config))
									}).catch(() => {
										// Mark the retry request as retry
										error.config.headers['x-retry-request'] = 'retry'

										// Repeath the request with the old access token
										// it will fail and the user will be signed out
										resolve(this.instance(error.config))
									})
								} else if (role === 'Cliente') {
									//Try Get Access token again if user are client

									axios.get(`${this.baseURL}/public/identity/clientes/token`, {
										headers: {
											authorization: `Bearer ${token}`
										}
									}).then((response) => {
										// Set accessToken in localStorage
										localStorage.setItem('accessToken', response.data.value)
										// Mark the retry request as retry
										error.config.headers['x-retry-request'] = 'retry'
										// Change the bearer to new token
										error.config.headers['Authorization'] = `Bearer ${token}`

										// Repeat the request with the
										// renewed acess token
										resolve(this.instance(error.config))
									}).catch(() => {
										// Mark the retry request as retry
										error.config.headers['x-retry-request'] = 'retry'

										// Repeath the request with the old access token
										// it will fail and the user will be signed out
										resolve(this.instance(error.config))
									})
								} else {
									// Mark the retry request as retry
									error.config.headers['x-retry-request'] = 'retry'

									// Repeath the request with the old access token
									// it will fail and the user will be signed out
									resolve(this.instance(error.config))
								}
							}
						}
					});
				} else if (response
					&& response.status === 401
					&& response.config
					&& response.config.headers['x-retry-request'] === 'retry') {
					firebase.auth().signOut()
						.then(() => {
							Store.dispatch(SessionActions.userLogoutSession())
						})
				} else if (response
					&& response.status === 401
					&& response.config
					&& response.config.headers['x-impersonated-request'] === 'impersonated') {
                    // Force logout
                    Store.dispatch(SessionActions.userLogoutSession())
                    // Return reject with special message
                    // tp prevent to show as a error
                    // on the UI
                    return Promise.reject(
                        new Error('IMPERSONATED_SESSION_EXPIRED')
                    );
				}

				return Promise.reject(error)
			})
		}

		// We catch request error due a request error
		// and retry it again
		this.instance.interceptors.response.use(undefined, (error) => {
			if (error.response) {
				return Promise.reject(error)
			}
            
            if (error.request && error.config.headers['x-impersonated-request'] === 'impersonated') {
                return new Promise.resolve()
            }

			return Promise.reject(error)
		})

		// We catch request error due a request error
		// in the retry
		this.instance.interceptors.response.use(null, (error) => {
			if (error.response) {
				return Promise.reject(error)
			}

			if (error.request) {
				if (error.message && error.message.includes('timeout')) {
					return Promise.reject(
						new Error('Esto está tardando demasiado. Asegúrate de tener conexión a internet e intenta nuevamente!'),
					)
				}

				if (error.message && error.message.includes('Network Error')) {
					return Promise.reject(
						new Error('Ups! Algo ha fallado debido a un error en la red de internet. Intenta nuevamente!'),
					)
				}

				if (error.message && error.message.includes('Request aborted')) {
					return Promise.reject(
						new Error('Ups! Algo ha fallado debido a un error en la red de internet. Intenta nuevamente!'),
					)
				}
			}

			return Promise.reject(error)
		})
	}

}
