import React from 'react'
import { Typography } from 'antd'
import moment from 'moment'
const processString = require('react-process-string')

const ChatMessage = React.forwardRef((props, ref) => {

	const { message, me, time } = props


	let config = [{
		regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
		fn: (key, result) =>
			<span key={key}>
				<a
					target="_blank"
					href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}
					style={{
						color: me ? '#c3205b' : "#fff",
						backgroundColor: me ? 'rgba(238,238,238,0.43)' : '#fb5b88',
						borderRadius: 3,
						padding: 1,
						textDecoration: 'underline'
					}}
				>
					{result[2]}.{result[3]}{result[4]}
				</a>
				{result[5]}
			</span>
	}, {
		regex: /(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
		fn: (key, result) =>
			<span key={key}>
				<a
					target="_blank" href={`http://${result[1]}.${result[2]}${result[3]}`}
					style={{
						color: me ? '#c3205b' : "#fff",
						backgroundColor: me ? 'rgba(238,238,238,0.43)' : '#fb5b88',
						borderRadius: 3,
						padding: 1,
						textDecoration: 'underline'
					}}
				>
					{result[1]}.{result[2]}{result[3]}
				</a>
				{result[4]}
			</span >
	}]

	return (
		<>
			{time && <div
				style={{
					width: '100%',
					padding: 3,
					display: 'flex',
					justifyContent: me ? 'flex-end' : 'flex-start',
					marginBottom: 5,
				}}>
				<div>
					<Typography.Text
						type="secondary"
						style={{
							fontSize: 11
						}}>
						{moment(moment.utc(time).toDate()).format("DD/MM/YY - h:mmA")}
					</Typography.Text>
				</div>
			</div>}
			<div
				ref={ref}
				style={{
					maxWidth: '80%',
					borderRadius: 8,
					border: me ? '1px solid #ddd' : 'none',
					backgroundColor: me ? 'rgba(238,238,238,0.43)' : '#C3205B',
					padding: '8px 12px',
					color: me ? '#555' : '#fff',
					display: 'flex',
					alignSelf: me ? 'flex-end' : 'flex-start',
					flexDirection: me ? 'row' : 'row-reverse',
					marginBottom: time ? 0 : 5,
				}}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'flex-start',
						minWidth: 10,
						wordBreak: 'break-all'
					}}>
					<Typography.Paragraph
						style={{
							color: me ? '#555' : '#fff',
							margin: 0
						}}>
						{processString(config)(message)}
					</Typography.Paragraph>
				</div>
				<div
					style={{
						display: 'flex',
						alignItems: 'flex-start',
						justifyContent: me ? 'flex-end' : 'flex-start',
						minWidth: 30,
					}}>
					<ion-icon
						name="person-circle-outline"
						style={{
							fontSize: 18,
						}}></ion-icon>
				</div>
			</div>

		</>
	)
})

export default ChatMessage