import React, { useEffect, useState, useCallback } from 'react'
import { Table, Typography } from 'antd'
import { connect } from 'react-redux'
import { UserStatsActions } from 'redux/actions'
import moment from 'moment'
import { AnDayPicker } from 'components/an'
import { PageHeader } from 'components'

const UserProveedorIncomesInfoScreen = React.memo((props) => {
	const {
		user,
		loading,
		data,
		dateTo,
		setDateTo,
		dateFrom,
		setDateFrom,
		getPayRevenueList,
		clearState,

		page,
		pageCount,
		totalItems,
	} = props

	const [currentPage, setCurrentPage] = useState(0)

	useEffect(() => {
		setCurrentPage(page)
	}, [page])

	useEffect(() => {
		if (user) {
			getPayRevenueList({
				sortOrder: "asc",
				page: currentPage
			}, null)
		}
	}, [currentPage, dateTo, dateFrom])

	useEffect(() => {
		//Nothing to do. Only clear state when unmount
		return () => {
			clearState()
		}
	}, [])

	const [dataSource, setDataSource] = useState([])
	const columns = [
		{
			title: 'Fecha',
			dataIndex: 'date',
			key: 'date',
			align: 'center',
			width: 80,
			ellipsis: {
				showTitle: false,
			},
		},
		{
			title: 'Experiencia',
			dataIndex: 'experience',
			key: 'experience',
			ellipsis: {
				showTitle: false,
			},
		},
		{
			title: 'Estado',
			dataIndex: 'state',
			key: 'state',
			ellipsis: {
				showTitle: false,
			},
		},
		{
			title: 'Valor',
			dataIndex: 'value',
			key: 'value',
			width: 120,
			align: 'right',
			ellipsis: {
				showTitle: false,
			},
			render: value => {
				if (value.state === 2) {
					return <Typography.Text>{value.mount}</Typography.Text>
				} else {
					return <Typography.Text delete type='secondary'>{value.mount}</Typography.Text>
				}
			},
		},
		{
			title: 'Neto',
			dataIndex: 'net',
			key: 'net',
			align: 'right',
			width: 120,
			ellipsis: {
				showTitle: false,
			},
			render: net => {
				if (net.state === 2) {
					return <Typography.Text strong>{net.mount}</Typography.Text>
				} else {
					return <Typography.Text delete type='secondary'>{net.mount}</Typography.Text>
				}
			},
		},

	]

	//updaters

	useEffect(() => {
		if (data && data.length > 0) {
			setDataSource(() => {
				return data.map(data => {
					return {
						key: data?.id,
						date: moment(data?.creado).format('DD/MM'),
						experience: data?.experiencia ? data.experiencia.nombre : 'No hay información',
						state: data.estado === 2 ? 'Vigente' : 'Cancelada',
						value: typeof data.total === 'number' ?
							{
								mount: new Intl.NumberFormat('es-AR', {
									maximumFractionDigits: 2,
									style: "currency",
									currency: "ARS"
								}).format(data.total),
								state: data.estado
							}
							:
							{
								state: 2,
								mount: 'No hay información'
							},
						net: typeof data.subTotalProveedor === 'number' ?
							{
								mount: new Intl.NumberFormat('es-AR', {
									maximumFractionDigits: 2,
									style: "currency",
									currency: "ARS"
								}).format(data.subTotalProveedor),
								state: data.estado
							}
							:
							{
								state: 2,
								mount: 'No hay información'
							},
					}
				})
			})
		} else {
			setDataSource([])
		}
	}, [data])

	//handlers

	const handleDateFromSelect = useCallback((e) => {
		setDateFrom(new Date(e))
		setCurrentPage(0)
	}, [setDateFrom])

	const handleDateToSelect = useCallback((e) => {
		setDateTo(new Date(e))
		setCurrentPage(0)
	}, [setDateTo])

	const handleOnChangePage = useCallback((page) => {
		setCurrentPage(page - 1)
	})

	return (
		<div
			className="main">
			<PageHeader
				title="Ingresos"
				subtitle="Ingresos generados por reservas"
			/>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					margin: '10px 15px 0px'
				}}
			>
				<AnDayPicker
					className='stats-day-picker'
					title='Seleccionar fecha "Desde"'
					value={dateFrom}
					withIcon={false}
					onChange={handleDateFromSelect}
					dayPickerProps={{
						disabledDays: [
							{
								after: new Date(dateTo)
							}
						]
					}}
				/>
                    -
                    <AnDayPicker
					className='stats-day-picker'
					title='Seleccionar fecha "Hasta"'
					value={dateTo}
					withIcon={false}
					onChange={handleDateToSelect}
					dayPickerProps={{
						disabledDays: [
							{
								before: new Date(dateFrom),
								after: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
							}
						]
					}}
				/>
			</div>

			<div
				style={{
					marginTop: 10
				}}>
				<Table
					loading={loading}
					dataSource={dataSource}
					columns={columns}
					pagination={{
						position: ['bottomCenter'],
						pageSize: pageCount,
						current: currentPage + 1,
						total: totalItems,
						onChange: handleOnChangePage,
						showSizeChanger: false
					}}
					locale={{ emptyText: 'No hay registros entre las fechas seleccionadas' }}
				/>
			</div>
		</div >
	)

})

const mapStateToProps = (state) => ({
	user: state.context.user,
	data: state.userStats.data,
	loading: state.userStats.loading,
	dateFrom: state.userStats.dateFrom,
	dateTo: state.userStats.dateTo,

	page: state.userStats.page,
	pageCount: state.userStats.pageCount,
	totalItems: state.userStats.totalItems,
})

const mapDispatchToProps = (dispatch) => ({
	getPayRevenueList: (params, criteria) => dispatch(UserStatsActions.getPayRevenueList(params, criteria)),
	setDateFrom: dateFrom => dispatch(UserStatsActions.setDateFrom(dateFrom)),
	setDateTo: dateTo => dispatch(UserStatsActions.setDateTo(dateTo)),
	clearState: () => dispatch(UserStatsActions.clearState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProveedorIncomesInfoScreen);
