const defaultState = {
	loading: false,
	conversationsList: [],
	messagesList: [],
	sending: false,
	sendingSuccess: false,
	pageCount: 0,
	totalItems: 0,
	page: 0,
	hasMore: true,
	badge: 0
}

export default function reducer(state = defaultState, action) {
	switch (action.type) {

		case 'CONVERSATIONS_FETCH_LIST_START':
			return Object.assign({}, state, {
				loading: true,
			})

		case 'CONVERSATIONS_FETCH_LIST_SUCCESS':
			return Object.assign({}, state, {
				conversationsList: [].concat(action.payload.conversationsList),
				pageCount: action.payload.pageCount,
				totalItems: action.payload.totalItems,
				page: action.payload.page,
				loading: false,
				hasMore: action.payload.hasMore,
			})

		case 'CONVERSATIONS_FETCH_LIST_FAILURE':
			return Object.assign({}, state, {
				loading: false,
			})

		case 'CONVERSATIONS_FETCH_MORE_LIST_START':
			return Object.assign({}, state, {
				loadingMoreResults: true,
			})

		case 'CONVERSATIONS_FETCH_MORE_LIST_SUCCESS':
			return Object.assign({}, state, {
				conversationsList: state.conversationsList.concat(action.payload.conversationsList),
				pageCount: action.payload.pageCount,
				totalItems: action.payload.totalItems,
				page: action.payload.page,
				hasMore: action.payload.hasMore,
				loadingMoreResults: false,
			})

		case 'CONVERSATIONS_FETCH_MORE_LIST_FAILURE':
			return Object.assign({}, state, {
				loadingMoreResults: false,
			})

		case 'CONVERSATIONS_FETCH_MESSAGES_START':
			return Object.assign({}, state, {
				loading: true,
			})

		case 'CONVERSATIONS_FETCH_MESSAGES_SUCCESS':
			return Object.assign({}, state, {
				loading: false,
				messagesList: [].concat(action.payload.messagesList),
			})

		case 'GECONVERSATIONS_FETCH_MESSAGES_FAILURE':
			return Object.assign({}, state, {
				loading: false,
			})

		case 'CONVERSATIONS_SEND_NEW_MESSAGE_START':
			return Object.assign({}, state, {
				sending: true,
				sendingSuccess: false,
			})

		case 'CONVERSATIONS_SEND_NEW_MESSAGE_SUCCESS':
			return Object.assign({}, state, {
				sending: false,
				sendingSuccess: true
			})

		case 'CONVERSATIONS_SEND_NEW_MESSAGE_FAILURE':
			return Object.assign({}, state, {
				sending: false,
				sendingSuccess: false,
			})

		case 'CONVERSATIONS_FETCH_MORE_MESSAGES_START':
			return Object.assign({}, state, {
				loadingMoreResults: true,
			})

		case 'CONVERSATIONS_FETCH_MORE_MESSAGES_SUCCESS':
			return Object.assign({}, state, {
				messagesList: state.messagesList.concat(action.payload.messagesList),
				pageCount: action.payload.pageCount,
				totalItems: action.payload.totalItems,
				page: action.payload.page,
				hasMore: action.payload.hasMore,
				loadingMoreResults: false,
			})

		case 'CONVERSATIONS_FETCH_MORE_MESSAGES_FAILURE':
			return Object.assign({}, state, {
				loadingMoreResults: false,
				hasMore: false,
			})

		case 'CONVERSATIONS_FETCH_BADGE_SUCCESS':
			return Object.assign({}, state, {
				badge: action.payload.badge,
			})

		case 'CONVERSATIONS_FETCH_BADGE_FAILURE':
			return Object.assign({}, state, {
				badge: 0,
			})

		case 'CONVERSATIONS_CLEAR_BADGE_STATE':
			return Object.assign({}, state, {
				badge: 0,
			})

		case 'MESSAGES_CLEAR_STATE':
			return Object.assign({}, state, {
				loading: false,
				conversationsList: [],
				messagesList: [],
				sending: false,
				sendingSuccess: false,
				pageCount: 0,
				totalItems: 0,
				page: 0,
				hasMore: true,
			})
		default:
			return state;
	}
}
