import React, { useState, useEffect, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { AnButtonGradient, AnLabelForm, AnCalendar, AnDayPicker, AnSelect, AnCountSelect } from "components/an";
import { ScrollToTop } from "components";
import { Row, Col, Alert, Button, Typography, Divider } from "antd";
import moment from "moment";
import { BookingActions } from "redux/actions";
import Animate from "rc-animate";
import { BookingTypes, BookingTypesExplanationMap } from "constants/index";
import { useLocation, useHistory, useParams } from "react-router-dom";

const BookingStepOne = React.memo((props) => {
    const {
        experience,
        experienceEvents,
        selectedDay,
        setSelectedDay,
        setBookingType,
        bookingType,
        setHourOption,
        hourOption,
        setSavedDate,
        peopleQuantity,
        setPeopleQuantity,
        finalPrice,
        setFinalPrice,
        savedDate,
        nextStep,
    } = props;

    const location = useLocation();
    const history = useHistory();

    /* ----------------
        Memo
    ------------------------ */

    // We map events as array of Javacript Date Objects
    // We need it to pass this array
    // to the calendar
    const selectedDays = useMemo(() => {
        return experienceEvents.map((date) => {
            return new Date(moment.utc(date.dayAndHour).format("YYYY/M/DD"));
        });
    }, [experienceEvents]);

    const selectedDayAsISOString = useMemo(() => {
        return experienceEvents.map((date) => {
            if (date.bookings !== date.capacity) {
                return moment.utc(date.dayAndHour).format("YYYY/M/DD");
            }
        });
    }, [experienceEvents]);

    const availableBookingTypesOptions = useMemo(() => {
        return experience.bookingType
            .filter((option) => option)
            .map((option) => {
                switch (option) {
                    case "complete":
                        return {
                            id: 1,
                            name: "Pago total ahora",
                        };
                    case "withPay":
                        return {
                            id: 2,
                            name: "Pago parcial ahora, y el resto, lo Pago en destino",
                        };
                    case "withoutPay":
                        return {
                            id: 3,
                            name: "Pago en destino",
                        };
                    default:
                        return {
                            id: null,
                            name: "",
                        };
                }
            });
    }, [experience]);

    /* ----------------
        State
    ------------------------ */

    const [selectedDayEvents, setSelectedDayEvents] = useState([]);
    const [maxPeopleQuantity, setMaxPeopleQuantity] = useState(null);
    const [lastDate, setLastDate] = useState(new Date());

    /* ----------------
        Callbacks
    ------------------------ */

    const handleBookingType = useCallback(
        (value) => {
            setBookingType(value.id);
        },
        [setBookingType]
    );

    const handleDayClick = useCallback(
        (day, modifiers = {}) => {
            if (modifiers.disabled) {
                return;
            }

            setSelectedDay(day);
            setSelectedDayEvents(() => {
                return experienceEvents
                    .sort((a, b) => {
                        return new Date(a.dayAndHour) - new Date(b.dayAndHour);
                    })
                    .filter((event) => {
                        return moment.utc(event.dayAndHour).format("DD/M/YYYY") === moment.utc(day).format("DD/M/YYYY");
                    })
                    .filter((event) => {
                        return event.bookings !== event.capacity;
                    });
            });

            setHourOption(null);
            setPeopleQuantity(experience?.peopleQuantity);
            setMaxPeopleQuantity(null);
        },
        [
            experience.peopleQuantity,
            experienceEvents,
            setSelectedDay,
            setHourOption,
            setPeopleQuantity,
            setMaxPeopleQuantity,
            setSelectedDayEvents,
        ]
    );

    const handleRemoveSelectedDay = useCallback(() => {
        if (location.state?.selectedDay || location.state?.selectedHour) {
            // Replace history to override the location
            // state
            history.replace(`${location.pathname}?step=1`);

            // Reset values
            setSelectedDay(null);
            setHourOption(null);
            setPeopleQuantity(experience.peopleQuantity);
            setMaxPeopleQuantity(null);
            setSelectedDayEvents([]);
        } else {
            setSelectedDay(null);
            setHourOption(null);
            setPeopleQuantity(experience.peopleQuantity);
            setMaxPeopleQuantity(null);
            setSelectedDayEvents([]);
        }
    }, [
        history,
        experience.peopleQuantity,
        location.pathname,
        location.state,
        setSelectedDay,
        setHourOption,
        setPeopleQuantity,
        setMaxPeopleQuantity,
        setSelectedDayEvents,
    ]);

    const handleQuantityPeople = useCallback(
        (quantity) => {
            setPeopleQuantity(quantity);
        },
        [setPeopleQuantity]
    );

    const handleSelectHour = useCallback(
        (option) => {
            setHourOption(option.id);
        },
        [setHourOption]
    );

    const handleNextStep = useCallback(() => {
        const currentDate = selectedDayEvents.find((date) => date.id === hourOption);

        setSavedDate({
            experienceDateId: hourOption,
            dayAndHour: currentDate.dayAndHour,
            peopleQuantity,
            capacity: currentDate.capacity,
            bookings: currentDate.bookings,
        });
    }, [selectedDayEvents, setSavedDate, hourOption, peopleQuantity]);

    /* ----------------
        Effects
    ------------------------ */

    useEffect(() => {
        if (selectedDay) {
            setSelectedDayEvents(() => {
                return experienceEvents
                    .sort((a, b) => {
                        return new Date(a.dayAndHour) - new Date(b.dayAndHour);
                    })
                    .filter((event) => {
                        return (
                            moment.utc(event.dayAndHour).format("DD/M/YYYY") ===
                            moment.utc(selectedDay).format("DD/M/YYYY")
                        );
                    });
            });
        }
    }, [experienceEvents, selectedDay]);

    useEffect(() => {
        if (experience) {
            setPeopleQuantity(experience.peopleQuantity);
        }
    }, [experience, setPeopleQuantity]);

    // When we have all required data as
    // a saved date, then continue with the
    // next step
    useEffect(() => {
        if (savedDate?.experienceDateId) {
            nextStep();
        }
    }, [savedDate, nextStep]);

    useEffect(() => {
        if (selectedDays.length > 0) {
            setLastDate(() => {
                const order = selectedDays.map((item) => item.getTime()).sort();
                return new Date(order[order.length - 1]);
            });
        }
    }, [selectedDays]);

    useEffect(() => {
        if (hourOption && selectedDayEvents.length > 0) {
            const selectedEvent = selectedDayEvents.find((date) => date.id === hourOption);
            const maxPeopleCapacityForSelectedEvent =
                (selectedEvent.capacity - selectedEvent.bookings) * experience.peopleQuantity;

            // Set max availability por the selected
            // event
            setMaxPeopleQuantity(maxPeopleCapacityForSelectedEvent);
        }
    }, [hourOption, selectedDayEvents, experience.peopleQuantity]);

    useEffect(() => {
        if (peopleQuantity > 0) {
            const calculateFinalPrice = Math.ceil(peopleQuantity / experience.peopleQuantity) * experience.price;

            // Set final price based on people
            // quantity selection
            setFinalPrice(calculateFinalPrice);
        } else {
            setFinalPrice(0);
        }
    }, [peopleQuantity, experience.peopleQuantity, experience.price, setFinalPrice]);

    return (
        <div className="booking-calendar">
            <ScrollToTop />

            <div className="main-container">
                <AnLabelForm
                    label="Forma de cobro"
                    tooltip
                    tooltipText="Seleccione alguna de las formas de cobro ofrecidas por el proveedor de la experiencia"
                />

                <AnSelect
                    shape="round"
                    placeholder="Seleccionar forma de cobro"
                    async={false}
                    options={availableBookingTypesOptions}
                    onChange={handleBookingType}
                    value={{ id: bookingType, name: BookingTypes[bookingType] }}
                />

                <Animate transitionName="fade" transitionAppear>
                    {!!bookingType && (
                        <>
                            <Divider />

                            <Alert
                                message="Leer antes de continuar:"
                                description={BookingTypesExplanationMap[bookingType].message}
                                type="warning"
                                showIcon
                                style={{
                                    marginTop: 15,
                                    borderRadius: 10,
                                }}
                            />
                        </>
                    )}
                </Animate>

                <Divider />

                {!selectedDay ? (
                    <>
                        <AnLabelForm
                            label="Seleccionar fecha"
                            tooltip
                            tooltipText="Elija la fecha en que desea reservar seleccionando alguno de los días disponibles"
                        />

                        <AnCalendar
                            onDayClick={handleDayClick}
                            toMonth={lastDate}
                            disabledDays={(day) => {
                                return selectedDayAsISOString.indexOf(moment.utc(day).format("YYYY/M/DD")) === -1;
                            }}
                            events={experienceEvents}
                            selectedDays={selectedDays}
                            selectedDay={selectedDay}
                        />
                    </>
                ) : (
                    <>
                        <Row gutter={[0, 20]}>
                            <Col span={24}>
                                <AnLabelForm label="Día" />

                                <AnDayPicker
                                    shape="round"
                                    value={new Date(selectedDay)}
                                    onClick={handleRemoveSelectedDay}
                                />
                            </Col>
                        </Row>

                        <Row gutter={[0, 20]}>
                            <Col span={24}>
                                <AnLabelForm
                                    label="Horario"
                                    tooltip
                                    tooltipText="Horarios disponibles para la fecha seleccionada"
                                />

                                <AnSelect
                                    shape="round"
                                    placeholder="Seleccionar horario"
                                    onChange={handleSelectHour}
                                    value={{ id: hourOption }}
                                    options={selectedDayEvents.map((date) => ({
                                        id: date.id,
                                        name: moment.utc(date.dayAndHour).format("HH:mm"),
                                    }))}
                                />
                            </Col>
                        </Row>

                        <Row gutter={[0, 20]}>
                            <Col span={24}>
                                <AnLabelForm label="Cantidad de personas" />

                                <AnCountSelect
                                    shape="round"
                                    value={peopleQuantity}
                                    maxLimit={maxPeopleQuantity}
                                    onChange={(quantity) => handleQuantityPeople(quantity)}
                                />
                            </Col>
                        </Row>

                        {typeof maxPeopleQuantity === "number" && (
                            <Row gutter={[0, 5]}>
                                <Col span={24}>
                                    {maxPeopleQuantity > 0 ? (
                                        <Typography.Text
                                            type="primary"
                                            size={13}
                                            style={{
                                                marginLeft: 10,
                                            }}
                                        >
                                            {`Capacidad máx en este horario: ${maxPeopleQuantity} personas`}
                                        </Typography.Text>
                                    ) : (
                                        <Typography.Text
                                            type="secondary"
                                            style={{
                                                marginLeft: 10,
                                            }}
                                        >
                                            Este horario se encuentra totalmente ocupado
                                        </Typography.Text>
                                    )}
                                </Col>
                            </Row>
                        )}

                        <Divider />

                        <div className="summary-items-container">
                            <div className="summary-item-container">
                                <div className="summary-title-container">
                                    <Typography.Text className="title">Capacidad x reserva:</Typography.Text>
                                </div>

                                <div className="summary-value-container">
                                    <Typography.Text type="secondary" ellipsis>
                                        {experience.peopleQuantity} personas
                                    </Typography.Text>
                                </div>
                            </div>

                            <div className="summary-item-container">
                                <div className="summary-title-container">
                                    <Typography.Text className="title">Precio x reserva:</Typography.Text>
                                </div>

                                <div className="summary-value-container">
                                    <Typography.Text type="secondary" ellipsis>
                                        {`$${experience.price}`}
                                    </Typography.Text>
                                </div>
                            </div>

                            <div className="summary-item-container">
                                <div className="summary-title-container">
                                    <Typography.Text className="title">Cantidad de reservas:</Typography.Text>
                                </div>

                                <div className="summary-value-container">
                                    <Typography.Text type="secondary" ellipsis>
                                        {Math.ceil(peopleQuantity / experience?.peopleQuantity)}
                                    </Typography.Text>
                                </div>
                            </div>

                            {bookingType && (
                                <>
                                    <div className="summary-item-container">
                                        <div className="summary-title-container">
                                            <Typography.Text className="title">Pago en app:</Typography.Text>
                                        </div>

                                        <div className="summary-value-container">
                                            <Typography.Text type="secondary" ellipsis>
                                                {bookingType === 1 && `$${finalPrice}`}
                                                {bookingType === 2 &&
                                                    `$${(finalPrice * experience?.bookingPercentage).toFixed(2)}`}
                                                {bookingType === 3 && `$${0}`}
                                            </Typography.Text>
                                        </div>
                                    </div>

                                    <div className="summary-item-container">
                                        <div className="summary-title-container">
                                            <Typography.Text className="title">Pago en destino:</Typography.Text>
                                        </div>

                                        <div className="summary-value-container">
                                            <Typography.Text type="secondary" ellipsis>
                                                {bookingType === 1 && `$${0}`}
                                                {bookingType === 2 &&
                                                    `$${(
                                                        finalPrice -
                                                        finalPrice * experience?.bookingPercentage
                                                    ).toFixed(2)}`}
                                                {bookingType === 3 && `$${finalPrice}`}
                                            </Typography.Text>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="summary-item-container">
                                <div className="summary-title-container">
                                    <Typography.Text strong className="title">
                                        Total
                                    </Typography.Text>
                                </div>

                                <div className="summary-value-container">
                                    <Typography.Text strong type="secondary" ellipsis>
                                        {`$${finalPrice}`}
                                    </Typography.Text>
                                </div>
                            </div>
                        </div>

                        <Divider />

                        <Row align="center" justify="end">
                            <Col>
                                <AnButtonGradient
                                    type="primary"
                                    shape="round"
                                    size="large"
                                    disabled={
                                        !(!!bookingType && peopleQuantity <= maxPeopleQuantity) ||
                                        !hourOption ||
                                        !peopleQuantity
                                    }
                                    onClick={handleNextStep}
                                >
                                    Siguiente
                                </AnButtonGradient>
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        </div>
    );
});

const mapStateToProps = (state) => ({
    selectedDay: state.booking.selectedDay,

    bookingType: state.booking.bookingType,
    hourOption: state.booking.hourOption,
    peopleQuantity: state.booking.peopleQuantity,
    finalPrice: state.booking.finalPrice,

    savedDate: state.booking.savedDate,
});

const mapDispatchToProps = (dispatch) => ({
    setBookingType: (bookingType) => dispatch(BookingActions.setBookingType(bookingType)),
    setSelectedDay: (selectedDay) => dispatch(BookingActions.setSelectedDay(selectedDay)),
    setHourOption: (hourOption) => dispatch(BookingActions.setHourOption(hourOption)),
    setPeopleQuantity: (peopleQuantity) => dispatch(BookingActions.setPeopleQuantity(peopleQuantity)),
    setFinalPrice: (finalPrice) => dispatch(BookingActions.setFinalPrice(finalPrice)),
    setSavedDate: (savedDate) => dispatch(BookingActions.setSavedDate(savedDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingStepOne);
