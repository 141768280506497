import RestClient from 'http/RestClient'
import axios from 'axios'

const CancelToken = axios.CancelToken

let cancel

export default class TagRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(
            undefined,
            useCredentials,
            refreshContext,
        )
    }

    getTags(params, criteria) {
        if (typeof cancel === 'function') cancel()

		const url = `${this.baseURL}/public/tags`

		return this.instance.get(url, {
			cancelToken: new CancelToken(function executor(c) {
				// An executor function receives a cancel function as a parameter
				cancel = c;
			}),
			params: {
				page: params?.page,
                pageCount: params?.pageCount,
                nombre: criteria?.name,
                inactivo: criteria?.inactive ? true : false,
			},
		})
    }
}