import React, { useState, useEffect } from 'react'
import { Button, Form, Typography } from 'antd'
import { AnButtonGradient, AnInput } from 'components/an'
import { ScrollToTop, Loader, ResetPasswordModal } from 'components'
import Animate from 'rc-animate'


const LoginUserEmailAndPass = React.memo((props) => {
    const {
        isMobile,
        image,
        loadingList,
        loginUserWithEmailAndPass,
        sendEmailToResetPassword,
        loadingEmailLink,
        sendEmailSuccess,
        clearState,
    } = props

    const [resetPasswordShow, setResetPasswordShow] = useState(false)
    const [show, setShow] = useState(isMobile ? false : true)

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 500)
    }, [setShow])

    const onFinish = values => {
        loginUserWithEmailAndPass(values)
    }

    const onClickResetPassword = () => {
        setResetPasswordShow(true)
    }

    return (
        <>
            <ScrollToTop behavior="auto" />

            <Loader loading={!show} />

            <Animate
                transitionName="zoom"
                transitionAppear
            >
                {(show && (
                    <div className="login-screen-iwrap">
                        <div className='login-img'>
                            <img
                                src={image}
                                alt='Login Spiderweb'
                            />
                        </div>

                        <div className="login-comp-container">
                            <h1
                                style={{
                                    margin: 0
                                }}
                            >
                                Ingresar
                            </h1>

                            <Typography.Text
                                type='secondary'
                                style={{
                                    marginBottom: 15,
                                    textAlign: 'center'
                                }}
                            >
                                Debes tener una cuenta usuario (proveedor o promotor)
                            </Typography.Text>

                            <Form
                                block
                                name="register"
                                onFinish={onFinish}
                                scrollToFirstError
                                size='large'
                                style={{
                                    width: '100%'
                                }}
                            >
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            type: "email",
                                            message: "No es un correo válido"
                                        },
                                        {
                                            required: true,
                                            message: "Ingresa un correo válido"
                                        }
                                    ]}
                                >
                                    <AnInput
                                        placeholder='Email'
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Ingresá una contraseña válida"
                                        }
                                    ]}
                                    hasFeedback
                                >
                                    <AnInput
                                        placeholder='Contraseña'
                                        type='password'
                                    />
                                </Form.Item>

                                <Form.Item
                                    noStyle
                                >
                                    <AnButtonGradient
                                        block
                                        type="primary"
                                        shape='round'
                                        loading={loadingList.email}
                                        htmlType="submit"
                                    >
                                        Ingresar
                                    </AnButtonGradient>

                                </Form.Item>

                                <Form.Item
                                    noStyle
                                >
                                    <Button
                                        type="link"
                                        onClick={onClickResetPassword}
                                        style={{
                                            width: '80%',
                                            position: 'relative',
                                            left: '10%',
                                            padding: 15
                                        }}
                                    >
                                        Olvidé mi contraseña
                                    </Button>
                                </Form.Item>
                            </Form>


                            {resetPasswordShow && (
                                <ResetPasswordModal
                                    sendEmailToResetPassword={sendEmailToResetPassword}
                                    loadingEmailLink={loadingEmailLink}
                                    sendEmailSuccess={sendEmailSuccess}
                                    clearState={clearState}
                                    onClose={() => {
                                        setResetPasswordShow(false)
                                    }}
                                />
                            )}
                        </div>
                    </div>
                ))}
            </Animate>
        </>
    )
})

export default LoginUserEmailAndPass