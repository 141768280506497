import React, { useState, useCallback, } from 'react'
import { Badge, Button } from 'antd'
import { AnInput } from 'components/an'

const SearchBar = React.memo((props) => {

    const {
        searchTextValue,
        onSearchByText,
        onClickSearchFilters,
        hasFilters = false
    } = props;

    const [text, setText] = useState(searchTextValue)

    const onChangeText = useCallback((event) => {
        setText(event.target.value)
        onSearchByText(event.target.value)
    }, [onSearchByText])

    return (
        <div className='search-container sticky'>
            <AnInput
                className="input primary right-space"
                placeholder='Buscar experiencia ...'
                value={text}
                onChange={onChangeText}
            />
            <Badge
                dot={hasFilters}
                offset={[-8, 5]}
                style={{ backgroundColor: "#c3125a" }}
            >
                <Button
                    shape="circle"
                    size="large"
                    className="button primary"
                    onClick={() => {
                        onClickSearchFilters()
                    }}
                >
                    <ion-icon
                        name="options-outline"
                        style={{
                            fontSize: 24,
                            color: '#C3125A'
                        }}
                    />
                </Button>
            </Badge>
        </div>
    );
})

export default SearchBar;