import React, { useState, useEffect } from 'react'
import { AnModal, AnButton } from 'components/an'
import { Button, Empty, Input, Spin, Typography } from 'antd'
import { Link } from 'react-router-dom'
import RenderChatMessages from 'components/chat/RenderChatMessages'
import { usePrevious } from 'hooks/usePrevious'
import { useInterval } from 'hooks/useInterval'

const NewMessageModal = React.memo((props) => {
	const {
		context,
		user,
		title,
		experienceId,
		addressee,
		sendMessage,
		loadingMessage,
		messagesList,
		clear = false,
		clearMessageState,
		onClose,
		getMessages,
		getMoreMessages,
		sending,
		sendingSuccess,
		loadingMoreResults,
		page,
		hasMore,
		isOwn,
	} = props

	const [messageInput, setMessageInput] = useState(null)
	const prevSending = usePrevious(sending)

	useEffect(() => {
		if (user.role !== "Anonimous") {
			let userProviderId = user.role === 'Usuario' ? user.sub : addressee.id
			getMessages(true, experienceId, userProviderId, addressee.id, addressee.type)
		}
	}, [getMessages, experienceId, user])

	useEffect(() => {
		//Nothing to do.. 
		//Just clear state on unmount
		return () => {
			if (clear) {
				clearMessageState()
			}
		}
	}, [clearMessageState])

	useEffect(() => {
		if (!(prevSending == undefined) &&
			prevSending !== sending &&
			!sending) {
			let userProviderId = user.role === 'Usuario' ? user.sub : addressee.id
			getMessages(false, experienceId, userProviderId, addressee.id, addressee.type)
		}
	}, [sending])

	//polling messages
	useInterval(() => {
		if (user?.role !== 'Anonimous') {
			let userProviderId = user.role === 'Usuario' ? user.sub : addressee.id
			getMessages(false, experienceId, userProviderId, addressee.id, addressee.type)
		}
	}, 7000)

	const back = () => {
		onClose()
	}

	const handleMessageInput = (e) => {
		setMessageInput(e.target.value)
	}

	const pressSendMessage = () => {
		sendMessage(experienceId, addressee, messageInput)
	}

	useEffect(() => {
		if (sendingSuccess) {
			setMessageInput(null)
		}
	}, [sendingSuccess])

	return (
		<AnModal
			full
			headerTitle="Nuevo mensaje"
			headerButtons={(
				<AnButton
					ghost
					type='primary'
					shape='round'
					size='large'
					onClick={() => {
						back()
					}}
				>
					Cerrar
				</AnButton>
			)}
		>
			<div
				style={{
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
				}}>
				<div
					style={{
						width: '100%',
						padding: 10,
						borderBottom: '1px solid #ddd',
						position: 'sticky',
						zIndex: 100,
						top: 0,
						backgroundColor: '#fff',
					}}
				>
					<Typography.Title
						level={5}
						ellipsis
						style={{
							margin: 0
						}}
					>
						{title ?
							title
							:
							'Titulo no disponible'}
					</Typography.Title>
					<Typography.Text
						type="secondary"
						ellipsis
						style={{
							fontSize: 14,
							margin: 0
						}}
					>
						{addressee?.name ?
							addressee?.name
							:
							'Nombre no disponible'}
					</Typography.Text>
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						width: '100%',
						flex: 8,
						overflow: 'auto',
						paddingBottom: '60px'
					}}
				>
					{user && user.role !== "Anonimous" ?
						loadingMessage ?
							<div
								style={{
									display: 'flex',
									flex: 1,
									alignItems: 'center',
									justifyContent: 'center',
									height: '100%',
									width: '100%',
								}}
							>
								<Spin
									size='large'
									style={{
										padding: 20
									}}
								/>
							</div >
							:
							messagesList.length > 0 ?
								<div
									style={{
										width: '100%',
										height: '100%',
										maxHeight: '100%',
										position: 'relative',
										padding: '15px 0'
									}}>
									<RenderChatMessages
										user={user}
										messagesList={messagesList}
										getMoreMessages={getMoreMessages}
										loadingMoreResults={loadingMoreResults}
										hasMore={hasMore}
										page={page}
										experienceId={experienceId}
										userProviderId={user.sub}
										senderId={addressee.id}
										type={addressee.type}
									/>
								</div>
								:
								<div
									style={{
										overflowY: 'auto',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										padding: '20px 0px 40px',
										flex: 4,
									}}
								>
									<Empty
										description={addressee?.type === 1 ?
											"Inicia una consulta o dejale un aviso al cliente"
											:
											"Envía la consulta que quieras. El proveedor te estará respondiendo a la brevedad"}
										image={(
											<ion-icon name="chatbubbles-outline" style={{ fontSize: 90, color: '#c8c8c8' }}></ion-icon>
										)}
									/>
								</div>
						:
						<div
							style={{
								overflowY: 'auto',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								padding: 20,
								flex: 4,
							}}
						>
							<Empty
								description="Para poder hacer consultas debes iniciar sesión"
								image={(
									<ion-icon name="chatbubbles-outline" style={{ fontSize: 90, color: '#c8c8c8' }}></ion-icon>
								)}
							/>
							<Typography.Text
								type='secondary'>
								Inicia sesión <Link to='/login'>aquí</Link>
							</Typography.Text>
						</div>
					}
				</div>

				{user && user.role !== "Anonimous" && //show input message if user or client is login
					<div
						style={{
							flex: 1,
							width: 'inherit',
							maxWidth: 'inherit',
							backgroundColor: '#F4F4F4',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							padding: 10,
							position: 'fixed',
							bottom: 0
						}}
					>
						{(user.role === 'Cliente' ||
							(user.role === 'Usuario' && context?.impersonated)) ||
							!isOwn ?
							<>
								<Input.TextArea
									autoSize={{ minRows: 1, maxRows: 4 }}
									onChange={handleMessageInput}
									value={messageInput}
									placeholder='Escribe tu mensaje..'
									style={{
										borderRadius: 8,
										padding: '8px 11px'
									}}
								>
								</Input.TextArea>
								<Button
									type="link"
									onClick={pressSendMessage}
									loading={sending}
									disabled={!messageInput || sending || loadingMessage}
								>
									Enviar
								</Button>
							</>
							:
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									flexDirection: 'column'
								}}>

								<Typography.Title
									style={{
										fontSize: 16,
										textAlign: 'center',
										margin: 0
									}}
									ellipsis
								>
									Este chat está en modo sólo lectura
								</Typography.Title>

								<Typography.Text
									type="secondary"
									ellipsis
									style={{
										fontSize: 11,
										textAlign: 'center',
										margin: 0
									}}
								>
									No podrás responder ni consultar
								</Typography.Text>
							</div>}
					</div>}
			</div>
		</AnModal>
	)
})

export default NewMessageModal