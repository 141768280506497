import React, { useEffect } from 'react'
import {
    Switch,
    Route,
    Redirect,
    useHistory,
} from "react-router-dom"

import { connect } from 'react-redux'
import { LoginActions, RegisterActions, SessionActions } from 'redux/actions'
import { isMobile } from 'react-device-detect'

import LoginClientStepOne from 'screens/LoginScreen/components/LoginClientStepOne'
import LoginClientStepTwo from 'screens/LoginScreen/components/LoginClientStepTwo'
import LoginUserSignIn from 'screens/LoginScreen/components/LoginUserSignIn'
import LoginUserEmailAndPass from 'screens/LoginScreen/components/LoginUserEmailAndPass'
import LoginAction from 'screens/LoginScreen/components/LoginAction'

import bkg from 'assets/images/login-bkg.png'

const LoginScreen = React.memo((props) => {
    const {
        loginState,
        signInWithEmailLink,
        sendSignInLinkToEmail,
        handleEmailClientInput,
        loginUser,
        loginLoadingList,
        loginUserWithEmailAndPass,
        clearState,
        signInFail,
        loadingEmailLink,
        sendEmailSuccess,
        signInMailSuccess,
        sendEmailToResetPassword,

        resetPassword,
        confirmPasswordReset,
    } = props

    const history = useHistory()

    useEffect(() => {
        clearState()

        return () => {
            clearState()
        }
    }, [clearState]);

    return (
        <div className="main full">
            <div className="login-screen-container">
                <Switch>
                    <Route
                        exact
                        path={`/login/action`}
                    >
                        <LoginAction
                            isMobile={isMobile}
                            image={bkg}
                            loadingList={loginLoadingList}
                            history={history}
                            resetPassword={resetPassword}
                            confirmPasswordReset={confirmPasswordReset}
                            signInWithEmailLink={signInWithEmailLink}
                            signInMailSuccess={signInMailSuccess}
                            signInFail={signInFail}
                            loadingEmailLink={loadingEmailLink}
                        />
                    </Route>

                    <Route
                        exact
                        path={`/login/user/signin`}
                    >
                        <LoginUserSignIn
                            isMobile={isMobile}
                            image={bkg}
                            history={history}
                            loginUser={loginUser}
                            loadingList={loginLoadingList}
                        />
                    </Route>

                    <Route
                        exact
                        path={`/login/user/signin/email`}
                    >
                        <LoginUserEmailAndPass
                            isMobile={isMobile}
                            image={bkg}
                            loginUserWithEmailAndPass={loginUserWithEmailAndPass}
                            sendEmailToResetPassword={sendEmailToResetPassword}
                            loadingEmailLink={loadingEmailLink}
                            loadingList={loginLoadingList}
                            sendEmailSuccess={sendEmailSuccess}
                            clearState={clearState}
                        />
                    </Route>

                    <Route
                        exact
                        path={`/login/client/confirm`}
                    >
                        <LoginClientStepTwo
                            isMobile={isMobile}
                            image={bkg}
                        />
                    </Route>
                    
                    <Route
                        exact
                        path={`/login`}
                    >
                        <LoginClientStepOne
                            isMobile={isMobile}
                            image={bkg}
                            history={history}
                            emailClient={loginState.emailClient}
                            handleEmailClientInput={handleEmailClientInput}
                            sendSignInLinkToEmail={sendSignInLinkToEmail}
                            loadingEmailLink={loadingEmailLink}
                            sendEmailSuccess={sendEmailSuccess}
                            signInFail={signInFail}
                        />
                    </Route>

                    <Redirect from='/login/*' to="/login" />
                </Switch>
            </div>
        </div>
    )
})


const mapStateToProps = state => ({
    loginState: state.login,
    loginLoadingList: state.session.loadingList,
    signInFail: state.session.signInFail,
    sendEmailSuccess: state.session.sendEmailSuccess,
    signInMailSuccess: state.session.signInMailSuccess,
    loadingPostulation: state.register.loadingPostulation,
    postulationSuccess: state.register.postulationSuccess,
    registerLoadingList: state.register.loadingList,
    loadingEmailLink: state.session.loadingEmailLink,
    resetPassword: state.session.actions.resetPassword,
})

const mapDispatchToProps = dispatch => ({
    handleEmailClientInput: (email) => dispatch(LoginActions.handleEmailClientInput(email)),
    sendSignInLinkToEmail: (email) => dispatch(SessionActions.sendSignInLinkToEmail(email)),
    signInWithEmailLink: (email, link) => dispatch(SessionActions.signInWithEmailLink(email, link)),
    loginUser: (provider) => dispatch(SessionActions.loginUser(provider)),
    loginUserWithEmailAndPass: (values) => dispatch(SessionActions.loginUserWithEmailAndPass(values)),
    sendNewPostulation: (values, type) => dispatch(RegisterActions.sendNewPostulation(values, type)),
    clearRegisterState: () => dispatch(RegisterActions.clearRegisterState()),
    startRegisterUser: (provider) => dispatch(RegisterActions.startRegisterUser(provider)),
    registerUserWithEmailAndPass: (values) => dispatch(RegisterActions.registerUserWithEmailAndPass(values)),
    clearState: () => dispatch(SessionActions.clearState()),
    sendEmailToResetPassword: (email) => dispatch(SessionActions.sendEmailToResetPassword(email)),
    confirmPasswordReset: (code, newPassword) => dispatch(SessionActions.confirmPasswordReset(code, newPassword))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)