import React from 'react'
import { connect } from 'react-redux'
import { UIActions } from 'redux/actions'
import { Drawer } from 'antd'
import { MainMenu } from 'components'

const LeftDrawer = React.memo((props) => {
    const {
        showLeftSide,
        toggleLeftDrawer,
    } = props

    return (
        <Drawer
            placement='left'
            closable={false}
            onClose={() => toggleLeftDrawer(false)}
            visible={showLeftSide}
            key='left'
            keyboard
            width={290}
        >
            <MainMenu
                style={{
                    padding: 0,
                }}
            />
        </Drawer>
    )
})


const mapStateToProps = (state) => ({
    showLeftSide: state.ui.showLeftSide
})

const mapDispatchToProps = (dispatch) => ({
    toggleLeftDrawer: (bool) => dispatch(UIActions.toggleLeftDrawer(bool)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LeftDrawer)