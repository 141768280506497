import React from 'react'
import { Row, Col, Checkbox } from 'antd'


const AnCheckbox = (props) => {
    const {
        className,
        options,
        value,
        onChange,
    } = props

    const handleOnChange = (val) => {
        onChange(val)
    }


    return (
        <Checkbox.Group
            className={className}
            value={value}
            onChange={handleOnChange}
        >
            <Row>
                {(options || []).map((option, index) => (
                    <div
                        className="an-checkbox-item"
                        key={index.toString()}
                        span={24}
                    >
                        <Checkbox value={option.id}>
                            {option.name}
                        </Checkbox>
                    </div>
                ))}
            </Row>
        </Checkbox.Group>
    )
}

export default AnCheckbox