import axios from 'axios'
import ErrorManager from 'services/error-manager'
import { MPTokenErrorMap } from 'constants/index'

const cardTypes = require('creditcards-types/types')
const Card = require('creditcards/card')
const Cvc = require('creditcards/cvc')

const expiration = require('creditcards/expiration')
const card = Card(cardTypes)
const cvc = Cvc(cardTypes)


function fetchDocumentTypes() {
	return async dispatch => {
		try {
			dispatch({
				type: 'PAYMENT_FETCH_DOCUMENT_TYPES_START'
			})

			const response = await axios.get('https://api.mercadopago.com/v1/identification_types', {
                params: {
                    public_key: process.env.REACT_APP_GATEWAY_MERCARDO_PAGO_PUBLIC_KEY,
                }
            })
			
			dispatch({
				type: 'PAYMENT_FETCH_DOCUMENT_TYPES_SUCCESS',
				payload: {
					documentTypes: response.data.map((type) => ({
						id: type.id,
						name: type.name
					}))
				}
			})
		} catch (error) {
			dispatch({
				type: 'PAYMENT_FETCH_DOCUMENT_TYPES_FAILURE'
			})
		}
	}
}

function fetchCardValidations() {
	return async dispatch => {
		try {
			dispatch({
				type: 'PAYMENT_FETCH_CARD_VALIDATIONS_START'
			})

			const response = await  axios.get('https://api.mercadopago.com/v1/payment_methods', {
                params: {
                    public_key: process.env.REACT_APP_GATEWAY_MERCARDO_PAGO_PUBLIC_KEY,
                }
			})
			
			dispatch({
				type: 'PAYMENT_FETCH_CARD_VALIDATIONS_SUCCESS',
				payload: {
					cardValidations: response.data
				}
			})
		} catch (error) {
			dispatch({
				type: 'PAYMENT_FETCH_CARD_VALIDATIONS_FAILURE',
			})
		}
	}
}

function setCardNumber(cardNumber) {
	return {
		type: 'PAYMENT_CARD_NUMBER_SET',
		payload: {
			value: card.parse(cardNumber),
			formatted: card.format(card.parse(cardNumber)),
			type: card.type(card.parse(cardNumber)),
			validLuhn: card.luhn(card.parse(cardNumber)),
			validCardNumber: card.isValid(card.parse(cardNumber)),
		}
	}
}

function setCardName(cardName) {
	return {
		type: 'PAYMENT_CARD_NAME_SET',
		payload: {
			cardName,
		}
	}
}

function setCardDocumentType(cardDocumentType) {
	return {
		type: 'PAYMENT_CARD_DOCUMENT_TYPE_SET',
		payload: {
			cardDocumentType,
		}
	}
}

function setCardDocumentNumber(cardDocumentNumber) {
	return {
		type: 'PAYMENT_CARD_DOCUMENT_NUMBER_SET',
		payload: {
			cardDocumentNumber,
		}
	}
}

function setCardExpiration(cardExpiration) {
	return {
		type: 'PAYMENT_CARD_EXPIRATION_SET',
		payload: {
			value: cardExpiration,
			validCardExpiration: cardExpiration.length === 7 &&
				expiration.month.isValid(expiration.month.parse(cardExpiration.split('/')[0])) &&
				expiration.year.isValid(expiration.year.parse(cardExpiration.split('/')[1])) &&
				!expiration.isPast(cardExpiration.split('/')[0], cardExpiration.split('/')[1])
		}
	}
}

function setCardSecurityCode(cardSecurityCode) {
	return {
		type: 'PAYMENT_CARD_SECURITY_CODE_SET',
		payload: {
			value: cardSecurityCode,
			validCardSecurityCode: cvc.isValid(cardSecurityCode),
		}
	}
}

function getPaymentToken(params) {
	return async dispatch => {
		try {
			dispatch({
				type: 'PAYMENT_FETCH_PAYMENT_TOKEN_START',
			})

			const response = await axios.post(`https://api.mercadopago.com/v1/card_tokens`, {
				card_number: params.cardNumber.value,
				security_code: params.securityCode,
				expiration_month: params.expirationMonth,
				expiration_year: params.expirationYear,
				cardholder: {
					name: params.cardName,
					identification: {
						type: params.cardDocumentType,
						number: params.cardDocumentNumber,
					},
				},
			}, {
				params: {
					public_key: process.env.REACT_APP_GATEWAY_MERCARDO_PAGO_PUBLIC_KEY,
				}  
			})

			dispatch({
				type: 'PAYMENT_FETCH_PAYMENT_TOKEN_SUCCESS',
				payload: {
					paymentToken: response.data.id
				}
			})
		} catch (error) {
			dispatch({
				type: 'PAYMENT_FETCH_PAYMENT_TOKEN_FAILURE',
			})

			dispatch({
				type: 'UI_ALERT_SET_ERROR',
				payload: {
					error: error.response.data.cause
						&& error.response.data.cause[0]
						&& MPTokenErrorMap[error.response.data.cause[0].code]
							? MPTokenErrorMap[error.response.data.cause[0].code]
							: 'Ha ocurrido un error al generar el token de pago'
				}
			})
		}
	}
}

function getCreditCardIssuerId(bin) {
	return async dispatch => {
		try {
			dispatch({
				type: 'PAYMENT_FETCH_CREDIT_CARD_ISSUER_ID_START',
			})

			const response = await axios.get(`https://api.mercadopago.com/v1/payment_methods/installments`, {
				params: {
					bin: bin.substring(0, 6),
					public_key: process.env.REACT_APP_GATEWAY_MERCARDO_PAGO_PUBLIC_KEY,
				},
			})

			dispatch({
				type: 'PAYMENT_FETCH_CREDIT_CARD_ISSUER_ID_SUCCESS',
				payload: {
					creditCardIssuerId: response.data.length === 1
						? response.data[0].issuer.id
						: response.data.map((option) => ({
							id: option.issuer.id,
							name: option.issuer.name
						}))
				}
			})
		} catch (error) {
			dispatch({
				type: 'PAYMENT_FETCH_CREDIT_CARD_ISSUER_ID_FAILURE',
			})

			dispatch({
				type: 'UI_ALERT_SET_ERROR',
				payload: {
					error: error.response.data.message
				}
			})
		}
	}
}

function reset() {
	return {
		type: 'PAYMENT_RESET'
	}
}



export const PaymentActions = {
	fetchDocumentTypes,
	fetchCardValidations,
	setCardNumber,
	setCardName,
	setCardDocumentType,
	setCardDocumentNumber,
	setCardExpiration,
	setCardSecurityCode,
	getPaymentToken,
	getCreditCardIssuerId,
	reset
}