import React, { useCallback, useRef } from 'react'
import { BookingListItem } from 'components'

const EMPTY_LOADER = ["", "", "", "", "", ""]

const BookingResultPagination = React.memo((props) => {
	const {
		user,
		loading,
		loadingMoreResults,
		hasMore,
		page,
		results = [],
		getMoreResults,
		openModalMessage,
	} = props

	const observer = useRef()

	const lastItemRef = useCallback(node => {
		if (loading) return
		if (observer.current) observer.current.disconnect()
		observer.current = new IntersectionObserver(entries => {
			if (entries[0].isIntersecting && hasMore) {
				let nextPage = page + 1
				getMoreResults({
					sortField: "experienciafecha",
					sortOrder: "desc",
					page: nextPage
				})
			}
		})
		if (node) observer.current.observe(node)
	}, [loading, page, hasMore, getMoreResults])

	return (
		<>
			{ loading && !(results.length > 1) ?
				EMPTY_LOADER.map((item, index) =>
					<BookingListItem
						key={index}
						loading={true} />)
				:
				results.map((booking, index) => {
					if (results.length === index + 1) {
						return <BookingListItem
							ref={lastItemRef}
							key={booking.id}
							loading={loading}
							bookingDetails={booking}
							openModalMessage={openModalMessage}
						/>
					}
					return (
						<BookingListItem
							key={index}
							loading={loading}
							bookingDetails={booking}
							openModalMessage={openModalMessage}
						/>
					)
				})
			}

			{loadingMoreResults && hasMore &&
				EMPTY_LOADER.map((item, index) =>
					<BookingListItem
						key={index}
						loading={true} />)}
		</>
	)
})

export default BookingResultPagination