const defaultState = {
    page: 0,
    pageCount: 0,
    loading: true,
    loadingMoreResults: false,
    error: false,
    dataFingerprint: null,
    data: [],
    totalItems: 0,
    hasMore: true,
    experience: {
        actions: {
            like: {
                id: null,
                loading: false,
                error: false,
                success: false,
            },
            unlike: {
                id: null,
                loading: false,
                error: false,
                success: false,
            }
        }
    }
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'FAVORITES_FETCH_FAVORITES_START':
            return Object.assign({}, state, {
                dataFingerprint: action.payload.dataFingerprint,
                loading: true,
                error: false,
            })

        case 'FAVORITES_FETCH_FAVORITES_SUCCESS':
            return Object.assign({}, state, {
                data: [].concat(action.payload.favorites),
                pageCount: action.payload.pageCount,
                totalItems: action.payload.totalItems,
                page: action.payload.page,
                loading: false,
                hasMore: action.payload.hasMore,
            })

        case 'FAVORITES_FETCH_FAVORITES_FAILURE':
            return Object.assign({}, state, {
                loading: false,
                error: true,
            })

        case 'FAVORITES_FETCH_MORE_FAVORITES_START':
            return Object.assign({}, state, {
                loadingMoreResults: true
            })

        case 'FAVORITES_FETCH_MORE_FAVORITES_SUCCESS':
            return Object.assign({}, state, {
                data: [].concat(state.data).concat(action.payload.favorites),
                pageCount: action.payload.pageCount,
                totalItems: action.payload.totalItems,
                page: action.payload.page,
                loadingMoreResults: false,
                hasMore: action.payload.hasMore,
            })

        case 'FAVORITES_FETCH_MORE_FAVORITES_FAILURE':
            return Object.assign({}, state, {
                loadingMoreResults: false
            })

        case 'LIKED_EXPERIENCE_START':
            return Object.assign({}, state, {
                experience: Object.assign({}, state.experience, {
                    actions: Object.assign({}, state.experience.actions, {
                        like: {
                            id: action.payload.id,
                            loading: true,
                            error: false,
                            success: false,
                        }
                    })
                })
            })

        case 'LIKED_EXPERIENCE_SUCCESS': {
            const data = action.payload && action.payload.id ? state.data.map((item) => {
                return item.id !== action.payload.id ? item : { ...item, isLiked: true }
            }) : null
            
            return Object.assign({}, state, {
                dataFingerprint: defaultState.dataFingerprint,
                data: [].concat(data),
                experience: Object.assign({}, state.experience, {
                    actions: Object.assign({}, state.experience.actions, {
                        like: {
                            id: null,
                            loading: false,
                            error: false,
                            success: true,
                        }
                    })
                })
            })
        }

        case 'LIKED_EXPERIENCE_FAILURE':
            return Object.assign({}, state, {
                experience: Object.assign({}, state.experience, {
                    actions: Object.assign({}, state.experience.actions, {
                        like: {
                            id: null,
                            loading: false,
                            error: true,
                            success: false,
                        }
                    })
                })
            })

        case 'LIKED_EXPERIENCE_RESET':
            return Object.assign({}, state, {
                experience: Object.assign({}, state.experience, {
                    actions: Object.assign({}, state.experience.actions, {
                        like: defaultState.experience.actions.like
                    })
                })
            })

        case 'UNLIKED_EXPERIENCE_START':
            return Object.assign({}, state, {
                experience: Object.assign({}, state.experience, {
                    actions: Object.assign({}, state.experience.actions, {
                        unlike: {
                            id: action.payload.id,
                            loading: true,
                            error: false,
                            success: false,
                        }
                    })
                })
            })

        case 'UNLIKED_EXPERIENCE_SUCCESS': {
            const data = action.payload && action.payload.id ? state.data.map((item) => {
                return item.id !== action.payload.id ? item : { ...item, isLiked: false }
            }) : null
            
            return Object.assign({}, state, {
                dataFingerprint: defaultState.dataFingerprint,
                data: [].concat(data),
                experience: Object.assign({}, state.experience, {
                    actions: Object.assign({}, state.experience.actions, {
                        unlike: {
                            id: null,
                            loading: false,
                            error: false,
                            success: true,
                        }
                    })
                })
            })
        }
        
        case 'UNLIKED_EXPERIENCE_FAILURE':
            return Object.assign({}, state, {
                experience: Object.assign({}, state.experience, {
                    actions: Object.assign({}, state.experience.actions, {
                        unlike: {
                            id: null,
                            loading: false,
                            error: true,
                            success: false,
                        }
                    })
                })
            })

        case 'UNLIKED_EXPERIENCE_RESET':
            return Object.assign({}, state, {
                experience: Object.assign({}, state.experience, {
                    actions: Object.assign({}, state.experience.actions, {
                        unlike: defaultState.experience.actions.like
                    })
                })
            })

        case 'FAVORITES_RESET':
            return Object.assign({}, defaultState)

        default:
            return state;
    }
}
