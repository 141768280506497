import React from 'react'
import { Row, Col, Space, Button, Collapse, Alert } from 'antd'
import moment from 'moment';

import ExperienceEventForm from './ExperienceEventForm'

const ExperienceDayEventsList = React.memo((props) => {
    const {
        day,
        close,
        showCreateEventForm,
        loading,
        events,
        activeEventIndex,
        setActiveEventIndex,
        handleUpdateExperienceEvent,
        handleDeleteExperienceEvent,
        loadingDelete,
        loadingUpdate,
		hasFlexibleHours
    } = props;

    return (
        <>
            <Row justify="end">
                <Col>
                    <Space>
                    <Button
                        type="ghost"
                        shape="round"
                        onClick={close}
                    >
                        Volver
                    </Button>
                    
                    <Button
                        type="primary"
                        shape="round"
                        onClick={showCreateEventForm}
                    >
                        Crear Evento
                    </Button>
                    </Space>
                </Col>
            </Row>

            <div
                style={{
                    padding: '10px',
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                    marginTop: 20,
                }}
            >
                {`${moment(day).format('LL')}`}
            </div>
            
            <div
                style={{
                    opacity: loading ? 0.4 : 1,
                    pointerEvents: loading ? 'none' : 'all',
                }}
            >
                {(events.length < 1 && (
                    <Alert
                        message="Sin Eventos"
                        description={'La fecha seleccionada aún no posee ningún evento'}
                        type="warning"
                        showIcon
                    />
                ))}
                
                <Collapse { ...props }
                    activeKey={activeEventIndex}
                    onChange={(activeKeyIndex) => {
                        setActiveEventIndex(activeKeyIndex)
                    }}
                >
                    {events.map((event, index) => (
                        <Collapse.Panel
                            key={index.toString()}
                            header={
                                `${moment.utc(event.dayAndHour).format('HH:mm ')}`
                            }
                            extra={(
                                <div>
                                    {`Quedan ${event.capacity - event.bookings} / ${event.capacity} disponibilidades`}
                                </div>
                            )}
                        >
                            <ExperienceEventForm
                                mode="edit"
                                event={event}
                                onSave={handleUpdateExperienceEvent}
                                onDelete={() => handleDeleteExperienceEvent(event.id)}
                                loadingDelete={loadingDelete}
                                loading={loadingUpdate}
								hasFlexibleHours={hasFlexibleHours}
                            />
                        </Collapse.Panel>
                    ))}
                </Collapse>
            </div>
        </>
    )
})

export default ExperienceDayEventsList