import { ExperienceRestClient } from "http/resources";
import ErrorManager from "services/error-manager";

function getExperience(typeUser, experienceId) {
    return async (dispatch) => {
        try {
            dispatch({
                type: "EXPERIENCE_FETCH_EXPERIENCE_START",
            });

            const response =
                typeUser === "Usuario" || typeUser === "Cliente"
                    ? await new ExperienceRestClient(true).getExperience({ experienceId })
                    : await new ExperienceRestClient().getExperience({ experienceId });

            dispatch({
                type: "EXPERIENCE_FETCH_EXPERIENCE_SUCCESS",
                payload: {
                    experience: {
                        id: response.data.id,
                        title: response.data.nombre,
                        provider: {
                            id: response.data.usuario.id,
                            name: response.data.usuario.nombre,
                        },
                        details: response.data.detalles,
                        detailsEn: response.data.detallesEn,
                        price: response.data.precio,
                        bookingType: [
                            response.data.tiposReserva.pagoCompleto ? "complete" : "",
                            response.data.tiposReserva.reservaConPago ? "withPay" : "",
                            response.data.tiposReserva.reservaSinPago ? "withoutPay" : "",
                        ],
                        createdDate: response.data.fechaCreada,
                        direction: {
                            street: response.data.direccion.calle,
                            number: response.data.direccion.numero,
                            depto: response.data.direccion.pisoDepto,
                            locate: response.data.direccion.localidad,
                        },
                        category: {
                            id: response.data.categoria?.id,
                            name: response.data.categoria?.nombre,
                        },
                        peopleQuantity: response.data.cantPersonas,
                        duration: response.data.duracion,
                        tolerance: response.data.tolerancia,
                        tags: response.data.tags,
                        languages: response.data.idiomas.map((language) => ({
                            id: language.id,
                            name: language.nombre,
                        })),
                        rating: response.data.rating,
                        isLiked: response.data.favorito || false,
                        timeZoneId: response.data.timeZoneId,
                        mediaList: response.data.mediosUrls || [],
                        isPublic: response.data.publicada,
                        externalURL: response.data.urlExterna,
                        bookingPercentage: response.data.porcentajeReserva,
						bookingRequires: [
                            response.data.requerirDondeRecoger ? "requireWhereToPickup" : "",
                            response.data.requerirDondeLlevar ? "requireWhereToGo" : "",
                            response.data.requerirNroVuelo ? "requireFlightNumber" : "",
                        ],
                    },
                },
            });
        } catch (error) {
            dispatch({
                type: "EXPERIENCE_FETCH_EXPERIENCE_FAILURE",
            });

            dispatch({
                type: "UI_ALERT_SET_ERROR",
                payload: {
                    error: ErrorManager.toMessage(error),
                },
            });
        }
    };
}

function reset() {
    return {
        type: "EXPERIENCE_RESET",
    };
}

function markExperienceAsViewed(typeUser, experienceId, promotorNic) {
    return async (dispatch) => {
        try {
            const response =
                typeUser === "Usuario" || typeUser === "Cliente"
                    ? await new ExperienceRestClient(true).markExperienceAsViewed({ experienceId, promotorNic })
                    : await new ExperienceRestClient().markExperienceAsViewed({ experienceId, promotorNic });
        } catch (error) {
            //nothing to do
        }
    };
}

// TODO
function dispatchError(err) {
    return {
        type: "UI_ALERT_SET_ERROR",
        payload: {
            error: ErrorManager.toMessage(err),
        },
    };
}

export const ExperienceActions = {
    getExperience,
    reset,
    markExperienceAsViewed,
    // TODO
    dispatchError,
};
