import React from 'react'
import { Row, Col } from 'antd'
import { AnLabelForm, AnInput, AnSelect } from 'components/an'
import InputMask from 'react-input-mask';

const BookingCreditCardForm = React.memo((props) => {
    const {
        cardNumberPlaceholder,
        cardNumber,
        setCardNumber,
        validCardNumber,
        cardNamePlaceholder,
        cardName,
        setCardName,
        cardDocumentTypePlaceholder,
        cardDocumentTypeOptions,
        cardDocumentType,
        setCardDocumentType,
        cardDocumentNumberPlaceholder,
        cardDocumentNumber,
        setCardDocumentNumber,
        cardExpirationPlaceholder,
        cardExpiration,
        validCardExpiration,
        setCardExpiration,
        cardSecurityCodePlaceholder,
        cardSecurityCode,
        setCardSecurityCode,
    } = props
    return (
        <>
            <Row
                gutter={[0, 15]}
            >
                <Col span={24}>
                    <AnLabelForm
                        label="Número *"
                    />
                    <AnInput
                        placeholder={cardNumberPlaceholder}
                        value={cardNumber}
                        onChange={setCardNumber}
                        suffix={
                            validCardNumber ? (
                                <ion-icon name="checkmark-outline" style={{ color: 'green' }}></ion-icon>
                            ) : null
                        }
                    />
                </Col>
            </Row>
            
            <Row
                gutter={[0, 15]}
            >
                <Col span={24}>
                    <AnLabelForm
                        label="Nombre *"
                    />
                    
                    <AnInput
                        placeholder={cardNamePlaceholder}
                        value={cardName}
                        onChange={setCardName}
                    />
                </Col>
            </Row>

            <Row
                gutter={[0, 15]}
            >
                <Col span={8}>
                    <AnLabelForm
                        label="Tipo *"
                    />

                    <AnSelect
                        shape="round"
                        placeholder={cardDocumentTypePlaceholder}
                        async={false}
                        options={cardDocumentTypeOptions}
                        onChange={setCardDocumentType}
                        value={cardDocumentType}
                    />
                </Col>

                <Col offset={1} span={15}>
                    <AnLabelForm
                        label="Número de Doc *"
                    />

                    <AnInput
                        placeholder={cardDocumentNumberPlaceholder}
                        value={cardDocumentNumber}
                        onChange={setCardDocumentNumber}
                    />
                </Col>
            </Row>

            <Row>
                <Col span={16}>
                    <AnLabelForm
                        label="Vencimiento *"
                    />

                    <InputMask
                        mask="99/9999"
                        maskPlaceholder={null}
                        value={cardExpiration}
                        onChange={setCardExpiration}
                    >
                        <AnInput
                            placeholder={cardExpirationPlaceholder}
                            suffix={
                                validCardExpiration ? (
                                    <ion-icon name="checkmark-outline" style={{ color: 'green' }}></ion-icon>
                                ) : null
                            }
                        />
                    </InputMask>
                </Col>

                <Col offset={1} span={7}>
                    <AnLabelForm
                        label="CVV *"
                    />
                    
                    <AnInput
                        placeholder={cardSecurityCodePlaceholder}
                        value={cardSecurityCode}
                        onChange={setCardSecurityCode}
                    />
                </Col>
            </Row>
        </>
    )
})

export default BookingCreditCardForm