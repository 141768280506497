import React, { useState, useEffect, useRef } from 'react'

const AnInputPhoneNumber = React.memo((props) => {
	const {
		className,
		shape,
		value,
		onChange,
		placeholder,
	} = props

	const [numberValue, setnumberValue] = useState(null)

	const isFirstRun = useRef(true);

	useEffect(() => {
		setnumberValue(value)
	}, [value])

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return
		} else {
			onChange(numberValue)
		}
	}, [numberValue])

	const onChangeInput = (e) => {
		let input = e.target.value
		let parseInput = parseInt(e.target.value)

		if (input === '' || isNaN(parseInput)) {
			setnumberValue('')
		} else if (input.length > 10) {
			setnumberValue(numberValue)
		} else {
			setnumberValue(parseInput)
		}
	}
	return (
		<input
			className={`ant-input ${className} ${shape}`}
			placeholder={placeholder}
			onChange={onChangeInput}
			type="text"

			value={numberValue}
			style={{ borderRadius: '6em' }}>

		</input>)
})

AnInputPhoneNumber.defaultProps = {
	className: 'an-input',
	shape: '',
	value: null,
	onChange: () => { },
	placeholder: 'Ingresar numero de teléfono',
}

export default AnInputPhoneNumber