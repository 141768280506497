import React, { useState, useEffect } from 'react'
import { Typography } from 'antd'
import { AnModal, AnButtonGradient, AnButton, AnInput } from 'components/an'
import {
	WhatsappShareButton,
} from "react-share"
import { usePrevious } from 'hooks/usePrevious'

const NewInvitationModal = React.memo((props) => {
	const {
		user,
		userInfo,
		onClose,
		sendLoading,
		sendInvitation
	} = props

	const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	const [emailInput, setEmailInput] = useState(null)
	const prevSendLoading = usePrevious(sendLoading)

	const back = () => {
		onClose()
	}

	const handleOnChange = (e) => {
		setEmailInput(e.target.value)
	}

	const onPressSend = () => {
		let url = `${window.location.protocol}//${window.location.hostname}${window.location.port !== 80 && window.location.port.length > 0 ? `:${window.location.port}` : ``}/postulation/user/postulation?type=2${user?.role === 'Usuario' ? `&parentCode=${encodeURIComponent(userInfo?.codigo)}` : ``}`

		sendInvitation(emailInput, url)
	}

	useEffect(() => {
		if ((prevSendLoading !== sendLoading) && !sendLoading) {
			setEmailInput(null)
		}
	}, [sendLoading])


	return (
		<AnModal
			headerTitle="Invitar"
			headerButtons={(
				<AnButton
					ghost
					type='primary'
					shape='round'
					size='large'
					style={{
						marginRight: 5
					}}
					onClick={() => {
						back()
					}}
				>
					Cerrar
				</AnButton>
			)}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: 20
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column',
						flex: 1,
						height: '100%',
						padding: 5,
						maxWidth: 550,
					}}
				>
					<Typography.Paragraph
						style={{
							fontSize: 14,
							textAlign: 'center',
							marginBottom: 20
						}}
					>
						Podes invitar a tus amigos para que sean Promotores, y ganar dinero!
						Las ventas que ellos realicen te generarán comisiones de por vida, y las de los Promotores que ellos inviten también.
                    </Typography.Paragraph>

					<WhatsappShareButton
						title='Hola! ¿Sabias que podes postularte como Promotor de Spiderweb y ganar dinero de forma muy fácil? Yo ya soy promotor!'
						url={`${window.location.protocol}//${window.location.hostname}${window.location.port !== 80 && window.location.port.length > 0 ? `:${window.location.port}` : ``}/postulation/user/postulation?type=2${user?.role === 'Usuario' ? `&parentCode=${encodeURIComponent(userInfo?.codigo)}` : ``}`}
						windowWidth={1080}
						windowHeight={720}
					>
						<AnButton
							ghost
							type='primary'
							shape='round'
							size='large'
							style={{
								margin: 15,
							}}
						>
							Enviar por WhatsApp
                        </AnButton>
					</WhatsappShareButton>

					<Typography.Text
						type='secondary'
					>
						o
						</Typography.Text>

					<AnInput
						className="an-input round"
						placeholder='Invitar por Email'
						value={emailInput}
						onChange={handleOnChange}
						style={{
							maxWidth: 350,
							marginTop: 15
						}}
					/>

					<AnButtonGradient
						type='primary'
						shape='round'
						size='large'
						loading={sendLoading}
						disabled={!EMAIL_REGEX.test(emailInput)}
						onClick={onPressSend}
						style={{
							width: 150,
							margin: 15,
						}}
					>
						Enviar
						</AnButtonGradient>
				</div>
			</div>
		</AnModal>
	)
})

export default NewInvitationModal