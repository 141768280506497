import React, { useState, useEffect, useCallback } from 'react'
import { Button, Alert } from 'antd';
import { AnInput, AnButtonGradient } from 'components/an'
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { UserExperiencesActions } from 'redux/actions';
import { ScrollToTop, PageHeader, ExperienceResultPagination, EmptyList } from 'components'

// TODO
import UserRestClient from 'http/resources/UserRestClient'

const UserExperiencesScreen = React.memo((props) => {
    const {
        context,
        user,
        loading,
        error,
        experiences,
        getExperiences,
        getMoreExperiences,
        loadingMoreResults,
        page,
        hasMore
    } = props

    const history = useHistory()

    const [isSearchView, setIsSearchView] = useState(false)

    useEffect(() => {
        getExperiences()
    }, [getExperiences]);

    const handleCloseErrorAlert = useCallback(() => {
        getExperiences(null, true)
    }, [getExperiences])

    const handleOnRequestCreateExperience = useCallback(() => {
        history.push('/user/experiences/form/new?step=1')
    })

    const handleOnSearch = useCallback((e) => {
        let { value } = e.target

        if (value.length > 2) {
            setIsSearchView(true);
            getExperiences({ name: value })
        } else if (value.length < 1) {
            setIsSearchView(false)
            getExperiences()
        }
    })

    return (
        <>
            <ScrollToTop />

            <div className="main">
                <PageHeader
                    title="Mis Experiencias"
                    subtitle="Tus experiencias creadas"
                />

                {error ? (
                    <div className="list-error-container margin-gap">
                        <Alert
                            message="Ups!"
                            description="Ha ocurrido un error al intentar obtener tus experiencias"
                            type="error"
                            showIcon
                            closeText="Reintentar"
                            onClose={handleCloseErrorAlert}
                        />
                    </div>
                ) : !isSearchView && experiences.length === 0 && !loading ? (
                    <EmptyList
                        description="Aún no has creado ninguna experiencia"
                        image={(
                            <ion-icon name="albums-outline"></ion-icon>
                        )}
                        actionButton={(
                            <>
                                {context?.impersonated && (
                                    <AnButtonGradient
                                        type='primary'
                                        shape='round'
                                        size='large'
                                        onClick={handleOnRequestCreateExperience}
                                    >
                                        Crear experiencia
                                    </AnButtonGradient>
                                )}
                            </>
                        )}
                    />
                ) : (
                            <>
                                <div className="search-container sticky">
                                    <AnInput
                                        placeholder='Buscar en mis experiencias'
                                        onChange={handleOnSearch}
                                        className={`input primary${context?.impersonated ? ' right-space' : ''}`}
                                        style={{
                                            height: 32
                                        }}
                                    />

                                    {context?.impersonated && (
                                        <AnButtonGradient
                                            type='primary'
                                            shape='round'
                                            size='large'
                                            onClick={handleOnRequestCreateExperience}
                                            style={{
                                                width: 130
                                            }}
                                        >
                                            Crear
                                        </AnButtonGradient>
                                    )}
                                </div>

                                {(isSearchView && experiences.length === 0 && !loading) ? (
                                    <EmptyList
                                        description="No se encontraron experiencias para tu búsqueda"
                                        image={(
                                            <ion-icon name="albums-outline"></ion-icon>
                                        )}
                                    />
                                ) : (
                                        <ExperienceResultPagination
                                            context={context}
                                            user={user}
                                            loading={loading}
                                            loadingMoreResults={loadingMoreResults}
                                            hasMore={hasMore}
                                            page={page}
                                            results={experiences}
                                            getMoreResults={getMoreExperiences}
                                            shareExperienceUrl={`${window.location.protocol}//${window.location.hostname}${window.location.port !== 80 && (window.location.port.length > 0) ? `:${window.location.port}` : ``}/experience/:id/share${user?.role === 'Usuario' ? `?promoter-nic=${user?.nic}` : ``}`}
                                            isUserView
                                        />
                                    )}
                            </>
                        )}
            </div>
        </>
    )
})

const mapStateToProps = (state) => ({
    context: state.context,
    user: state.context.user,
    loading: state.userExperiences.loading,
    error: state.userExperiences.error,
    experiences: state.userExperiences.data,
    page: state.userExperiences.page,
    pageCount: state.userExperiences.pageCount,
    loadingMoreResults: state.userExperiences.loadingMoreResults,
    hasMore: state.userExperiences.hasMore,
})

const mapDispatchToProps = (dispatch) => ({
    getExperiences: (criteria) => dispatch(UserExperiencesActions.getExperiences(criteria)),
    getMoreExperiences: (page, criteria) => dispatch(UserExperiencesActions.getMoreExperiences(page, criteria))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserExperiencesScreen)