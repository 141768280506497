import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { UIActions, SessionActions } from 'redux/actions'
import { Link, useLocation, useHistory } from "react-router-dom"
import { Avatar, Typography, Divider, Button, Badge } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { AnButton } from 'components/an'

const MainMenu = props => {
    const {
        style,
        user,
        toggleLeftDrawer,
        userLogoutSession,
        badge,
    } = props

    const history = useHistory()
    const location = useLocation()

    const handleItemMenuClick = useCallback((path) => {
        // Close drawer
        toggleLeftDrawer(false)

        // Redirect
        location.pathname === path
            ? window.scrollTo(0, 0)
            : history.push(path)
    })


    return (
        <div
            style={{
                position: 'sticky',
                top: -7,
                padding: '15px 0px',

                ...style
            }}
        >
            <div
                style={{
                    borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
                    padding: '0px 0px 10px',
                    marginBottom: '24px',
                }}
            >
                {!user || user?.role === 'Anonimous' ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: '13px'
                            }}
                        >
                            Aún no has iniciado sesión
                        </Typography>

                        <Link
                            to="/login"
                            onClick={() => toggleLeftDrawer(false)}
                        >
                            <Button type="link" block style={{ padding: 0, fontSize: '13px' }}>
                                Iniciar Sesión
                            </Button>
                        </Link>
                    </div>
                ) : (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <Avatar
                                size={48}
                                icon={(
                                    <UserOutlined />
                                )}
                                src={user?.picture || ""}
                            />

                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    marginLeft: 10,
                                    overflow: 'hidden',
                                }}
                            >
                                <div className="truncate" style={{ width: '100%', fontSize: 13, }}>
                                    {user?.unique_name || user?.email || 'Nombre no disponible'}
                                </div>

                                <Button
                                    style={{
                                        padding: 0,
                                        fontSize: 12,
                                        height: 'auto',
                                    }}
                                    type="link"
                                    onClick={() => {
                                        userLogoutSession()
                                        toggleLeftDrawer(false)
                                    }}
                                >
                                    Cerrar Sesión
                            </Button>
                            </div>
                        </div>
                    )}
            </div>


            <div
                className='drawer-body'
            >
                <ul>
                    <li>
                        <div>
                            <ion-icon name="home-outline"></ion-icon>
                            <a
                                className="truncate"
                                onClick={() => {
                                    handleItemMenuClick('/search')
                                }}
                            >
                                Home
                            </a>
                        </div>
                    </li>

                    <li>
                        <div>
                            <ion-icon name="heart-outline"></ion-icon>
                            <a
                                className="truncate"
                                onClick={() => {
                                    handleItemMenuClick('/liked')
                                }}
                            >
                                Mis favoritos
                            </a>
                        </div>
                    </li>

                    <li>
                        <div>
                            <ion-icon name="chatbox-outline"></ion-icon>
                            <Badge
                                count={badge}
                                overflowCount={9}
                                offset={[23, 11]}
                                style={{ backgroundColor: "#c3125a" }}
                            >
                                <a
                                    className="truncate"
                                    onClick={() => {
                                        handleItemMenuClick('/messages')
                                    }}
                                >
                                    Mensajes
                                </a>
                            </Badge>
                        </div>
                    </li>

                    {user?.role === 'Usuario' && (
                        <>
                            <li>
                                <div>
                                    <ion-icon name="albums-outline"></ion-icon>
                                    <a
                                        className="truncate"
                                        onClick={() => {
                                            handleItemMenuClick('/user/experiences')
                                        }}
                                    >
                                        Mis experiencias
                                    </a>
                                </div>
                            </li>

                            <li>
                                <div>
                                    <ion-icon name="checkmark-circle-outline"></ion-icon>
                                    <a
                                        className="truncate"
                                        onClick={() => {
                                            handleItemMenuClick('/user/bookings')
                                        }}
                                    >
                                        Estado de reservas
                                    </a>
                                </div>
                            </li>

                            <li>
                                <div>
                                    <ion-icon name="share-social-outline"></ion-icon>
                                    <a
                                        className="truncate"
                                        onClick={() => {
                                            handleItemMenuClick('/user/promotor')
                                        }}
                                    >
                                        Ingresos como Promotor
                                    </a>
                                </div>
                            </li>

                            <li>
                                <div>
                                    <ion-icon name="stats-chart-outline"></ion-icon>
                                    <a
                                        className="truncate"
                                        onClick={() => {
                                            handleItemMenuClick('/user/proveedor')
                                        }}
                                    >
                                        Ingresos como Proveedor
                                    </a>
                                </div>
                            </li>

                            <li>
                                <div>
                                    <ion-icon name="person-outline"></ion-icon>
                                    <a
                                        className="truncate"
                                        to='/user'
                                        onClick={() => {
                                            handleItemMenuClick('/user')
                                        }}
                                    >
                                        Perfil
                                    </a>
                                </div>
                            </li>
                        </>
                    )}

                    {(user?.role === 'Cliente' || user?.role === 'Anonimous') && (
                        <>
                            <li>
                                <div>
                                    <ion-icon name="checkmark-circle-outline"></ion-icon>
                                    <a
                                        className="truncate"
                                        onClick={() => {
                                            handleItemMenuClick('/bookings')
                                        }}
                                    >
                                        Mis reservas
                                    </a>
                                </div>
                            </li>
                        </>
                    )}
                </ul>
            </div>

            {user?.role !== 'Usuario' /* && user?.role !== 'Cliente'  */ && (
                <>
                    <Divider />

                    <a
                        onClick={() => {
                            handleItemMenuClick('/postulation')
                        }}
                    >
                        <AnButton
                            ghost
                            shape='round'
                            type='primary'
                            block
                            style={{
                                marginBottom: 10,
                                fontSize: '13px',
                                lineHeight: '13px',
                            }}
                        >
                            Quiero ser Proveedor
                        </AnButton>
                    </a>

                    <a
                        onClick={() => {
                            handleItemMenuClick('/postulation')
                        }}
                    >
                        <AnButton
                            ghost
                            shape='round'
                            type='primary'
                            block
                            style={{
                                fontSize: '13px',
                                lineHeight: '13px',
                            }}
                        >
                            Quiero ser Promotor
                        </AnButton>
                    </a>
                </>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.context.user,
    badge: state.messages.badge,
})

const mapDispatchToProps = (dispatch) => ({
    toggleLeftDrawer: (bool) => dispatch(UIActions.toggleLeftDrawer(bool)),
    userLogoutSession: () => dispatch(SessionActions.userLogoutSession()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu)