import UserRestClient from "http/resources/UserRestClient"
import ErrorManager from 'services/error-manager'
import axios from 'axios'

function getPromotorStats(userId) {
	return (dispatch, getState) => {
		dispatch({
			type: 'USER_GET_PROMOTOR_STATS_START'
		})
		const { dateFrom, dateTo } = getState().userStats
		new UserRestClient(true).getPromotorStats({
			userId,
			dateFrom,
			dateTo
		})
			.then(res => {
				dispatch({
					type: 'USER_GET_PROMOTOR_STATS_SUCCESS',
					payload: {
						promotorStatsList: res.data
					}
				})
			})
			.catch(error => {
				dispatch({
					type: "USER_GET_PROMOTOR_STATS_FAILURE",
				})
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(error)
					}
				})
			})
	}
}

function getProveedorStats(userId) {
	return (dispatch, getState) => {
		dispatch({
			type: 'USER_GET_PROVEEDOR_STATS_START'
		})
		const { dateFrom, dateTo } = getState().userStats
		new UserRestClient(true).getProveedorStats({
			userId,
			dateFrom,
			dateTo
		})
			.then(res => {
				dispatch({
					type: 'USER_GET_PROVEEDOR_STATS_SUCCESS',
					payload: {
						proveedorStatsList: res.data
					}
				})
			})
			.catch(error => {
				dispatch({
					type: "USER_GET_PROVEEDOR_STATS_FAILURE",
				})
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(error)
					}
				})
			})
	}
}

function getPromotorShareList(isRed, page) {
	return (dispatch, getState) => {
		dispatch({
			type: 'USER_GET_INFO_STATS_START'
		})
		const { dateFrom, dateTo } = getState().userStats
		new UserRestClient(true).getShareList({
			dateFrom,
			dateTo,
			red: isRed,
			page
		})
			.then(response => {
				dispatch({
					type: 'USER_GET_INFO_STATS_SUCCESS',
					payload: {
						data: response.data.items || [],
						page: page ? page : 0,
						pageCount: response.data.pageCount,
						totalItems: response.data.totalItems,
					}
				})
			})
			.catch(error => {
				dispatch({
					type: "USER_GET_INFO_STATS_FAILURE",
				})
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(error)
					}
				})
			})
	}
}


function getPromotorCommissionsList(isRed, page) {
	return (dispatch, getState) => {
		dispatch({
			type: 'USER_GET_INFO_STATS_START'
		})
		const { dateFrom, dateTo } = getState().userStats
		new UserRestClient(true).getCommissionsList({
			dateFrom,
			dateTo,
			red: isRed,
			page,
		})
			.then(response => {
				dispatch({
					type: 'USER_GET_INFO_STATS_SUCCESS',
					payload: {
						data: response.data.items || [],
						page: page ? page : 0,
						pageCount: response.data.pageCount,
						totalItems: response.data.totalItems,
					}
				})
			})
			.catch(error => {
				dispatch({
					type: "USER_GET_INFO_STATS_FAILURE",
				})
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(error)
					}
				})
			})
	}
}

function getPromotorBookingsList(params, criteria) {
	return (dispatch, getState) => {
		dispatch({
			type: 'USER_GET_INFO_STATS_START'
		})
		const { dateFrom, dateTo } = getState().userStats
		
		new UserRestClient(true).getPromotorBookings({
			...params,
			dateFrom,
			dateTo,
		}, criteria)
			.then(response => {
				dispatch({
					type: 'USER_GET_INFO_STATS_SUCCESS',
					payload: {
						data: response.data.items || [],
						page: params.page ? params.page : 0,
						pageCount: response.data.pageCount,
						totalItems: response.data.totalItems,
					}
				})
			})
			.catch(error => {
				if (error instanceof axios.Cancel) {
					// Nothing to do ...
				} else {
					dispatch({
						type: "USER_GET_INFO_STATS_FAILURE",
					})
					dispatch({
						type: 'UI_ALERT_SET_ERROR',
						payload: {
							error: ErrorManager.toMessage(error)
						}
					})
				}

			})
	}
}

function getProveedorBookingsList(params, criteria) {
	return (dispatch, getState) => {
		dispatch({
			type: 'USER_GET_INFO_STATS_START'
		})
		const { dateFrom, dateTo } = getState().userStats

		new UserRestClient(true).getBookings({
			...params,
			dateFrom,
			dateTo
		}, criteria)
			.then(response => {
				dispatch({
					type: 'USER_GET_INFO_STATS_SUCCESS',
					payload: {
						data: response.data.items || [],
						page: params.page ? params.page : 0,
						pageCount: response.data.pageCount,
						totalItems: response.data.totalItems,
					}
				})
			})
			.catch(error => {
				if (error instanceof axios.Cancel) {
					// Nothing to do ...
				} else {
					dispatch({
						type: "USER_GET_INFO_STATS_FAILURE",
					})
					dispatch({
						type: 'UI_ALERT_SET_ERROR',
						payload: {
							error: ErrorManager.toMessage(error)
						}
					})
				}

			})
	}
}

function getPayRevenueList(params, criteria) {
	return (dispatch, getState) => {
		dispatch({
			type: 'USER_GET_INFO_STATS_START'
		})
		const { dateFrom, dateTo } = getState().userStats

		new UserRestClient(true).getBookings({
			...params,
			dateFrom,
			dateTo
		}, criteria)
			.then(response => {
				dispatch({
					type: 'USER_GET_INFO_STATS_SUCCESS',
					payload: {
						data: response.data.items || [],
						page: params.page ? params.page : 0,
						pageCount: response.data.pageCount,
						totalItems: response.data.totalItems,
					}
				})
			})
			.catch(error => {
				if (error instanceof axios.Cancel) {
					// Nothing to do ...
				} else {
					dispatch({
						type: "USER_GET_INFO_STATS_FAILURE",
					})
					dispatch({
						type: 'UI_ALERT_SET_ERROR',
						payload: {
							error: ErrorManager.toMessage(error)
						}
					})
				}

			})
	}
}

function getUserWalletInfoList(page) {
	return (dispatch, getState) => {
		dispatch({
			type: 'USER_GET_INFO_STATS_START'
		})
		const { dateFrom, dateTo } = getState().userStats
		new UserRestClient(true).getUserWalletInfoList({
			dateFrom,
			dateTo,
			page
		})
			.then(response => {
				dispatch({
					type: 'USER_GET_INFO_STATS_SUCCESS',
					payload: {
						data: response.data.items || [],
						page: page ? page : 0,
						pageCount: response.data.pageCount,
						totalItems: response.data.totalItems,
					}
				})
			})
			.catch(error => {
				if (error instanceof axios.Cancel) {
					// Nothing to do ...
				} else {
					dispatch({
						type: "USER_GET_INFO_STATS_FAILURE",
					})
					dispatch({
						type: 'UI_ALERT_SET_ERROR',
						payload: {
							error: ErrorManager.toMessage(error)
						}
					})
				}

			})
	}
}

function getProveedorViewsList(page) {
	return (dispatch, getState) => {
		dispatch({
			type: 'USER_GET_INFO_STATS_START'
		})
		const { dateFrom, dateTo } = getState().userStats
		new UserRestClient(true).getViewsStats({
			dateFrom,
			dateTo,
			page
		})
			.then(response => {
				dispatch({
					type: 'USER_GET_INFO_STATS_SUCCESS',
					payload: {
						data: response.data.items || [],
						page: page ? page : 0,
						pageCount: response.data.pageCount,
						totalItems: response.data.totalItems,
					}
				})
			})
			.catch(error => {
				if (error instanceof axios.Cancel) {
					// Nothing to do ...
				} else {
					dispatch({
						type: "USER_GET_INFO_STATS_FAILURE",
					})
					dispatch({
						type: 'UI_ALERT_SET_ERROR',
						payload: {
							error: ErrorManager.toMessage(error)
						}
					})
				}

			})
	}
}

function getUserWalletMovementById(id) {
	return (dispatch) => {
		dispatch({
			type: 'USER_GET_WALLET_MOVEMENT_START'
		})

		new UserRestClient(true).getUserMovementById({
			id
		})
			.then(res => {
				dispatch({
					type: 'USER_GET_WALLET_MOVEMENT_SUCCESS',
					payload: {
						movement: res.data
					}
				})
			})
			.catch(error => {
				if (error instanceof axios.Cancel) {
					// Nothing to do ...
				} else {
					dispatch({
						type: "USER_GET_WALLET_MOVEMENT_FAILURE",
					})
					dispatch({
						type: 'UI_ALERT_SET_ERROR',
						payload: {
							error: ErrorManager.toMessage(error)
						}
					})
				}

			})
	}
}

function setDateFrom(dateFrom) {
	return {
		type: 'USER_STATS_SET_DATE_FROM',
		payload: {
			dateFrom
		}
	}
}

function setDateTo(dateTo) {
	return {
		type: 'USER_STATS_SET_DATE_TO',
		payload: {
			dateTo
		}
	}
}


function clearState() {
	return {
		type: 'USER_STATS_CLEAR_STATE',
	}
}
export const UserStatsActions = {
	getPromotorStats,
	setDateFrom,
	setDateTo,
	getPromotorShareList,
	getPromotorCommissionsList,
	getPromotorBookingsList,
	getProveedorStats,
	getProveedorBookingsList,
	getPayRevenueList,
	getProveedorViewsList,
	getUserWalletInfoList,
	getUserWalletMovementById,
	clearState,
}