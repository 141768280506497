import React, { useState, useCallback, useMemo } from 'react'
import { Modal } from 'antd'
import moment from 'moment'

const AnTimePicker = React.memo((props) => {
    const {
        className,
        shape,
        placeholder,
        format,
        value,
        disabled,
        onChange
    } = props
    
    const options = useMemo(() => ({
        hours: [
            '00',
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23'
        ],
        minutes: [
            '00',
            '05',
            '10',
            '15',
            '20',
            '25',
            '30',
            '35',
            '40',
            '45',
            '50',
            '55'
        ]
    }), [])

    const [open, setOpen] = useState(false)
    const [selectedHour, setSelectedHour] = useState(moment(value || moment().hours('00')).format('HH'))
    const [selectedMinute, setSelectedMinute] = useState(moment(value || moment().minutes('00')).format('mm'))

    // Handle open date picker modal
    // on user clicks on input
    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [setOpen])

    // Handle close when the user
    // click cancel modal
    // In this case we reset posible
    // selected hour o minute to the original
    // value
    const handleClose = useCallback(() => {
        setSelectedHour(moment(value || moment()).hours())
        setSelectedMinute(moment(value || moment()).minutes())
        setOpen(false)
    }, [value, setSelectedHour, setSelectedMinute, setOpen, moment])

    // Handle user select an hour
    // option
    const handleSelectHourOption = useCallback((hour) => {
        setSelectedHour(hour)
    }, [setSelectedMinute])

    // Handle user select a minute
    // option
    const handleSelectMinuteOption = useCallback((minute) => {
        setSelectedMinute(minute)
    }, setSelectedMinute)

    // Handle user apply selection
    // In this case we apply the change
    // calling onChange prop
    const handleOk = useCallback(() => {
        onChange(
            moment(value || moment())
                .hours(selectedHour)
                .minutes(selectedMinute)
                .seconds(0)
                .milliseconds(0)
        )
        setOpen(false)
    }, [selectedHour, selectedMinute, onChange, setOpen, moment])

    return (
        <>
            <button
                className={`ant-picker ${className} ${shape} ${disabled ? ' ant-picker-disabled' : ''}`}
                disabled={disabled}
                onClick={handleOpen}
            >
                <div className="ant-picker-input">
                    <input
                        readonly={true}
                        placeholder={placeholder}
                        size="10"
                        autocomplete="off"
                        value={value ? `${moment(value || moment()).format(format || 'HH:mm')}` : ''}
                    />

                    <span className="ant-picker-suffix">
                        <ion-icon name="time-outline"></ion-icon>
                    </span>
                </div>
            </button>

            <Modal
                centered
                transitionName="zoom"
                title={`Seleccionar Horario`}
                visible={open}
                onOk={handleOk}
                onCancel={handleClose}
                okText='Confirmar'
                cancelText='Cerrar'
                cancelButtonProps={{
                    shape: 'round'
                }}
                okButtonProps={{
                    shape: 'round'
                }}
            >
                <div className="an-time-picker-options-container">
                    <div className="an-time-picker-column-owrap">
                        <div className="title">HORA</div>
                        <div
                            className="an-time-picker-column-iwrap"
                        >
                            {options.hours.map((hour) => (
                                <button
                                    className={`an-time-picker-option${hour === selectedHour ? ' an-time-picker-option--selected' : ''}`}
                                    onClick={() => handleSelectHourOption(hour)}
                                >
                                    {hour}
                                </button>
                            ))}
                        </div>
                    </div>
                    
                    <div className="an-time-picker-column-owrap">
                        <div className="title">MINUTO</div>
                        <div
                            className="an-time-picker-column-iwrap"
                        >
                            {options.minutes.map((minute) => (
                                <button
                                    className={`an-time-picker-option${minute === selectedMinute ? ' an-time-picker-option--selected' : ''}`}
                                    onClick={() => handleSelectMinuteOption(minute)}
                                >
                                    {minute}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
})

AnTimePicker.defaultProps = {
    className: 'an-input',
    shape: '',
    placeholder: 'Seleccionar',
    format: 'DD-MM-yyyy',
    onChange: () => {},
    modalProps: {
        // ... 
    },
    timePickerProps: {
        // ...  
    },
}

export default AnTimePicker;