import React, { useCallback } from 'react'
import {
    Link,
    useHistory,
    useLocation,
} from "react-router-dom"
import { connect } from 'react-redux'
import { UIActions } from 'redux/actions'
import logo from 'assets/images/logo.png'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useMedia } from 'react-use'

const TopBar = React.memo((props) => {
    const {
        toggleLeftDrawer,
        user,
        isRoot = true,
        hideTopBarLinkToLogin = false
    } = props

    let isDesktop = useMedia('(min-width: 768px)')
    let history = useHistory()
    let location = useLocation()
    // TODO
    //const storageExperienceId = localStorage.getItem('experienceId')

    const handleClickSpiderwebLogo = useCallback((e) => {
        e.preventDefault()
        // Redirect
        location.pathname === '/search'
            ? window.scrollTo(0, 0)
            : history.push('/search')
    }, [location, history])


    function handleNavAction() {
        if (isRoot) {
            toggleLeftDrawer(true)
        } else {
            history.goBack()
        }
    }

    return (
        <div
            id='top-bar'
            className="nav-container-owrap"
            style={{
                borderBottom: location.pathname === '/search' ?
                    isDesktop ?
                        '1px solid #dbdbdb'
                        :
                        'none'
                    :
                    '1px solid #dbdbdb'
            }}>
            <div className="nav-container-iwrap">
                <div
                    className='menu'
                    onClick={handleNavAction}
                >
                    {isRoot ? (
                        <ion-icon
                            size="large"
                            name="menu-outline"
                            style={{
                                color: '#C3125A'
                            }}
                        />
                    ) : (
                            <ion-icon
                                size="large"
                                name="arrow-back-outline"
                                style={{
                                    color: '#C3125A'
                                }}
                            />
                        )}
                </div>

                <a onClick={handleClickSpiderwebLogo}>
                    <img
                        className="logo"
                        src={logo}
                        alt='logo Spiderweb'
                    />
                </a>


                <div className="nav-action">
                    {!hideTopBarLinkToLogin && (
                        <Link
                            to={user?.role === 'Usuario'
                                ? '/user'
                                : user?.role === 'Cliente'
                                    ? '#'
                                    : '/login'
                            }
                        >
                            {user?.role === 'Usuario' || user?.role === 'Cliente' ? (
                                <Avatar
                                    size={32}
                                    icon={
                                        <UserOutlined />
                                    }
                                    src={user?.picture || ""}
                                />
                            ) : (
                                    <ion-icon
                                        name="log-in-outline"
                                        size="large"
                                        style={{
                                            color: '#C3125A'
                                        }}
                                    />
                                )}
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
})

const mapStateToProps = (state) => ({
    user: state.context.user
})

const mapDispatchToProps = (dispatch) => ({
    toggleLeftDrawer: (bool) => dispatch(UIActions.toggleLeftDrawer(bool)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TopBar)