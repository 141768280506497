
function toggleLeftDrawer(bool) {
    return {
        type: 'UI_TOGGLE_LEFT_DRAWER',
        payload: {
            showLeftSide: bool
        }
    }
}

function toggleBottomDrawer(bool) {
    return {
        type: 'UI_TOGGLE_BOTTOM_DRAWER',
        payload: {
            showBottomDrawer: bool
        }
    }
}

function clearMessage() {
    return {
        type: 'UI_ALERT_CLEAR_MESSAGE',
    }
}

function setVisibilityWindowUI(bool) {
    return {
        type: 'UI_SET_VISIBILITY_WINDOWS',
        payload: {
            visibilityWindow: bool
        }
    }
}

export const UIActions = {
    clearMessage,
    toggleLeftDrawer,
    toggleBottomDrawer,
    setVisibilityWindowUI,
}