import React, { useEffect, useState, useCallback } from 'react'
import { Table, Typography } from 'antd'
import { connect } from 'react-redux'
import { UserStatsActions } from 'redux/actions'
import moment from 'moment'
import { PageHeader } from 'components'
import { AnDayPicker } from 'components/an'
import { TypeWalletMovementList } from 'constants/index'
import { useHistory } from 'react-router-dom'
import { withQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';

const UserWalletInfoScreen = React.memo((props) => {
    const {
        query,
        setQuery,
        user,
        loading,
        data,
        dateTo,
        setDateTo,
        dateFrom,
        setDateFrom,
        getUserWalletInfoList,
        clearState,

        pageCount,
        totalItems,
    } = props

    const history = useHistory()

    const {
        p,
        f,
        t,
    } = query;

    useEffect(() => {
        if (f.length > 0 && t.length > 0) {
            setDateFrom(f)
            setDateTo(t)
        } /* else {
            setQuery({
                t: moment(dateTo).format('yyyy-MM-DD 03:00:00'),
                f: moment(dateFrom).format('yyyy-MM-DD 03:00:00')
            })
        } */

    }, [f, t])

    useEffect(() => {
        if (user) {
            getUserWalletInfoList(p)
        }
    }, [p, dateTo, dateFrom])

    useEffect(() => {
        //Nothing to do. Only clear state when unmount
        return () => {
            clearState()
        }
    }, [])


    const [dataSource, setDataSource] = useState([])
    const columns = [
        {
            title: 'Fecha',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            width: 60,
            ellipsis: {
                showTitle: false,
            }
        },
        {
            title: 'Tipo',
            dataIndex: 'type',
            key: 'type',
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: 'Experiencia',
            dataIndex: 'experience',
            key: 'experience',
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: 'Neto',
            dataIndex: 'net',
            key: 'net',
            ellipsis: {
                showTitle: false,
            },
            width: 120,
            align: 'right',
            render: net => {
                if (net.state === 1) {
                    return <Typography.Text>{net.mount}</Typography.Text>
                } else {
                    return <Typography.Text delete type='secondary'>{net.mount}</Typography.Text>
                }
            },
        },
        {
            title: 'Saldo',
            dataIndex: 'balance',
            key: 'balance',
            ellipsis: {
                showTitle: false,
            },
            width: 110,
            align: 'right',
            render: balance => <Typography.Text strong>{balance}</Typography.Text>,
        },
    ]

    //updaters


    useEffect(() => {
        if (data && data.length > 0) {
            setDataSource(() => {
                data.unshift({})
                return data.map((item, index) => {
                    return {
                        key: index === 0 ? index : item.id,
                        date: index === 0 ? 'Inicial' : moment(item?.creado).format('DD/MM'),
                        type: index === 0 ? '' : typeof item.tipo === 'number' ? TypeWalletMovementList[item.tipo] : 'No hay información',
                        experience: index === 0 ? '' : item?.experiencia ? item.experiencia.nombre : 'No hay información',
                        promotor: index === 0 ? '' : item?.usuario ? item.usuario.nombre : 'No hay información',
                        net: index === 0 ? '' :
                            typeof item.monto === 'number' ?
                                {
                                    state: item.estado,
                                    mount: new Intl.NumberFormat('es-AR', {
                                        maximumFractionDigits: 2,
                                        style: "currency",
                                        currency: "ARS"
                                    }).format(item?.monto)
                                }
                                :
                                {
                                    state: 1,
                                    mount: 'No hay información'
                                },
                        balance: index === 0 ?
                            new Intl.NumberFormat('es-AR', {
                                maximumFractionDigits: 2,
                                style: "currency",
                                currency: "ARS"
                            }).format(data[1]?.estado === 2 ? data[1].saldo : (data[1]?.saldo - data[1].monto))
                            :
                            typeof item.saldo === 'number' ?
                                new Intl.NumberFormat('es-AR', {
                                    maximumFractionDigits: 2,
                                    style: "currency",
                                    currency: "ARS"
                                }).format(item?.saldo)
                                :
                                'No hay información',
                    }
                })
            })
        } else {
            setDataSource([])
        }
    }, [data])

    //handlers

    const handleDateFromSelect = useCallback((e) => {
        setDateFrom(new Date(e))
        setQuery({
            p: 0,
            f: moment(new Date(e)).format('yyyy-MM-DD 03:00:00')
        })
    }, [setDateFrom, setQuery])

    const handleDateToSelect = useCallback((e) => {
        setDateTo(new Date(e))
        setQuery({
            p: 0,
            t: moment(new Date(e)).format('yyyy-MM-DD 03:00:00')
        })
    }, [setDateTo, setQuery])

    const handleOnClickRow = useCallback((record, rowIndex) => {
        if (record.key) {
            history.push(`/user/wallet/details/${record.key}`)
        }
    }, [history])

    const handleOnChangePage = useCallback((page) => {
        setQuery({ p: page - 1 })
    })

    return (
        <>
            <div className="main">
                <PageHeader
                    title="Billetera"
                    subtitle="Movimientos de billetera"
                />

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        margin: '10px 15px 0px'
                    }}
                >
                    <AnDayPicker
                        className='stats-day-picker'
                        title='Seleccionar fecha "Desde"'
                        value={dateFrom}
                        withIcon={false}
                        onChange={handleDateFromSelect}
                        dayPickerProps={{
                            disabledDays: [
                                {
                                    after: new Date(dateTo)
                                }
                            ]
                        }}
                    />
                    -
                    <AnDayPicker
                        className='stats-day-picker'
                        title='Seleccionar fecha "Hasta"'
                        value={dateTo}
                        withIcon={false}
                        onChange={handleDateToSelect}
                        dayPickerProps={{
                            disabledDays: [
                                {
                                    before: new Date(dateFrom),
                                    after: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
                                }
                            ]
                        }}
                    />
                </div>

                <div
                    className='wallet-table'
                    style={{
                        marginTop: 10
                    }}>
                    <Table
                        loading={loading}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={{
                            position: ['bottomCenter'],
                            pageSize: pageCount + 1,
                            current: p + 1,
                            total: totalItems,
                            onChange: handleOnChangePage,
                            showSizeChanger: false
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: () => handleOnClickRow(record, rowIndex), // click row
                            };
                        }}
                        locale={{ emptyText: 'No hay registros entre las fechas seleccionadas' }}
                    />
                </div>
            </div>
        </>
    )
})

const mapStateToProps = (state) => ({
    user: state.context.user,
    data: state.userStats.data,
    loading: state.userStats.loading,
    dateFrom: state.userStats.dateFrom,
    dateTo: state.userStats.dateTo,
    pageCount: state.userStats.pageCount,
    totalItems: state.userStats.totalItems,
})

const mapDispatchToProps = (dispatch) => ({
    getUserWalletInfoList: (page) => dispatch(UserStatsActions.getUserWalletInfoList(page)),
    setDateFrom: dateFrom => dispatch(UserStatsActions.setDateFrom(dateFrom)),
    setDateTo: dateTo => dispatch(UserStatsActions.setDateTo(dateTo)),
    clearState: () => dispatch(UserStatsActions.clearState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withQueryParams({
        p: withDefault(NumberParam, 0), // The current page
        f: withDefault(StringParam, ""),//  The current date from
        t: withDefault(StringParam, ""),// The current date to
    }, UserWalletInfoScreen)
);
