import React, { useEffect, useState, useCallback } from 'react'
import { Table, Typography } from 'antd'
import { connect } from 'react-redux'
import { UserStatsActions } from 'redux/actions'
import moment from 'moment'
import { PageHeader } from 'components'
import { AnDayPicker } from 'components/an'

const UserPromotorCommissionsInfoScreen = React.memo((props) => {
    const {
        user,
        loading,
        data,
        dateTo,
        setDateTo,
        dateFrom,
        setDateFrom,
        getPromotorCommissionsList,
        clearState,

        page,
        pageCount,
        totalItems,
    } = props

    const [currentPage, setCurrentPage] = useState(0)

    useEffect(() => {
        setCurrentPage(page)
    }, [page])

    useEffect(() => {
        if (user) {
            getPromotorCommissionsList(false, currentPage)
        }
    }, [currentPage, dateTo, dateFrom])

    useEffect(() => {
        //Nothing to do. Only clear state when unmount
        return () => {
            clearState()
        }
    }, [])


    const [dataSource, setDataSource] = useState([])

    const columns = [
        {
            title: 'Fecha',
            dataIndex: 'date',
            key: 'date',
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: 'Experiencia',
            dataIndex: 'experience',
            key: 'experience',
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: 'Comision',
            dataIndex: 'mount',
            key: 'mount',
            align: 'right',
            ellipsis: {
                showTitle: false,
            },
            render: commission => {
                if (commission.state === 2) {
                    return <Typography.Text delete type='secondary'>{commission.mount}</Typography.Text>
                } else {
                    return <Typography.Text strong>{commission.mount}</Typography.Text>
                }
            },
        },
    ]

    //updaters

    useEffect(() => {
        if (data && data.length > 0) {
            setDataSource(() => {
                return data.map(commission => {
                    return {
                        key: commission?.id,
                        experience: commission?.experiencia ? commission.experiencia.nombre : 'No hay información',
                        date: moment(commission?.fecha).format('DD/MM/yyyy'),
                        mount: typeof commission?.monto === 'number' ?
                            {
                                mount: new Intl.NumberFormat('es-AR', {
                                    maximumFractionDigits: 2,
                                    style: "currency",
                                    currency: "ARS"
                                }).format(commission.monto),
                                state: commission.estado
                            }
                            :
                            {
                                state: 1,
                                mount: 'No hay información'
                            }
                    }
                })
            })
        } else {
            setDataSource([])
        }
    }, [data])

    //handlers

    const handleDateFromSelect = useCallback((e) => {
        setDateFrom(new Date(e))
        setCurrentPage(0)
    }, [setDateFrom])

    const handleDateToSelect = useCallback((e) => {
        setDateTo(new Date(e))
        setCurrentPage(0)
    }, [setDateTo])

    const handleOnChangePage = useCallback((page) => {
        setCurrentPage(page - 1)
    })

    return (
        <>
            <div className="main">
                <PageHeader
                    title="Comisiones"
                    subtitle="Comisiones generadas por vos"
                />

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        margin: '10px 15px 0px'
                    }}
                >
                    <AnDayPicker
                        className='stats-day-picker'
                        title='Seleccionar fecha "Desde"'
                        value={dateFrom}
                        withIcon={false}
                        onChange={handleDateFromSelect}
                        dayPickerProps={{
                            disabledDays: [
                                {
                                    after: new Date(dateTo)
                                }
                            ]
                        }}
                    />
                    -
                    <AnDayPicker
                        className='stats-day-picker'
                        title='Seleccionar fecha "Hasta"'
                        value={dateTo}
                        withIcon={false}
                        onChange={handleDateToSelect}
                        dayPickerProps={{
                            disabledDays: [
                                {
                                    before: new Date(dateFrom),
                                    after: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
                                }
                            ]
                        }}
                    />
                </div>

                <div
                    style={{
                        marginTop: 10
                    }}>
                    <Table
                        loading={loading}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={{
                            position: ['bottomCenter'],
                            pageSize: pageCount,
                            current: currentPage + 1,
                            total: totalItems,
                            onChange: handleOnChangePage,
                            showSizeChanger: false
                        }}
                        locale={{ emptyText: 'No hay registros entre las fechas seleccionadas' }}
                    />

                </div>
            </div>
        </>
    )
})

const mapStateToProps = (state) => ({
    user: state.context.user,
    data: state.userStats.data,
    loading: state.userStats.loading,
    dateFrom: state.userStats.dateFrom,
    dateTo: state.userStats.dateTo,

    page: state.userStats.page,
    pageCount: state.userStats.pageCount,
    totalItems: state.userStats.totalItems,
})

const mapDispatchToProps = (dispatch) => ({
    getPromotorCommissionsList: (isRed, page) => dispatch(UserStatsActions.getPromotorCommissionsList(isRed, page)),
    setDateFrom: dateFrom => dispatch(UserStatsActions.setDateFrom(dateFrom)),
    setDateTo: dateTo => dispatch(UserStatsActions.setDateTo(dateTo)),
    clearState: () => dispatch(UserStatsActions.clearState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPromotorCommissionsInfoScreen);
