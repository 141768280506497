import React, { useCallback, useRef } from 'react'
import { Row, Spin, Typography } from 'antd'
import { ExperienceListItem } from 'components'

const EMPTY_LOADER = ["", "", "", "", "", ""]

const ExperienceResultPagination = React.memo((props) => {
    const {
        context,
        user,
        loading,
        loadingMoreResults,
        hasMore,
        page,
        results = [],
        getMoreResults,
        likeExperience,
        unlikeExperience,
        shareExperienceUrl,
        isUserView = false,
        isBookingView = false,
        infinityScrollBottomMessage,
        likeItemId,
        openModalMessage,
    } = props

    const observer = useRef()

    const lastItemRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()

        if (IntersectionObserver) {
            observer.current = new IntersectionObserver(entries => {
                
                if (entries[0].isIntersecting && hasMore) {
                    let nextPage = page + 1
                    if (isBookingView) {
                        getMoreResults(user?.sub, nextPage)
                    } else {
                        getMoreResults(user, nextPage)
                    }
                }
            })
        }

        if (node) observer.current.observe(node)
    }, [loading, page, hasMore, getMoreResults])

    return (
        <>
            {loading && !(results.length > 1) ? (
                EMPTY_LOADER.map((item, index) => (
                    <ExperienceListItem
                        key={index}
                        loading={true}
                    />
                ))
            ) : results.map((experience, index) => {
                if ( results.length === index + 1) {
                    return (
                        <ExperienceListItem
                            ref={lastItemRef}
                            key={index.toString()}
                            context={context}
                            user={user}
                            loading={loading}
                            experience={experience}
                            likeExperience={likeExperience}
                            unlikeExperience={unlikeExperience}
                            likeItemId={likeItemId}
                            shareExperienceUrl={shareExperienceUrl}
                            isUserView={isUserView}
                            isBookingView={isBookingView}
                            openModalMessage={openModalMessage}
                        />
                    )
                }
                return (
                    <ExperienceListItem
                        key={index.toString()}
                        context={context}
                        user={user}
                        loading={loading}
                        experience={experience}
                        likeExperience={likeExperience}
                        unlikeExperience={unlikeExperience}
                        likeItemId={likeItemId}
                        shareExperienceUrl={shareExperienceUrl}
                        isUserView={isUserView}
                        isBookingView={isBookingView}
                        openModalMessage={openModalMessage}
                    />
                )
            })}

            {(loadingMoreResults && hasMore) ? (
                <>
                    <Row
                        justify="center"
                        style={{
                            marginTop: '40px',
                            padding: '20px 15px',
                            borderTop: '1px dashed #e5e5e5',
                        }}
                    >
                        <Spin
                            tip="Cargando más experiencias"
                        />
                    </Row>
                </>
            ) : infinityScrollBottomMessage ? (
                <Row
                    justify="center"
                    style={{
                        marginTop: '40px',
                        padding: '15px 15px 15px',
                        borderTop: '1px dashed #e5e5e5',
                        textAlign: 'center'
                    }}
                >
                    <Typography>
                        {infinityScrollBottomMessage}
                    </Typography>
                </Row>
            ) : null}
        </>
    )
})

export default ExperienceResultPagination