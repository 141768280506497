import React, { useState, useEffect } from 'react'
import { Typography } from 'antd'
import { ScrollToTop, Loader } from 'components'
import { AnButtonGradient } from 'components/an'
import { Link } from "react-router-dom"
import Animate from 'rc-animate'

const PostulationHasConfirm = React.memo((props) => {
    const {
        isMobile,
        image,
    } = props

    const [show, setShow] = useState(isMobile ? false : true)

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 500)
    }, [setShow])

    return (
        <>
            <ScrollToTop behavior="auto" />
            
            <Loader loading={!show} />
            
            <Animate
                transitionName="zoom"
                transitionAppear
            >
                {(show && (
                    <div className="login-screen-iwrap">
                        <div className='login-img'>
                            <img
                                src={image}
                                alt='Login Spiderweb'
                            />
                        </div>
                        
                        <div className="login-comp-container">
                            <h1
                                style={{
                                    margin: 0
                                }}
                            >
                                Registro
                            </h1>

                            <Typography.Text
                                type='secondary'
                                style={{
                                    marginBottom: 15,
                                    textAlign: 'center'
                                }}

                            >
								Tu postulación fue enviada. Te enviaremos un correo electrónico confirmando tu postulación y un link para que la finalices
                            </Typography.Text>
                            
                            <Link
                                to='/search'
                            >
                                <AnButtonGradient
                                    type="primary"
                                    shape='round'
                                    size='large'

                                >
                                    De acuerdo!
                                </AnButtonGradient>
                            </Link>
                        </div>
                    </div>
                ))}
            </Animate>
        </>
    )
})

export default PostulationHasConfirm