import React, { useState, useEffect } from 'react'
import { Row, Col, Divider, Button } from 'antd'
import { Form, Switch } from 'antd';
import moment from 'moment'
import { Loader } from 'components'
import {
    AnModal,
    AnInput,
    AnDayPicker,
    AnCountSelect,
    AnSlider,
    AnButtonGradient,
    AnButton,
} from 'components/an'


const FiltersModal = React.memo((props) => {
    const {
        loading,
        categoryOptions,
        languagesOptions,
        tagsOptions,
        onOpen,
        onClose,
        fromDate,
        toDate,
        categories,
        peopleQuantity,
        languages,
        duration,
        maxPrice,
        tags,
        onApplySearchCriteria,
        onClickRemoveFilters,
    } = props;

    const [criteria, setCriteria] = useState({
        fromDate: fromDate,
        toDate: toDate,
        categories: categories,
        peopleQuantity: peopleQuantity,
        languages: languages,
        duration: duration,
        maxPrice: maxPrice,
        tags: tags,
    })

    useEffect(() => {
        onOpen()
    }, [onOpen])

    return (
        <AnModal
            className="experiences-filters-modal"
            headerTitle="Filtros"
            headerButtons={(
                <>
                    <AnButton
                        ghost
                        type='primary'
                        shape='round'
                        size='large'
                        style={{
                            marginRight: 5
                        }}
                        onClick={onClose}
                    >
                        Cerrar
                    </AnButton>

                    <AnButtonGradient
                        type='primary'
                        shape='round'
                        size='large'
                        onClick={() => {
                            onApplySearchCriteria(criteria)
                        }}
                    >
                        Aplicar
                    </AnButtonGradient>
                </>
            )}
        >
            {loading ? (
                <Loader loading={loading} />
            ) : (
                    <>
                        <div className="remove-filters-button-container">
                            <Button
                                ghost
                                type='primary'
                                shape='round'
                                onClick={onClickRemoveFilters}
                            >
                                Remover Filtros
                        </Button>
                        </div>

                        <Form
                            size="large"
                            layout="vertical"
                            onFinish={() => { }}
                        >
                            <Row
                                xs={24}
                                style={{
                                    background: '#fff',
                                }}
                            >
                                <Col
                                    xs={24}
                                    className="filter-section-title"
                                >
                                    Fechas:
                            </Col>

                                <Col xs={24}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                                            <div className="filter-section-input-title">
                                                Desde
                                        </div>

                                            <AnDayPicker
                                                shape="round"
                                                modalProps={{
                                                    cancelButtonProps: {
                                                        shape: 'round'
                                                    },
                                                    okButtonProps: {
                                                        shape: 'round'
                                                    },
                                                }}
                                                dayPickerProps={{
                                                    disabledDays: {
                                                        before: new Date(),
                                                    }
                                                }}
                                                value={criteria?.fromDate}
                                                onChange={(date) => {
                                                    setCriteria({
                                                        ...criteria,
                                                        fromDate: date.format('YYYY-MM-DD'),
                                                        toDate: moment(date).isAfter(moment(criteria.toDate))
                                                            ? moment(date).format('YYYY-MM-DD')
                                                            : criteria.toDate
                                                    })
                                                }}
                                            />
                                        </Col>

                                        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                                            <div className="filter-section-input-title">
                                                Hasta
                                        </div>

                                            <AnDayPicker
                                                shape="round"
                                                modalProps={{
                                                    cancelButtonProps: {
                                                        shape: 'round'
                                                    },
                                                    okButtonProps: {
                                                        shape: 'round'
                                                    },
                                                }}
                                                dayPickerProps={{
                                                    disabledDays: {
                                                        before: moment(criteria?.fromDate || moment()).toDate(),
                                                    }
                                                }}
                                                value={criteria?.toDate}
                                                onChange={(date) => {
                                                    setCriteria({
                                                        ...criteria,
                                                        toDate: date.format('YYYY-MM-DD'),
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            {categoryOptions.length > 0 && (
                                <>
                                    <Divider type="horizontal" />

                                    <Row
                                        xs={24}
                                        style={{
                                            background: '#fff',
                                        }}
                                    >
                                        <Col
                                            xs={24}
                                            className="filter-section-title"
                                        >
                                            Categorias:
                                    </Col>

                                        <Col xs={24}>
                                            <Row gutter={[16, 20]}>
                                                {categoryOptions.map((item) => (
                                                    <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                            }}
                                                        >
                                                            <Switch
                                                                checked={criteria.categories && criteria.categories.indexOf(item.id) !== -1}
                                                                onChange={(checked) => {
                                                                    if (checked) {
                                                                        setCriteria({
                                                                            ...criteria,
                                                                            categories: [].concat(criteria.categories).concat(item.id)
                                                                        })
                                                                    } else {
                                                                        setCriteria({
                                                                            ...criteria,
                                                                            categories: criteria.categories.filter(category => category !== item.id)
                                                                        })
                                                                    }
                                                                }}
                                                            />

                                                            <div
                                                                style={{
                                                                    marginLeft: 10,
                                                                }}
                                                            >
                                                                {item.name}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            {tagsOptions.length > 0 && (
                                <>
                                    <Divider type="horizontal" />

                                    <Row
                                        xs={24}
                                        style={{
                                            background: '#fff',
                                        }}
                                    >
                                        <Col
                                            xs={24}
                                            className="filter-section-title"
                                        >
                                            Etiquetas:
                                    </Col>

                                        <Col xs={24}>
                                            <Row gutter={[16, 20]}>
                                                {tagsOptions.map((item) => (
                                                    <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                            }}
                                                        >
                                                            <Switch
                                                                checked={criteria.tags && criteria.tags.indexOf(item.id) !== -1}
                                                                onChange={(checked) => {
                                                                    if (checked) {
                                                                        setCriteria({
                                                                            ...criteria,
                                                                            tags: [].concat(criteria.tags).concat(item.id)
                                                                        })
                                                                    } else {
                                                                        setCriteria({
                                                                            ...criteria,
                                                                            tags: criteria.tags.filter(tag => tag !== item.id)
                                                                        })
                                                                    }
                                                                }}
                                                            />

                                                            <div
                                                                style={{
                                                                    marginLeft: 10,
                                                                }}
                                                            >
                                                                {item.name}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            <Divider type="horizontal" />

                            <Row
                                xs={24}
                                style={{
                                    background: '#fff',
                                }}
                            >
                                <Col
                                    xs={24}
                                    className="filter-section-title"
                                >
                                    Cantidad de Personas:
                            </Col>

                                <Col xs={24}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24}>
                                            <AnCountSelect
                                                shape="round"
                                                value={criteria?.peopleQuantity}
                                                onChange={(value) => {
                                                    setCriteria({
                                                        ...criteria,
                                                        peopleQuantity: value,
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            {languagesOptions.length > 0 && (
                                <>
                                    <Divider type="horizontal" />

                                    <Row
                                        xs={24}
                                        style={{
                                            background: '#fff',
                                        }}
                                    >
                                        <Col
                                            xs={24}
                                            className="filter-section-title"
                                        >
                                            Idioma Hablado:
                                    </Col>

                                        <Col xs={24}>
                                            <Row gutter={[16, 20]}>
                                                {languagesOptions.map((language) => (
                                                    <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                            }}
                                                        >
                                                            <Switch
                                                                checked={criteria.languages && criteria.languages.indexOf(language.id) !== -1}
                                                                onChange={(checked) => {
                                                                    if (checked) {
                                                                        setCriteria({
                                                                            ...criteria,
                                                                            languages: [].concat(criteria.languages).concat(language.id)
                                                                        })
                                                                    } else {
                                                                        setCriteria({
                                                                            ...criteria,
                                                                            languages: criteria.languages.filter(item => item !== language.id)
                                                                        })
                                                                    }
                                                                }}
                                                            />

                                                            <div
                                                                style={{
                                                                    marginLeft: 10,
                                                                }}
                                                            >
                                                                {language.name}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            <Divider type="horizontal" />

                            <Row
                                xs={24}
                                style={{
                                    background: '#fff',
                                }}
                            >
                                <Col
                                    xs={24}
                                    className="filter-section-title"
                                >
                                    Duración
                                </Col>

                                <Col xs={24}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={{ span: 24 }}>
                                            <div className="filter-section-input-title">
                                                Hasta
                                        </div>

                                            <AnSlider
                                                value={criteria?.duration || 0}
                                                onChange={(value) => {
                                                    setCriteria({
                                                        ...criteria,
                                                        duration: value,
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Divider type="horizontal" />

                            <Row
                                xs={24}
                                style={{
                                    background: '#fff',
                                }}
                            >
                                <Col
                                    xs={24}
                                    className="filter-section-title"
                                >
                                    Tu Presupuesto:
                            </Col>

                                <Col xs={24}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={{ span: 24 }}>
                                            <div className="filter-section-input-title">
                                                Hasta
                                        </div>

                                            <AnInput
                                                shape="round"
                                                type="number"
                                                placeholder="10000"
                                                prefix={
                                                    '$'
                                                }
                                                value={criteria?.maxPrice}
                                                onChange={(e) => {
                                                    setCriteria({
                                                        ...criteria,
                                                        maxPrice: e.target.value,
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </>
                )}
        </AnModal>
    )
})


export default FiltersModal