import React from 'react'
import {
	Button
} from 'antd'

const AnButtonGradient = React.memo((props) => {
	const {
		children,
		type,
		shape,
		size,
		style,
        htmlType,
        block,
		onClick,
		loading,
		disabled
	} = props

	return (
		<Button
            block={block}
			type={type}
			className='an-button-gradient'
			htmlType={htmlType}
			size={size}
			shape={shape}
			onClick={onClick}
            loading={loading}
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundImage:
					type === 'default' || disabled ?
						"none"
						:
						'linear-gradient(to right, #C41F5D 0%,#A31B50 61%, #360C27 100%)',
				border: type === 'default' ? '3px solid #C3205B' : 'none',
				color: type === 'default' ? '#C3205B' : '#FFF',
				height: 32,
				fontWeight: '500',
				...style
			}}
			disabled={disabled}
        >
			{children}
		</Button>
	)
})

export default AnButtonGradient