import React, { useState, useEffect } from 'react'
import { ScrollToTop, Loader } from 'components'
import { AnButton } from 'components/an'
import { Card, Avatar } from 'antd'
import promotorIcon from 'assets/images/login-promotor.png'
import proveedorIcon from 'assets/images/login-proveedor.png'
import Animate from 'rc-animate'

const PostulationUserStepOne = React.memo((props) => {
    const {
        isMobile,
        history
    } = props

    const [show, setShow] = useState(isMobile ? false : true)

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 500)
    }, [setShow])


    return (
        <>
            <ScrollToTop behavior="auto" />

            <Loader loading={!show} />

            <Animate
                transitionName="zoom"
                transitionAppear
            >
                {(show && (
                    <div className="login-screen-iwrap">
                        <div className="login-comp-container">
                            <Card
                                bordered={false}
                                actions={[
                                    <AnButton
                                        block
                                        ghost
                                        type="primary"
                                        shape="round"
                                        size="large"
                                        onClick={() => history.push(`/postulation/user/postulation?type=1`)}
                                    >
                                        Quiero ser Promotor
                                    </AnButton>
                                ]}
                                style={{
                                    marginTop: 20,
                                    marginBottom: 20,
                                }}
                                bodyStyle={{
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                }}
                            >
                                <Card.Meta
                                    avatar={
                                        <Avatar
                                            size={64}
                                            src={promotorIcon}
                                        />
                                    }
                                    title="Promotor"
                                    description="Los promotores son nuestro motor más importante. Son quienes se encargan de acercar las experiencias a los potenciales clientes, ofreciendo las que más se adapten a sus gustos y preferencias."
                                />
                            </Card>

                            <Card
                                bordered={false}
                                actions={[
                                    <AnButton
                                        block
                                        ghost
                                        type="primary"
                                        shape="round"
                                        size="large"
                                        onClick={() => history.push(`/postulation/user/postulation?type=2`)}
                                    >
                                        Quiero ser Proveedor
                                    </AnButton>
                                ]}
                                style={{
                                    marginBottom: 40,
                                }}
                                bodyStyle={{
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                }}
                            >
                                <Card.Meta
                                    avatar={
                                        <Avatar
                                            size={64}
                                            src={proveedorIcon}
                                        />
                                    }
                                    title="Proveedor"
                                    description="Los proveedores son los que ofrecen las mejores experiencias para compartir con nuestros clientes. Ellos se encargan de sumar actividades, salidas, experiencias, recorridos y viajes a nuestra lista de opciones"
                                />
                            </Card>
                        </div>
                    </div>
                ))}
            </Animate>
        </>
    )
})

export default PostulationUserStepOne