import React, { useCallback } from 'react'
import { useHistory, useLocation } from "react-router-dom"
import { Badge, Typography } from 'antd'

const BottomBar = React.memo((props) => {
    const {
        badge
    } = props

    let location = useLocation()
    let history = useHistory()

    const handleTabClick = useCallback((path) => {
        location.pathname === path
            ? window.scrollTo(0, 0)
            : history.push(path)
    })

    return (
        <nav
            className="bottom-bar">

            <div
                className="bottom-bar-item"
                onClick={() => {
                    handleTabClick('/search')
                }}
            >
                <ion-icon
                    name={(location.pathname === '/search')
                        ? "home"
                        : "home-outline"
                    }
                />

                <Typography>
                    Home
                </Typography>
            </div>

            <div
                className="bottom-bar-item"
                onClick={() => {
                    handleTabClick('/liked')
                }}
            >
                <ion-icon
                    name={(location.pathname === '/liked')
                        ? "heart"
                        : "heart-outline"
                    }
                />

                <Typography>
                    Favoritos
                </Typography>
            </div>

            <div
                className="bottom-bar-item"
                onClick={() => {
                    handleTabClick('/messages')
                }}
            >
                <Badge
                    count={badge}
                    overflowCount={9}
                    offset={[0, 5]}
                    style={{ backgroundColor: "#c3125a" }}
                >
                    <ion-icon
                        name={(location.pathname === '/messages')
                            ? "chatbox"
                            : "chatbox-outline"
                        }
                    />
                </Badge>

                <Typography>
                    Mensajes
                </Typography>
            </div>
        </nav>
    );
})

export default BottomBar