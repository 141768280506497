import React from 'react'
import Icon from '@ant-design/icons'

const RatingSvg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="19.59" height="20.97" viewBox="0 0 19.59 20.97">
		<g transform="translate(-46.92 -331.417)">
			<path d="M501.536,881.848a2.443,2.443,0,0,1-.935.849l-3.239,1.663a2.447,2.447,0,0,1-2.437-.12l-3.06-1.973a2.446,2.446,0,0,1-1.115-2.171l.179-3.637a2.447,2.447,0,0,1,1.323-2.051l3.238-1.663a2.448,2.448,0,0,1,2.437.119l3.06,1.973a2.448,2.448,0,0,1,1.115,2.171l-.179,3.637a2.444,2.444,0,0,1-.387,1.2m-9.3-5.994a1.35,1.35,0,0,0-.213.66l-.179,3.636a1.347,1.347,0,0,0,.613,1.192l3.059,1.973a1.345,1.345,0,0,0,1.339.066l3.238-1.663a1.345,1.345,0,0,0,.727-1.126l.179-3.636a1.345,1.345,0,0,0-.613-1.192l-3.059-1.973a1.347,1.347,0,0,0-1.339-.066l-3.238,1.663a1.343,1.343,0,0,0-.514.466" transform="translate(-485.011 -509.466) rotate(-3)" fill="#c3205b" />
			<path d="M501.745,880.594a3.334,3.334,0,0,1-1.276,1.158l-5.952,3.057a3.342,3.342,0,0,1-3.328-.163l-5.623-3.626a3.339,3.339,0,0,1-1.522-2.963l.328-6.684a3.338,3.338,0,0,1,1.805-2.8l5.952-3.057a3.34,3.34,0,0,1,3.328.164l5.623,3.626a3.34,3.34,0,0,1,1.522,2.963l-.329,6.684a3.332,3.332,0,0,1-.529,1.641m-15.919-10.266a2.23,2.23,0,0,0-.354,1.1l-.329,6.683a2.237,2.237,0,0,0,1.019,1.985l5.623,3.626a2.235,2.235,0,0,0,2.228.11l5.952-3.057a2.236,2.236,0,0,0,1.209-1.874l.329-6.683a2.236,2.236,0,0,0-1.019-1.985l-5.623-3.626a2.236,2.236,0,0,0-2.228-.11l-5.952,3.057a2.23,2.23,0,0,0-.855.775" transform="translate(-481.735 -506.243) rotate(-3)" fill="#c3205b" />
		</g>
	</svg>
)

const RatingIcon = props => <Icon component={RatingSvg} {...props} />

export default RatingIcon

