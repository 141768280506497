import ClientRestClient from "http/resources/ClientRestClient";
import ErrorManager from "services/error-manager";

function getClientInfo(user) {
    return async (dispatch) => {
        try {
            dispatch({
                type: "BOOKING_CLIENT_FETCH_INFO_START",
            });

            const response = await new ClientRestClient(true).getClient(user?.sub);

            dispatch({
                type: "BOOKING_CLIENT_FETCH_INFO_SUCCESS",
                payload: {
                    clientName: response.data.nombre,
                    clientEmail: response.data.email,
                    clientPhone: response.data.telefono,
                    clientAdditionalComments: "",
                },
            });
        } catch (error) {
            dispatch({
                type: "BOOKING_CLIENT_FETCH_INFO_FAILURE",
            });
        }
    };
}

function createNewBooking(experienceId, bookingData) {
    return (dispatch) => {
        const { dateId, userPromotorNic, peopleQuantity, bookingType, paymentData, clientInfo } = bookingData;

        dispatch({
            type: "BOOKING_CREATE_BOOKING_START",
        });

        new ClientRestClient()
            .createNewBooking({
                experienceId,
                dateId,
                userPromotorNic,
                peopleQuantity,
                bookingType,
                paymentData,
                clientInfo,
            })
            .then((res) => {
                dispatch({
                    type: "BOOKING_CREATE_BOOKING_SUCCESS",
                    payload: {
                        bookingId: res.request.response,
                    },
                });
            })
            .catch((err) => {
                dispatch({
                    type: "BOOKING_CREATE_BOOKING_FAILURE",
                    payload: {
                        error: ErrorManager.toMessage(err),
                    },
                });
            });
    };
}

function getBookingStatus(bookingId) {
    return (dispatch) => {
        dispatch({
            type: "BOOKING_GET_BOOKING_STATUS_START",
        });

        new ClientRestClient()
            .getBookingStatus({
                bookingId,
            })
            .then((res) => {
                dispatch({
                    type: "BOOKING_GET_BOOKING_STATUS_SUCCESS",
                    payload: {
                        bookingDetails: res.data,
                    },
                });
            })
            .catch((err) => {
                dispatch({
                    type: "BOOKING_GET_BOOKING_STATUS_FAILURE",
                });
                dispatch({
                    type: "UI_ALERT_SET_ERROR",
                    payload: {
                        error: ErrorManager.toMessage(err),
                    },
                });
            });
    };
}

function setBookingType(bookingType) {
    return {
        type: "BOOKING_SET_STATE",
        payload: {
            name: "bookingType",
            value: bookingType,
        },
    };
}

function setHourOption(hourOption) {
    return {
        type: "BOOKING_SET_STATE",
        payload: {
            name: "hourOption",
            value: hourOption,
        },
    };
}

function setSavedDate(savedDate) {
    return {
        type: "BOOKING_SET_STATE",
        payload: {
            name: "savedDate",
            value: savedDate,
        },
    };
}

function setPeopleQuantity(peopleQuantity) {
    return {
        type: "BOOKING_SET_STATE",
        payload: {
            name: "peopleQuantity",
            value: peopleQuantity,
        },
    };
}

function setFinalPrice(finalPrice) {
    return {
        type: "BOOKING_SET_STATE",
        payload: {
            name: "finalPrice",
            value: finalPrice,
        },
    };
}

function setSelectedDay(selectedDay) {
    return {
        type: "BOOKING_SET_STATE",
        payload: {
            name: "selectedDay",
            value: selectedDay,
        },
    };
}

function setClientName(clientName) {
    return {
        type: "BOOKING_SET_STATE",
        payload: {
            name: "clientName",
            value: clientName,
        },
    };
}

function setClientEmail(clientEmail) {
    return {
        type: "BOOKING_SET_STATE",
        payload: {
            name: "clientEmail",
            value: clientEmail,
        },
    };
}

function setClientPhone(clientPhone) {
    return {
        type: "BOOKING_SET_STATE",
        payload: {
            name: "clientPhone",
            value: clientPhone,
        },
    };
}

function setClientAdditionalComments(clientAdditionalComments) {
    return {
        type: "BOOKING_SET_STATE",
        payload: {
            name: "clientAdditionalComments",
            value: clientAdditionalComments,
        },
    };
}

function setClientWhereToPickup(text) {
    return {
        type: "BOOKING_SET_STATE",
        payload: {
            name: "whereToPickup",
            value: text,
        },
    };
}

function setClientWhereToGo(text) {
    return {
        type: "BOOKING_SET_STATE",
        payload: {
            name: "whereToGo",
            value: text,
        },
    };
}

function setClientFlightNumber(text) {
    return {
        type: "BOOKING_SET_STATE",
        payload: {
            name: "flightNumber",
            value: text,
        },
    };
}

function reset() {
    return {
        type: "BOOKING_RESET",
    };
}

export const BookingActions = {
    getClientInfo,
    setBookingType,
    setSelectedDay,
    setHourOption,
    setSavedDate,
    setPeopleQuantity,
    setFinalPrice,
    setClientName,
    setClientEmail,
    setClientPhone,
    setClientAdditionalComments,

    createNewBooking,
    getBookingStatus,
    setClientWhereToPickup,
    setClientWhereToGo,
    setClientFlightNumber,
    reset,
};
