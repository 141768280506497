import ClientRestClient from 'http/resources/ClientRestClient'
import UserRestClient from 'http/resources/UserRestClient'
import ErrorManager from 'services/error-manager'
import auth from 'session/api'
import JWT from 'services/jwt'

function loginUserWithEmailAndPass(values) {
	return async dispatch => {

		dispatch({
			type: 'LOADING_SET_LOADING',
			payload: {
				loadingList: {
					email: true,
				}
			}
		})

		auth.signInWithEmailAndPassword(values.email, values.password)
			.then(res => {
				new UserRestClient(false).getAccessToken()
					.then(res => {
						// Store the access token
						localStorage.setItem('accessToken', res.data.value)

						dispatch({
							type: 'LOGIN_USER_SUCCESS',
							payload: {
								loadingList: {
									google: false,
									facebook: false,
									email: false
								}
							}
						})

						dispatch({
							type: 'CONTEXT_SET',
							payload: {
								user: JWT.parseToken(res.data.value)
							}
						})
					})
					.catch(err => {
						dispatch({
							type: 'LOGIN_USER_FAILURE',
						})
						dispatch({
							type: 'UI_ALERT_SET_ERROR',
							payload: {
								error: 'Hubo un problema, intente nuevamente'
							}
						})
					})

			})
			.catch(err => {
				dispatch({
					type: 'LOGIN_USER_FAILURE',
				})
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(err)
					}
				})
			})
	}
}
function loginUser(provider) {
	return async dispatch => {
		if (provider === 'facebook') {
			dispatch({
				type: 'LOADING_SET_LOADING',
				payload: {
					loadingList: {
						facebook: true,
					}
				}
			})
			//handle register with Facebook provider
			auth.signInWithFacebook()
				.then(result => {
					getAccessToken(dispatch)
				})
				.catch(err => {
					dispatch({
						type: 'UI_ALERT_SET_ERROR',
						payload: {
							error: ErrorManager.toMessage(err)
						}
					})
				})
		} else if (provider === 'google') {
			//handle register with Google provider
			dispatch({
				type: 'LOADING_SET_LOADING',
				payload: {
					loadingList: {
						google: true,
					}
				}
			})
			await auth.signInWithGoogle()
				.then(res => {
					getAccessToken(dispatch)
				})
				.catch(err => {
					dispatch({
						type: 'LOGIN_USER_FAILURE',
					})
					dispatch({
						type: 'UI_ALERT_SET_ERROR',
						payload: {
							error: ErrorManager.toMessage(err)
						}
					})
				})
		}
	}
}

function getAccessToken(dispatch) {
	new UserRestClient().getAccessToken()
		.then(res => {
			// Store the access token
			localStorage.setItem('accessToken', res.data.value)

			dispatch({
				type: 'LOGIN_USER_SUCCESS',
				payload: {
					loadingList: {
						google: false,
						facebook: false
					}
				}
			})

			dispatch({
				type: 'CONTEXT_SET',
				payload: {
					user: JWT.parseToken(res.data.value)
				}
			})
		})
		.catch(err => {
			dispatch({
				type: 'LOGIN_USER_FAILURE',
			})
			dispatch({
				type: 'UI_ALERT_SET_ERROR',
				payload: {
					error: 'Hubo un problema, intente nuevamente'
				}
			})
		})

}

function sendSignInLinkToEmail(email) {
	return dispatch => {
		dispatch({
			type: 'SEND_EMAIL_LOADING',
		})
		auth.sendSignInLinkToEmail(email)
			.then(res => {
				dispatch({
					type: 'SEND_EMAIL_SUCCESS',
				})
				localStorage.setItem('emailForSignIn', email)
			})
			.catch(err => {
				dispatch({
					type: 'LOGIN_USER_FAILURE',
				})
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(err)
					}
				})
			})
	}
}

function signInWithEmailLink(email, link) {
	return async dispatch => {
		try {
			dispatch({
				type: 'SIGN_IN_EMAIL_START'
			})

			const anonimousToken = await auth.currentUser().getIdToken()

			// Sign with firebase with provided link
			await auth.signInWithEmailLink(email, link)

			const response = await new ClientRestClient().getAccessToken(anonimousToken)

			dispatch({
				type: 'SIGN_IN_EMAIL_SUCCESS'
			})

			localStorage.removeItem('emailForSignIn')
			localStorage.setItem('accessToken', response.data.value)

			dispatch({
				type: 'CONTEXT_SET',
				payload: {
					user: JWT.parseToken(response.data.value)
				}
			})
		} catch (error) {
			localStorage.removeItem('emailForSignIn')

			dispatch({
				type: 'SIGN_IN_EMAIL_FAILED'
			})
			dispatch({
				type: 'UI_ALERT_SET_ERROR',
				payload: {
					error: ErrorManager.toMessage(error)
				}
			})
		}
	}
}


function userLogoutSession() {
	return async (dispatch, getState) => {
		try {
			if (auth.currentUser()) {
				// Sign out from firebase
				await auth.signOut()
			}

			// Remove access token and posible impersonate
			// token if it's a impersonate session
			localStorage.removeItem('accessToken')
			localStorage.removeItem('impersonateToken')

			dispatch({
				type: 'CONTEXT_SET',
				payload: {
					user: null,
					impersonated: getState().context.impersonated ? false : null,
				}
			})
		} catch (e) {
			// TODO
		}
	}
}

function sendEmailToResetPassword(email) {
	return async (dispatch) => {
		dispatch({
			type: 'SESSION_SEND_EMAIL_RESET_PASSWORD_START'
		})
		auth.sendPasswordResetEmail(email)
			.then(() => {
				dispatch({
					type: 'SESSION_SEND_EMAIL_RESET_PASSWORD_SUCCESS'
				})
				dispatch({
					type: 'UI_ALERT_SET_SUCCESS',
					payload: {
						message: 'Se envió el correo de recuperación'
					}
				})

			})
			.catch(error => {
				dispatch({
					type: 'SESSION_SEND_EMAIL_RESET_PASSWORD_FAILED'
				})
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(error)
					}
				})
			})
	}
}

function confirmPasswordReset(code, newPassword) {
	return async (dispatch) => {
		dispatch({
			type: 'SESSION_RESET_PASSWORD_START'
		})
		auth.confirmPasswordReset(code, newPassword)
			.then(() => {
				dispatch({
					type: 'SESSION_RESET_PASSWORD_SUCCESS'
				})
				dispatch({
					type: 'UI_ALERT_SET_SUCCESS',
					payload: {
						message: 'La contraseña se reestableció correctamente'
					}
				})

			})
			.catch(error => {
				dispatch({
					type: 'SESSION_RESET_PASSWORD_FAILED'
				})
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(error)
					}
				})
			})
	}
}

function clearState() {
	return {
		type: 'SESSION_CLEAR_STATE'
	}
}
export const SessionActions = {
	userLogoutSession,
	signInWithEmailLink,
	sendSignInLinkToEmail,
	loginUserWithEmailAndPass,
	loginUser,
	clearState,
	sendEmailToResetPassword,
	confirmPasswordReset,
}

