import React from 'react'
import { Space, Empty } from 'antd'

const EmptyList = React.memo((props) => {
    const {
        description,
        image,
        actionButton,
    } = props

    return (
        <div className="empty-list-container">
            <Space direction="vertical" align="center">
                <Empty
                    description={description}
                    image={image}
                /> 

                {!!actionButton && (
                    <>
                        {actionButton}
                    </>
                )}
            </Space>
        </div>
    )
})

EmptyList.defaultProps = {
    description: 'No se han encontrados resultados',
    image: (
        <ion-icon name="alert-circle-outline"></ion-icon>
    ),
    actionButton: null,
}

export default EmptyList;