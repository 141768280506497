const defaultState = {
    // List
    loading: true,
    error: false,
    data: [],
	pageCount: 0,
	totalItems: 0,
	page: 0,
	hasMore: true,
    
    experienceId: null, // TODO
    
    experience: {
        loading: false,
        error: false,
        data: {
            id: '',
            title: '',
            provider: {
                id: '',
                name: ''
            },
            details: '',
            detailsEn: '',
            price: 0,
            bookingType: [],
            createdDate: '',
            direction: {
                street: '',
                number: '',
                depto: '',
                locate: {}
            },
            category: {
                id: '',
                name: '',
                inactive: false,
            },
            peopleQuantity: 0,
            duration: '',
            tolerance: '',
            tags: [],
            languages: [],
            externalUrl: '',
            timeZoneId: '',
            isPublic: false,
            rating: 0,
            mediaList: [],
            isLiked: false,
			flexibleHours: false,
        },
        actions: {
            create: {
                id: null,
                loading: false,
                success: false,
                error: false,
            },
            update: {
                loading: false,
                success: false,
                error: false,
            },
            publish: {
                loading: false,
                public: false,
                success: false,
                error: false,
            }
        }
    },
    
    checkIsPublic: false, // TODO
	loadingCheck: false, // TODO
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'USER_EXPERIENCES_FETCH_EXPERIENCES_START':
            return Object.assign({}, state, {
                loading: true,
                error: false,
            })

        case 'USER_EXPERIENCES_FETCH_EXPERIENCES_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: action.payload.experiences,
                pageCount: action.payload.pageCount,
                totalItems: action.payload.totalItems,
                page: action.payload.page,
                hasMore: action.payload.hasMore,
            })

        case 'USER_EXPERIENCES_FETCH_EXPERIENCES_FAILURE':
            return Object.assign({}, state, {
                loading: true,
                error: true,
            })

        case 'USER_EXPERIENCES_MORE_RESULTS_LOADING':
            return Object.assign({}, state, {
                loadingMoreResults: true
            })

        case 'USER_EXPERIENCES_MORE_RESULTS_SUCCESS':
            return Object.assign({}, state, {
                data: [].concat(state.data).concat(action.payload.experiences),
                pageCount: action.payload.pageCount,
                totalItems: action.payload.totalItems,
                page: action.payload.page,
                loadingMoreResults: false,
                hasMore: action.payload.hasMore,
            })

        case 'USER_EXPERIENCES_MORE_RESULTS_FAILURE':
            return Object.assign({}, state, {
                loadingMoreResults: false
            })

        case 'USER_EXPERIENCES_FETCH_EXPERIENCE_START':
            return Object.assign({}, state, {
                loading: true,
                error: false,
            })

        case 'USER_EXPERIENCES_FETCH_EXPERIENCE_SUCCESS':
            return Object.assign({}, state, {
                experience: Object.assign({}, state.experience, {
                    data: Object.assign({}, state.experience.data, {
                        ...action.payload.experience,
                    }
                )}),
                checkIsPublic: action.payload.experience.isPublic,
                loading: false,
            })

        case 'USER_EXPERIENCES_FETCH_EXPERIENCE_FAILURE':
            return Object.assign({}, state, {
                loading: false,
                error: true,
            })

        case 'USER_EXPERIENCES_CREATE_EXPERIENCE_START':
            return Object.assign({}, state, {
                experience: Object.assign({}, state.experience, {
                    actions: Object.assign({}, state.experience.actions, {
                        create: {
                            id: null,
                            loading: true,
                            success: false,
                            error: false,
                        }
                    })
                })
            })

        case 'USER_EXPERIENCES_CREATE_EXPERIENCE_SUCCESS':
            return Object.assign({}, state, {
                experience: Object.assign({}, state.experience, {
                    actions: Object.assign({}, state.experience.actions, {
                        create: {
                            id: action.payload.experienceId,
                            loading: false,
                            success: true,
                            error: false,
                        }
                    })
                })
            })

        case 'USER_EXPERIENCES_CREATE_EXPERIENCE_FAILURE':
            return Object.assign({}, state, {
                experience: Object.assign({}, state.experience, {
                    actions: Object.assign({}, state.experience.actions, {
                        create: {
                            id: null,
                            loading: false,
                            success: false,
                            error: true,
                        }
                    })
                })
            })

        case 'USER_EXPERIENCES_CREATE_EXPERIENCE_RESET':
            return Object.assign({}, state, {
                experience: Object.assign({}, state.experience, {
                    actions: Object.assign({}, state.experience.actions, {
                        create: defaultState.experience.actions.create
                    })
                })
            })

        case 'USER_EXPERIENCES_FETCH_EXPERIENCE_MEDIA_START':
            return Object.assign({}, state, {
                loading: true,
                experience: Object.assign({}, state.experience, {
                    data: Object.assign({}, state.experience.data, {
                        mediaList: []
                    },
                )}),
            })

        case 'USER_EXPERIENCES_FETCH_EXPERIENCE_MEDIA_SUCCESS':
            return Object.assign({}, state, {
                experience: Object.assign({}, state.experience, {
                    data: Object.assign({}, state.experience.data, {
                        mediaList: action.payload.mediaList
                    },
                )}),
                loading: false,
            })

        case 'USER_EXPERIENCES_UPDATE_EXPERIENCE_START':
            return Object.assign({}, state, {
                experience: Object.assign({}, state.experience, {
                    actions: Object.assign({}, state.experience.actions, {
                        update: {
                            loading: true,
                            success: false,
                            error: false,
                        }
                    })
                })
            })

        case 'USER_EXPERIENCES_UPDATE_EXPERIENCE_SUCCESS':
            return Object.assign({}, state, {
                experience: Object.assign({}, state.experience, {
                    actions: Object.assign({}, state.experience.actions, {
                        update: {
                            loading: false,
                            success: true,
                            error: false,
                        }
                    })
                })
            })

        case 'USER_EXPERIENCES_UPDATE_EXPERIENCE_FAILURE':
            return Object.assign({}, state, {
                experience: Object.assign({}, state.experience, {
                    actions: Object.assign({}, state.experience.actions, {
                        update: {
                            loading: false,
                            success: false,
                            error: true,
                        }
                    })
                })
            })
        
        case 'USER_EXPERIENCES_UPDATE_EXPERIENCE_RESET':
            return Object.assign({}, state, {
                experience: Object.assign({}, state.experience, {
                    actions: Object.assign({}, state.experience.actions, {
                        update: defaultState.experience.actions.update
                    })
                })
            })

        case 'SET_EXPERIENCE_TO_PUBLIC_LOADING':
            return Object.assign({}, state, {
                loadingCheck: true
            })

        case 'SET_EXPERIENCE_TO_PUBLIC_SUCCESS':
            return Object.assign({}, state, {
                loadingCheck: false,
                checkIsPublic: action.payload.checkIsPublic
            })

        case 'SET_EXPERIENCE_TO_PUBLIC_FAILURE':
            return Object.assign({}, state, {
                loadingCheck: false,
                checkIsPublic: state.checkIsPublic,
            })
        
        case 'USER_EXPERIENCES_EXPERIENCE_RESET':
            return Object.assign({}, state, {
                experience: defaultState.experience
            })

        case 'USER_EXPERIENCES_RESET':
            return Object.assign({}, defaultState)
        
        default:
            return state;
    }
}