import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import Reactotron from 'config/reactotron'

const Store = createStore(
    rootReducer,
    compose(
        applyMiddleware(thunk),
        Reactotron.createEnhancer())
);

export default Store;