export const FirebaseErrorsMap = {
	'auth/invalid-custom-token': 'El Token de session customizado es inválido',
	'auth/provider-already-linked': 'El proveedor ya se encuentra linkeado',
	'auth/invalid-credential': 'Credencial inválida',
	'auth/credential-already-in-use': 'La credencial ya se encuentra en uso',
	'auth/email-already-in-use': 'El email ya se encuentra en uso',
	'auth/operation-not-allowed': 'Operación no permitida',
	'auth/invalid-email': 'Email Inválido',
	'auth/wrong-password': 'Contraseña Incorrecta',
	'auth/invalid-verification-code': 'Código de verificación inválido',
	'auth/invalid-verification-id': 'ID de verificación inválido',
	'auth/captcha-check-failed': 'Chequeo de captcha inválido',
	'auth/invalid-phone-number': 'El Número de línea ingresado, o bien, no es válido, o bien el servicio de SMS no lo reconoce como válido',
	'auth/missing-phone-number': 'No se encuentra el número telefónico',
	'auth/quota-exceeded': 'Cuota excedida',
	'auth/user-disabled': 'Usuario deshabilitado',
	'auth/auth-domain-config-required': 'Configuración de dominio de autenticación requerido',
	'auth/cancelled-popup-request': 'Popup Cancelada',
	'auth/popup-blocked': 'Popup bloqueada',
	'auth/operation-not-supported-in-this-environment': 'Operación no soportada en este entorno',
	'auth/popup-closed-by-user': 'Popup cerrada por el usuario',
	'auth/unauthorized-domain': 'Dominio no autorizado',
	'auth/user-mismatch': 'No coincide el usuario',
	'auth/user-not-found': 'Usuario no encontrado',
	'auth/missing-android-pkg-name': 'No se encuentra el nombre de paquete android',
	'auth/missing-continue-uri': 'No se encuentra el continue uri',
	'auth/missing-ios-bundle-id': 'No se encuentra el nombre de paqueta ios',
	'auth/invalid-continue-uri': 'Continue uri inválido',
	'auth/unauthorized-continue-uri': 'Contunue uri no autorizado',
	'auth/no-such-provider': 'No se encontro el proveedor',
	'auth/requires-recent-login': 'Se requiere login reciente',
	'auth/weak-password': 'Password no seguro',
	'auth/unknown': 'Error inesperado',
	'auth/session-expired': 'El id de verificacion ha expirado',
	'auth/app-not-authorized': 'Aplicación no autorizada',
	'auth/account-exists-with-different-credential': 'Ya existe una cuenta con ese mail generada con otro proveedor de autenticación',
	'auth/code-expired': 'El código de verificación SMS ha expirado, inicie la verificación nuevamente',
	'auth/internal-error': 'El link de verificación ha vencido',
	'auth/id-token-revoked': 'El token se encuentra revocado',
	'auth/id-token-expired': 'El token ID que se proporcionó está vencido',
	"cors-unsupported": "Este navegador no es soportado",
	"storage/invalid-argument": 'No es un archivo válido',
	"storage/unknown": "Error desconocido",
	"storage/object-not-found": "No se encontró el archivo",
	"storage/unauthenticated": "Inicie sesión nuevamente, por favor",
	"storage/unauthorized": "Esta acción no está permitida",
	"storage/canceled": "Canceló la operación",
};

export const ShareChannels = {
	EMAIL: 1,
	WHATSAPP: 2,
	FACEBOOK: 3,
	TWITTER: 5,
}

export const ShareChannelsList = {
	1: "Email",
	2: "WhatsApp",
	3: "Facebook",
	5: "Twitter",
}

export const TypeWalletMovementList = {
	1: "Venta",
	2: "Pago por MP",
	3: "Pago",
	4: "Cobro destino",
	5: "Comisión Promoción",
	6: "Comisión Spiderweb",
	7: "Comisión MP",
	8: "Cobro"
}

export const BookingStatus = {
	1: {
		label: 'Pendiente de pago',
		color: '#ffe58f'
	},
	2: {
		label: 'Confirmada',
		color: 'green'
	},
	3: {
		label: 'Pago rechazado',
		color: 'red'
	},
	4: {
		label: 'Esperando cancelación de pago',
		color: '#ffe58f'
	},
	5: {
		label: 'Cancelada',
		color: 'red'
	}
}

export const BookingTypes = {
	1: 'Pago completo de la reserva',
	2: 'Pago parcial de la reserva',
	3: 'Reserva sin pago'
}

export const BookingTypesExplanationMap = {
	1: {
		message: "La reserva con pago completo indica que le cobraremos todos los conceptos desde la aplicación, por lo que no deberá abonar nada en destino."
	},
	2: {
		message: "La reserva con pago parcial indica que desde la aplicación le cobraremos una parte del valor total de la reserva en concepto de Reserva o Seña, y el resto se cobrará en destino."
	},
	3: {
		message: "La reserva con pago en destino indica que puede reservar desde la aplicación sin realizar ningún pago, abonando la totalidad de la reserva en destino"
	}
}

export const TypeOfUser = {
	'Cliente': 1,
	'Usuario': 2,
}

export const MPTokenErrorMap = {
	'E111': 'Error en el JSON',
	'E114': 'El campo public key esta vacío',
	'E202': 'Revisá tu numero de tarjeta',
	'E203': 'Revisá el código de seguridad',
	'E213': 'Revisá los datos',
	'200': 'El campo public key esta vacío',
	'205': 'Ingresá el número de tu tarjeta',
	'208': 'Elige un mes',
}

export const ExperiencesConfig = {
	pageCount: 10,
}
