

function handleEmailClientInput(emailClient) {
	return {
		type: 'HANDLE_EMAIL_CLIENT_INPUT',
		payload: {
			emailClient
		}
	}
}


export const LoginActions = {
	handleEmailClientInput
}