import React, { useState, useEffect } from 'react'

const AnCountSelect = React.memo((props) => {
	const {
		className,
		shape,
		value,
		step,
		minLimit = 0,
		maxLimit = null,
		onChange,
		disabled
	} = props


	const [numberValue, setnumberValue] = useState(value)

	useEffect(() => {
		if (minLimit !== 0) {
			setnumberValue(minLimit)
		} else {
			setnumberValue(value)
		}
		return () => {
			setnumberValue(minLimit)
		}
	}, [minLimit])

	useEffect(() => {
		if (typeof maxLimit === 'number' && (value + step) > maxLimit) {
			setnumberValue(maxLimit)
		} else {
			setnumberValue(value)
		}
	}, [maxLimit])

	useEffect(() => {
		onChange(numberValue)
	}, [numberValue])

	const onChangeInput = (e) => {
		let input = e.target.value
		let parseInput = parseInt(e.target.value)
		if (input === '' && isNaN(parseInput)) {
			setnumberValue(minLimit)
		} else if (!isNaN(parseInput)) {
			if (parseInput >= minLimit) {
				setnumberValue(parseInput)
			} else {
				setnumberValue(minLimit)
			}
		} else {
			setnumberValue(minLimit)
		}
	}

	const handleMinus = () => {
		setnumberValue(number => {
			if (number === minLimit) return minLimit
			if (number > minLimit) return number - step
		})
	}
	const handlePlus = () => {
		setnumberValue(number => {
			if (maxLimit) {
				if (number === maxLimit) return maxLimit
				if (number < maxLimit) return number + step
			} else {
				return number + step
			}
		})
	}

	return (
		<div
			className={`ant-picker ${className} ${shape}`}
		>
			<div className="ant-picker-input">
				<input
					disabled={maxLimit === 0 || disabled}
					type='text'
					onChange={onChangeInput}
					value={value}
					inputMode="numeric"
					size="10"
					autoComplete={false}
				/>
			</div>

			<span className="ant-picker-suffix">
				<button
					onClick={handleMinus}
					disabled={maxLimit === 0 || disabled}
				>
					<ion-icon name="remove-circle-outline"></ion-icon>
				</button>

				<button
					disabled={maxLimit === 0 || disabled}
					onClick={handlePlus}>
					<ion-icon name="add-circle-outline"></ion-icon>
				</button>
			</span>
		</div>
	)
})

AnCountSelect.defaultProps = {
	className: 'an-input an-count-select',
	shape: '',
	value: 0,
	step: 1,
	onChange: () => { },
	disabled: false
}


export default AnCountSelect