import React, { useEffect } from 'react'

const AnModal = React.memo((props) => {
    const {
        className,
        headerTitle,
        headerButtons,
        children,
        full,
    } = props;

    useEffect(() => {
        document.body.classList.add('modal')
        document.body.classList.add('blocked')
        document.body.classList.add('force')

        return () => {
            document.body.classList.remove('modal')
            document.body.classList.remove('blocked')
            document.body.classList.remove('force')
        }
    }, [document])

    return (
        <div className={`an-modal ${className}`}>
            <div className="modal-backdrop">
                <div className="modal-container">
                    <div className="modal-header-owrap">
                        <div className="modal-header-iwrap">
                            <div className="modal-header-title">
                                {headerTitle}
                            </div>

                            <div className="modal-header-buttons-wrap">
                                {headerButtons}
                            </div>
                        </div>
                    </div>

                    <div className={`modal-body ${full ? 'full' : null}`}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
})

AnModal.defaultProps = {
    className: '',
    headerTitle: 'Título',
    headerButtons: null,
    full: false
}

export default AnModal