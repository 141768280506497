import ClientRestClient from 'http/resources/ClientRestClient'
import ErrorManager from 'services/error-manager'
import axios from 'axios'

function getBookings(user, params, criteria) {
    return async dispatch => {
        try {
            dispatch({
                type: "CLIENT_BOOKINGS_FETCH_BOOKINGS_START",
            })

            const response = await new ClientRestClient(true).getBookings(
                params,
                {
                    clientId: user?.sub,
                    ...criteria
                }
            )
            dispatch({
                type: "CLIENT_BOOKINGS_FETCH_BOOKINGS_SUCCESS",
                payload: {
                    bookings: response.data.items.map(booking => ({
                        bookingId: booking.id,
                        date: booking.fecha,
                        bookingStatus: booking.estado,
                        id: booking.experiencia.id,
                        title: booking.experiencia.nombre,
                        details: booking.experiencia.detalles,
                        detailsEn: booking.experiencia.detallesEn,
                        price: booking.experiencia.precio,
                        rating: booking.experiencia.rating,
                        ranking: booking.experiencia.ranking,
                        isLiked: booking.experiencia.favorito,
                        isPublic: booking.experiencia.publicada,
                        images: booking.experiencia.mediosUrls,
                        provider: booking.usuarioProveedor,
                        clientContact: booking.datosContacto,
                    })),
                    pageCount: response.data.pageCount,
                    totalItems: response.data.totalItems,
                    page: response.data.page,
                    hasMore: !(response.data.items.length < response.data.pageCount)
                }
            })
        } catch (error) {
            if (error instanceof axios.Cancel) {
                // Nothing to do ...
            } else {
                dispatch({
                    type: "CLIENT_BOOKINGS_FETCH_BOOKINGS_FAILURE",
                })
            }
        }
    }
}


function getMoreBookings(user, params, criteria) {
    return async dispatch => {
        try {
            dispatch({
                type: "CLIENT_BOOKINGS_BOOKINGS_FETCH_MORE_BOOKINGS_START",
            })

            const response = await new ClientRestClient(true).getBookings(
                params,
                {
                    clientId: user?.sub,
                    ...criteria
                }
            )

            dispatch({
                type: "CLIENT_BOOKINGS_BOOKINGS_FETCH_MORE_BOOKINGS_SUCCESS",
                payload: {
                    bookings: response.data.items.map(booking => ({
                        bookingId: booking.id,
                        date: booking.fecha,
                        bookingStatus: booking.estado,
                        id: booking.experiencia.id,
                        title: booking.experiencia.nombre,
                        details: booking.experiencia.detalles,
                        detailsEn: booking.experiencia.detallesEn,
                        price: booking.experiencia.precio,
                        rating: booking.experiencia.rating,
                        ranking: booking.experiencia.ranking,
                        isLiked: booking.experiencia.favorito,
                        isPublic: booking.experiencia.publicada,
                        images: booking.experiencia.mediosUrls,
                        provider: booking.usuarioProveedor,
                        clientContact: booking.datosContacto,
                    })),
                    pageCount: response.data.pageCount,
                    totalItems: response.data.totalItems,
                    page: response.data.page,
                    hasMore: !(response.data.items.length < response.data.pageCount)
                }
            })
        } catch (error) {
            dispatch({
                type: "CLIENT_BOOKINGS_BOOKINGS_FETCH_MORE_BOOKINGS_FAILURE",
            })
        }
    }
}

function cancelBooking(bookingId, cancelReason) {
    return dispatch => {
        dispatch({
            type: "CLIENT_BOOKINGS_CANCEL_START",
        })
        new ClientRestClient(true).cancelBooking({
            bookingId,
            cancelReason
        })
            .then((res) => {
                dispatch({
                    type: 'UI_ALERT_SET_SUCCESS',
                    payload: {
                        message: 'La reserva se canceló correctamente'
                    }
                })
                dispatch({
                    type: "CLIENT_BOOKINGS_CANCEL_SUCCESS",
                })
            })
            .catch((error) => {
                dispatch({
                    type: "CLIENT_BOOKINGS_CANCEL_FAILURE",
                })
                dispatch({
                    type: 'UI_ALERT_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(error)
                    }
                })
            })

    }
}

function clearState() {
    return {
        type: 'CLIENT_BOOKINGS_CLEAR_STATE'
    }
}


export const ClientBookingsActions = {
    getBookings,
    getMoreBookings,
    cancelBooking,
    clearState
}