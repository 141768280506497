import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import { ScrollToTop, Loader } from 'components'
import { AnButtonGradient } from 'components/an'
import { Typography, Divider } from 'antd'
import moment from 'moment'
import { BookingActions } from 'redux/actions'
import BookingSummary from './components/BookingSummary'

const BookingStepThree = React.memo((props) => {
	const {
        experience,
        loading,
        loadingCreateBooking,
		savedDate,
		bookingId,
        getBookingStatus,
        
		bookingDetails,
        resetBooking,
        
        jumpStep,
	} = props

	useEffect(() => {
		if (savedDate && bookingId) {
			getBookingStatus(bookingId)
		} else {
			jumpStep(1)
		}
        
        return () => {
			resetBooking()
		}
	}, [savedDate, bookingId, getBookingStatus, resetBooking, jumpStep])

    return (
        <div className="booking-summary-after-confirmation">
            <ScrollToTop />
            
            <div className="main-container">
                <Loader
                    loading={loading || loadingCreateBooking}
                />  
                
                {!loading && !loadingCreateBooking && (
                    <>
                        {bookingDetails.id ? (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                <ion-icon
                                    name="checkmark-circle-outline"
                                    style={{
                                        fontSize: 150,
                                        color: '#5A9261',
                                    }}
                                />

                                <Typography.Title
                                    level={5}
                                >
                                    Reserva Confirmada
                                </Typography.Title>

                                <Divider />

                                <BookingSummary
                                    title={bookingDetails.experiencia?.nombre}
                                    day={moment.utc(bookingDetails.fecha).format('DD-MM-yyyy')}
                                    hour={moment.utc(bookingDetails.fecha).format('HH:mm')}
                                    bookingType={bookingDetails.tipoReserva }
                                    capacity={`${experience?.peopleQuantity} Personas`}
                                    price={`$${experience.price}`}
                                    bookingsQuantity={bookingDetails?.cantidad}
                                    bookingPercentage={experience?.bookingPercentage}
                                    total={bookingDetails.total}
                                />

                                <div
                                    style={{
                                        padding: '20px 10px 10px 10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-evenly',
                                    }}
                                >
                                    <Link
                                        to='/search'
                                    >
                                        <AnButtonGradient
                                            type='primary'
                                            shape='round'
                                            size='large'
                                        >
                                            Quiero ver más experiencias
                                        </AnButtonGradient>
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                <ion-icon
                                    name="close-circle-outline"
                                    style={{
                                        fontSize: 150,
                                        color: '#cf346c',
                                    }}
                                />

                                <Typography.Title
                                    level={5}
                                >
                                    Ha ocurrido un error al realizar la reserva
                                </Typography.Title>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
})

const mapStateToProps = (state) => ({
	savedDate: state.booking.savedDate,
	bookingId: state.booking.bookingId,
	bookingDetails: state.booking.bookingDetails,
    loading: state.booking.loading,
    loadingCreateBooking: state.booking.loadingCreateBooking,

})

const mapDispatchToProps = (dispatch) => ({
	getBookingStatus: bookingId => dispatch(BookingActions.getBookingStatus(bookingId)),
	resetBooking: () => dispatch(BookingActions.reset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(BookingStepThree)

