import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Row, Col, Space, Divider, Button, Spin, Typography, Switch } from "antd";
import { AnButton, AnButtonGradient, AnLabelForm, AnCalendar } from "components/an";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Loader, ScrollToTop } from "components";
import { ExperienceEventsActions, UserExperiencesActions } from "redux/actions";
import moment from "moment";
import ExperienceDayEventsList from "./components/ExperienceDayEventsList";
import ExperienceEventForm from "./components/ExperienceEventForm";

const EditStepTwo = React.memo((props) => {
    const {
        loading,
        prevStep,
        experience,

        loadingExperienceEvents,
        experienceEvents,
        getExperienceEvents,

        updateExperienceEvent,
        createExperienceUniqueEvent,
        createExperienceUniqueEventWithFlexibleHour,
        createExperienceRecurrenceEvent,
        createExperienceRecurrenceEventFlexibleHours,
        removeExperienceEvent,
        removeAllExperienceEvents,

        loadingCreateUniqueEvent,
        successCreateUniqueEvent,

        successCreateRecurrentEvent,
        loadingCreateRecurrentEvent,

        loadingUpdateUniqueEvent,
        successUpdateUniqueEvent,

        loadingRemoveUniqueEvent,
        successRemoveUniqueEvent,

        loadingRemoveAllEvents,
        successRemoveAllEvents,

        loadingCheck,
        checkIsPublic,
        setExperienceToPublic,
    } = props;

    /* ----------
        State
    ------------------------ */

    // The current selected day in the calendar
    // as Javacript Date Object
    const [selectedDay, setSelectedDay] = useState("");

    // Keep only the events filtered by the
    // selected day with the some struct and types
    // as original events object
    const [selectedDayEvents, setSelectedDayEvents] = useState([]);

    // Keep the current collapse open item
    // index. If no open items the value is an empty array
    const [activeSelectedDayEventIndex, setActiveSelectedDayEventIndex] = useState([]);

    // We use it to show/hide the create event
    // form
    const [showCreateEventForm, setShowCreateEventForm] = useState(false);

    /* ----------
        Hooks
    ------------------------ */

    const history = useHistory();

    // We map events as array of Javacript Date Objects
    // We need it to pass this array
    // to the picker
    const selectedDays = useMemo(() => {
        return experienceEvents.map((event) => {
            return new Date(moment.utc(event.dayAndHour).format("YYYY/M/DD"));
        });
    }, [experienceEvents]);

    const handleDayClick = (day, modifiers = {}) => {
        if (modifiers.disabled) {
            return;
        }

        setSelectedDay(day);
        setSelectedDayEvents(() => {
            const mapping = experienceEvents
                .sort((a, b) => {
                    return new Date(a.dayAndHour) - new Date(b.dayAndHour);
                })
                .filter((event) => {
                    return moment.utc(event.dayAndHour).format("DD/M/YYYY") === moment.utc(day).format("DD/M/YYYY");
                });

            return mapping;
        });
    };

    // Handle create unique or recurrent
    // event
    const handleCreateExperienceEvent = (recurrent, data) => {
        // If the current experience is setted "flexibleHours" handle create new
        // events with others endpoints
        if (experience?.flexibleHours) {
            if (!recurrent) {
                const eventStartDate = moment(selectedDay)
                    .hours(data.startTime.format("HH"))
                    .minutes(data.startTime.format("mm"))
                    .format("YYYY-MM-DDTHH:mm:ss");

                createExperienceUniqueEventWithFlexibleHour(experience?.id, {
                    dayAndHour: eventStartDate,
                    peopleQuantity: data.capacity,
                    startTime: moment(data.startTime, "h:mm A").format("H:mm"),
                    endTime: moment(data.endTime, "h:mm A").format("H:mm"),
                });
            } else {
                // TODO: Save recurrent event with flexibleHours
                // Before create event we format event date as YYYY-MM-DDTHH:mm:ss
                // Note that we don't use final Z so we manage event time
                // as experience place local time independiently of current
                // browser timezone
                const eventStartDate = moment(selectedDay)
                    .hours(data.startTime.format("HH"))
                    .minutes(data.startTime.format("mm"))
                    .format("YYYY-MM-DDTHH:mm:ss");

                // This not mean finish event time, it mean
                // when creation recurrecy ends
                const recurrencyEndDateTime = moment(data.recurrencyEndDate)
                    .hours(data.startTime.format("HH"))
                    .minutes(data.startTime.format("mm"))
                    .format("YYYY-MM-DDTHH:mm:ss");

                createExperienceRecurrenceEventFlexibleHours(experience?.id, {
                    startTime: moment(data.startTime, "h:mm A").format("H:mm"),
                    endTime: moment(data.endTime, "h:mm A").format("H:mm"),

                    startDate: eventStartDate,
                    finishDate: recurrencyEndDateTime,
                    peopleQuantity: data.capacity,
                    recurrenceDays: data.recurrencyDays,
                });
            }
        } else {
            if (!recurrent) {
                // Before create event we format event date as YYYY-MM-DDTHH:mm:ss
                // Note that we don't use final Z so we manage event time
                // as experience place local time independiently of current
                // browser timezone
                const eventStartDate = moment(selectedDay)
                    .hours(data.time.format("HH"))
                    .minutes(data.time.format("mm"))
                    .format("YYYY-MM-DDTHH:mm:ss");

                createExperienceUniqueEvent(experience?.id, eventStartDate, data.capacity);
            } else {
                // Before create event we format event date as YYYY-MM-DDTHH:mm:ss
                // Note that we don't use final Z so we manage event time
                // as experience place local time independiently of current
                // browser timezone
                const eventStartDate = moment(selectedDay)
                    .hours(data.time.format("HH"))
                    .minutes(data.time.format("mm"))
                    .format("YYYY-MM-DDTHH:mm:ss");

                // This not mean finish event time, it mean
                // when creation recurrecy ends
                const recurrencyEndDateTime = moment(data.recurrencyEndDate)
                    .hours(data.time.format("HH"))
                    .minutes(data.time.format("mm"))
                    .format("YYYY-MM-DDTHH:mm:ss");

                createExperienceRecurrenceEvent(experience?.id, {
                    startDate: eventStartDate,
                    finishDate: recurrencyEndDateTime,
                    peopleQuantity: data.capacity,
                    recurrenceDays: data.recurrencyDays,
                });
            }
        }
    };

    // Handle update unique event
    const handleUpdateExperienceEvent = useCallback(
        (id, capacity) => {
            updateExperienceEvent(experience.id, id, capacity);
        },
        [updateExperienceEvent, experience?.id]
    );

    // Handle delete unique event
    const handleDeleteExperienceEvent = useCallback(
        (id) => {
            removeExperienceEvent(experience.id, id);
        },
        [removeExperienceEvent, experience.id]
    );

    // Handle remove al calendar events
    const handleRemoveAllExperienceEvents = useCallback(() => {
        removeAllExperienceEvents(experience.id);
    }, [removeAllExperienceEvents, experience.id]);

    // Handle publish/unpublish the experience
    const handleSwitchOnChange = useCallback(
        (value) => {
            setExperienceToPublic(experience.id, value);
        },
        [setExperienceToPublic, experience.id]
    );

    // TODO
    const maxDate = () => {
        let maxDate = new Date();
        maxDate.setMonth(maxDate.getMonth() + 3);
        return maxDate;
    };

    /* ----------
        Effects
    ------------------------ */

    // Get experience events
    useEffect(() => {
        if (experience?.id) {
            getExperienceEvents(experience?.id);
        }
    }, [getExperienceEvents, experience?.id]);

    // Every time and event is modified
    // we get again the experience
    // events
    useEffect(() => {
        if (
            successCreateUniqueEvent ||
            successCreateRecurrentEvent ||
            successUpdateUniqueEvent ||
            successRemoveUniqueEvent ||
            successRemoveAllEvents
        ) {
            getExperienceEvents(experience?.id);
        }
    }, [
        getExperienceEvents,
        experience?.id,
        successUpdateUniqueEvent,
        successCreateUniqueEvent,
        successCreateRecurrentEvent,
        successRemoveUniqueEvent,
        successRemoveAllEvents,
    ]);

    // Every time experience events changes
    // if there's selected day we need update
    // the actual day events to show the updates
    // in the current day events list
    useEffect(() => {
        if (selectedDay) {
            setSelectedDayEvents(() => {
                return experienceEvents
                    .sort((a, b) => {
                        return new Date(a.dayAndHour) - new Date(b.dayAndHour);
                    })
                    .filter((event) => {
                        return moment.utc(event.dayAndHour).format("DD/M/YYYY") === selectedDay.toLocaleDateString();
                    });
            });
        }
    }, [experienceEvents]);

    //
    useEffect(() => {
        if (successCreateUniqueEvent) {
            setShowCreateEventForm(false);
        }

        if (successCreateRecurrentEvent) {
            setShowCreateEventForm(false);
            setSelectedDay("");
            setSelectedDayEvents([]);
        }

        if (successRemoveUniqueEvent) {
            setActiveSelectedDayEventIndex([]);
        }
    }, [successCreateUniqueEvent, successCreateRecurrentEvent, successRemoveUniqueEvent]);

    return (
        <>
            <ScrollToTop />

            <Loader loading={loading} />

            {!loading && (
                <div
                    style={{
                        padding: 15, // TODO - This must be a global class as container of sub main content
                    }}
                >
                    <>
                        {!selectedDay && (
                            <div>
                                <AnCalendar
                                    // React day picker props
                                    loading={loadingExperienceEvents}
                                    onDayClick={handleDayClick}
                                    disabledDays={
                                        loadingExperienceEvents
                                            ? [
                                                  {
                                                      before: new Date(),
                                                      after: new Date(),
                                                  },
                                              ]
                                            : [
                                                  {
                                                      before: new Date(),
                                                  },
                                              ]
                                    }
                                    selectedDays={selectedDays}
                                    // Other props
                                    mode="admin"
                                    events={experienceEvents}
                                    selectedDay={selectedDay}
                                    removeAllEvents={handleRemoveAllExperienceEvents}
                                    removingAllEvents={loadingRemoveAllEvents}
                                    removeAllEventsSuccess={successRemoveAllEvents}
                                    removeAllEventsButtonLabel="Eliminar todos los eventos"
                                    removeAllEventsModalTitle="Advertencia"
                                    removeAllEventsModalDescription="Recordá que sólo se eliminarán los eventos que no tengan lugares reservados"
                                    removeAllEventsModalConfirmButtonLabel="Confirmar"
                                    removeAllEventsModalCancelButtonLabel="Cancelar"
                                    calendarNotAvailable={experience.externalUrl}
                                    calendarNotAvailableAlertTitle="Calendario no disponible"
                                    calendarNotAvailableAlertDescription="La experiencia no posee calendario de eventos ya que se encuentra asociada a un enlace externo"
                                    calendarNotAvailableAlertType="warning"
                                    noEventsAlertTitle="Experiencia sin eventos"
                                    noEventsAlertDescription="La experiencia aún no posee ningún evento creado"
                                    noEventsAlertType="warning"
                                />

                                <Divider />

                                <div>
                                    <AnLabelForm
                                        className="an-label no-horizontal-margin"
                                        label="Configuración de experiencia"
                                        tooltip
                                        tooltipText="Si activa esta opción la experiencia se encontrará pública y los clientes podrán ver, consultar y/o reservar la experiencia"
                                    />

                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography.Text>Experiencia publicada</Typography.Text>

                                        <Switch
                                            defaultChecked={checkIsPublic}
                                            checked={checkIsPublic}
                                            loading={loadingCheck}
                                            onChange={handleSwitchOnChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {!!selectedDay && (
                            <>
                                {!showCreateEventForm && (
                                    <ExperienceDayEventsList
                                        day={selectedDay}
                                        close={() => {
                                            setSelectedDay("");
                                            setActiveSelectedDayEventIndex([]);
                                        }}
                                        showCreateEventForm={() => {
                                            setShowCreateEventForm(true);
                                            setActiveSelectedDayEventIndex([]);
                                        }}
                                        loading={loadingExperienceEvents}
                                        events={selectedDayEvents}
                                        activeEventIndex={activeSelectedDayEventIndex}
                                        setActiveEventIndex={(activeKeyIndex) => {
                                            setActiveSelectedDayEventIndex(activeKeyIndex);
                                        }}
                                        handleUpdateExperienceEvent={handleUpdateExperienceEvent}
                                        handleDeleteExperienceEvent={handleDeleteExperienceEvent}
                                        loadingDelete={loadingRemoveUniqueEvent}
                                        loadingUpdate={loadingUpdateUniqueEvent}
                                        accordion
                                        destroyInactivePanel
                                        hasFlexibleHours={experience.flexibleHours}
                                    />
                                )}

                                {showCreateEventForm && (
                                    <>
                                        <Row justify="end">
                                            <Col>
                                                <Space>
                                                    <Button
                                                        type="ghost"
                                                        shape="round"
                                                        onClick={() => {
                                                            setShowCreateEventForm(false);
                                                        }}
                                                    >
                                                        Volver
                                                    </Button>
                                                </Space>
                                            </Col>
                                        </Row>

                                        <div
                                            style={{
                                                padding: "10px",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                marginTop: 20,
                                            }}
                                        >
                                            {`${moment(selectedDay).format("LL")}`}
                                        </div>

                                        <ExperienceEventForm
                                            className="experience-event-form"
                                            mode="create"
                                            date={selectedDay}
                                            onSave={handleCreateExperienceEvent}
                                            loading={loadingCreateUniqueEvent || loadingCreateRecurrentEvent}
                                            hasFlexibleHours={experience.flexibleHours}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </>

                    {!selectedDay && (
                        <>
                            <Divider dashed />

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-around",
                                }}
                            >
                                <AnButton
                                    block
                                    ghost
                                    type="primary"
                                    shape="round"
                                    size="large"
                                    style={{
                                        width: "50%",
                                        marginRight: "5px",
                                    }}
                                    onClick={prevStep}
                                >
                                    Volver
                                </AnButton>

                                <AnButtonGradient
                                    block
                                    type="primary"
                                    shape="round"
                                    size="large"
                                    style={{
                                        width: "50%",
                                        marginRight: "5px",
                                    }}
                                    onClick={() => {
                                        history.push("/user/experiences");
                                    }}
                                >
                                    Finalizar
                                </AnButtonGradient>
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
});

const mapStateToProps = (state) => ({
    user: state.context.user,
    experienceEvents: state.experienceEvents.experienceEvents,
    loadingExperienceEvents: state.experienceEvents.loading,

    loadingCreateUniqueEvent: state.experienceEvents.loadingCreateUniqueEvent,
    successCreateUniqueEvent: state.experienceEvents.successCreateUniqueEvent,

    loadingCreateRecurrentEvent: state.experienceEvents.loadingCreateRecurrentEvent,
    successCreateRecurrentEvent: state.experienceEvents.successCreateRecurrentEvent,

    loadingRemoveUniqueEvent: state.experienceEvents.loadingRemoveUniqueEvent,
    successRemoveUniqueEvent: state.experienceEvents.successRemoveUniqueEvent,

    loadingRemoveAllEvents: state.experienceEvents.loadingRemoveAllEvents,
    successRemoveAllEvents: state.experienceEvents.successRemoveAllEvents,

    loadingUpdateUniqueEvent: state.experienceEvents.loadingUpdateUniqueEvent,
    successUpdateUniqueEvent: state.experienceEvents.successUpdateUniqueEvent,

    checkIsPublic: state.userExperiences.checkIsPublic,
    loadingCheck: state.userExperiences.loadingCheck,
});

const mapDispatchToProps = (dispatch) => ({
    getExperienceEvents: (experienceId) => dispatch(ExperienceEventsActions.getEvents(experienceId)),
    createExperienceUniqueEvent: (experienceId, dayAndHour, peopleQuantity) =>
        dispatch(ExperienceEventsActions.createUniqueEvent(experienceId, dayAndHour, peopleQuantity)),
    createExperienceUniqueEventWithFlexibleHour: (experienceId, params) =>
        dispatch(ExperienceEventsActions.createUniqueEventWithFlexibleHour(experienceId, params)),
    createExperienceRecurrenceEvent: (experienceId, form) =>
        dispatch(ExperienceEventsActions.createRecurrenceEvent(experienceId, form)),
    createExperienceRecurrenceEventFlexibleHours: (experienceId, form) =>
        dispatch(ExperienceEventsActions.createRecurrenceEventFlexibleHours(experienceId, form)),
    updateExperienceEvent: (experienceId, dateId, editPeopleQuantity) =>
        dispatch(ExperienceEventsActions.updateEvent(experienceId, dateId, editPeopleQuantity)),
    removeExperienceEvent: (experienceId, dateId) =>
        dispatch(ExperienceEventsActions.removeUniqueEvent(experienceId, dateId)),
    removeAllExperienceEvents: (experienceId) => dispatch(ExperienceEventsActions.removeAllEvents(experienceId)),

    setExperienceToPublic: (experienceId, isPublic) =>
        dispatch(UserExperiencesActions.setExperienceToPublic(experienceId, isPublic)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditStepTwo);
