import React, { useEffect, useCallback } from 'react'
import { Alert, Descriptions, Divider, Typography } from 'antd'
import { connect } from 'react-redux'
import { UserStatsActions } from 'redux/actions'
import moment from 'moment'
import { Loader, PageHeader } from 'components'
import { TypeWalletMovementList } from 'constants/index'
import { useParams } from 'react-router-dom'
import { BookingTypes, BookingStatus } from 'constants/index'

const UserWalletInfoScreen = React.memo((props) => {
    const {
        user,
        getUserWalletMovementById,
        movement,
        clearState,
    } = props

    const { id } = useParams()

    useEffect(() => {
        if (user) {
            getUserWalletMovementById(id)
        }
    }, [id])

    useEffect(() => {
        return () => {
            clearState()
        }
    }, [])

    const handleCloseErrorAlert = useCallback(() => {
        getUserWalletMovementById(id)
    }, [id])

    return (
        <>
            <div className="main">
                <PageHeader
                    title="Información de movimiento"
                    subtitle={
                        !movement.loading ?
                            movement.data.type ?
                                TypeWalletMovementList[movement.data.type]
                                :
                                'Detalles'
                            :
                            'Cargando..'}
                />
                <div className='user-wallet-details-screen'>
                    {movement.loading ?
                        <Loader
                            loading={movement.loading}
                        />
                        :
                        movement.error ?
                            <Alert
                                message="Ups!"
                                description="Ha ocurrido un error al intentar obtener los detalles del movimiento"
                                type="error"
                                showIcon
                                closeText="Reintentar"
                                onClose={handleCloseErrorAlert}
                            />
                            :
                            <>
                                {movement.data.experience.id &&
                                    <>
                                        <div className='user-wallet-details-title'>
                                            <h3>Reserva</h3>
                                            <Typography.Title
                                                level={1}
                                                ellipsis={{ rows: 1, expandable: false, symbol: '..' }}
                                            >
                                                {movement.data.experience.name || 'No hay información'}
                                            </Typography.Title>
                                            <h2>{movement.data.providerUser.name || 'No hay información'}</h2>
                                        </div>

                                        <Divider />
                                    </>}

                                <Descriptions
                                    title="Detalles de movimiento"
                                    column={1}
                                >

                                    <Descriptions.Item
                                        label="Monto"
                                    >

                                        <Typography.Text
                                            delete={movement.data.state === 2}
                                        >
                                            {movement.data.mount ?
                                                typeof movement.data.mount === 'number' ?
                                                    new Intl.NumberFormat('es-AR', {
                                                        maximumFractionDigits: 2,
                                                        style: "currency",
                                                        currency: "ARS"
                                                    }).format(movement.data.mount)
                                                    :
                                                    'No hay información'
                                                :
                                                'No hay información'}
                                        </Typography.Text>

                                    </Descriptions.Item>

                                    <Descriptions.Item
                                        label="Tipo"
                                    >
                                        {movement.data.type ?
                                            TypeWalletMovementList[movement.data.type]
                                            :
                                            'No hay información'}
                                    </Descriptions.Item>
                                    {movement.data.details &&
                                        < Descriptions.Item
                                            label="Comentarios"
                                        >
                                            <Typography.Paragraph>
                                                {movement.data.details}
                                            </Typography.Paragraph>
                                        </Descriptions.Item>}

                                </Descriptions>

                                {movement.data.experience.id &&
                                    <>
                                        <Divider />

                                        <Descriptions
                                            title="Detalles de reserva"
                                            column={1}
                                        >
                                            <Descriptions.Item
                                                label="Fecha creación"
                                            >
                                                {movement.data.bookingDate ?
                                                    moment.utc(movement.data.created).format('DD/MM/yyyy - HH:mm')
                                                    :
                                                    'No hay información'}
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label="Fecha reservada"
                                            >
                                                {movement.data.bookingDate ?
                                                    moment.utc(movement.data.bookingDate).format('DD/MM/yyyy - HH:mm')
                                                    :
                                                    'No hay información'}
                                            </Descriptions.Item>

                                            <Descriptions.Item
                                                label="Estado de reserva"
                                            >{movement.data.bookingState ?
                                                BookingStatus[movement.data.bookingState].label
                                                :
                                                'No hay información'}
                                            </Descriptions.Item>

                                            <Descriptions.Item
                                                label="Cantidad de reservas"
                                            >
                                                {movement.data.quantity || 'No hay información'}
                                            </Descriptions.Item>

                                            <Descriptions.Item
                                                label="Total"
                                            >
                                                {typeof movement.data.total === 'number' ?
                                                    new Intl.NumberFormat('es-AR', {
                                                        maximumFractionDigits: 2,
                                                        style: "currency",
                                                        currency: "ARS"
                                                    }).format(movement.data.total)
                                                    :
                                                    'No hay información'}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </>
                                }
                                {movement.data.providerUser?.id === user.sub &&
                                    <>
                                        <Divider />

                                        <Descriptions
                                            title="Comisiones"
                                            column={1}
                                        >
                                            <Descriptions.Item
                                                label="Comisión Spiderweb"
                                            >
                                                {typeof movement.data.commissionPlatform === 'number' ?
                                                    new Intl.NumberFormat('es-AR', {
                                                        maximumFractionDigits: 2,
                                                        style: "currency",
                                                        currency: "ARS"
                                                    }).format(movement.data.commissionPlatform)
                                                    :
                                                    'No hay información'}
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label="Comisiones Promotores"
                                            >
                                                {typeof movement.data.commissionPromotor === 'number' ?
                                                    new Intl.NumberFormat('es-AR', {
                                                        maximumFractionDigits: 2,
                                                        style: "currency",
                                                        currency: "ARS"
                                                    }).format(movement.data.commissionPromotor)
                                                    :
                                                    'No hay información'}
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label="Comisión Mercado Pago"
                                            >
                                                {typeof movement.data.commissionPlatformPay === 'number' ?
                                                    new Intl.NumberFormat('es-AR', {
                                                        maximumFractionDigits: 2,
                                                        style: "currency",
                                                        currency: "ARS"
                                                    }).format(movement.data.commissionPlatformPay)
                                                    :
                                                    'No hay información'}
                                            </Descriptions.Item>
                                        </Descriptions>

                                        <Divider />

                                        <Descriptions
                                            title="Datos de pago"
                                            column={1}
                                        >
                                            <Descriptions.Item
                                                label="Forma de pago"
                                            >
                                                {movement.data.bookingType ?
                                                    BookingTypes[movement.data.bookingType]
                                                    :
                                                    'No hay información'}
                                            </Descriptions.Item>
                                            {movement.data.bookingType === 1 &&
                                                <Descriptions.Item
                                                    label="Medio de pago"
                                                >
                                                    Mercado Pago
                                        </Descriptions.Item>}

                                            {movement.data.bookingType === 1 &&
                                                <Descriptions.Item
                                                    label="Comisión Proveedor"
                                                >
                                                    {typeof movement.data.commissionUserPay === 'number' ?
                                                        new Intl.NumberFormat('es-AR', {
                                                            maximumFractionDigits: 2,
                                                            style: "currency",
                                                            currency: "ARS"
                                                        }).format(movement.data.commissionUserPay)
                                                        :
                                                        'No hay información'}
                                                </Descriptions.Item>}

                                        </Descriptions>
                                    </>}
                            </>
                    }
                </div>
            </div>
        </>
    )
})

const mapStateToProps = (state) => ({
    user: state.context.user,
    data: state.userStats.data,
    movement: state.userStats.movement,
})

const mapDispatchToProps = (dispatch) => ({
    getUserWalletMovementById: (id) => dispatch(UserStatsActions.getUserWalletMovementById(id)),
    clearState: () => dispatch(UserStatsActions.clearState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserWalletInfoScreen);
