// @flow
import RestClient from "http/RestClient";
import axios from "axios";
const CancelToken = axios.CancelToken;
let cancel;

export default class ClientRestClient extends RestClient {
    constructor(useCredentials: boolean, refreshContext: ?boolean) {
        super(undefined, useCredentials, refreshContext);
    }

    getClient(id) {
        const url = `${this.baseURL}/secured/clientes/${id}`;
        return this.instance.get(url);
    }

    /*
     * Methods
     */

    getAccessToken(anonimousToken) {
        const url = `${this.baseURL}/public/identity/clientes/token`;
        return this.instance.get(url, {
            params: {
                anonimousToken,
            },
        });
    }

    createNewBooking(params) {
        const url = `${this.baseURL}/public/reservas`;

        return this.instance.post(url, {
            experienciaId: params.experienceId,
            experienciaFechaId: params.dateId,
            promotorCodigo: params.userPromotorNic,
            cantidad: params.peopleQuantity,
            tipoReserva: params.bookingType,
            medioPago: params?.paymentData
                ? {
                      cardToken: params.paymentData.paymentToken,
                      paymentMethodId: params.paymentData.paymentMethodId,
                      paymentIssuerId: params.paymentData.creditCardIssuerId,
                  }
                : null,
            clienteNombre: params?.clientInfo?.clientName,
            emailContacto: params?.clientInfo?.clientEmail,
            clienteTelefono: params?.clientInfo?.clientPhone,
            datosContacto: params?.clientInfo?.clientAdditionalComments,
            dondeRecoger: params?.clientInfo?.whereToPickup,
            dondeLlevar: params?.clientInfo?.whereToGo,
            nroVuelo: params?.clientInfo?.flightNumber,
        });
    }

    getBookingStatus(params) {
        const url = `${this.baseURL}/public/reservas/${params.bookingId}`;

        return this.instance.get(url);
    }

    getBookings(params, criteria) {
        if (typeof cancel === "function") cancel();
        const url = `${this.baseURL}/secured/reservas`;

        return this.instance.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
            params: {
                clienteId: criteria.clientId,
                experienciaNombre: criteria.name,
                page: params?.page,
                pageCount: params?.pageCount,
                sortField: params?.sortField,
                sortOrder: params?.sortOrder,
            },
        });
    }

    cancelBooking(params) {
        const url = `${this.baseURL}/secured/reservas/${params.bookingId}/cancelar`;

        return this.instance.put(url, {
            motivoAnulacion: params.cancelReason,
        });
    }

    /* -----------------------
	 Messages
	 --------------------- */

    sendNewMessage(params) {
        const url = `${this.baseURL}/secured/mensajes`;

        return this.instance.post(url, {
            experienciaId: params.experienceId,
            destinatarioId: params.addresseeId,
            destinatarioTipoUser: params.addresseeType,
            contenido: params.message,
        });
    }

    setConversationRead(params) {
        const url = `${this.baseURL}/secured/mensajes/${params.messageId}/leido`;

        return this.instance.patch(url, {});
    }

    getConversationsList(params) {
        const url = `${this.baseURL}/secured/mensajes/conversaciones`;

        return this.instance.get(url, {
            params: {
                page: params?.page,
            },
        });
    }
}
