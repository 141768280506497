import { ExperienceEventRestClient } from 'http/resources'
import ErrorManager from 'services/error-manager'

function getEvents(experienceId) {
    return dispatch => {
        dispatch({
            type: 'EXPERIENCE_EVENTS_FETCH_EVENTS_START'
        })
        new ExperienceEventRestClient().getExperienceEvents({
            experienceId
        })
            .then(res => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_FETCH_EVENTS_SUCCESS',
                    payload: {
                        experienceEvents: res.data.map((date => {
                            return {
                                id: date.id,
                                dayAndHour: date.fecha,
                                capacity: date.capacidad,
                                bookings: date.reservas
                            }
                        }))
                    }
                })
            })
            .catch(err => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_FETCH_EVENTS_FAILUR'
                })
                
                dispatch({
                    type: 'UI_ALERT_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(err)
                    }
                })
            })
    }
}

function createUniqueEvent(experienceId, dayAndHour, peopleQuantity) {
    return dispatch => {
        dispatch({
            type: 'EXPERIENCE_EVENTS_CREATE_UNIQUE_EVENT_START'
        })
        new ExperienceEventRestClient(true).createEvent({
            experienceId,
            dayAndHour,
            peopleQuantity
        })
            .then(res => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_CREATE_UNIQUE_EVENT_SUCCESS'
                })
                dispatch({
                    type: 'UI_ALERT_SET_SUCCESS',
                    payload: {
                        message: 'Evento único creado'
                    }
                })
            })
            .catch(err => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_CREATE_UNIQUE_EVENT_FAILURE'
                })
                dispatch({
                    type: 'UI_ALERT_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(err)
                    }
                })
            })
            .finally(() => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_CREATE_UNIQUE_EVENT_RESET'
                })
            }) 
    }
}

function createUniqueEventWithFlexibleHour(experienceId,params) {
    return dispatch => {
        dispatch({
            type: 'EXPERIENCE_EVENTS_CREATE_UNIQUE_EVENT_START'
        })

		const {
			dayAndHour,
			peopleQuantity,
			startTime,
			endTime
			} = params;

        new ExperienceEventRestClient(true).createEventWithFlexibleHours({
			experienceId,
			dayAndHour,
			peopleQuantity,
			startTime,
			endTime,
        })
            .then(res => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_CREATE_UNIQUE_EVENT_SUCCESS'
                })
                dispatch({
                    type: 'UI_ALERT_SET_SUCCESS',
                    payload: {
                        message: 'Evento único creado'
                    }
                })
            })
            .catch(err => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_CREATE_UNIQUE_EVENT_FAILURE'
                })
                dispatch({
                    type: 'UI_ALERT_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(err)
                    }
                })
            })
            .finally(() => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_CREATE_UNIQUE_EVENT_RESET'
                })
            }) 
    }
}

function createRecurrenceEvent(experienceId, form) {
    return dispatch => {
        dispatch({
            type: 'EXPERIENCE_EVENTS_CREATE_RECURRENT_EVENT_START'
        })

        const { startDate, finishDate, peopleQuantity, recurrenceDays } = form
        const verify = ["D", "L", "M", "X", "J", "V", "S"]

        const recurrenceDaysFormat = verify.map((vDay, index) => {
            let counter = null
            recurrenceDays.map(day => {
                if (day === vDay) counter = index
            })

            return counter
        }).filter(item => item !== null)

        new ExperienceEventRestClient(true).createEvents({
            experienceId,
            startDate,
            finishDate,
            peopleQuantity,
            recurrenceDays: recurrenceDaysFormat
        })
            .then(res => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_CREATE_RECURRENT_EVENT_SUCCESS'
                })
                dispatch({
                    type: 'UI_ALERT_SET_SUCCESS',
                    payload: {
                        message: 'Recurrencia creada'
                    }
                })
            })
            .catch(err => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_CREATE_RECURRENT_EVENT_FAILURE'
                })
                dispatch({
                    type: 'UI_ALERT_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(err)
                    }
                })
            })
            .finally(() => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_CREATE_RECURRENT_EVENT_RESET'
                })
            })
    }
}

function createRecurrenceEventFlexibleHours(experienceId, form) {
    return dispatch => {
        dispatch({
            type: 'EXPERIENCE_EVENTS_CREATE_RECURRENT_EVENT_START'
        })

        const { startDate, finishDate, peopleQuantity, recurrenceDays, startTime, endTime } = form
        const verify = ["D", "L", "M", "X", "J", "V", "S"]

        const recurrenceDaysFormat = verify.map((vDay, index) => {
            let counter = null
            recurrenceDays.map(day => {
                if (day === vDay) counter = index
            })

            return counter
        }).filter(item => item !== null)

        new ExperienceEventRestClient(true).createEventsFlexibleHours({
            experienceId,
            startTime,
			endTime,
			startDate,
			finishDate,
            peopleQuantity,
            recurrenceDays: recurrenceDaysFormat
        })
            .then(res => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_CREATE_RECURRENT_EVENT_SUCCESS'
                })
                dispatch({
                    type: 'UI_ALERT_SET_SUCCESS',
                    payload: {
                        message: 'Recurrencia creada'
                    }
                })
            })
            .catch(err => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_CREATE_RECURRENT_EVENT_FAILURE'
                })
                dispatch({
                    type: 'UI_ALERT_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(err)
                    }
                })
            })
            .finally(() => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_CREATE_RECURRENT_EVENT_RESET'
                })
            })
    }
}

function updateEvent(experienceId, dateId, editPeopleQuantity) {
    return dispatch => {
        dispatch({
            type: 'EXPERIENCE_EVENTS_UPDATE_UNIQUE_EVENT_START'
        })

        new ExperienceEventRestClient(true).updateEvent({
            experienceId,
            dateId,
            editPeopleQuantity
        })
            .then(res => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_UPDATE_UNIQUE_EVENT_SUCCESS'
                })
                dispatch({
                    type: 'UI_ALERT_SET_SUCCESS',
                    payload: {
                        message: 'Evento editado'
                    }
                })
            })
            .catch(err => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_UPDATE_UNIQUE_EVENT_FAILURE'
                })
                dispatch({
                    type: 'UI_ALERT_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(err)
                    }
                })
            })
            .finally(() => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_UPDATE_UNIQUE_EVENT_RESET'
                })
            })
    }
}

function removeUniqueEvent(experienceId, dateId) {
    return dispatch => {
        dispatch({
            type: 'EXPERIENCE_EVENTS_REMOVE_UNIQUE_EVENT_START'
        })
        new ExperienceEventRestClient(true).removeEvent({
            experienceId,
            dateId
        })
            .then(res => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_REMOVE_UNIQUE_EVENT_SUCCESS'
                })
                dispatch({
                    type: 'UI_ALERT_SET_SUCCESS',
                    payload: {
                        message: 'Evento eliminado'
                    }
                })
            })
            .catch(err => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_REMOVE_UNIQUE_EVENT_FAILURE'
                })
                dispatch({
                    type: 'UI_ALERT_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(err)
                    }
                })
            })
            .finally(() => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_REMOVE_UNIQUE_EVENT_RESET'
                })
            })
    }
}

function removeAllEvents(experienceId) {
    return dispatch => {
        dispatch({
            type: 'EXPERIENCE_EVENTS_REMOVE_ALL_EVENTS_START'
        })
        new ExperienceEventRestClient(true).removeAllEvents({
            experienceId
        })
            .then(res => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_REMOVE_ALL_EVENTS_SUCCESS'
                })
                dispatch({
                    type: 'UI_ALERT_SET_SUCCESS',
                    payload: {
                        message: 'Se eliminaron todos los eventos'
                    }
                })
            })
            .catch(err => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_REMOVE_ALL_EVENTS_FAILURE'
                })
                dispatch({
                    type: 'UI_ALERT_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(err)
                    }
                })
            })
            .finally(() => {
                dispatch({
                    type: 'EXPERIENCE_EVENTS_REMOVE_ALL_EVENTS_RESET'
                })
            })
    }
}

function reset() {
    return {
        type: 'EXPERIENCE_EVENTS_RESET',
    }
}

export const ExperienceEventsActions = {
    getEvents,
    updateEvent,
    createUniqueEvent,
	createUniqueEventWithFlexibleHour,
    createRecurrenceEvent,
	createRecurrenceEventFlexibleHours,
    removeUniqueEvent,
    removeAllEvents,
    reset,
}