const defaultState = {
    loading: false,
    experienceEvents: [],
    loadingCreateUniqueEvent: false,
    successCreateUniqueEvent: false,
    loadingCreateRecurrentEvent: false,
    successCreateRecurrentEvent: false,
    loadingUpdateUniqueEvent: false,
    successUpdateUniqueEvent: false,
    loadingRemoveUniqueEvent: false,
    successRemoveUniqueEvent: false,
    loadingRemoveAllEvents: false,
    successRemoveAllEvents: false,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {

        case 'EXPERIENCE_EVENTS_FETCH_EVENTS_START':
            return Object.assign({}, state, {
                loading: true,
                experienceEvents: [],
            });

        case 'EXPERIENCE_EVENTS_FETCH_EVENTS_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                experienceEvents: [...action.payload.experienceEvents],
            });

        case 'EXPERIENCE_EVENTS_FETCH_EVENTS_FAILURE':
            return Object.assign({}, state, {
                loading: false,
            });

        case 'EXPERIENCE_EVENTS_CREATE_UNIQUE_EVENT_START':
            return Object.assign({}, state, {
                loadingCreateUniqueEvent: true,
                successCreateUniqueEvent: false,
            });

        case 'EXPERIENCE_EVENTS_CREATE_UNIQUE_EVENT_SUCCESS':
            return Object.assign({}, state, {
                loadingCreateUniqueEvent: false,
                successCreateUniqueEvent: true,
            });

        case 'EXPERIENCE_EVENTS_CREATE_UNIQUE_EVENT_FAILURE':
            return Object.assign({}, state, {
                loadingCreateUniqueEvent: false,
                successCreateUniqueEvent: false,
            });
        
        case 'EXPERIENCE_EVENTS_CREATE_UNIQUE_EVENT_RESET':
            return Object.assign({}, state, {
                loadingCreateUniqueEvent: defaultState.loadingCreateUniqueEvent,
                successCreateUniqueEvent: defaultState.successCreateUniqueEvent,
            });

        case 'EXPERIENCE_EVENTS_UPDATE_UNIQUE_EVENT_START':
            return Object.assign({}, state, {
                loadingUpdateUniqueEvent: true,
                successUpdateUniqueEvent: false,
            });

        case 'EXPERIENCE_EVENTS_UPDATE_UNIQUE_EVENT_SUCCESS':
            return Object.assign({}, state, {
                loadingUpdateUniqueEvent: false,
                successUpdateUniqueEvent: true,
            });

        case 'EXPERIENCE_EVENTS_UPDATE_UNIQUE_EVENT_FAILURE':
            return Object.assign({}, state, {
                loadingUpdateUniqueEvent: false,
                successUpdateUniqueEvent: false,
            });

        case 'EXPERIENCE_EVENTS_UPDATE_UNIQUE_EVENT_RESET':
            return Object.assign({}, state, {
                loadingUpdateUniqueEvent: defaultState.loadingUpdateUniqueEvent,
                successUpdateUniqueEvent: defaultState.successUpdateUniqueEvent,
            });

        case 'EXPERIENCE_EVENTS_REMOVE_UNIQUE_EVENT_START':
            return Object.assign({}, state, {
                loadingRemoveUniqueEvent: true,
                successRemoveUniqueEvent: false
            });

        case 'EXPERIENCE_EVENTS_REMOVE_UNIQUE_EVENT_SUCCESS':
            return Object.assign({}, state, {
                loadingRemoveUniqueEvent: false,
                successRemoveUniqueEvent: true
            });

        case 'EXPERIENCE_EVENTS_REMOVE_UNIQUE_EVENT_FAILURE':
            return Object.assign({}, state, {
                loadingRemoveUniqueEvent: false,
                successRemoveUniqueEvent: false
            });

        case 'EXPERIENCE_EVENTS_REMOVE_UNIQUE_EVENT_RESET':
            return Object.assign({}, state, {
                loadingRemoveUniqueEvent: defaultState.loadingRemoveUniqueEvent,
                successRemoveUniqueEvent: defaultState.successRemoveUniqueEvent,
            });

        case 'EXPERIENCE_EVENTS_REMOVE_ALL_EVENTS_START':
            return Object.assign({}, state, {
                loadingRemoveAllEvents: true,
                successRemoveAllEvents: false
            });

        case 'EXPERIENCE_EVENTS_REMOVE_ALL_EVENTS_SUCCESS':
            return Object.assign({}, state, {
                loadingRemoveAllEvents: false,
                successRemoveAllEvents: true
            });

        case 'EXPERIENCE_EVENTS_REMOVE_ALL_EVENTS_FAILURE':
            return Object.assign({}, state, {
                loadingRemoveAllEvents: false,
                successRemoveAllEvents: false
            });

        case 'EXPERIENCE_EVENTS_REMOVE_ALL_EVENTS_RESET':
            return Object.assign({}, state, {
                loadingRemoveAllEvents: defaultState.loadingRemoveAllEvents,
                successRemoveAllEvents: defaultState.successRemoveAllEvents,
            });

        //create recurrence
        case 'EXPERIENCE_EVENTS_CREATE_RECURRENT_EVENT_START':
            return Object.assign({}, state, {
                loadingCreateRecurrentEvent: true,
                successCreateRecurrentEvent: false,
            });

        case 'EXPERIENCE_EVENTS_CREATE_RECURRENT_EVENT_SUCCESS':
            return Object.assign({}, state, {
                loadingCreateRecurrentEvent: false,
                successCreateRecurrentEvent: true,
            });

        case 'EXPERIENCE_EVENTS_CREATE_RECURRENT_EVENT_FAILURE':
            return Object.assign({}, state, {
                loadingCreateRecurrentEvent: false,
                successCreateRecurrentEvent: false,
            });

        case 'EXPERIENCE_EVENTS_CREATE_RECURRENT_EVENT_RESET':
            return Object.assign({}, state, {
                loadingCreateRecurrentEvent: defaultState.loadingCreateRecurrentEvent,
                successCreateRecurrentEvent: defaultState.successCreateRecurrentEvent,
            });

        case 'EXPERIENCE_EVENTS_RESET':
            return Object.assign({}, defaultState)

        default:
            return state;
    }
}
