import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ConversationResultPagination, PageHeader, RequiredSessionMessage, ScrollToTop } from 'components'
import { MessagesActions } from 'redux/actions'

const MessagesScreen = React.memo((props) => {
    const {
        user,
        getConversationsList,
        getMoreConversations,
        loading,
        conversationsList,
        clearState,
        page,
        loadingMoreResults,
        hasMore,
        getMessagesBadge,
    } = props

    // Fetch badge first render
    useEffect(() => {
        if (user?.role !== 'Anonimous') getMessagesBadge()
    }, [getMessagesBadge, user])

    useEffect(() => {
        if (user.role !== 'Anonimous') {
            getConversationsList(user.role)
        }

        return () => {
            clearState()
        }
    }, [user])

    return (
        <>
            <ScrollToTop />

            <div className="main">
                <PageHeader
                    title="Mensajes"
                    subtitle="Conversaciones realizadas"
                />

                <div className='messages-conversations-container'>
                    {user?.role !== 'Anonimous' ? (
                        <>
                            {(conversationsList.length === 0 && !loading) ? (
                                <div>
                                    <div
                                        style={{
                                            padding: 10,
                                            height: '70vh',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <h3>No hay mensajes iniciados</h3>
                                    </div>
                                </div>
                            ) : (
                                    <ConversationResultPagination
                                        user={user}
                                        loading={loading}
                                        loadingMoreResults={loadingMoreResults}
                                        hasMore={hasMore}
                                        page={page}
                                        results={conversationsList}
                                        getMoreResults={getMoreConversations}
                                    />
                                )}
                        </>
                    ) : (
                            <RequiredSessionMessage
                                description="Para ver tus mensajes tienes que iniciar sesión"
                            />
                        )}
                </div>
            </div>
        </>
    )
})

const mapStateToProps = (state) => ({
    user: state.context.user,
    loading: state.messages.loading,
    conversationsList: state.messages.conversationsList,
    page: state.messages.page,
    hasMore: state.messages.hasMore,
    loadingMoreResults: state.messages.loadingMoreResults,
})

const mapDispatchToProps = (dispatch) => ({
    getConversationsList: (typeUser) => dispatch(MessagesActions.getConversationsList(typeUser)),
    getMoreConversations: (typeUser, page) => dispatch(MessagesActions.getMoreConversations(typeUser, page)),
    clearState: () => dispatch(MessagesActions.clearState()),
    //messages badge
    getMessagesBadge: () => dispatch(MessagesActions.getMessagesBadge()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MessagesScreen);

