const defaultState = {
	loadingPostulation: false,
	postulationSuccess: false,
	userEmail: '',
	loadingList: {
		google: false,
		facebook: false,
		email: false
	},
	postulation: {
		loading: false,
		error: null,
		success: false,
		data: {
			id: null,
			name: null,
			surname: null,
			code: null,
			gatewayPayAuth: null,
			email: null,
			parent: {
				id: null,
				name: null
			},
			postulationType: null
		}
	}
};

export default function reducer(state = defaultState, action) {
	switch (action.type) {

		case 'REGISTER_USER_ALREADY_EXISTS':
			return Object.assign({}, state, {
				loadingList: {
					...defaultState.loadingList,
				},
			})

		case 'REGISTER_COMPLETE_REGISTER_SUCCESS':
			return Object.assign({}, state, {
				loadingList: {
					...defaultState.loadingList,
				},
				postulation: Object.assign({}, state.postulation, {
					success: true,
				})
			})

		case 'REGISTER_COMPLETE_REGISTER_FAILURE':
			return Object.assign({}, state, {
				loadingList: {
					...defaultState.loadingList,
				},
				userEmail: '',
				postulation: Object.assign({}, state.postulation, {
					success: false,
				})
			})

		case 'REGISTER_SET_LOADING':
			return Object.assign({}, state, {
				loadingList: {
					...state.loadingList,
					...action.payload.loadingList
				}
			})

		case 'REGISTER_FAILURE':
			return Object.assign({}, state, {
				loadingList: {
					...defaultState.loadingList,
				},
				userEmail: ''
			})

		case 'NEW_POSTULATION_LOADING':
			return Object.assign({}, state, {
				loadingPostulation: true,
				postulationSuccess: false
			})

		case 'NEW_POSTULATION_SUCCESS':
			return Object.assign({}, state, {
				loadingPostulation: false,
				postulationSuccess: true
			})

		case 'NEW_POSTULATION_FAILURE':
			return Object.assign({}, state, {
				loadingPostulation: false,
				postulationSuccess: false,
			})

		case 'POSTULATION_FETCH_POSTULATION_START':
			return Object.assign({}, state, {
				postulation: Object.assign({}, state.postulation, {
					loading: true,
					success: false,
				})
			})


		case 'POSTULATION_FETCH_POSTULATION_SUCCESS':
			return Object.assign({}, state, {
				postulation: Object.assign({}, state.postulation, {
					loading: false,
					data: {
						id: action.payload.id,
						name: action.payload.postulation.nombre,
						surname: action.payload.postulation.apellido,
						code: action.payload.postulation.codigo,
						email: action.payload.postulation.email,
						gatewayPayAuth: action.payload.postulation.autorizadoGatewayPagos,
						parent: {
							id: action.payload.postulation.parent.id,
							name: action.payload.postulation.parent.nombre
						},
						postulationType: action.payload.postulation.tipoPostulacion
					}
				})
			})

		case 'POSTULATION_FETCH_POSTULATION_FAILED':
			return Object.assign({}, state, {
				postulation: Object.assign({}, state.postulation, {
					loading: false,
					error: true,
				})
			})

		case 'POSTULATION_SET_CODE_INPUT':
			return Object.assign({}, state, {
				postulation: Object.assign({}, state.postulation, {
					loading: false,
					data: Object.assign({}, state.postulation.data, {
						code: action.payload.code,
					})
				})
			})

		case 'REGISTER_CLEAR_STATE':
			return Object.assign({}, state, {
				...defaultState
			})

		default:
			return state;
	}
}
