const defaultState = {
    loading: false,
    error: false,
	data: [],
};

export default function reducer(state = defaultState, action) {
	switch (action.type) {

		case 'CATEGORIES_FETCH_CATEGORIES_START':
			return Object.assign({}, state, {
                loading: true,
                error: false,
                data: [],
			})

		case 'CATEGORIES_FETCH_CATEGORIES_SUCCESS':
			return Object.assign({}, state, {
				loading: false,
				data: action.payload.categories
			})

		case 'CATEGORIES_FETCH_CATEGORIES_FAILURE':
			return Object.assign({}, state, {
				loading: false,
				error: true,
			})

		default:
			return state;
	}
}
