import { ExperienceRestClient } from 'http/resources'
import axios from 'axios'

function getExperiences(user, criteria, ignoreFingerprint) {
    return async (dispatch, getState) => {
        try {
            const {
                dataFingerprint
            } = getState().feed

            if (ignoreFingerprint || !dataFingerprint
                || dataFingerprint?.userId !== user?.sub
                || dataFingerprint?.name !== criteria?.name
                || dataFingerprint?.fromDate !== criteria?.fromDate
                || dataFingerprint?.toDate !== criteria?.toDate
                || dataFingerprint?.categories !== criteria?.categories?.sort().join('&')
                || dataFingerprint?.peopleQuantity !== criteria?.peopleQuantity
                || dataFingerprint?.languages !== criteria?.languages?.sort().join('&')
                || dataFingerprint?.duration !== criteria?.duration
                || dataFingerprint?.maxPrice !== criteria?.maxPrice
                || dataFingerprint?.tags !== criteria?.tags?.sort().join('&')
                || dataFingerprint?.localityId !== criteria?.localityId
            ) {
                dispatch({
                    type: "FEED_FETCH_EXPERIENCES_START",
                    payload: {
                        dataFingerprint: {
                            userId: user?.sub,
                            name: criteria?.name,
                            fromDate: criteria?.fromDate,
                            toDate: criteria?.toDate,
                            categories: criteria?.categories?.sort().join('&'),
                            peopleQuantity: criteria?.peopleQuantity,
                            languages: criteria?.languages?.sort().join('&'),
                            duration: criteria?.duration,
                            maxPrice: criteria?.maxPrice,
                            tags: criteria?.tags?.sort().join('&'),
                            localityId: criteria?.localityId,
                        }
                    }
                })

                const response = user?.role === 'Usuario' || user?.role === 'Cliente'
                    ? await new ExperienceRestClient(true).getSearchResult(null, criteria)
                    : await new ExperienceRestClient().getSearchResult(null, criteria)
                dispatch({
                    type: "FEED_FETCH_EXPERIENCES_SUCCESS",
                    payload: {
                        experiences: response.data.experiencias.items.map((item) => ({
                            id: item.id,
                            title: item.nombre,
                            details: item.detalles,
                            detailsEn: item.detallesEn,
                            price: item.precio,
                            rating: item.rating,
                            ranking: item.ranking,
                            isLiked: item.favorito,
                            isPublic: item.publicada,
                            images: item.mediosUrls,
                            externalURL: item.urlExterna,
                        })),
                        currentLocality: response.data.localidad,
                        currentTags: response.data.tags?.map(tag => ({
                            id: tag.id,
                            name: tag.nombre,
                        })) || [],
                        pageCount: response.data.experiencias.pageCount,
                        totalItems: response.data.experiencias.totalItems,
                        page: response.data.experiencias.page,
                        hasMore: !(response.data.experiencias.items.length < response.data.experiencias.pageCount)
                    }
                })
            }
        } catch (error) {
            if (!error instanceof axios.Cancel) {
                dispatch({
                    type: 'FEED_FETCH_EXPERIENCES_FAILURE'
                })
            }
        }
    }
}

function getMoreExperiences(user, page, criteria) {
    return async dispatch => {
        try {
            dispatch({
                type: "FEED_FETCH_MORE_EXPERIENCES_START",
            })


            const response = user?.role === 'Usuario' || user?.role === 'Cliente'
                ? await new ExperienceRestClient(true).getSearchResult({ page }, criteria)
                : await new ExperienceRestClient().getSearchResult({ page })

            dispatch({
                type: "FEED_FETCH_MORE_EXPERIENCES_SUCCESS",
                payload: {
                    experiences: response.data.experiencias.items.map((item) => ({
                        id: item.id,
                        title: item.nombre,
                        details: item.detalles,
                        detailsEn: item.detallesEn,
                        price: item.precio,
                        rating: item.rating,
                        ranking: item.ranking,
                        isLiked: item.favorito,
                        isPublic: item.publicada,
                        images: item.mediosUrls,
                        externalURL: item.urlExterna,
                    })),
                    pageCount: response.data.experiencias.pageCount,
                    totalItems: response.data.experiencias.totalItems,
                    page: response.data.experiencias.page,
                    hasMore: !(response.data.experiencias.items.length < 1
                        || response.data.experiencias.page === (page - 1))
                }
            })
        } catch (error) {
            dispatch({
                type: 'FEED_FETCH_MORE_EXPERIENCES_FAILURE'
            })
        }
    }
}

function reset() {
    return {
        type: 'FEED_RESET'
    }
}



export const FeedActions = {
    getExperiences,
    getMoreExperiences,
    reset,
}