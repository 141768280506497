import React, { useEffect, useCallback, useMemo, useRef } from 'react'
import { Switch, Route, useLocation, useHistory, Redirect } from "react-router-dom"
import { routes } from 'routes'
import { AnAlertBar, AnAlertBarManager } from 'components/an'
import { TopBar, BottomBar, LeftDrawer, MainMenu } from 'components'
import AuthSessionRoute from 'routes/_/AuthSessionRoute'
import AuthOnlyUserRoute from 'routes/_/AuthOnlyUserRoute'
import AuthRoute from 'routes/_/AuthRoute'
import { connect } from 'react-redux'
import { HistoryActions, UIActions } from 'redux/actions'
import { Alert } from 'antd'

const Router = React.memo((props) => {
    const {
        impersonated,
        message,
        type,
        user,
        initialHistoryIndex,
        setInitialHistoryIndex,
        clearMessage,
        badge,
    } = props

    const location = useLocation()
    const alertRef = useRef()

    // TODO
    // useEffect(() => {
    //     history.listen(() => {
    //         if (initialHistoryIndex === null) {
    //             setInitialHistoryIndex(history.length - 1)
    //         }
    //     })
    // }, [initialHistoryIndex, history.length, setInitialHistoryIndex])
  
     useEffect(() => {
        if (message.length > 0) {
            AnAlertBarManager.showAlertBar(alertRef.current, {
                message: message,
                type: type,
            })
            clearMessage()
        }

    }, [message, clearMessage, alertRef, type])

    const routesConfig = useMemo(() => routes, [routes])

    const getRoutes = useCallback((routes) => {
        return routes.map(route => {
            if (route.auth && !route.onlyUser) {
                return <AuthRoute
                    key={route.id}
                    path={route.path}
                    component={route.component}
                    exact={route.exact}
                />
            } else if (route.onlyUser) {
                return <AuthOnlyUserRoute
                    key={route.id}
                    path={route.path}
                    component={route.component}
                    exact={route.exact}
                />
            } else if (route.session) {
                return <AuthSessionRoute
                    key={route.id}
                    path={route.path}
                    component={route.component}
                    exact={route.exact}
                />
            } else {
                return <Route
                    key={route.id}
                    path={route.path}
                    component={route.component}
                    exact={route.exact}
                />
            }
        })
    }, routes)

    // TODO - Use memorization
    const getActualRoute = useMemo(() => routesConfig.find(route => {
        //TODO: fix bug with dynamic routes with id
        if (location.pathname.indexOf('/experience/') !== -1) {
            return route.id === 'experience'
        }
        if (location.pathname.indexOf('/user/experiences/form/') !== -1) {
            return route.id === 'user-experience-form'
        }

        if (location.pathname.indexOf('/booking/') !== -1) {
            return route.id === 'booking'
        }

        if (location.pathname.indexOf('/bookings/') !== -1) {
            return route.id === 'profile-booking-details'
        }

        if (location.pathname.indexOf('/login/') !== -1) {
            return route.id === 'login'
        }
        
        if (location.pathname.indexOf('/messages/') !== -1) {
            return route.id === 'message-id'
        }
        
        if (location.pathname.indexOf('/postulation/') !== -1) {
            return route.id === 'postulation'
        }
        
        if (location.pathname.indexOf('/user/wallet/details') !== -1) {
            return route.id === 'user-wallet-details'
        }

        return location.pathname === route.pathId
    }), [routesConfig, location])

    const routeId = getActualRoute?.id
    const className = getActualRoute?.className
    const showTopBar = getActualRoute?.topBar
    const hideTopBarLinkToLogin = getActualRoute?.hideTopBarLinkToLogin;
    const showBottomBar = getActualRoute?.bottomBar
    const showSidebar = getActualRoute?.sideBar
    const root = getActualRoute?.root

    return (
        <>
            {showTopBar && (
                <TopBar
                    isRoot={root}
                    hideTopBarLinkToLogin={hideTopBarLinkToLogin}
                />
            )}

            <div id={`screen-owrap ${routeId}`} className={`screen-owrap ${!showBottomBar ? 'no-bottom-offset' : ''} ${className}`}>
                {impersonated && (
                    <div className="impersonated-session-alert-container">
                        <Alert
                            message="Impersonando Usuario"
                            description={`Se ha iniciado una sesión impersonando al usuario ${user?.unique_name}`}
                            type="warning"
                            showIcon
                        />
                    </div>
                )}

                <div className="screen-iwrap">
                    <Switch>
                        {getRoutes(routes)}

                        <Redirect from="/*" to="/search" />
                    </Switch>

                    {showSidebar && (
                        <div className="sidebar">
                            <MainMenu />
                        </div>
                    )}
                </div>
            </div>

            <AnAlertBar
                ref={alertRef}
            />

            <LeftDrawer />

            {showBottomBar && (
                <BottomBar
                    badge={badge} />
            )}
        </>
    )
})

const mapStateToProps = (state) => ({
    user: state.context.user,
    initialHistoryIndex: state.history.initialHistoryIndex,
    message: state.ui.message,
    type: state.ui.type,

    badge: state.messages.badge,
})

const mapDispatchToProps = (dispatch) => ({
    clearMessage: () => dispatch(UIActions.clearMessage()),
    setInitialHistoryIndex: index => dispatch(HistoryActions.setInitialHistoryIndex(index))
});

export default connect(mapStateToProps, mapDispatchToProps)(Router)