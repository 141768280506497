const defaultState = {
	emailClient: '',
};

export default function reducer(state = defaultState, action) {
	switch (action.type) {
		case 'HANDLE_EMAIL_CLIENT_INPUT':
			return Object.assign({}, state, {
				emailClient: action.payload.emailClient,
			})
		default:
			return state;
	}
}
