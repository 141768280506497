import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ClientBookingsActions, MessagesActions } from 'redux/actions'
import { AnInput } from 'components/an'
import { PageHeader, EmptyList, ExperienceResultPagination, RequiredSessionMessage, NewMessageModal } from 'components'
import { Alert } from 'antd'
import { TypeOfUser } from 'constants/index'
import { withQueryParams, StringParam } from 'use-query-params';

const BookingsScreen = React.memo((props) => {
    const {
        context,
        user,
        loading,
        error,
        page,
        pageCount,
        loadingMoreResults,
        hasMore,
        bookings,
        getBookings,
        getMoreBookings,
        loadingMessage,
        messagesList,
        clearMessageState,

        sending,
        sendingSuccess,
        loadingMoreResultsMessages,
        pageMessages,
        hasMoreMessages,
        getMessages,
        getMoreMessages,
        sendMessage,
        query,
        setQuery,
    } = props

    const [selectedBookingDetails, setSelectedBookingDetails] = useState({})
    const [newMessageShow, setNewMessageShow] = useState(false)

    useEffect(() => {
        if (user?.role !== 'Anonimous') {
            getBookings(
                user,
                {
                    pageCount,
                    sortField: "experienciafecha",
                    sortOrder: "desc",
                },
                {
                    name: query.name
                })
        }
    }, [user, pageCount, query.name, getBookings])

    const onRequestGetMoreBookings = useCallback(() => {
        if (!loadingMoreResults && hasMore) {
            getMoreBookings(
                user,
                {
                    pageCount,
                    page: page + 1,
                    sortField: "experienciafecha",
                    sortOrder: "desc",
                },
                {
                    name: query.name
                })
        }
    }, [loadingMoreResults, hasMore, pageCount, page, query.name, getMoreBookings])

    const handleOnSearch = useCallback((e) => {
        if (e.target.value.length > 2) {
            setQuery({
                name: e.target.value,
            })
        } else {
            setQuery({
                name: undefined,
            })
        }
    }, [setQuery])

    const handleCloseErrorAlert = useCallback(() => {
        getBookings(
            user,
            {
                pageCount,
                sortField: "experienciafecha",
                sortOrder: "desc",
            },
            {
                name: query.name
            })
    }, [user, query.name, getBookings])

    const openModalMessage = useCallback((experience, provider) => {
        setSelectedBookingDetails(() => ({
            experience,
            addressee: {
                id: provider.id,
                name: provider.nombre,
                type: TypeOfUser['Usuario'],
            },
        }))
        setNewMessageShow(true)
    })

    const onSendMessage = useCallback((experienceId, addressee, message) => {
        sendMessage(experienceId, addressee, message)
    })

    return (
        <>
            <div className="main">
                <PageHeader
                    title="Mis reservas"
                    subtitle="Estado de mis reservas"
                />

                {user?.role !== 'Anonimous' ? (
                    <>
                        {error ? (
                            <div className="list-error-container margin-gap">
                                <Alert
                                    message="Ups!"
                                    description="Ha ocurrido un error al intentar obtener tus reservas"
                                    type="error"
                                    showIcon
                                    closeText="Reintentar"
                                    onClose={handleCloseErrorAlert}
                                />
                            </div>
                        ) : (
                                <>
                                    <div className="search-container sticky">
                                        <AnInput
                                            placeholder="Buscar en mis reservas"
                                            value={query.name}
                                            onChange={handleOnSearch}
                                            className="input primary"
                                        />
                                    </div>

                                    {bookings.length === 0 && !loading ? (
                                        <EmptyList
                                            description={
                                                query.name
                                                    ? "No se encontraron reservas para tu búsqueda"
                                                    : "Aún no tienes reservas"
                                            }
                                            image={
                                                query.name
                                                    ? <ion-icon name="search-outline" />
                                                    : <ion-icon name="checkmark-circle-outline" />
                                            }
                                        />
                                    ) : (
                                            <ExperienceResultPagination
                                                isBookingView
                                                user={user}
                                                loading={loading}
                                                loadingMoreResults={loadingMoreResults}
                                                hasMore={hasMore}
                                                page={page}
                                                results={bookings}
                                                getMoreResults={onRequestGetMoreBookings}
                                                shareExperienceUrl={`${window.location.protocol}//${window.location.hostname}${window.location.port !== 80 && (window.location.port.length > 0) ? `:${window.location.port}` : ``}/experience/:id/share${user?.role === 'Usuario' ? `?promoter-nic=${user?.nic}` : ``}`}
                                                infinityScrollBottomMessage="Ya viste todas tus reservas"
                                                openModalMessage={openModalMessage}
                                            />
                                        )}
                                </>
                            )}
                    </>
                ) : (
                        <RequiredSessionMessage
                            description="Para ver tus reservas tienes que iniciar sesión"
                        />
                    )}
            </div>



            {newMessageShow && <NewMessageModal
                context={context}
                user={user}
                title={selectedBookingDetails?.experience?.title}
                experienceId={selectedBookingDetails?.experience?.id}
                addressee={selectedBookingDetails?.addressee}
                sendMessage={onSendMessage}
                loadingMessage={loadingMessage}
                messagesList={messagesList}
                clear
                clearMessageState={clearMessageState}
                onClose={() => { setNewMessageShow(false) }}

                getMessages={getMessages}
                getMoreMessages={getMoreMessages}
                sending={sending}
                sendingSuccess={sendingSuccess}
                loadingMoreResults={loadingMoreResultsMessages}
                page={pageMessages}
                hasMore={hasMoreMessages}
            />}
        </>
    )
})

const mapStateToProps = (state) => ({
    context: state.context,
    user: state.context.user,
    page: state.clientBookings.page,
    pageCount: state.clientBookings.pageCount,
    loading: state.clientBookings.loading,
    error: state.clientBookings.error,
    bookings: state.clientBookings.data,
    hasMore: state.clientBookings.hasMore,
    loadingMoreResults: state.clientBookings.loadingMoreResults,

    loadingMessage: state.messages.loading,
    messagesList: state.messages.messagesList,

    sending: state.messages.sending,
    sendingSuccess: state.messages.sendingSuccess,
    loadingMoreResultsMessages: state.messages.loadingMoreResults,
    pageMessages: state.messages.page,
    hasMoreMessages: state.messages.hasMore,
})

const mapDispatchToProps = (dispatch) => ({
    getBookings: (user, params, criteria) => dispatch(ClientBookingsActions.getBookings(user, params, criteria)),
    getMoreBookings: (user, params, criteria) => dispatch(ClientBookingsActions.getMoreBookings(user, params, criteria)),
    sendMessage: (experienceId, addressee, message) => dispatch(MessagesActions.sendMessage(experienceId, addressee, message)),
    clearMessageState: () => dispatch(MessagesActions.clearState()),
    getMessages: (withLoading, experienceId, userProviderId, senderId, type) => dispatch(MessagesActions.getMessages(withLoading, experienceId, userProviderId, senderId, type)),
    getMoreMessages: (experienceId, userProviderId, senderId, type, page) => dispatch(MessagesActions.getMoreMessages(experienceId, userProviderId, senderId, type, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withQueryParams({
        name: StringParam,
    }, BookingsScreen)
);
