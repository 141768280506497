const defaultState = {
    loading: true,
    error: false,
    data: {
        id: '',
        title: '',
        provider: {
            id: '',
            name: ''
        },
        details: '',
        detailsEn: '',
        price: 0,
        bookingType: ['', '', ''],
        createdDate: '',
        direction: {
            street: '',
            number: '',
            depto: '',
            locate: {}
        },
        category: {
            id: '',
            name: ''
        },
        peopleQuantity: 0,
        duration: '',
        tolerance: '',
        tags: [],
        languages: [],
        media: [],
        timeZoneId: '',
        isPublic: false,
        rating: 0,
        mediaList: [],
        isLiked: false,
        externalURL: '',
    },
    actions: {
        like: {
            id: null,
            loading: false,
            error: false,
            success: false,
        },
        unlike: {
            id: null,
            loading: false,
            error: false,
            success: false,
        }
    }
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'EXPERIENCE_FETCH_EXPERIENCE_START':
            return Object.assign({}, state, {
                loading: true,
                error: false,
            })

        case 'EXPERIENCE_FETCH_EXPERIENCE_SUCCESS':
            return Object.assign({}, state, {
                data: {
                    ...state.data,
                    ...action.payload.experience
                },
                loading: false,
            })

        case 'EXPERIENCE_FETCH_EXPERIENCE_FAILURE':
            return Object.assign({}, state, {
                error: true,
            })
        
        case 'LIKED_EXPERIENCE_START':
            return Object.assign({}, state, {
                actions: Object.assign({}, state.actions, {
                    like: {
                        id: action.payload.id,
                        loading: true,
                        error: false,
                        success: false,
                    }
                })
            })

        case 'LIKED_EXPERIENCE_SUCCESS':
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, {
                    isLiked: action.payload.id === state.data.id ? true : state.data.isLiked
                }),
                actions: Object.assign({}, state.actions, {
                    like: {
                        id: null,
                        loading: false,
                        error: false,
                        success: true,
                    }
                })
            })

        case 'LIKED_EXPERIENCE_FAILURE':
            return Object.assign({}, state, {
                actions: Object.assign({}, state.actions, {
                    like: {
                        id: null,
                        loading: false,
                        error: true,
                        success: false,
                    }
                })
            })

        case 'LIKED_EXPERIENCE_RESET':
            return Object.assign({}, state, {
                actions: Object.assign({}, state.actions, {
                    like: defaultState.actions.like
                })
            })
        
        case 'UNLIKED_EXPERIENCE_START':
            return Object.assign({}, state, {
                actions: Object.assign({}, state.actions, {
                    unlike: {
                        id: action.payload.id,
                        loading: true,
                        error: false,
                        success: false,
                    }
                })
            })

        case 'UNLIKED_EXPERIENCE_SUCCESS':
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, {
                    isLiked: action.payload.id === state.data.id ? false : state.data.isLiked
                }),
                actions: Object.assign({}, state.actions, {
                    unlike: {
                        id: null,
                        loading: false,
                        error: false,
                        success: true,
                    }
                })
            })

        case 'UNLIKED_EXPERIENCE_FAILURE':
            return Object.assign({}, state, {
                actions: Object.assign({}, state.actions, {
                    unlike: {
                        id: null,
                        loading: false,
                        error: true,
                        success: false,
                    }
                })
            })

        case 'UNLIKED_EXPERIENCE_RESET':
            return Object.assign({}, state, {
                actions: Object.assign({}, state.actions, {
                    unlike: defaultState.actions.like
                })
            })

        case 'EXPERIENCE_RESET':
            return Object.assign({}, defaultState)

        default:
            return state;
    }
}
