import React, { useCallback, useEffect } from 'react'
import { Tooltip, Typography } from 'antd'
import ContentLoader from 'react-content-loader'
import { connect } from 'react-redux'
import './style.css'
import { UserStatsActions } from 'redux/actions'
import { Link } from 'react-router-dom'
import { PageHeader } from 'components'
import { AnDayPicker } from 'components/an'

const UserProveedorStats = React.memo((props) => {
    const {
        user,
        loading,
        proveedorStatsList,
        getProveedorStats,
        dateTo,
        setDateTo,
        dateFrom,
        setDateFrom,
    } = props

    useEffect(() => {
        if (user) {
            getProveedorStats(user.sub)
        }
    }, [])

    //handlers

    const handleDateFromSelect = useCallback((e) => {
        setDateFrom(new Date(e))
        getProveedorStats(user.sub)
    })

    const handleDateToSelect = useCallback((e) => {
        setDateTo(new Date(e))
        getProveedorStats(user.sub)
    })

    return (
        <div className="main">
            <PageHeader
                title="Ingresos como Proveedor"
                subtitle="Detalle de tu actividad como Proveedor"
            />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    margin: '10px 15px 0px'
                }}
            >
                <AnDayPicker
                    className='stats-day-picker'
                    title='Seleccionar fecha "Desde"'
                    value={dateFrom}
                    withIcon={false}
                    onChange={handleDateFromSelect}
                    dayPickerProps={{
                        disabledDays: [
                            {
                                after: new Date(dateTo)
                            }
                        ]
                    }}
                />
                    -
                    <AnDayPicker
                    className='stats-day-picker'
                    title='Seleccionar fecha "Hasta"'
                    value={dateTo}
                    withIcon={false}
                    onChange={handleDateToSelect}
                    dayPickerProps={{
                        disabledDays: [
                            {
                                before: new Date(dateFrom),
                                after: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
                            }
                        ]
                    }}
                />
            </div>

            <div className='stats-container'>
                {loading ? (
                    <ContentLoader
                        height={320}
                        style={{
                            width: '100%',
                        }}>
                        <rect x="15px" y="0" rx='8px' ry='8px' width="42.5%" height="100px" />
                        <rect x="52.5%" y="0" rx='8px' ry='8px' width="42.5%" height="100px" />
                        <rect x="15px" y="110px" rx='8px' ry='8px' width="42.5%" height="100px" />
                        <rect x="52.5%" y="110px" rx='8px' ry='8px' width="42.5%" height="100px" />
                    </ContentLoader>
                ) : (
                        <div
                            className='user-proveedor-report-info-container'>

                            <div className='user-promotor-report-info stat1'>
                                <Link to='/user/proveedor/views'>
                                    <h2>
                                        {typeof proveedorStatsList?.cantVistas === 'number' ?
                                            `${proveedorStatsList?.cantVistas}`
                                            :
                                            'No disponible'}
                                    </h2>
                                    <Typography.Text
                                        type='secondary'
                                        style={{
                                            color: '#fff'
                                        }}>
                                        Experiencias vistas
                                    </Typography.Text>

                                    <Tooltip title="Te mostramos la cantidad de Experiencias visitadas por potenciales clientes">
                                        <ion-icon
                                            style={{
                                                position: 'absolute',
                                                color: '#fff',
                                                top: 8,
                                                right: 8,
                                                fontSize: 20
                                            }}
                                            name="information-circle-outline"></ion-icon>
                                    </Tooltip>
                                </Link>
                            </div>

                            <div className='user-promotor-report-info stat2'>
                                <Link
                                    to='/user/proveedor/bookings'
                                >
                                    <h2
                                        style={{
                                            margin: 0,
                                            color: '#fff'

                                        }}>
                                        {typeof proveedorStatsList?.cantReservas === 'number' ?
                                            new Intl.NumberFormat('es-AR', {
                                                maximumFractionDigits: 1,
                                                notation: "compact",
                                                compactDisplay: "short"
                                            })
                                                .format(proveedorStatsList?.cantReservas)
                                            :
                                            'No disponible'}
                                    </h2>
                                    <Typography.Text
                                        type='secondary'
                                        style={{
                                            color: '#fff'
                                        }}>
                                        Reservadas
                                        </Typography.Text>
                                </Link>

                                <Tooltip title="Te mostramos la cantidad de Reservas efectivamente realizadas por los clientes">
                                    <ion-icon
                                        style={{
                                            position: 'absolute',
                                            color: '#fff',
                                            top: 8,
                                            right: 8,
                                            fontSize: 20
                                        }}
                                        name="information-circle-outline"></ion-icon>
                                </Tooltip>
                            </div>

                            <div className='user-promotor-report-info stat3'>
                                <Link to='/user/proveedor/incomes'>
                                    <h2>
                                        {typeof proveedorStatsList?.totalIngresos === 'number' ?
                                            `$${proveedorStatsList?.totalIngresos}`
                                            :
                                            'No disponible'}
                                    </h2>
                                    <Typography.Text
                                        type='secondary'
                                        style={{
                                            color: '#fff'
                                        }}>
                                        Ingresos
                                    </Typography.Text>

                                    <Tooltip title="Te mostramos los Ingresos generados por venta de tus experiencias">
                                        <ion-icon
                                            style={{
                                                position: 'absolute',
                                                color: '#fff',
                                                top: 8,
                                                right: 8,
                                                fontSize: 20
                                            }}
                                            name="information-circle-outline"></ion-icon>
                                    </Tooltip>
                                </Link>
                            </div>

                            <div className='user-promotor-report-info stat4'>
                                <Link to='/user/wallet' >
                                    <h2>
                                        {typeof proveedorStatsList?.totalBilletera === 'number' ?
                                            `$${proveedorStatsList?.totalBilletera}`
                                            :
                                            'No disponible'}
                                    </h2>
                                    <Typography.Text
                                        type='secondary'
                                        style={{
                                            color: '#fff'
                                        }}>
                                        Billetera
                                    </Typography.Text>
                                </Link>

                                <Tooltip title="Dinero disponible en tu billetera">
                                    <ion-icon
                                        style={{
                                            position: 'absolute',
                                            color: '#fff',
                                            top: 8,
                                            right: 8,
                                            fontSize: 20
                                        }}
                                        name="information-circle-outline"></ion-icon>
                                </Tooltip>
                            </div>
                        </div>
                    )}
            </div>
        </div >
    )
})

const mapStateToProps = (state) => ({
    user: state.context.user,
    proveedorStatsList: state.userStats.proveedorStatsList,
    loading: state.userStats.loading,
    dateFrom: state.userStats.dateFrom,
    dateTo: state.userStats.dateTo,

})

const mapDispatchToProps = (dispatch) => ({
    getProveedorStats: userId => dispatch(UserStatsActions.getProveedorStats(userId)),
    setDateFrom: dateFrom => dispatch(UserStatsActions.setDateFrom(dateFrom)),
    setDateTo: dateTo => dispatch(UserStatsActions.setDateTo(dateTo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProveedorStats);
