import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const AuthOnlyUserRoute = ({ component: Component, user, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                user?.role === 'Usuario' ?
                    <Component {...props} />
                    :
                    <Redirect to="/search" />

            }
        />
    )
}

export default connect(state => ({ user: state.context.user }))(AuthOnlyUserRoute);