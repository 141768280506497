import React, { useState, useEffect } from 'react'
import { Button, Typography } from 'antd'
import { ScrollToTop, Loader } from 'components'
import { AnInput } from 'components/an'
import { Link } from "react-router-dom"
import Animate from 'rc-animate'

const LoginClientStepOne = React.memo((props) => {
    const {
        isMobile,
        image,
        history,
        sendSignInLinkToEmail,
        emailClient,
        loadingEmailLink,
        signInFail,
        handleEmailClientInput,
        sendEmailSuccess
    } = props

    const [show, setShow] = useState(isMobile ? false : true)

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 500)
    }, [setShow])

    const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const handleInput = (e) => {
        handleEmailClientInput(e.target.value)
    }

    const loginWithLink = async (e) => {
        e.preventDefault()
        await sendSignInLinkToEmail(emailClient)

    }
    useEffect(() => {
        if (sendEmailSuccess && !signInFail) {
            handleEmailClientInput('')
            history.push(`/login/client/confirm`)
        }
    }, [sendEmailSuccess, signInFail])

    return (
        <>
            <ScrollToTop behavior="auto" />
            
            <Loader loading={!show} />
            
            <Animate
                transitionName="zoom"
                transitionAppear
            >
                {(show && (
                    <div className="login-screen-iwrap">
                        <div className='login-img'>
                            <img
                                src={image}
                                alt='Login Spiderweb'
                            />
                        </div>
                        
                        <div className="login-comp-container">
                            <h1
                                style={{
                                    margin: 0,
                                }}
                            >
                                Ingreso Fácil
                            </h1>
            
                            <Typography.Text
                                type='secondary'
                                style={{
                                    textAlign: 'center'
                                }}
                            >
                                Te enviaremos un link de ingreso al correo electrónico
                            </Typography.Text>
                            
                            <form
                                onSubmit={loginWithLink}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    marginBottom: 14
                                }}
                            >
                                <AnInput
                                    className="an-input round"
                                    placeholder='Email'
                                    size='large'
                                    onChange={handleInput}
                                    value={emailClient}
                                    style={{
                                        margin: 10
                                    }}
                                />
            
                                <Button
                                    htmlType='submit'
                                    type="primary"
                                    shape="round"
                                    size='large'
                                    disabled={!EMAIL_REGEX.test(emailClient)}
                                    loading={loadingEmailLink}
                                    style={{
                                        minWidth: '210px'
                                    }}
                                >
                                    Enviar código
                                </Button>
                            </form>
                            
                            <div
                                style={{
                                    textAlign: 'center',
                                    width: '100%'
                                }}
                            >
                                <span>ó</span>
                                
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'space-around',
                                        margin: '14px 0px',
                                    }}
                                >
                                    <Link
                                        to={`/login/user/signin`}
                                    >
                                        <Button
                                            block
                                            ghost
                                            type="primary"
                                            shape="round"
                                            size='large'
                                            style={{
                                                minWidth: '210px',
                                                margin: 5
                                            }}
                                        >
                                            Soy un promotor
                                        </Button>
                                    </Link>
                                    
                                    <Link
                                        to={`/login/user/signin`}
                                    >
                                        <Button
                                            block
                                            ghost
                                            type="primary"
                                            shape="round"
                                            size='large'
                                            style={{
                                                minWidth: '210px',
                                                margin: 5
                                            }}
                                        >
                                            Soy un proveedor
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Animate>
        </>
    )
})

export default LoginClientStepOne