import React from 'react'
import { Typography } from 'antd'

const BookingSummary = React.memo((props) => {
    const {
        title,
        day,
        hour,
        bookingType,
        capacity,
        price,
        bookingsQuantity,
        bookingPercentage,
        total,
    } = props
    
    return (
        <div className="summary-items-container">
            <div className="summary-item-container">
                <div className="summary-title-container">
                    <Typography.Text className="title">
                        Experiencia:
                    </Typography.Text>
                </div>

                <div className="summary-value-container">
                    <Typography.Text
                        type='secondary'
                        ellipsis
                    >
                        {title}
                    </Typography.Text>
                </div>
            </div>

            <div className="summary-item-container">
                <div className="summary-title-container">
                    <Typography.Text className="title">
                        Día:
                    </Typography.Text>
                </div>

                <div className="summary-value-container">
                    <Typography.Text
                        type='secondary'
                        ellipsis
                    >
                        {day}
                    </Typography.Text>
                </div>
            </div>

            <div className="summary-item-container">
                <div className="summary-title-container">
                    <Typography.Text className="title">
                        Hora:
                    </Typography.Text>
                </div>

                <div className="summary-value-container">
                    <Typography.Text
                        type='secondary'
                        ellipsis
                    >
                        {hour}
                    </Typography.Text>
                </div>
            </div>

            <div className="summary-item-container">
                <div className="summary-title-container">
                    <Typography.Text className="title">
                        Tipo de reserva:
                    </Typography.Text>
                </div>

                <div className="summary-value-container">
                    <Typography.Text
                        type='secondary'
                        ellipsis
                    >
                        {bookingType === 1
                            ? 'Pago completo'
                            : bookingType === 2
                                ? 'Pago parcial'
                                : 'Sin pago'
                        }
                    </Typography.Text>
                </div>
            </div>

            <div className="summary-item-container">
                <div className="summary-title-container">
                    <Typography.Text className="title">
                        Capacidad x reserva:
                    </Typography.Text>
                </div>

                <div className="summary-value-container">
                    <Typography.Text
                        type='secondary'
                        ellipsis
                    >
                        {capacity}
                    </Typography.Text>
                </div>
            </div>

            <div className="summary-item-container">
                <div className="summary-title-container">
                    <Typography.Text className="title">
                        Precio x reserva:
                    </Typography.Text>
                </div>

                <div className="summary-value-container">
                    <Typography.Text
                        type='secondary'
                        ellipsis
                    >
                        {price}
                    </Typography.Text>
                </div>
            </div>

            <div className="summary-item-container">
                <div className="summary-title-container">
                    <Typography.Text className="title">
                        Cantidad de reservas:
                    </Typography.Text>
                </div>

                <div className="summary-value-container">
                    <Typography.Text
                        type='secondary'
                        ellipsis
                    >
                        {bookingsQuantity}
                    </Typography.Text>
                </div>
            </div>

            <div className="summary-item-container">
                <div className="summary-title-container">
                    <Typography.Text className="title">
                        Pago en app:
                </Typography.Text>
                </div>

                <div className="summary-value-container">
                    <Typography.Text
                        type='secondary'
                        ellipsis
                    >
                        {bookingType === 1 && `$${total}`}
                        {bookingType === 2 && `$${(total * bookingPercentage).toFixed(2)}`}
                        {bookingType === 3 && `$${0}`}
                    </Typography.Text>
                </div>
            </div>

            <div className="summary-item-container">
                <div className="summary-title-container">
                    <Typography.Text className="title">
                        Pago en destino:
                </Typography.Text>
                </div>

                <div className="summary-value-container">
                    <Typography.Text
                        type='secondary'
                        ellipsis
                    >
                        {bookingType === 1 && `$${0}`}
                        {bookingType === 2 && `$${(total - (total * bookingPercentage)).toFixed(2)}`}
                        {bookingType === 3 && `$${total}`}
                    </Typography.Text>
                </div>
            </div>

            <div className="summary-item-container">
                <div className="summary-title-container">
                    <Typography.Text
                        strong
                        className="title"
                    >
                        Total:
                    </Typography.Text>
                </div>

                <div className="summary-value-container">
                    <Typography.Text
                        strong
                        type='secondary'
                        ellipsis
                    >
                        {`$${total}`}
                    </Typography.Text>
                </div>
            </div>
        </div>
    )
})

export default BookingSummary