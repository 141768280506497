import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useParams, useHistory } from "react-router-dom"
import { connect } from 'react-redux'
import { withQueryParams, NumberParam, withDefault } from 'use-query-params'
import { UserExperiencesActions } from 'redux/actions'
import { PageHeader } from 'components'

import ExperienceFormDetailsStep from './components/ExperienceFormStepA-Details'
import ExperienceFormMediaStep from './components/ExperienceFormStepB-Media'
import ExperienceFormAvailabilityStep from './components/ExperienceFormStepC-Availavility'


const UserExperienceFormScreen = React.memo((props) => {
    const {
        query,
        setQuery,
        experience,
        loading,
        getExperience,
        resetExperience,
        actions,
    } = props

    const history = useHistory()
    const experienceId = useParams().id

    //state to handle forms if user is create new experience
    const [isCreation, setisCreation] = useState(false)

    useEffect(() => {
        if (experienceId && experienceId !== 'new') {
            getExperience(experienceId)
        }
        return () => {
            resetExperience()
        }
    }, [resetExperience, query.step])

    useEffect(() => {
        if (experienceId && experienceId !== 'new') {
            setisCreation(false)
        } else {
            setisCreation(true)
        }
    }, [experienceId])


    useEffect(() => {
        if (actions.update.success) {
            nextStep()
        } else if (actions.create.id) {
            history.push(`/user/experiences/form/${actions.create.id}?step=2`)
        }
    }, [actions.update.success, actions.create.id])

    // Handle next step
    const nextStep = useCallback(() => {
        setQuery({ step: query.step + 1 })
    }, [query.step])

    // Handle previous step
    const prevStep = useCallback(() => {
        history.goBack()
    })

    const saving = useMemo(() => (
        actions.update.loading || actions.create.loading
    ), [actions.update.loading, actions.create.loading])

    return (
        <>
            <div className="main">
                <PageHeader
                    title={isCreation ? 'Crear experiencia' : experience.title || 'Cargando ...'}
                    subtitle={ query.step === 1
                        ? 'Datos generales'
                        : query.step === 2
                            ? 'Imágenes'
                            : 'Calendario de eventos'
                    }
                />
                
                {query.step === 1 && (
                    <ExperienceFormDetailsStep
                        loading={loading}
                        saving={saving}
                        isCreation={isCreation}
                        experience={experience}
                        nextStep={nextStep}
                    />
                )}

                {query.step === 2 && (
                    <ExperienceFormMediaStep
                        loading={loading}
                        experience={experience}
                        prevStep={prevStep}
                        nextStep={nextStep}
                    />
                )}
                
                {query.step === 3 && (
                    <ExperienceFormAvailabilityStep
                        loading={loading}
                        experience={experience}
                        prevStep={prevStep}
                    />
                    
                )}
            </div>
        </>
    )
})


const mapStateToProps = state => ({
    loading: state.userExperiences.loading,
    experience: state.userExperiences.experience.data,
    actions: state.userExperiences.experience.actions,
})

const mapDispatchToProps = dispatch => ({
    getExperience: (experienceId) => dispatch(UserExperiencesActions.getExperience(experienceId)),
    resetExperience: () => dispatch(UserExperiencesActions.resetExperience()),
})

export default connect(mapStateToProps, mapDispatchToProps)(
    withQueryParams({
        step: withDefault(NumberParam, ""), // The current step
    }, UserExperienceFormScreen)
)