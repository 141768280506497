const defaultState = {
    documentTypes: {
        loading: false,
        error: false,
        data: []
    },
    cardValidations: {
        loading: false,
        error: false,
        data: []
    },
    cardNumber: {
        value: '',
        formatted: '',
		type: '',
		validLuhn: false,
		validCardNumber: false,
    },
    cardName: '',
    cardDocumentType: 'DNI',
    cardDocumentNumber: '',
    cardExpiration: {
        value: '',
        validCardExpiration: false,
    },
    cardSecurityCode: {
        value: '',
        validCardSecurityCode: false,
    },
    creditCardIssuerId: {
        loading: false,
        value: null,
    },
    paymentToken: {
        loading: false,
        value: '',
    }
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'PAYMENT_FETCH_DOCUMENT_TYPES_START':
            return Object.assign({}, state, {
                documentTypes: Object.assign({}, state.documentTypes, {
                    loading: true,
                    error: false,
                    data: [],
                })
            });

        case 'PAYMENT_FETCH_DOCUMENT_TYPES_SUCCESS':
            return Object.assign({}, state, {
                documentTypes: Object.assign({}, state.documentTypes, {
                    loading: false,
                    data: action.payload.documentTypes,
                })
            });

        case 'PAYMENT_FETCH_DOCUMENT_TYPES_FAILURE':
            return Object.assign({}, state, {
                documentTypes: Object.assign({}, state.documentTypes, {
                    loading: false,
                    error: true,
                })
            });

        case 'PAYMENT_FETCH_CARD_VALIDATIONS_START':
            return Object.assign({}, state, {
                cardValidations: Object.assign({}, state.cardValidations, {
                    loading: true,
                    error: false,
                    data: [],
                })
            });

        case 'PAYMENT_FETCH_CARD_VALIDATIONS_SUCCESS':
            return Object.assign({}, state, {
                cardValidations: Object.assign({}, state.cardValidations, {
                    loading: false,
                    data: action.payload.cardValidations,
                })
            });

        case 'PAYMENT_FETCH_CARD_VALIDATIONS_FAILURE':
            return Object.assign({}, state, {
                cardValidations: Object.assign({}, state.cardValidations, {
                    loading: false,
                    error: true,
                })
            });

        case 'PAYMENT_CARD_NUMBER_SET':
            return Object.assign({}, state, {
                cardNumber: {
                    value: action.payload.value,
			        formatted: action.payload.formatted,
                    type: action.payload.type,
			        validLuhn: action.payload.validLuhn,
			        validCardNumber: action.payload.validCardNumber,
                }
            });
        
        case 'PAYMENT_CARD_NAME_SET':
            return Object.assign({}, state, {
                cardName: action.payload.cardName
            });

        case 'PAYMENT_CARD_DOCUMENT_TYPE_SET':
            return Object.assign({}, state, {
                cardDocumentType: action.payload.cardDocumentType
            });

        case 'PAYMENT_CARD_DOCUMENT_NUMBER_SET':
            return Object.assign({}, state, {
                cardDocumentNumber: action.payload.cardDocumentNumber
            });

        case 'PAYMENT_CARD_EXPIRATION_SET':
            return Object.assign({}, state, {
                cardExpiration: {
                    value: action.payload.value,
                    validCardExpiration: action.payload.validCardExpiration,
                }
            });

        case 'PAYMENT_CARD_SECURITY_CODE_SET':
            return Object.assign({}, state, {
                cardSecurityCode: {
                    value: action.payload.value,
                    validCardSecurityCode: action.payload.validCardSecurityCode,
                }
            });

        case 'PAYMENT_FETCH_CREDIT_CARD_ISSUER_ID_START':
            return Object.assign({}, state, {
                creditCardIssuerId: {
                    loading: true,
                    value: null,
                }
            }); 
            
        case 'PAYMENT_FETCH_CREDIT_CARD_ISSUER_ID_SUCCESS':
            return Object.assign({}, state, {
                creditCardIssuerId: {
                    loading: false,
                    value: action.payload.creditCardIssuerId,
                }
            }); 

        case 'PAYMENT_FETCH_CREDIT_CARD_ISSUER_ID_FAILURE':
            return Object.assign({}, state, {
                creditCardIssuerId: {
                    loading: false,
                    value: null,
                }
            }); 

        case 'PAYMENT_FETCH_PAYMENT_TOKEN_START':
            return Object.assign({}, state, {
                paymentToken: {
                    loading: true,
                    value: '',
                }
            });

        case 'PAYMENT_FETCH_PAYMENT_TOKEN_SUCCESS':
            return Object.assign({}, state, {
                paymentToken: {
                    loading: false,
                    value: action.payload.paymentToken,
                }
            });

        case 'PAYMENT_FETCH_PAYMENT_TOKEN_FAILURE':
            return Object.assign({}, state, {
                paymentToken: {
                    loading: false,
                    value: ''
                }
            });
        
        case 'PAYMENT_RESET':
            return Object.assign({}, defaultState)

        default:
            return state;
    }
}
