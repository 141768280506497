const defaultState = {
	loading: false,
	error: false,
	data: [],
};

export default function reducer(state = defaultState, action) {
	switch (action.type) {

		case 'TAGS_FETCH_TAGS_START':
			return Object.assign({}, state, {
				loading: true,
				error: false,
				data: [],
			})

		case 'TAGS_FETCH_TAGS_SUCCESS':
			return Object.assign({}, state, {
				loading: false,
				data: action.payload.tags
			})

		case 'TAGS_FETCH_TAGS_FAILURE':
			return Object.assign({}, state, {
				loading: false,
				error: true,
			})

		default:
			return state;
	}
}
