import RestClient from 'http/RestClient'
import axios from 'axios'

export default class FavoriteRestClient extends RestClient {
	constructor(useCredentials, refreshContext) {
		super(
			undefined,
			useCredentials,
			refreshContext,
		)
	}

    getFavoritesList(params) {
		const url = `${this.baseURL}/${this.secured ? 'secured' : 'public'}/experiencias/favoritos`

		return this.instance.get(url, {
			params: {
				page: params?.page
			}
		})
	}
    
    likeExperience(experienceId) {
        const url = `${this.baseURL}/${this.secured ? 'secured' : 'public'}/experiencias/${experienceId}/favoritos`

		return this.instance.post(url, {
            experienciaId: experienceId,
		})
    }

    unlikeExperience(experienceId) {
        const url = `${this.baseURL}/${this.secured ? 'secured' : 'public'}/experiencias/${experienceId}/favoritos`

		return this.instance.delete(url)
    }
}
