import UserRestClient from 'http/resources/UserRestClient'

function getGatewayAuthorizationURL(id) {
    return async dispatch => {
        try {
            dispatch({
                type: 'USER_GATEWAY_FETCH_AUTHORIZATION_URL_START'
            })

            const response = await new UserRestClient(true).getGatewayAuthorizationURL(id)

            dispatch({
                type: 'USER_GATEWAY_FETCH_AUTHORIZATION_URL_SUCCESS',
                payload: {
                    url: response.data.value.url
                }
            })
        } catch (error) {
            dispatch({
                type: 'USER_GATEWAY_FETCH_AUTHORIZATION_URL_FAILURE',
            })
        } finally {
            dispatch({
                type: 'USER_GATEWAY_FETCH_AUTHORIZATION_URL_RESET'
            })
        }
    }
}

function linkGateway(id, code, redirectURI) {
    return async dispatch => {
        try {
            dispatch({
                type: 'USER_GATEWAY_LINK_GATEWAY_START'
            })

            const response = await new UserRestClient(true).linkGateway(id, code, redirectURI)

            dispatch({
                type: 'USER_GATEWAY_LINK_GATEWAY_SUCCESS',
            })
        } catch (error) {
            dispatch({
                type: 'USER_GATEWAY_LINK_GATEWAY_FAILURE',
                payload: {
                    error: error.response.data.errors[0].message
                }
            })
        } finally {
            dispatch({
                type: 'USER_GATEWAY_LINK_GATEWAY_RESET',
            })
        }
    }
}

function unlinkGateway(id) {
    return async dispatch => {
        try {
            dispatch({
                type: 'USER_GATEWAY_UNLINK_GATEWAY_START',
            })
            
            const response = await new UserRestClient(true).unlinkGateway(id)

            dispatch({
                type: 'USER_GATEWAY_UNLINK_GATEWAY_SUCCESS',
            })
        } catch (error) {
            dispatch({
                type: 'USER_GATEWAY_UNLINK_GATEWAY_FAILURE',
            })
        } finally {
            dispatch({
                type: 'USER_GATEWAY_UNLINK_GATEWAY_RESET',
            })
        }
    }
}

export const UserGatewayActions = {
    getGatewayAuthorizationURL,
    linkGateway,
    unlinkGateway,
}