import { useState, useEffect } from 'react'

export const useImageError = () => {
	const [el, setEl] = useState(null) // contains image reference
	const [error, setError] = useState(false) // contains error flag

	const _handleError = () => { setError(true) }  // set error

	useEffect(() => {
		// use of error event of the image tag
		if (el) {
			el.addEventListener("error", _handleError)
		}

		return () => {
			if (el) {
				el.removeEventListener("error", _handleError)
			}
		}
	}, [el])

	return [
		setEl, // set the image ref
		error, // error flag
		el // img ref(for special case which requires ref)
	];
};