// @flow
import RestClient from 'http/RestClient';
import axios from 'axios'
import moment from 'moment'

const CancelToken = axios.CancelToken
let cancel

export default class UserRestClient extends RestClient {
    constructor(useCredentials: boolean, refreshContext: ?boolean) {
        super(
            undefined,
            useCredentials,
            refreshContext,
        );
    }

    getAccessToken() {
        const url = `${this.baseURL}/public/identity/usuarios/token`
        return this.instance.get(url)
    }


    verifyUserEmail(params) {
        const url = `${this.baseURL}/public/identity/usuarios`


        return this.instance.get(url, {
            params: {
                email: params.email,
            },
        });
    }

    sendNewPostulation(params) {
        const url = `${this.baseURL}/public/postulaciones`

        return this.instance.post(url, {
            email: params.email,
            nombre: params.name,
            apellido: params.surname,
            telefono: params.phone,
            parentCodigo: params.parentCode,
            tipo: params.type,
            informacionAdicional: params.moreInfo
        });
    }

    getPostulationById(id) {
        const url = `${this.baseURL}/public/postulaciones/${id}/usuario`

        return this.instance.get(url, {});
    }

    patchPostulationById(id, data) {
        const url = `${this.baseURL}/public/postulaciones/${id}`

        return this.instance.patch(url, data)
    }

    registerNewUser(code) {
        const url = `${this.baseURL}/public/identity/usuarios`


        return this.instance.post(url, {
            codigo: code
        });
    }

    /* -----------------------
        Experiences
        --------------------- */

    getBookings(params, criteria) {
        if (typeof cancel === 'function') cancel()
        const url = `${this.baseURL}/secured/reservas`

        return this.instance.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
            params: {
                page: params?.page,
                ExperienciaFechaDesde: params?.experienceDateFrom ? moment(params.experienceDateFrom).format('yyyy-MM-DD 03:00:00') : null,
                ExperienciaFechaHasta: params?.experienceDateTo ? moment(params.experienceDateTo).add(1, 'day').format('yyyy-MM-DD 03:00:00') : null,
                sortField: params?.sortField,
                sortOrder: params?.sortOrder,
                FechaDesde: params?.dateFrom ? moment(params.dateFrom).format('yyyy-MM-DD 03:00:00') : null,
                FechaHasta: params?.dateTo ? moment(params.dateTo).add(1, 'day').format('yyyy-MM-DD 03:00:00') : null,
                UsuarioPromotorId: criteria?.userPromotorId,
                nombre: criteria?.name,
                tipo: criteria?.bookingType,
            }
        });
    }

    getUserInfo(params) {
        const url = `${this.baseURL}/secured/usuarios/${params.userId}`

        return this.instance.get(url);
    }

    sendInvitation(params) {
        const url = `${this.baseURL}/secured/usuarios/invitar`

        return this.instance.post(url, {
            email: params.email,
            invitacionUrl: params.url,
        });
    }

    /* -----------------------
    Messages
      --------------------- */
    getConversationsList(params) {
        const url = `${this.baseURL}/secured/mensajes/conversaciones`

        return this.instance.get(url, {
            params: {
                page: params?.page,
            }
        })
    }

    getMessages(params) {
        const url = `${this.baseURL}/secured/mensajes`

        return this.instance.get(url, {
            params: {
                ExperienciaId: params.experienceId,
                UsuarioProveedorId: params.userProviderId,
                ClienteId: params.clientId,
                UsuarioId: params.userId,
                page: params.page,
            }
        });
    }

    sendNewMessage(params) {
        const url = `${this.baseURL}/secured/mensajes`

        return this.instance.post(url, {
            experienciaId: params.experienceId,
            destinatarioId: params.addresseeId,
            destinatarioTipoUser: params.addresseeType,
            contenido: params.message
        });
    }

    setConversationRead(params) {
        const url = `${this.baseURL}/secured/mensajes/${params.messageId}/leido`

        return this.instance.patch(url, {})
    }


    /* -----------------------
      Gateway
      --------------------- */

    getGatewayAuthorizationURL(id) {
        const url = `${this.baseURL}/secured/usuarios/${id}/autorizacionurl`

        return this.instance.get(url);
    }

    linkGateway(id, code, redirectURI) {
        const url = `${this.baseURL}/secured/usuarios/${id}/autorizacion`

        return this.instance.post(url, {
            authorizationCode: code,
            urlRedirect: redirectURI
        });
    }

    unlinkGateway(id) {
        const url = `${this.baseURL}/secured/usuarios/${id}`

        return this.instance.patch(url, {
            autorizadoGatewayPagos: false
        });

    }

    /* -----------------------
      Stats
      --------------------- */


    getProveedorStats(params) {
        const url = `${this.baseURL}/secured/usuarios/${params.userId}/panelproveedor`
        return this.instance.get(url, {
            params: {
                FechaDesde: params?.dateFrom ? moment(params.dateFrom).format('yyyy-MM-DD 03:00:00') : null,
                FechaHasta: params?.dateTo ? moment(params.dateTo).add(1, 'day').format('yyyy-MM-DD 03:00:00') : null,
            }
        });
    }

    getPromotorBookings(params) {
        const url = `${this.baseURL}/secured/reservas/promotor`
        return this.instance.get(url, {
            params: {
                FechaDesde: params?.dateFrom ? moment(params.dateFrom).format('yyyy-MM-DD 03:00:00') : null,
                FechaHasta: params?.dateTo ? moment(params.dateTo).add(1, 'day').format('yyyy-MM-DD 03:00:00') : null,
                MiRed: params?.red,
                page: params?.page,
                sortField: params?.sortField,
                sortOrder: params?.sortOrder,
            }
        });
    }

    getPromotorStats(params) {
        const url = `${this.baseURL}/secured/usuarios/${params.userId}/panelpromotor`
        return this.instance.get(url, {
            params: {
                FechaDesde: params?.dateFrom ? moment(params.dateFrom).format('yyyy-MM-DD 03:00:00') : null,
                FechaHasta: params?.dateTo ? moment(params.dateTo).add(1, 'day').format('yyyy-MM-DD 03:00:00') : null,
            }
        });
    }

    getShareList(params) {
        const url = `${this.baseURL}/secured/vistas/promotor`
        return this.instance.get(url, {
            params: {
                FechaDesde: params?.dateFrom ? moment(params.dateFrom).format('yyyy-MM-DD 03:00:00') : null,
                FechaHasta: params?.dateTo ? moment(params.dateTo).add(1, 'day').format('yyyy-MM-DD 03:00:00') : null,
                MiRed: params?.red,
                page: params.page,
            }
        });
    }

    getCommissionsList(params) {
        const url = `${this.baseURL}/secured/comisiones`
        return this.instance.get(url, {
            params: {
                FechaDesde: params?.dateFrom ? moment(params.dateFrom).format('yyyy-MM-DD 03:00:00') : null,
                FechaHasta: params?.dateTo ? moment(params.dateTo).add(1, 'day').format('yyyy-MM-DD 03:00:00') : null,
                MiRed: params?.red,
                page: params?.page
            }
        });
    }

    getViewsStats(params) {
        const url = `${this.baseURL}/secured/vistas/proveedor`
        return this.instance.get(url, {
            params: {
                FechaDesde: params?.dateFrom ? moment(params.dateFrom).format('yyyy-MM-DD 03:00:00') : null,
                FechaHasta: params?.dateTo ? moment(params.dateTo).add(1, 'day').format('yyyy-MM-DD 03:00:00') : null,
                page: params?.page
            }
        });
    }

    getUserWalletInfoList(params) {
        const url = `${this.baseURL}/secured/movimientos`

        return this.instance.get(url, {
            params: {
                FechaDesde: params?.dateFrom ? moment(params.dateFrom).format('yyyy-MM-DD 03:00:00') : null,
                FechaHasta: params?.dateTo ? moment(params.dateTo).add(1, 'day').format('yyyy-MM-DD 03:00:00') : null,
                page: params?.page,
            }
        });
    }

    getUserMovementById(params) {
        const url = `${this.baseURL}/secured/movimientos/${params.id}`

        return this.instance.get(url, {});
    }


}
