import React, { useCallback } from 'react'
import { Select, Spin, Typography, Tag, Space } from 'antd'

const AnSelectArray = React.memo((props) => {
    const {
        id,
        className,
        wrapperClassName,
        value,
        onChange,
        fetchOptions,
        options,
        fetching,
        placeholder,
        onBlur,
    } = props

    const onCloseTag = useCallback((removedTagId) => {
        onChange((value || []).filter(tag => tag.id !== removedTagId))
    }, [onChange, value])

    const addNewTag = useCallback((e) => {
        const alreadySelected = value && value.find(option => option.id === e.key);

        if (!alreadySelected) {
            onChange([].concat(value || []).concat([{ id: e.key, name: e.label }]))
        }
    }, [onChange, value])


    const handleOnSearch = criteria => {
        if (criteria.length > 2) {
            fetchOptions(criteria)
        } else if (criteria.length < 1) {
            fetchOptions()
        }
    }

    const handleOnFocus = () => {
        fetchOptions()
    }

    return (
        <div
            id={id}
            className={wrapperClassName}
        >
            <Select
                className={className}
                showSearch
                placeholder={placeholder}
                optionFilterProp="children"
                onSearch={handleOnSearch}
                onSelect={addNewTag}
                onFocus={handleOnFocus}
                onBlur={onBlur}
                labelInValue
                value={[]}
                notFoundContent={
                    fetching ? (
                        <Spin size="small" />
                    ) : (
                            <Typography.Text type='secondary'>No hay resultados</Typography.Text>
                        )
                }
            >

                {!fetching && value && value[0] && value[0].id && options.length < 1 && (
                    <Select.Option value={value[0].id}>{`${value[0].name}${value[0].inactive ? ' ( Inactivo )' : ''}`}</Select.Option>
                )}

                {options.map(result => (
                    <Select.Option key={result.id} value={result.id}>{`${result.name}${result?.inactive ? ' ( Inactiva )' : ''}`}</Select.Option>
                ))}
            </Select>

            {((value || []).length > 0 && (
                <div
                    style={{
                        marginTop: 10,
                    }}
                >
                    {value.map((tag) => {
                        return (
                            <Tag
                                key={tag.id}
                                closable
                                onClose={() => onCloseTag(tag.id)}
                            >
                                <Space>
                                    <Typography>
                                        {tag.name}
                                    </Typography>

                                    {(tag.inactive && (
                                        <Typography.Text
                                            type="danger"
                                        >
                                            ( Inactivo )
                                        </Typography.Text>
                                    ))}
                                </Space>
                            </Tag>
                        )
                    })}
                </div>
            ))}
        </div>
    )
})

export default AnSelectArray
