import React, { useEffect, useState } from 'react'
import ContentLoader from "react-content-loader"
import { Tag, Typography, Spin, Button } from 'antd'
import { AnButtonGradient } from 'components/an'
import { Link } from 'react-router-dom'
import { RatingIcon } from 'components/icons'
import { ExperienceImageCarousel, ExperienceShare } from 'components'
import moment from 'moment'
import { BookingStatus } from 'constants/index'
import AspectRatio from 'react-aspect-ratio'
import { LoadingOutlined } from '@ant-design/icons'
import Icon from '@ant-design/icons'
import { getWithExpiry } from 'services/local-storage'

// TODO
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const HeartSvg = () => <ion-icon name="heart-outline"></ion-icon>
const HeartSvgFilled = () => <ion-icon name="heart"></ion-icon>
const HeartIcon = props => (<Icon component={props.showFilled ? HeartSvgFilled : HeartSvg} {...props} />)

const ExperienceListItem = React.forwardRef((props, ref) => {
    const {
        context,
        user,
        loading,
        experience,
        likeExperience,
        unlikeExperience,
        shareExperienceUrl,
        isUserView = false,
        isBookingView = false,
        isExperienceView = false,
        likeItemId,
        openModalMessage,
    } = props

    const [isLoading, setIsLoading] = useState(true)
    const [showShareOptions, setshowShareOptions] = useState(false)

    function onClickLikeExperience() {
        if (!likeItemId) {
            if (!experience?.isLiked) {
                likeExperience(user?.role, experience?.id)
            } else {
                unlikeExperience(user?.role, experience?.id)
            }
        }
    }

    useEffect(() => {
        setIsLoading(loading)
    }, [loading])
    return (
        <div className="experience-list-item" ref={ref}>
            {isLoading ? (
                <AspectRatio ratio="3/2">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flex: 1,
                            background: '#f0f0f0'
                        }}
                    >
                        <Spin indicator={antIcon} />
                    </div>
                </AspectRatio>
            ) : (
                    <div className="experience-image-carousel-container">
                        <ExperienceImageCarousel
                            mediaList={experience?.images || experience?.mediaList || []}
                            linkTo={{
                                pathname: experience?.externalURL
                                    ? `${experience.externalURL}?aid=8345&cmp=${getWithExpiry('promoter-nic') || 'SPW'}`
                                    : `/experience/${experience?.id}`,
                            }}
                            linkTarget={experience?.externalURL
                                ? "_blank"
                                : "_self"
                            }
                        />

                        {!(isUserView || isBookingView || showShareOptions) && (
                            <div
                                className="experience-favorite-icon-container"
                                onClick={onClickLikeExperience}
                            >
                                {likeItemId === experience?.id ? (
                                    <Spin indicator={antIcon} style={{ color: '#C3125A' }} />
                                ) : (
                                        <HeartIcon
                                            showFilled={experience?.isLiked}
                                            style={{
                                                fontSize: 21,
                                                color: experience?.isLiked ? '#C3125A' : '#C3125A',
                                            }}
                                        />
                                    )}
                            </div>
                        )}

                        <div className="experience-share-container">
                            <ExperienceShare
                                shareUrl={(shareExperienceUrl || '').replace(':id', experience?.id)}
                                showShareOptions={showShareOptions}
                                setshowShareOptions={setshowShareOptions}
                            />
                        </div>
                    </div>
                )}

            {!isExperienceView && (
                <>
                    <div
                        style={{
                            padding: '12px 10px 0px'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'baseline',
                                justifyContent: 'space-between',
                            }}
                        >
                            {isLoading ? (
                                <ContentLoader
                                    height={30}
                                    style={{
                                        width: '100%'
                                    }}>
                                    <rect x="0" y="0" width="100%" height="20px" />
                                </ContentLoader>
                            ) : (
                                    <>
                                        <Typography.Title
                                            level={4}
                                            strong
                                            style={{
                                                fontFamily: 'Righteous'
                                            }}
                                            ellipsis={{ rows: 1, expandable: false, symbol: '..' }}
                                        >
                                            {experience?.title || 'No disponible'}
                                        </Typography.Title>

                                        <Typography.Title
                                            level={4}
                                            strong
                                            style={{
                                                fontFamily: 'Righteous',
                                                marginTop: 0,
                                                marginLeft: 10
                                            }}
                                        >
                                            ${experience?.price || 0}
                                        </Typography.Title>
                                    </>
                                )}
                        </div>

                        {isLoading ? (
                            <ContentLoader
                                height={45}
                                style={{
                                    width: '100%'
                                }}>
                                <rect x="0" y="4px" width="100%" height="14px" />
                                <rect x="0" y="22px" width="100%" height="14px" />
                            </ContentLoader>
                        ) : (
                                isBookingView ? (
                                    <>
                                        <Typography.Paragraph
                                            ellipsis={{ rows: 2, expandable: false, symbol: '..' }}
                                            style={{
                                                fontFamily: 'Roboto',
                                                fontWeight: '300'
                                            }}>
                                            {`Reserva para el día ${moment.utc(experience?.date).format('DD/MM/yyyy')} a las ${moment.utc(experience?.date).format('HH:mm')}`}
                                        </Typography.Paragraph>
                                        {experience?.clientContact && <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            <ion-icon
                                                name="document-text-outline"
                                                style={{
                                                    color: '#C3125A',
                                                    fontSize: 14,
                                                    paddingTop: 2,
                                                    paddingRight: 4,
                                                    alignSelf: 'flex-start'
                                                }}
                                            />
                                            <Typography.Paragraph
                                                type='secondary'
                                                style={{
                                                    width: '90%',
                                                    margin: 0
                                                }}
                                                ellipsis={{ rows: 1, expandable: true, symbol: 'ver más' }}
                                            >
                                                {experience?.clientContact}
                                            </Typography.Paragraph>
                                        </div>}
                                    </>
                                ) : (
                                        <Typography.Paragraph
                                            ellipsis={{ rows: 2, expandable: false, symbol: '..' }}
                                            style={{
                                                fontFamily: 'Roboto',
                                                fontWeight: '300'
                                            }}>
                                            {experience?.details || 'No hay descripción disponible'}
                                        </Typography.Paragraph>
                                    )
                            )}
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'baseline',
                            justifyContent: 'space-between',
                            padding: '0px 10px 12px'
                        }}
                    >
                        {isLoading ? (
                            <ContentLoader
                                height={45}
                                style={{
                                    width: '100%'
                                }}>
                                <rect x="0" y="16px" width="40px" height="14px" />
                                {!isBookingView &&
                                    <rect x="50%" y="16px" width="45px" height="14px" />
                                }
                                <rect x="65%" y="4px" rx='20px' ry='20px' width="35%" height="40px" />
                            </ContentLoader>
                        ) : (
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {isBookingView && typeof experience?.bookingStatus === 'number' ? (
                                            experience?.bookingStatus > 0 ? (
                                                <Tag
                                                    color={BookingStatus[experience?.bookingStatus]?.color}
                                                    style={{
                                                        borderRadius: 12
                                                    }}
                                                >
                                                    {BookingStatus[experience?.bookingStatus]?.label}
                                                </Tag>
                                            ) : null
                                        ) : (
                                                <>
                                                    <Typography.Text
                                                        style={{
                                                            fontFamily: 'Roboto',
                                                            fontSize: 16,
                                                            marginRight: 5,
                                                        }}
                                                    >
                                                        {experience?.rating}
                                                    </Typography.Text>

                                                    <RatingIcon />
                                                </>
                                            )}
                                    </div>

                                    {isUserView ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <ion-icon
                                                name="earth-outline"
                                                style={{
                                                    marginRight: 5,
                                                    marginBottom: 2,
                                                    color: experience?.isPublic ? '#333' : '#AAA',
                                                    fontSize: 16,
                                                }}
                                            />
                                            <div>
                                                <Typography.Text
                                                    style={{
                                                        fontSize: 14,
                                                        marginRight: 10,
                                                        color: experience?.isPublic ? '#333' : '#AAA',
                                                    }}
                                                >{experience?.isPublic ? 'Publicado' : 'No publicado'}</Typography.Text>
                                            </div>

                                            {context?.impersonated && (
                                                <Link
                                                    to={`/user/experiences/form/${experience?.id}?step=1`}>
                                                    <AnButtonGradient
                                                        type='primary'
                                                        shape='round'
                                                        size='large'
                                                        style={{
                                                            width: 130
                                                        }}
                                                    >
                                                        Editar
                                                </AnButtonGradient>
                                                </Link>
                                            )}
                                        </div>
                                    ) : isBookingView ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}>
                                            { typeof openModalMessage === 'function' &&
                                                < Button
                                                    size='large'
                                                    type="ghost"
                                                    shape="circle"
                                                    icon={<ion-icon name="chatbubbles-outline" />}
                                                    style={{
                                                        margin: 15,
                                                    }}
                                                    onClick={() => openModalMessage(experience, experience?.provider)}
                                                />
                                            }
                                            <Link
                                                to={`/bookings/${experience?.bookingId}`}
                                            >
                                                <AnButtonGradient
                                                    type='primary'
                                                    shape='round'
                                                    size='large'
                                                >
                                                    Ver reserva
                                            </AnButtonGradient>
                                            </Link>
                                        </div>
                                    ) : (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}>
                                                    <Link
                                                        to={{
                                                            pathname: experience?.externalURL
                                                                ? `${experience.externalURL}?aid=8345&cmp=${getWithExpiry('promoter-nic') || 'SPW'}`
                                                                : `/experience/${experience?.id}`,
                                                        }}
                                                        target={experience?.externalURL
                                                            ? "_blank"
                                                            : "_self"
                                                        }
                                                    >
                                                        {user?.role !== 'Usuario' ? (
                                                            <Typography.Text
                                                                style={{
                                                                    fontSize: 14,
                                                                    marginRight: 10,
                                                                    color: '#707070'
                                                                }}
                                                            >
                                                                Ver más
                                                            </Typography.Text>
                                                        ) : (
                                                                <AnButtonGradient
                                                                    type='primary'
                                                                    shape='round'
                                                                    size='large'
                                                                >
                                                                    Ver más
                                                                </AnButtonGradient>
                                                            )}
                                                    </Link>

                                                    {user?.role !== 'Usuario' && (
                                                        <Link
                                                            to={{
                                                                pathname: experience?.externalURL
                                                                    ? experience.externalURL
                                                                    : `/booking/${experience?.id}`,
                                                                search: experience?.externalURL
                                                                    ? `?aid=8345&cmp=${getWithExpiry('promoter-nic') || 'SPW'}`
                                                                    : '?step=1'
                                                            }}
                                                            target={experience?.externalURL
                                                                ? "_blank"
                                                                : "_self"
                                                            }
                                                        >
                                                            <AnButtonGradient
                                                                type='primary'
                                                                shape='round'
                                                                size='large'
                                                            >
                                                                Reservar
                                                    </AnButtonGradient>
                                                        </Link>
                                                    )}
                                                </div>
                                            )}
                                </>
                            )}
                    </div>
                </>
            )}
        </div>
    )
})

export default (ExperienceListItem);