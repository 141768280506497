import React from 'react'
import { withQueryParams, StringParam, withDefault } from 'use-query-params'
import LoginResetPassword from './LoginResetPassword'
import LoginClientStepThree from './LoginClientStepThree'
import { Alert } from 'antd'
import { Link } from 'react-router-dom'
import { AnButtonGradient } from 'components/an'

const LoginAction = React.memo((props) => {
	const {
		query,
		isMobile,
		image,
		history,
		confirmPasswordReset,
		resetPassword,
		loadingList,
		signInWithEmailLink,
		signInMailSuccess,
		signInFail,
		loadingEmailLink,
	} = props

	const { oobCode } = query

	switch (query.mode) {
		case 'resetPassword':
			return (
				<LoginResetPassword
					isMobile={isMobile}
					image={image}
					loadingList={loadingList}
					history={history}
					resetPassword={resetPassword}
					confirmPasswordReset={confirmPasswordReset}
					oobCode={oobCode}
				/>)
		case 'signIn':
			return (
				<LoginClientStepThree
					image={image}
					history={history}
					signInWithEmailLink={signInWithEmailLink}
					signInMailSuccess={signInMailSuccess}
					signInFail={signInFail}
					loadingEmailLink={loadingEmailLink}
				/>)
		default:
			return (
				<Alert
					message="Hubo un problema"
					description="Ha ocurrido un error intentando realizar esta acción"
					type="error"
					showIcon
					action={
						< Link
							to='/search'
						>
							<AnButtonGradient
								type="primary"
								shape='round'
								size='small'
							>
								Volver al inicio
						</AnButtonGradient>
						</Link >
					}
					style={{
						marginTop: 15,
						borderRadius: 10
					}}
				/>
			)
	}
})

export default withQueryParams({
	mode: withDefault(StringParam, ""), // Action's mode (Firebase)
	oobCode: withDefault(StringParam, ""), // ooB Code from Firebase
}, LoginAction)