import React, { useState, useRef} from 'react'
import { Carousel } from 'antd'
import noImg from 'assets/images/no-image-placeholder.png'
import errorImg from 'assets/images/error-image-placeholder.png'
import { useImageError } from 'hooks/useImageError'
import AspectRatio from 'react-aspect-ratio';
import { Link } from 'react-router-dom'
import { Loader } from 'components'

// TODO
function Image({ src }) {
    const [imgLoading, setImgLoading] = useState(true)
    const [setImg, hasError] = useImageError()
    const style = {
        width: '100%',
    }

	const handleOnLoadImage = (e) => {
		setImgLoading(false);
	}

    if (hasError) {
        return <img
            src={errorImg}
            alt=''
            style={style} />
    }
    return (
		<>
			<Loader loading={imgLoading}></Loader>
			<img
				alt='Experience Image Carousel - Spiderweb'
				ref={setImg}
				src={src}
				style={style} 
				onLoad={handleOnLoadImage}/>
		</>
    );
}

const ExperienceImageCarousel = React.memo((props) => {
    const {
        mediaList = [],
        linkTo,
        linkTarget,
    } = props

    const ref = useRef()
    const [currentImageIndex, setCurrentImageIndex] = useState(1)

    function onChange(from, to) {
        setCurrentImageIndex(to + 1)
    }

    const handlePrevStep = () => {
        ref.current.prev()
    }
    const handleNextStep = () => {
        ref.current.next()
    }
    return (
        <div className="experience-image-carousel">
            {mediaList.length > 0 && (
                <div className="counter-container">
                    <span>{`${currentImageIndex}/${mediaList.length}`}</span>
                </div>
            )}

            <Carousel
                ref={ref}
                dots={false}
                beforeChange={onChange}
                easing='ease-in-out'
				lazyLoad='progressive'
            >
                {mediaList.length > 0 ? (
                    mediaList.map((mediaUrl, index) => (
                        <AspectRatio
                            ratio="3/2"
                            key={index.toString()}
                        >
                            <Link
                                to={linkTo}
                                target={linkTarget}
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Image src={mediaUrl} />
                            </Link>
                        </AspectRatio>
                    ))
                ) : (
                        <AspectRatio ratio="3/2">
                            <Link
                                to={linkTo}
                                target={linkTarget}
                            >
                                <img
                                    src={noImg}
                                    alt=''
                                    style={{
                                        width: '100%'
                                    }}
                                />
                            </Link>
                        </AspectRatio>
                    )}
            </Carousel>

            {mediaList.length > 1 && (
                <>
                    <div className="stepper-container">
                        <button
                            className='step-btn'
                            onClick={handlePrevStep}
                        >
                            <ion-icon
                                name="chevron-back-outline"
                            />
                        </button>
                    </div>

                    <div className="stepper-container right">
                        <button
                            className='step-btn'
                            onClick={handleNextStep}
                        >
                            <ion-icon
                                name="chevron-forward-outline"
                            />
                        </button>
                    </div>
                </>)
            }
        </div >
    )
})

export default ExperienceImageCarousel;