const defaultState = {
    loading: true, // starts as true and goes to false when the context initialize
    impersonated: null, // null = not impersonated / true = impersonated / false = logout from imperonation
    user: null, // the parsed token object or null if no session
};

export default function reducer(state = defaultState, action) {
	switch (action.type) {
		case 'CONTEXT_SET':
			return Object.assign({}, state, {
                loading: false,
                impersonated: action.payload.impersonated,
                user: action.payload.user,
            })
		default:
			return state;
	}
}
