import React, { useEffect, useState, useCallback } from 'react'
import { ScrollToTop, Loader } from 'components'
import Animate from 'rc-animate'
import { AnButtonGradient, AnInput } from 'components/an'
import { Alert, Button, Typography } from 'antd'
import { Link } from 'react-router-dom'

const LoginClientStepThree = React.memo((props) => {
    const {
        image,
        signInWithEmailLink,
        signInFail,
        history,
        signInMailSuccess,
        loadingEmailLink
    } = props

    const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isBookingAnonimous = localStorage.getItem('isBookingAnonimous') || false
    const experienceId = localStorage.getItem('experienceId') || ''
    const email = localStorage.getItem('emailForSignIn')
    const [hasEmailSaved, setHasEmailSaved] = useState(true)
    const [mailConfirm, setMailConfirm] = useState(null)

    useEffect(() => {
        if (email) {
            signInWithEmailLink(email, window.location.href)
        } else {
            setHasEmailSaved(false)
        }
    }, [signInWithEmailLink, email])

    useEffect(() => {
        if (isBookingAnonimous &&
            experienceId &&
            signInMailSuccess) {
            history.push(`/booking/${experienceId}`)
        }
    }, [isBookingAnonimous, signInMailSuccess, experienceId, history]);

    const finishLogin = useCallback((e) => {
        e.preventDefault()
        signInWithEmailLink(mailConfirm, window.location.href)
    }, [mailConfirm, signInWithEmailLink])

    return (
        <>
            <ScrollToTop behavior="auto" />

            <Animate
                transitionName="zoom"
                transitionAppear
            >
                <div className="login-screen-iwrap">
                    <div className='login-img'>
                        <img
                            src={image}
                            alt='Login Spiderweb'
                        />
                    </div>

                    <div className="login-comp-container">
                        <h1
                            style={{
                                margin: 0
                            }}
                        >
                            Ingreso Fácil
                        </h1>

                        <Typography.Text
                            type='secondary'
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            Finaliza el ingreso con el link que enviamos a tu correo electrónico
                        </Typography.Text>

                        {signInFail ?
                            <Alert
                                message="Hubo un problema"
                                description="No pudimos autenticarte, intenta ingresar nuevamente"
                                type="error"
                                showIcon
                                action={
                                    <Link
                                        to='/login'
                                    >
                                        <AnButtonGradient
                                            type="primary"
                                            shape='round'
                                            size='small'
                                        >
                                            Reintentar
                                        </AnButtonGradient>
                                    </Link>
                                }
                                style={{
                                    marginTop: 15,
                                    borderRadius: 10
                                }}
                            />
                            :
                            hasEmailSaved ?
                                <Loader
                                    loading="true"
                                    title="Ingresando ..."
                                />
                                :
                                <form
                                    onSubmit={finishLogin}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                        marginBottom: 14
                                    }}
                                >
                                    <AnInput
                                        className="an-input round"
                                        placeholder='Confirma tu email'
                                        size='large'
                                        onChange={(e) => { setMailConfirm(e.target.value) }}
                                        value={mailConfirm}
                                        style={{
                                            margin: 10
                                        }}
                                    />

                                    <Button
                                        htmlType='submit'
                                        type="primary"
                                        shape="round"
                                        size='large'
                                        disabled={!EMAIL_REGEX.test(mailConfirm)}
                                        loading={loadingEmailLink}
                                        style={{
                                            minWidth: '210px'
                                        }}
                                    >
                                        Ingresar
                                </Button>
                                </form>}
                    </div>
                </div>
            </Animate>
        </>
    )
})

export default LoginClientStepThree