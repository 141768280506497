import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"

const ScrollToTop = React.memo((props) => {
    const {
        behavior = 'smooth',
    } = props

    const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo({
            top: 0,
            left: 0,
            behavior,
        });
	}, [pathname])

	return null
})

export default ScrollToTop