const defaultState = {
    initialHistoryIndex: null,
};

export default function reducer(state = defaultState, action) {
	switch (action.type) {
		case 'INITIAL_HISTORY_INDEX_SET':
			return Object.assign({}, state, {
                initialHistoryIndex: state.initialHistoryIndex === null ? action.payload.index : state.initialHistoryIndex
            })
		default:
			return state;
	}
}
