import React, { useState, useEffect, useCallback, useMemo } from "react";
import { AnButtonGradient, AnButton, AnCalendar } from "components/an";
import { Typography, Spin, Divider, Button } from "antd";
import { Link, useParams, useLocation } from "react-router-dom";
import { ExperienceActions, ExperienceEventsActions, FavoritesActions, MessagesActions } from "redux/actions";
import { connect } from "react-redux";
import { RatingIcon } from "components/icons";
import { useQuery } from "hooks/useQuery";
import { ScrollToTop, PageHeader, Loader, ExperienceListItem, NewMessageModal } from "components";
import moment from "moment";
import { TypeOfUser } from "constants/index";
import { setWithExpiry, getWithExpiry } from "services/local-storage";

const ExperienceScreen = React.memo((props) => {
    const {
        context,
        user,
        getExperience,
        getExperienceEvents,
        experience,
        loading,
        likeExperience,
        unlikeExperience,
        loadingMessage,
        messagesList,
        clearExperienceState,
        clearMessageState,
        actions,

        experienceEvents,
        loadingExperienceEvents,

        markExperienceAsViewed,

        sending,
        sendingSuccess,
        loadingMoreResultsMessages,
        pageMessages,
        hasMoreMessages,
        getMessages,
        getMoreMessages,
        sendMessage,
    } = props;

    const { id } = useParams();

    const [selectedDay, setSelectedDay] = useState("");
    const [selectedDayEvents, setSelectedDayEvents] = useState([]);
    const [newMessageShow, setNewMessageShow] = useState(false);

    const query = useQuery(useLocation().search);

    // If 'promoter-nic' is in the query parameters, save it to LocalStorage
    if (query.get("promoter-nic")) {
        setWithExpiry("promoter-nic", query.get("promoter-nic"), 86400000 * 30);
    }

    useEffect(() => {
        if (user) {
            getExperience(user?.role, id);
            getExperienceEvents(id);
        }

        return () => {
            clearExperienceState();
        };
    }, [id, user, getExperience]);

    useEffect(() => {
        if (!loading && experience.id.length > 0 && experience.provider.id !== user?.sub) {
            if (query.get("promoter-nic")) {
                // Mark the experience as view if the client uses a shared link
                //console.log('%c [VIEW SHARED] ', 'background: green; color: #fff');
                markExperienceAsViewed(user?.role, id, query.get("promoter-nic"));
            } else {
                //console.log('%c [VIEW] ', 'background: green; color: #fff');
                markExperienceAsViewed(user?.role, id);
            }
        }
    }, [id, user, experience, loading]);

    // We map date as picker needs to
    // display modifiers in the calendar
    const selectedDays = useMemo(() => {
        return experienceEvents.map((date) => {
            return new Date(moment.utc(date.dayAndHour).format("YYYY/M/DD"));
        });
    }, [experienceEvents]);

    const selectedDayAsISOString = useMemo(() => {
        return experienceEvents.map((date) => {
            if (date.bookings !== date.capacity) {
                return moment.utc(date.dayAndHour).format("YYYY/M/DD");
            }
        });
    }, [experienceEvents]);

    const onRequestLikeExperience = useCallback((typeUser, experienceId) => {
        likeExperience(typeUser, experienceId);
    });

    const onRequestUnlikeExperience = useCallback((typeUser, experienceId) => {
        unlikeExperience(typeUser, experienceId);
    });

    const handleDayClick = (day, modifiers = {}) => {
        if (modifiers.disabled) {
            return;
        }

        if (day !== selectedDay) {
            var element = document.querySelector("#sss");
            element.scrollIntoView({ behavior: "smooth", block: "start" });
            setSelectedDay(day);
            setSelectedDayEvents(() => {
                return experienceEvents
                    .sort((a, b) => {
                        return new Date(a.dayAndHour) - new Date(b.dayAndHour);
                    })
                    .filter((date) => {
                        return moment.utc(date.dayAndHour).format("DD/M/YYYY") === moment.utc(day).format("DD/M/YYYY");
                    });
            });
        } else {
            setSelectedDay("");
            setSelectedDayEvents([]);
        }
    };

    const onSendMessage = useCallback((experienceId, addressee, message) => {
        sendMessage(experienceId, addressee, message);
    });

    return (
        <>
            <ScrollToTop />

            <div className="main">
                <Loader loading={loading} />

                {!loading && (
                    <>
                        <PageHeader title={experience.title} subtitle={experience.provider.name} />

                        <ExperienceListItem
                            user={user}
                            isExperienceView={true}
                            experience={experience}
                            likeItemId={actions.like.id || actions.unlike.id}
                            likeExperience={onRequestLikeExperience}
                            unlikeExperience={onRequestUnlikeExperience}
                            shareExperienceUrl={`${window.location.protocol}//${window.location.hostname}${
                                window.location.port !== 80 && window.location.port.length > 0
                                    ? `:${window.location.port}`
                                    : ``
                            }/experience/:id/share${user?.role === "Usuario" ? `?promoter-nic=${user?.nic}` : ``}`}
                        />

                        <div className="experience-information-container">
                            <div className="experience-price">
                                <Typography.Title
                                    level={1}
                                    strong
                                    style={{
                                        fontFamily: "Righteous",
                                        margin: 0,
                                    }}
                                >
                                    {`$${experience.price}`}
                                </Typography.Title>

                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography.Text
                                        style={{
                                            padding: 5,
                                            fontFamily: "Roboto",
                                            fontSize: 18,
                                        }}
                                    >
                                        {experience.rating}
                                    </Typography.Text>

                                    <RatingIcon />
                                </div>
                            </div>

                            {user?.role !== "Usuario" && (
                                <Link
                                    to={{
                                        pathname: experience?.externalURL ? experience.externalURL : `/booking/${id}`,
                                        search: experience?.externalURL
                                            ? `?aid=8345&cmp=${getWithExpiry("promoter-nic") || "SPW"}`
                                            : "?step=1",
                                    }}
                                    target={experience?.externalURL ? "_blank" : "_self"}
                                >
                                    <AnButtonGradient
                                        size="large"
                                        shape="round"
                                        type="primary"
                                        style={{
                                            width: "60%",
                                            left: "20%",
                                        }}
                                    >
                                        Reservar
                                    </AnButtonGradient>
                                </Link>
                            )}

                            <Divider />

                            <div className="experience-details">
                                <Typography.Title className="experience-information-item-title" level={3}>
                                    Detalles
                                </Typography.Title>

                                <div className="experience-details-list">
                                    {!!experience?.duration && moment.duration(experience.duration).asHours() > 0 && (
                                        <div className="experience-details-item">
                                            <ion-icon name="hourglass-outline" />

                                            <Typography.Text strong>
                                                {moment
                                                    .duration(moment.duration(experience.duration).asHours(), "h")
                                                    .format("d [days], h [hours] m [minutes]", {
                                                        trunc: true,
                                                        trim: "both",
                                                        minValue: 0,
                                                    })
                                                    .replace("days", "días")
                                                    .replace("day", "día")
                                                    .replace("hours", "horas")
                                                    .replace("hour", "hora")
                                                    .replace("minutes", "minutos")
                                                    .replace("minute", "minuto")}
                                            </Typography.Text>
                                        </div>
                                    )}

                                    {experience?.languages.length > 0 && (
                                        <div className="experience-details-item">
                                            <ion-icon name="language-outline" />

                                            <Typography.Text strong>
                                                {experience.languages.map(
                                                    (language, index) =>
                                                        `${language.name}${
                                                            experience.languages.length === index + 1 ? "" : " / "
                                                        }`
                                                )}
                                            </Typography.Text>
                                        </div>
                                    )}

                                    {!!experience?.peopleQuantity && (
                                        <div className="experience-details-item">
                                            <ion-icon name="people-outline" />

                                            <Typography.Text strong>
                                                {experience.peopleQuantity === 1
                                                    ? `1 persona`
                                                    : `${experience.peopleQuantity} personas`}
                                            </Typography.Text>
                                        </div>
                                    )}

                                    {!!experience?.direction?.locate && !!experience?.direction?.locate?.provincia && (
                                        <div className="experience-details-item">
                                            <ion-icon name="earth-outline"></ion-icon>

                                            <Typography.Text strong>
                                                {experience.direction?.locate
                                                    ? `${experience.direction?.locate?.nombre}, ${experience.direction?.locate?.provincia?.nombre}`
                                                    : `No hay detalles`}
                                            </Typography.Text>
                                        </div>
                                    )}

                                    {!!experience?.direction?.street && (
                                        <div className="experience-details-item">
                                            <ion-icon name="location-outline" />

                                            <Typography.Text strong>
                                                {`${experience.direction.street} ${
                                                    !!experience?.direction?.number
                                                        ? ` ${experience.direction.number}`
                                                        : ""
                                                } ${
                                                    !!experience?.direction?.depto
                                                        ? ` ${experience.direction.depto}`
                                                        : ""
                                                }`}
                                            </Typography.Text>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <Divider />

                            <div className="experience-description">
                                <Typography.Title className="experience-information-item-title" level={3}>
                                    Descripción
                                </Typography.Title>

                                <Typography.Paragraph
                                    ellipsis={{
                                        rows: 5,
                                        expandable: true,
                                        symbol: "ver más",
                                    }}
                                    style={{
                                        fontFamily: "Roboto",
                                    }}
                                >
                                    {experience?.details ? experience.details : "No hay detalles"}
                                </Typography.Paragraph>
                            </div>

                            {experience.detailsEn && (
                                <>
                                    <Divider />

                                    <div className="experience-description">
                                        <Typography.Title className="experience-information-item-title" level={3}>
                                            Description (en)
                                        </Typography.Title>

                                        <Typography.Paragraph
                                            ellipsis={{
                                                rows: 5,
                                                expandable: true,
                                                symbol: "ver más",
                                            }}
                                            style={{
                                                fontFamily: "Roboto",
                                            }}
                                        >
                                            {experience.detailsEn}
                                        </Typography.Paragraph>
                                    </div>
                                </>
                            )}

                            {!experience?.externalURL && (
                                <>
                                    <Divider />

                                    <div className="experience-availability">
                                        <Typography.Title className="experience-information-item-title" level={3}>
                                            Disponibilidad
                                        </Typography.Title>

                                        <Spin spinning={loadingExperienceEvents} tip="Calculando disponibilidad ... ">
                                            <AnCalendar
                                                className={"an-calendar view-mode"}
                                                onDayClick={handleDayClick}
                                                disabledDays={(day) => {
                                                    return (
                                                        selectedDayAsISOString.indexOf(
                                                            moment.utc(day).format("YYYY/M/DD")
                                                        ) === -1
                                                    );
                                                }}
                                                mode="view"
                                                loading={loadingExperienceEvents}
                                                events={selectedDays}
                                                selectedDays={selectedDays}
                                                selectedDay={selectedDay}
                                                noEventsAlertTitle="Sin Disponibilidad"
                                                noEventsAlertDescription="La experiencia se encuentra sin disponibilidad en este momento"
                                            />

                                            <div id="sss">
                                                {!!selectedDay && (
                                                    <div className="selected-day-events-container">
                                                        <div className="title-container">
                                                            <ion-icon name="calendar-outline"></ion-icon>

                                                            <div className="title">
                                                                {moment(selectedDay).format("LL")}
                                                            </div>
                                                        </div>

                                                        {selectedDayEvents.map((event) => (
                                                            <div className="selected-day-event-item">
                                                                <div className="event-item-time-container">
                                                                    <ion-icon
                                                                        name="time-outline"
                                                                        style={{
                                                                            fontSize: 23,
                                                                        }}
                                                                    />

                                                                    <div className="event-item-time">
                                                                        {moment.utc(event.dayAndHour).format("HH:mm")}
                                                                    </div>
                                                                </div>

                                                                {user?.role !== "Usuario" && (
                                                                    <Link
                                                                        to={{
                                                                            pathname: `/booking/${id}`,
                                                                            search: "?step=1",
                                                                            state: {
                                                                                selectedDay: selectedDay,
                                                                                selectedHour: event.id,
                                                                            },
                                                                        }}
                                                                    >
                                                                        <Button ghost type="primary" shape="round">
                                                                            Reservar
                                                                        </Button>
                                                                    </Link>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </Spin>
                                    </div>
                                </>
                            )}

                            {experience?.tags.length > 0 && (
                                <>
                                    <Divider />

                                    <div className="experience-tags">
                                        {experience.tags.map((tag) => (
                                            <span className="tag-span" key={tag.id}>
                                                {tag.nombre}
                                            </span>
                                        ))}
                                    </div>
                                </>
                            )}

                            <Divider />

                            {experience?.provider?.id !== user.sub && (
                                <>
                                    <div className="experience-chat-button-container">
                                        <Typography.Text type="secondary">¿Alguna consulta?</Typography.Text>

                                        <AnButton
                                            ghost
                                            type="primary"
                                            shape="round"
                                            size="large"
                                            style={{
                                                margin: 5,
                                            }}
                                            onClick={() => {
                                                setNewMessageShow(true);
                                            }}
                                        >
                                            Chat con nosotros
                                        </AnButton>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>

            {newMessageShow && (
                <NewMessageModal
                    context={context}
                    user={user}
                    title={experience.title}
                    experienceId={experience.id}
                    addressee={{
                        id: experience.provider.id,
                        name: experience.provider.name,
                        type: TypeOfUser["Usuario"],
                    }}
                    sendMessage={onSendMessage}
                    loadingMessage={loadingMessage}
                    messagesList={messagesList}
                    clear
                    clearMessageState={clearMessageState}
                    onClose={() => {
                        setNewMessageShow(false);
                    }}
                    getMessages={getMessages}
                    getMoreMessages={getMoreMessages}
                    sending={sending}
                    sendingSuccess={sendingSuccess}
                    loadingMoreResults={loadingMoreResultsMessages}
                    page={pageMessages}
                    hasMore={hasMoreMessages}
                    isOwn={experience?.provider?.id == user.sub}
                />
            )}
        </>
    );
});

const mapStateToProps = (state) => ({
    context: state.context,
    user: state.context.user,
    loading: state.experience.loading,
    experience: state.experience.data,
    loadingMessage: state.messages.loading,
    messagesList: state.messages.messagesList,
    actions: state.experience.actions,

    experienceEvents: state.experienceEvents.experienceEvents,
    loadingExperienceEvents: state.experienceEvents.loading,

    sending: state.messages.sending,
    sendingSuccess: state.messages.sendingSuccess,
    loadingMoreResultsMessages: state.messages.loadingMoreResults,
    pageMessages: state.messages.page,
    hasMoreMessages: state.messages.hasMore,
});

const mapDispatchToProps = (dispatch) => ({
    getExperience: (typeUser, experienceId) => dispatch(ExperienceActions.getExperience(typeUser, experienceId)),
    getExperienceEvents: (experienceId) => dispatch(ExperienceEventsActions.getEvents(experienceId)),
    likeExperience: (typeUser, experienceId) => dispatch(FavoritesActions.likeExperience(typeUser, experienceId)),
    unlikeExperience: (typeUser, experienceId) => dispatch(FavoritesActions.unlikeExperience(typeUser, experienceId)),
    clearExperienceState: () => dispatch(ExperienceActions.reset()),
    markExperienceAsViewed: (typeUser, experienceId, promotorNic) =>
        dispatch(ExperienceActions.markExperienceAsViewed(typeUser, experienceId, promotorNic)),

    clearMessageState: () => dispatch(MessagesActions.clearState()),
    sendMessage: (experienceId, addressee, message) =>
        dispatch(MessagesActions.sendMessage(experienceId, addressee, message)),
    getMessages: (withLoading, experienceId, userProviderId, senderId, type) =>
        dispatch(MessagesActions.getMessages(withLoading, experienceId, userProviderId, senderId, type)),
    getMoreMessages: (experienceId, userProviderId, senderId, type, page) =>
        dispatch(MessagesActions.getMoreMessages(experienceId, userProviderId, senderId, type, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExperienceScreen);
