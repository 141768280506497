import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { FavoritesActions, MessagesActions } from 'redux/actions'
import { PageHeader, ExperienceResultPagination, EmptyList, ScrollToTop } from 'components'
import { Alert } from 'antd'

// TODO
import UserRestClient from 'http/resources/UserRestClient'

const FavoritesScreen = React.memo((props) => {
    const {
        user,
        loading,
        loadingMoreResults,
        error,
        favorites,
        hasMore,
        page,
        likeExperience,
        unlikeExperience,
        getFavorites,
        getMoreFavorites,
        actions,
    } = props

    useEffect(() => {
        if (user) {
            getFavorites(user)
        }
    }, [user, getFavorites])

    const onRequestLikeExperience = useCallback((typeUser, experienceId) => {
        likeExperience(typeUser, experienceId)
    })

    const onRequestUnlikeExperience = useCallback((typeUser, experienceId) => {
        unlikeExperience(typeUser, experienceId)
    })

    const handleCloseErrorAlert = useCallback(() => {
        getFavorites(user?.role, true)
    }, [user, getFavorites])

    return (
        <>
            <ScrollToTop />
            <div className="main">
                <PageHeader
                    title="Mis Favoritos"
                    subtitle="Tus experiencias preferidas"
                />

                {error ? (
                    <div className="list-error-container margin-gap">
                        <Alert
                            message="Ups!"
                            description="Ha ocurrido un error al intentar obtener tus experiencias favoritas"
                            type="error"
                            showIcon
                            closeText="Reintentar"
                            onClose={handleCloseErrorAlert}
                        />
                    </div>
                ) : favorites.length === 0 && !loading ? (
                    <EmptyList
                        description="No tienes favoritos guardados"
                        image={(
                            <ion-icon name="heart-circle-outline" />
                        )}
                    />
                ) : (
                            <ExperienceResultPagination
                                user={user}
                                loading={loading}
                                loadingMoreResults={loadingMoreResults}
                                hasMore={hasMore}
                                page={page}
                                results={favorites}
                                getMoreResults={getMoreFavorites}
                                likeItemId={actions.like.id || actions.unlike.id}
                                likeExperience={onRequestLikeExperience}
                                unlikeExperience={onRequestUnlikeExperience}
                                shareExperienceUrl={`${window.location.protocol}//${window.location.hostname}${window.location.port !== 80 && (window.location.port.length > 0) ? `:${window.location.port}` : ``}/experience/:id/share${user?.role === 'Usuario' ? `?promoter-nic=${user?.nic}` : ``}`}
                            />
                        )}
            </div>
        </>
    )
})

const mapStateToProps = (state) => ({
    user: state.context.user,
    loading: state.favorites.loading,
    error: state.favorites.error,
    favorites: state.favorites.data,
    page: state.favorites.page,
    pageCount: state.favorites.pageCount,
    loadingMoreResults: state.favorites.loadingMoreResults,
    hasMore: state.favorites.hasMore,
    actions: state.feed.experience.actions,
})

const mapDispatchToProps = (dispatch) => ({
    getFavorites: (user, ignoreFingerprint) => dispatch(FavoritesActions.getFavorites(user, ignoreFingerprint)),
    getMoreFavorites: (user, page) => dispatch(FavoritesActions.getMoreFavorites(user, page)),
    likeExperience: (typeUser, experienceId) => dispatch(FavoritesActions.likeExperience(typeUser, experienceId)),
    unlikeExperience: (typeUser, experienceId) => dispatch(FavoritesActions.unlikeExperience(typeUser, experienceId)),
})


export default connect(mapStateToProps, mapDispatchToProps)(FavoritesScreen);
