import React, { useState, useCallback, useEffect } from 'react'
import { Modal, Button } from 'antd'
import moment from 'moment'
import DayPicker from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'

const AnDayPicker = React.memo((props) => {
    const {
        className,
        shape,
        placeholder,
        format,
        value,
        disabled,
        onChange,
        modalProps,
        dayPickerProps,
        withIcon,
        title,
        onClick,
    } = props

    const [open, setOpen] = useState(false)
    const [selectedDay, setSelectedDay] = useState(moment.utc(value || moment()))
    const [selectedMonth, setSelectedMonth] = useState(moment.utc(value || moment()))

    /* --------------------- 
        Handlers
    ---------------------------- */

    const handleOpen = useCallback(() => {
        // Open modal
        setOpen(true)
    }, [setOpen])

    const handleClose = useCallback(() => {
        setSelectedDay(moment.utc(value || moment()))
        setSelectedMonth(moment.utc(value || moment()))
        setOpen(false)
    }, [value, setOpen, moment])

    const handleSelectDay = useCallback((day, modifiers = {}) => {
        if (modifiers.disabled || modifiers.today) {
            return
        }

        setSelectedDay(moment(day))
    }, [selectedDay, setSelectedDay])

    const handleOk = useCallback(() => {
        onChange(moment(selectedDay))
        setSelectedMonth(moment(selectedDay))
        setOpen(false)
    }, [selectedDay, onChange, setOpen])


    useEffect(() => {
        setSelectedDay(moment(value || moment()))
        setSelectedMonth(moment(value || moment()))
    }, [value])

    return (
        <>
            <button
                className={`ant-picker ${className} ${shape} ${disabled ? ' ant-picker-disabled' : ''}`}
                disabled={disabled}
                onClick={() => {
                    if (typeof onClick === 'function') {
                        return onClick()
                    }

                    handleOpen()
                }}
            >
                <div className="ant-picker-input">
                    <input
                        readonly={true}
                        placeholder={placeholder}
                        size="10"
                        autocomplete="off"
                        value={value ? `${moment.utc(value).format(format)}` : ''}
                    />
                    {withIcon &&
                        <span className="ant-picker-suffix">
                            <ion-icon name="calendar-outline"></ion-icon>
                        </span>}

                </div>
            </button>

            <Modal
                transitionName="zoom"
                title={title}
                okText="Confirmar"
                cancelText="Cerrar"
                visible={open}
                onOk={handleOk}
                onCancel={handleClose}
                cancelButtonProps={{
                    shape: 'round'
                }}
                okButtonProps={{
                    shape: 'round'
                }}

                {...modalProps}
            >
                <div className="an-day-picker-options-container">
                    <DayPicker
                        locale="es"
                        localeUtils={MomentLocaleUtils}
                        onDayClick={handleSelectDay}
                        month={selectedMonth ? moment(selectedMonth).toDate() : new Date()}
                        onMonthChange={(date) => {
                            setSelectedMonth(moment(date))
                        }}
                        modifiers={{
                            highlighted: moment(selectedDay).toDate(),
                        }}
                        navbarElement={(p) => (
                            <div className="DayPicker-NavBar">
                                <Button
                                    className="DayPicker-NavButton DayPicker-NavButton--prev"
                                    onClick={() => p.onPreviousClick()}
                                >
                                    <ion-icon name="chevron-back-outline"></ion-icon>
                                </Button>

                                <Button
                                    className="DayPicker-NavButton DayPicker-NavButton--next"
                                    onClick={() => { p.onNextClick() }}
                                >
                                    <ion-icon name="chevron-forward-outline"></ion-icon>
                                </Button>
                            </div>
                        )}

                        {...dayPickerProps}
                    />
                </div>
            </Modal>
        </>
    )
})

AnDayPicker.defaultProps = {
    className: 'an-input',
    shape: '',
    placeholder: 'Seleccionar',
    format: 'DD-MM-yyyy',
    onChange: () => { },
    modalProps: {
        // ... 
    },
    dayPickerProps: {
        // ...  
    },
    withIcon: true,
    title: "Seleccionar Fecha"
}

export default AnDayPicker;