import React, { useEffect, useState, useCallback } from 'react'
import { AnButtonGradient, AnInput } from 'components/an'
import { ScrollToTop, PageHeader, BookingResultPagination, NewMessageModal } from 'components'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Typography } from 'antd'
import { MessagesActions, UserBookingsActions } from 'redux/actions'
import { TypeOfUser } from 'constants/index'

const UserBookingsScreen = React.memo((props) => {
    const {
        context,
        user,
        loading,
        getUserBookings,
        userBookingsList,
        loadingMoreResults,
        getMoreUserBookings,
        hasMore,
        page,
        sendMessage,
        loadingMessage,
        messagesList,
        clearMessageState,
        getMessages,
        getMoreMessages,

        sending,
        sendingSuccess,
        loadingMoreResultsMessages,
        pageMessages,
        hasMoreMessages,
    } = props

    let today = new Date()

    const [selectedBookingDetails, setSelectedBookingDetails] = useState({})
    const [newMessageShow, setNewMessageShow] = useState(false)

    const handleOnChange = (e) => {
        let { value } = e.target
        if (value.length > 2) {
            getUserBookings({
                experienceDateFrom: today,
                sortField: "experienciafecha",
                sortOrder: "desc",
            }, { name: value })
        } else if (value.length < 1) {
            getUserBookings({
                sortField: "experienciafecha",
                sortOrder: "desc",
            })
        }
    }

    useEffect(() => {
        if (user) {
            getUserBookings({
                sortField: "experienciafecha",
                sortOrder: "desc",
            })
        }
    }, [getUserBookings, user])

    const openModalMessage = useCallback((experience, client) => {
        setSelectedBookingDetails(() => ({
            experience,
            addressee: {
                id: client.id,
                name: client.nombre,
                type: TypeOfUser['Cliente'],
            },
        }))
        setNewMessageShow(true)
    })

    const onSendMessage = useCallback((experienceId, addressee, message) => {
        sendMessage(experienceId, addressee, message)
    })

    return (
        <>
            <div
                className="main"
            >
                <PageHeader
                    title="Reservas"
                    subtitle="Estado de reservas"
                />

                <div>
                    <ScrollToTop />

                    <div>
                        {!(userBookingsList.length === 0 && !loading) && (
                            <div
                                className="search-container sticky"
                            >

                                <AnInput
                                    placeholder='Buscar en reservas'
                                    onChange={handleOnChange}
                                    className="input primary"
                                />

                            </div>
                        )}

                        <div
                            style={{
                                padding: '0 10px',
                                width: '100%'
                            }}
                        >
                            {(userBookingsList.length === 0 && !loading) ? (
                                <div
                                    style={{
                                        padding: 10,
                                        height: '60vh',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <h3
                                        style={{
                                            marginBottom: 10
                                        }}>Aún no hay reservas</h3>

                                    <Typography.Text
                                        type='secondary'>
                                        ¿Ya creaste tus experiencias?
                                    </Typography.Text>

                                    <Link
                                        to='/user/experiences/create'>
                                        <AnButtonGradient
                                            type='primary'
                                            shape='round'
                                            size='large'
                                            style={{
                                                position: 'relative',
                                                margin: 10
                                            }}
                                        >
                                            Crear experiencia nueva
                                    </AnButtonGradient>
                                    </Link>

                                </div>
                            ) : (
                                    <BookingResultPagination
                                        loading={loading}
                                        loadingMoreResults={loadingMoreResults}
                                        hasMore={hasMore}
                                        page={page}
                                        results={userBookingsList}
                                        getMoreResults={getMoreUserBookings}
                                        openModalMessage={openModalMessage}
                                    />
                                )}
                        </div>
                    </div>
                </div>
            </div>


            {  newMessageShow && <NewMessageModal
                context={context}
                user={user}
                title={selectedBookingDetails?.experience?.nombre}
                experienceId={selectedBookingDetails?.experience?.id}
                addressee={selectedBookingDetails?.addressee}
                sendMessage={onSendMessage}
                loadingMessage={loadingMessage}
                messagesList={messagesList}
                clear
                clearMessageState={clearMessageState}
                onClose={() => { setNewMessageShow(false) }}

                getMessages={getMessages}
                getMoreMessages={getMoreMessages}
                sending={sending}
                sendingSuccess={sendingSuccess}
                loadingMoreResults={loadingMoreResultsMessages}
                page={pageMessages}
                hasMore={hasMoreMessages}
                isOwn={true}
            />}
        </>
    )
})

const mapStateToProps = (state) => ({
    context: state.context,
    user: state.context.user,
    loading: state.userBookings.loading,
    userBookingsList: state.userBookings.userBookingsList,
    loadingMoreResults: state.userBookings.loadingMoreResults,
    hasMore: state.userBookings.hasMore,
    page: state.userBookings.page,
    loadingMessage: state.messages.loading,
    messagesList: state.messages.messagesList,

    sending: state.messages.sending,
    sendingSuccess: state.messages.sendingSuccess,
    loadingMoreResultsMessages: state.messages.loadingMoreResults,
    pageMessages: state.messages.page,
    hasMoreMessages: state.messages.hasMore,
})

const mapDispatchToProps = (dispatch) => ({
    getUserBookings: (params, criteria) => dispatch(UserBookingsActions.getUserBookings(params, criteria)),
    getMoreUserBookings: (params, criteria) => dispatch(UserBookingsActions.getMoreUserBookings(params, criteria)),
    sendMessage: (experienceId, addressee, message) => dispatch(MessagesActions.sendMessage(experienceId, addressee, message)),
    clearMessageState: () => dispatch(MessagesActions.clearState()),
    getMessages: (withLoading, experienceId, userProviderId, senderId, type) => dispatch(MessagesActions.getMessages(withLoading, experienceId, userProviderId, senderId, type)),
    getMoreMessages: (experienceId, userProviderId, senderId, type, page) => dispatch(MessagesActions.getMoreMessages(experienceId, userProviderId, senderId, type, page)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserBookingsScreen)

