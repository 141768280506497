import UserRestClient from 'http/resources/UserRestClient'
import ClientRestClient from 'http/resources/ClientRestClient'
import ErrorManager from 'services/error-manager'
import { MessageRestClient } from 'http/resources'

function sendMessage(experienceId, addressee = { type: 1, id: null }, message) {
	return async dispatch => {
		dispatch({
			type: 'CONVERSATIONS_SEND_NEW_MESSAGE_START',
		})
		new UserRestClient(true).sendNewMessage({
			experienceId,
			addresseeId: addressee.id,
			addresseeType: addressee.type,
			message
		})
			.then(() => {
				dispatch({
					type: 'CONVERSATIONS_SEND_NEW_MESSAGE_SUCCESS',
					payload: {
						message
					}
				})
			})
			.catch(error => {
				dispatch({
					type: 'CONVERSATIONS_SEND_NEW_MESSAGE_FAILURE',
				})
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(error)
					}
				})
			})
	}
}

function getConversationsList(typeUser) {
	return async dispatch => {
		try {
			dispatch({
				type: 'CONVERSATIONS_FETCH_LIST_START',
			})

			const response = typeUser === 'Usuario'
				? await new UserRestClient(true).getConversationsList()
				: await new ClientRestClient(true).getConversationsList()

			dispatch({
				type: 'CONVERSATIONS_FETCH_LIST_SUCCESS',
				payload: {
					conversationsList: response.data.items,
					pageCount: response.data.pageCount,
					totalItems: response.data.totalItems,
					page: response.data.page,
					hasMore: !(response.data.items.length < response.data.pageCount)
				}
			})

		} catch (error) {
			dispatch({
				type: 'CONVERSATIONS_FETCH_LIST_FAILURE',
			})
			dispatch({
				type: 'UI_ALERT_SET_ERROR',
				payload: {
					error: ErrorManager.toMessage(error)
				}
			})
		}
	}
}


function getMoreConversations(typeUser, page) {
	return async dispatch => {
		try {
			dispatch({
				type: "CONVERSATIONS_FETCH_MORE_LIST_START",
			})

			const response = typeUser === 'Usuario'
				? await new UserRestClient(true).getConversationsList({ page })
				: await new ClientRestClient(true).getConversationsList({ page })

			dispatch({
				type: "CONVERSATIONS_FETCH_MORE_LIST_SUCCESS",
				payload: {
					conversationsList: response.data.items,
					pageCount: response.data.pageCount,
					totalItems: response.data.totalItems,
					page: response.data.page,
					hasMore: !(response.data.items.length < 1
						|| response.data.page === (page - 1))
				}
			})
		} catch (error) {
			dispatch({
				type: 'CONVERSATIONS_FETCH_MORE_LIST_FAILURE'
			})
			dispatch({
				type: 'UI_ALERT_SET_ERROR',
				payload: {
					error: ErrorManager.toMessage(error)
				}
			})
		}
	}
}


function getMessages(withLoading = true, experienceId, userProviderId, senderId, type = 1) {
	return async dispatch => {
		if (withLoading) {
			dispatch({
				type: 'CONVERSATIONS_FETCH_MESSAGES_START',
			})
		}

		new UserRestClient(true).getMessages({
			experienceId,
			userProviderId,
			[type === 1 ?
				"clientId"
				:
				"userId"]: senderId
		})
			.then(res => {
				dispatch({
					type: 'CONVERSATIONS_FETCH_MESSAGES_SUCCESS',
					payload: {
						messagesList: res.data.items
					}
				})
			})
			.catch(error => {
				dispatch({
					type: 'GECONVERSATIONS_FETCH_MESSAGES_FAILURE',
				})
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(error)
					}
				})
			})
	}
}
function getMoreMessages(experienceId, userProviderId, senderId, type = 1, page) {
	return async dispatch => {

		dispatch({
			type: 'CONVERSATIONS_FETCH_MORE_MESSAGES_START',
		})

		new UserRestClient(true).getMessages({
			experienceId,
			userProviderId,
			[type === 1 ?
				"clientId"
				:
				"userId"]: senderId,
			page
		})
			.then(response => {
				dispatch({
					type: 'CONVERSATIONS_FETCH_MORE_MESSAGES_SUCCESS',
					payload: {
						messagesList: response.data.items,
						pageCount: response.data.pageCount,
						totalItems: response.data.totalItems,
						page: response.data.page,
						hasMore: !(response.data.items.length < 1
							|| response.data.page === (page - 1))
					}
				})
			})
			.catch(error => {
				dispatch({
					type: 'CONVERSATIONS_FETCH_MORE_MESSAGES_FAILURE',
				})
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(error)
					}
				})
			})
	}
}

function setConversationRead(messageId, typeUser) {
	return async dispatch => {

		try {
			const response = typeUser === 'Usuario'
				? await new UserRestClient(true).setConversationRead({ messageId })
				: await new ClientRestClient(true).setConversationRead({ messageId })

		} catch (error) {

			dispatch({
				type: 'UI_ALERT_SET_ERROR',
				payload: {
					error: ErrorManager.toMessage(error)
				}
			})
		}

	}
}

function clearState() {
	return {
		type: 'MESSAGES_CLEAR_STATE',
	}
}

function getMessagesBadge() {
	return async (dispatch, getState) => {

		const { user } = getState().context

		if (user && user.role !== 'Anonimous') {
			new MessageRestClient(true).getMessagesBadge()
				.then(response => {
					dispatch({
						type: 'CONVERSATIONS_FETCH_BADGE_SUCCESS',
						payload: {
							badge: response.data.cantidad
						}
					})
				})
				.catch(() => {
					dispatch({
						type: 'CONVERSATIONS_FETCH_MORE_MESSAGES_FAILURE',
					})
				})
		}

	}
}

function clearBadge() {
	return {
		type: 'CONVERSATIONS_CLEAR_BADGE_STATE',
	}
}


export const MessagesActions = {
	getConversationsList,
	getMessages,
	sendMessage,
	setConversationRead,
	clearState,
	getMoreConversations,
	getMoreMessages,
	getMessagesBadge,
	clearBadge,
}