const defaultState = {
    loading: false,
    error: false,
    actions: {
        fetchGatewayAuthorizationURL: {
            loading: false,
            error: false,
            url: '',
        },
        linkGateway: {
            loading: false,
            error: false,
            success: false,
        },
        unlinkGateway: {
            loading: false,
            error: false,
            success: false,
        }
    }
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'USER_GATEWAY_FETCH_AUTHORIZATION_URL_START':
            return Object.assign({}, state, {
                actions: Object.assign({}, state.actions, {
                    fetchGatewayAuthorizationURL: {
                        loading: true,
                        error: false,
                        url: '',
                    }
                })
            });

        case 'USER_GATEWAY_FETCH_AUTHORIZATION_URL_SUCCESS':
            return Object.assign({}, state, {
                actions: Object.assign({}, state.actions, {
                    fetchGatewayAuthorizationURL: {
                        loading: false,
                        error: false,
                        url: action.payload.url
                    }
                })
            });

        case 'USER_GATEWAY_FETCH_AUTHORIZATION_URL_FAILURE':
            return Object.assign({}, state, {
                actions: Object.assign({}, state.actions, {
                    fetchGatewayAuthorizationURL: {
                        loading: false,
                        error: true,
                        url: '',
                    }
                })
            });

        case 'USER_GATEWAY_FETCH_AUTHORIZATION_URL_RESET':
            return Object.assign({}, state, {
                actions: Object.assign({}, state.actions, {
                    fetchGatewayAuthorizationURL: defaultState.actions.fetchGatewayAuthorizationURL,
                })
            });

        case 'USER_GATEWAY_LINK_GATEWAY_START':
            return Object.assign({}, state, {
                actions: Object.assign({}, state.actions, {
                    linkGateway: {
                        loading: true,
                        error: false,
                        success: false,
                    }
                })
            }) 
        
        case 'USER_GATEWAY_LINK_GATEWAY_SUCCESS':
            return Object.assign({}, state, {
                actions: Object.assign({}, state.actions, {
                    linkGateway: {
                        loading: false,
                        error: false,
                        success: true,
                    }
                })
            })

        case 'USER_GATEWAY_LINK_GATEWAY_FAILURE':
            return Object.assign({}, state, {
                actions: Object.assign({}, state.actions, {
                    linkGateway: {
                        loading: false,
                        error: action.payload.error,
                        success: false,
                    }
                })
            }) 

        case 'USER_GATEWAY_LINK_GATEWAY_RESET':
            return Object.assign({}, state, {
                actions: Object.assign({}, state.actions, {
                    linkGateway: defaultState.actions.linkGateway
                })
            }) 

        case 'USER_GATEWAY_UNLINK_GATEWAY_START':
            return Object.assign({}, state, {
                actions: Object.assign({}, state.actions, {
                    unlinkGateway: {
                        loading: true,
                        error: false,
                        success: false,
                    }
                })
            });

        case 'USER_GATEWAY_UNLINK_GATEWAY_SUCCESS':
            return Object.assign({}, state, {
                actions: Object.assign({}, state.actions, {
                    unlinkGateway: {
                        loading: false,
                        error: false,
                        success: true,
                    }
                })
            });

        case 'USER_GATEWAY_UNLINK_GATEWAY_FAILURE':
            return Object.assign({}, state, {
                actions: Object.assign({}, state.actions, {
                    unlinkGateway: {
                        loading: false,
                        error: true,
                        success: false,
                    }
                })
            });

        case 'USER_GATEWAY_UNLINK_GATEWAY_RESET':
            return Object.assign({}, state, {
                actions: Object.assign({}, state.actions, {
                    unlinkGateway: defaultState.actions.unlinkGateway
                })
            });

        default:
            return state;
    }
}
