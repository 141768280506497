import { Typography } from 'antd';
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { UserGatewayActions } from 'redux/actions';
import { withQueryParams, StringParam } from 'use-query-params';
import { Loader } from 'components'
import logo from 'assets/images/logo.png'

const UserGatewayAuthorization = React.memo((props) => {
    const {
        user,
        query,
        linkGateway,
        actions
    } = props
    
    const location = useLocation()
    const [e, setE] = useState('')

    useEffect(() => {
        if (user && query.code) {
            linkGateway(user.sub, query.code, process.env.REACT_APP_GATEWAY_REDIRECT_URL)
        }
    }, [user, query.code, linkGateway, location])

    useEffect(() => {
        if (actions.linkGateway.success) {
            window.opener.postMessage('success', '*')
        }
    })

    useEffect(() => {
        if (actions.linkGateway.error) {
            setE(actions.linkGateway.error)
        }
    }, [actions.linkGateway.error])
    
    return (
        <div
            style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                maxWidth: '300px',
                margin: '80px auto 20px',
                textAlign: 'center'
            }}
        >
            <img
                className="logo"
                src={logo}
                alt='logo Spiderweb'
            />
            
            <div
                style={{
                    marginTop: 15,
                }}
            >
                {e ? (
                    <>
                        <Typography.Text
                            style={{
                                fontSize: 20,
                                color: 'red',
                                marginBottom: 10,
                                display: 'block'
                            }}
                        >
                            La vinculación ha fallado!
                        </Typography.Text>
                        <div>
                            { e }
                        </div>
                    </>
                ) : (
                    <Loader
                        loading={true}
                        title="Vinculando Mercado Pago"
                        description="Espere ... "
                    />
                )}
            </div>
        </div>
    )
})

const mapStateToProps = (state) => ({
    user: state.context.user,
    actions: state.userGateway.actions,
})

const mapDispatchToProps = (dispatch) => ({
    linkGateway: (id, code, redirectURI) => dispatch(UserGatewayActions.linkGateway(id, code, redirectURI))
})

export default connect(mapStateToProps, mapDispatchToProps)(
    withQueryParams({
        code: StringParam
    }, UserGatewayAuthorization)
);