import React, { useState, useEffect } from 'react'
import { Typography } from 'antd'
import { AnModal, AnButton, AnInput } from 'components/an'

const ResetPasswordModal = React.memo((props) => {
	const {
		onClose,
		sendEmailToResetPassword,
		loadingEmailLink,
		sendEmailSuccess,
		clearState
	} = props;

	const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	const [emailToReset, setEmailToReset] = useState(null)
	useEffect(() => {
		if (sendEmailSuccess) {
			onClose()
		}
		return () => {
			clearState()
		}
	}, [sendEmailSuccess]);

	const back = () => {
		onClose()
	}

	const handleOnChange = (e) => {
		setEmailToReset(e.target.value)
	}

	const onConfirmResetPass = async () => {
		sendEmailToResetPassword(emailToReset)
	}

	return (
		<AnModal
			headerTitle="Contraseña"
			headerButtons={(
				<>
					<AnButton
						ghost
						type='primary'
						shape='round'
						size='large'
						onClick={() => {
							back()
						}}
					>
						Cerrar
                    </AnButton>

				</>
			)}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					flex: 1,
					height: '100%',
					textAlign: 'center',
					padding: '0px 30px',
				}}
			>
				<Typography.Title
					level={5}
				>
					Recuperar contraseña
				</Typography.Title>

				<Typography.Text>Ingresa el correo electrónico con el que te registraste, te enviaremos un link para recuperar la contraseña</Typography.Text>

				<AnInput
					className="an-input round"
					placeholder='Email'
					name='email'
					onChange={handleOnChange}
					value={emailToReset}
					style={{
						maxWidth: 350,
						margin: 10
					}}
				/>

				<AnButton
					ghost
					shape='round'
					type='primary'
					block
					onClick={onConfirmResetPass}
					disabled={!EMAIL_REGEX.test(emailToReset) || loadingEmailLink}
					loading={loadingEmailLink}
					style={{
						maxWidth: 350,
						margin: 10
					}}
				>
					Recuperar contraseña
            	</AnButton>
			</div>

		</AnModal >
	)
})

export default ResetPasswordModal