import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = (
    <LoadingOutlined style={{ fontSize: 28 }} spin />
)

const Loader = React.memo((props) => {
    const {
        loading,
        title,
        description,
        children,
    } = props

    if (children) {
        return (
            <Spin indicator={antIcon} spinning={loading} tip={title}>
                {children}
            </Spin>
        )
    }

    return loading ? (
        <div className="loader-container">
            <Spin indicator={antIcon} />

            {(!!title && (
                <div className="loader-title">
                    <span style={{ fontDisplay: 'block' }}>{title}</span>
                </div>
            ))}

            {(!!description && (
                <div className="loader-description">
                    <span>{description}</span>
                </div>
            ))}
        </div>
    ) : null
})


Loader.defaultProps = {
    loading: false,
    title: '',
    description: '',
}

export default Loader;