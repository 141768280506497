import React, { useEffect, useState } from 'react'
import { Alert, Upload, Button, Divider } from 'antd'
import { AnButton, AnButtonGradient } from 'components/an'
import { connect } from 'react-redux'
import { ScrollToTop, Loader } from 'components'
import { UploadOutlined } from '@ant-design/icons'
import { v1 as uuid } from 'uuid'
import firebase from 'firebase'
import { ExperienceActions, UserExperiencesActions } from 'redux/actions'
import ImgCrop from 'antd-img-crop';

const EditStepThree = React.memo((props) => {
    const {
        user,
        loading,
        prevStep,
        nextStep,
        getExperienceMedia,
        experience,
        pushNewExperienceMedia,
        deleteExperienceMedia,
        dispatchError,
    } = props

    useEffect(() => {
        if (experience?.id) {
            getExperienceMedia(experience?.id)
        }
    }, [getExperienceMedia, experience?.id])

    const [mediaList, setMediaList] = useState([])
    const [mediaListToFetch, setMediaListToFetch] = useState([])

    useEffect(() => {
        //When open this page fetch experiences details and set mediaList 
        //state with experience.media items

        if (experience?.mediaList?.length > 0) {
            setMediaList(state => {
                const updateMediaList = experience.mediaList.map((item, index) => {
                    return {
                        uid: item.id,
                        name: item.id,
                        status: 'done',
                        url: item.url,
                        thumbUrl: item.url,
                        extension: item.extension,
                        altText: item.altText
                    }
                })
                return updateMediaList
            })

            setMediaListToFetch(() => experience.mediaList)
        }
    }, [experience])

    const handleCustomUpload = async ({ onError, onSuccess, file }) => {

        //create storageRef
        const storageRef = firebase.storage().ref(`/usuarios/${user?.sub}/experiencias/${experience?.id}/images`)
        //create uuid name for file
        const name = uuid().replace(/-/g, "")
        //get extension file
        const extension = file.name.split('.').pop()
        //Create ref child to file
        const imgFile = storageRef.child(`${name}.${extension}`)
        //config metaData's file
        const metadata = {
            contentType: file.type
        }

        try {
            //save file on firebase
            const image = await imgFile.put(file, metadata)
            //Get download url from firebase
            const url = await imgFile.getDownloadURL()
            const newImage = {
                id: name,
                url,
                extension,
                altText: '',
            }

            await pushNewExperienceMedia(experience?.id, newImage, mediaListToFetch)
            //if everything is ok save newImage in setMediaListToFetch
            setMediaListToFetch(mediaListToFetch => mediaListToFetch.concat(newImage))
            onSuccess(newImage, image);
        } catch (error) {
            dispatchError(error)
            onError(error);
        }
    }

    const handleOnRemove = async (file) => {
        const fileId = file.response ? file.response.id : file.uid
        const fileExtension = file.response ? file.response.extension : file.extension

        //create storageRef
        const storageRef = firebase.storage().ref(`/usuarios/${user.sub}/experiencias/${experience?.id}/images`)
        //Create ref child to file
        const imgFile = storageRef.child(`${fileId}.${fileExtension}`)

        try {
            //first delete img referende id from API
            await deleteExperienceMedia(experience?.id, fileId, mediaListToFetch)
            //then delete image from firebase Storage
            await imgFile.delete()
            //if everything is ok delete correct file in setMediaListToFetch
            setMediaListToFetch(mediaListToFetch => mediaListToFetch.filter(item => item.id !== fileId))
        } catch (error) {
            dispatchError(error)
            //return false to prevent onRemove action
            return false
        }
    }

    const onFileChange = (e) => {
        setMediaList(e.fileList.filter(file => !!file.status && file.status !== 'error'))
    }

    const handleBeforeUpload = file => {
        const supportedMimeTypes = [
            'image/png',
            'image/jpeg',
            'video/mpeg',
            'video/mp4',
            'video/mpeg4',
            'image/svg+xml',
        ]

        const validFormat = supportedMimeTypes.indexOf(file.type) !== -1

        if (validFormat) {
            return validFormat
        } else {
            dispatchError(new Error('Formato no permitido'))
            return false
        }
    }

    return (
        <>
            <ScrollToTop />
            
            <Loader
                loading={loading}
            />
            
            {!loading && (
                <div
                    style={{
                        padding: 15 // TODO - This must be a global class as container of sub main content
                    }}
                >
                    <div>
                        <ImgCrop
                            aspect={3/2}
                            grid
                            fillColor="#000"
                            rotate
                            modalTitle="Editar Imagen"
                            modalOk="Aplicar"
                            modalCancel="Cancelar"
                        >
                            <Upload
                                customRequest={handleCustomUpload}
                                onChange={onFileChange}
                                onRemove={handleOnRemove}
                                listType="picture"
                                fileList={[...mediaList]}
                                beforeUpload={handleBeforeUpload}
                            >
                                <Button
                                    block
                                    icon={(
                                        <UploadOutlined />
                                    )}
                                >
                                    Subir nueva foto/video...
                                </Button>
                            </Upload>
                        </ImgCrop>

                    </div>

                    {mediaListToFetch.length === 0 && (
                        <>
                            <Divider />

                            <Alert
                                message="Experiencia sin imágenes"
                                description="La experiencia no tiene imágenes cargadas"
                                type="warning"
                                showIcon
                            />
                        </>
                    )}

                    <Divider dashed />

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-around'
                        }}
                    >
                        <AnButton
                            ghost
                            onClick={prevStep}
                            type='primary'
                            shape='round'
                            size='large'
                            style={{
                                width: '50%',
                                marginRight: '5px',
                            }}
                        >
                            Volver
                        </AnButton>

                        <AnButtonGradient
                            type='primary'
                            shape='round'
                            size='large'
                            style={{
                                width: '50%',
                                marginRight: '5px',
                            }}
                            onClick={nextStep}
                        >
                            Siguiente
                        </AnButtonGradient>
                    </div>
                </div>
            )}
        </>
    )
})


const mapStateToProps = state => ({
    user: state.context.user,
})

const mapDispatchToProps = dispatch => ({
    getExperienceMedia: experienceId => dispatch(UserExperiencesActions.getExperienceMedia(experienceId)),
    pushNewExperienceMedia: (experienceId, media, mediaList) => dispatch(UserExperiencesActions.pushNewExperienceMedia(experienceId, media, mediaList)),
    deleteExperienceMedia: (experienceId, fileId, mediaList) => dispatch(UserExperiencesActions.deleteExperienceMedia(experienceId, fileId, mediaList)),
    dispatchError: (error) => dispatch(ExperienceActions.dispatchError(error)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditStepThree)