import {
    FeedScreen,
    FavoritesScreen,
    LoginScreen,
    ExperienceScreen,
    UserProveedorStats,
    UserExperienceFormScreen,
    UserExperiencesScreen,
    MessagesScreen,
    BookingScreen,
    BookingsScreen,
    BookingDetailsScreen,
    UserBookingsScreen,
    UserPromotorStats,
    UserPromotorShareInfoScreen,
    UserPromotorBookingsInfoScreen,
    UserPromotorCommissionsInfoScreen,
    UserProveedorBookingsInfoScreen,
    UserProveedorIncomesInfoScreen,
    UserProveedorViewsInfoScreen,
    MessageScreen,
    UserProfileScreen,
    UserGatewayAuthorizationScreen,
    PostulationScreen,
    UserPromotorRedShareInfoScreen,
    UserPromotorRedBookingsInfoScreen,
    UserPromotorRedCommissionsInfoScreen,
    UserWalletInfoScreen,
    UserWalletDetailsScreen,
} from 'screens'


const routes = [
    {
        id: 'search',
        pathId: '/search',
        path: '/search',
        exact: true,
        component: FeedScreen,
        className: 'feed-screen',
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: false,
        session: false,
        root: true,
        onlyUser: false,
    },
    {
        id: 'liked',
        pathId: '/liked',
        path: '/liked',
        exact: true,
        component: FavoritesScreen,
        className: 'favorites-screen',
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: false,
        session: false,
        root: true,
        onlyUser: false
    },
    {
        id: 'messages',
        pathId: '/messages',
        path: '/messages',
        exact: true,
        component: MessagesScreen,
        className: 'messages-screen',
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: false,
        session: false,
        root: true,
        onlyUser: false
    },
    {
        id: 'message-id',
        pathId: '/messages',
        path: '/messages/:id',
        exact: true,
        component: MessageScreen,
        className: 'message-detail-screen',
        topBar: true,
        bottomBar: false,
        sideBar: true,
        auth: false,
        session: false,
        root: false,
        onlyUser: false,
    },
    {
        id: 'login',
        pathId: '/login',
        path: '/login',
        exact: false,
        component: LoginScreen,
        className: 'login-screen',
        topBar: true,
        hideTopBarLinkToLogin: true,
        bottomBar: false,
        sideBar: false,
        auth: false,
        session: true,
        root: false,
        onlyUser: false
    },
    {
        id: 'postulation',
        pathId: '/postulation',
        path: '/postulation',
        exact: false,
        component: PostulationScreen,
        className: 'login-screen',
        topBar: true,
        hideTopBarLinkToLogin: true,
        bottomBar: false,
        sideBar: false,
        auth: false,
        session: false,
        root: false,
        onlyUser: false
    },
    {
        id: 'experience',
        pathId: '/experience',
        path: '/experience/:id',
        exact: true,
        component: ExperienceScreen,
        className: 'experience-detail-screen',
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: false,
        session: false,
        root: false,
        onlyUser: false
    },
    {
        id: 'booking',
        pathId: '/booking',
        path: '/booking/:id',
        exact: true,
        component: BookingScreen,
        className: 'booking-screen',
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: false,
        session: false,
        root: false,
        onlyUser: false
    },
    {
        id: 'profile-bookings',
        pathId: '/bookings',
        path: '/bookings',
        exact: true,
        component: BookingsScreen,
        className: 'bookings-screen',
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: false,
        session: false,
        root: false,
        onlyUser: false
    },
    {
        id: 'profile-booking-details',
        pathId: '/bookings',
        path: '/bookings/:id',
        exact: true,
        component: BookingDetailsScreen,
        className: 'experience-detail-screen',
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: true,
        session: false,
        root: false,
        onlyUser: false,
    },
    {
        id: 'user-profile',
        pathId: '/user',
        path: '/user',
        exact: true,
        component: UserProfileScreen,
        className: 'user-profile-screen',
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: true,
        session: false,
        root: false,
        onlyUser: true,
    },
    {
        id: 'user-gateway-authorization',
        pathId: '/user/gateway/authorization',
        path: '/user/gateway/authorization',
        exact: true,
        component: UserGatewayAuthorizationScreen,
        className: 'user-gateway-authorization',
        topBar: false,
        bottomBar: false,
        sideBar: false,
        auth: false,
        session: false,
        root: false,
        onlyUser: true,
    },
    {
        id: 'user-experiences',
        pathId: '/user/experiences',
        path: '/user/experiences',
        exact: true,
        component: UserExperiencesScreen,
        className: 'user-experiences-screen',
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: true,
        session: false,
        root: false,
        onlyUser: true,
    },
    {
        id: 'user-experience-form',
        pathId: '/user/experiences/form',
        path: '/user/experiences/form/:id',
        exact: true,
        component: UserExperienceFormScreen,
        className: 'user-experience-form-screen',
        topBar: true,
        bottomBar: false,
        sideBar: true,
        auth: true,
        session: false,
        root: false,
        onlyUser: true,
    },
    {
        id: 'user-bookings',
        pathId: '/user/bookings',
        path: '/user/bookings',
        exact: true,
        component: UserBookingsScreen,
        className: 'user-bookings-screen',
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: true,
        session: false,
        root: false,
        onlyUser: true,
    },
    {
        id: 'user-proveedor',
        pathId: '/user/proveedor',
        path: '/user/proveedor',
        exact: true,
        component: UserProveedorStats,
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: true,
        session: false,
        root: false,
        onlyUser: true,
    },
    {
        id: 'user-proveedor-bookings',
        pathId: '/user/proveedor/bookings',
        path: '/user/proveedor/bookings',
        exact: true,
        component: UserProveedorBookingsInfoScreen,
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: true,
        session: false,
        root: false,
        onlyUser: true,
    },
    {
        id: 'user-proveedor-incomes',
        pathId: '/user/proveedor/incomes',
        path: '/user/proveedor/incomes',
        exact: true,
        component: UserProveedorIncomesInfoScreen,
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: true,
        session: false,
        root: false,
        onlyUser: true,
    },
    {
        id: 'user-proveedor-views',
        pathId: '/user/proveedor/views',
        path: '/user/proveedor/views',
        exact: true,
        component: UserProveedorViewsInfoScreen,
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: true,
        session: false,
        root: false,
        onlyUser: true,
    },
    {
        id: 'user-promotor',
        pathId: '/user/promotor',
        path: '/user/promotor',
        exact: true,
        component: UserPromotorStats,
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: true,
        session: false,
        root: false,
        onlyUser: true,
    },
    {
        id: 'user-promotor-share-own',
        pathId: '/user/promotor/share/own',
        path: '/user/promotor/share/own',
        exact: true,
        component: UserPromotorShareInfoScreen,
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: true,
        session: false,
        root: false,
        onlyUser: true,
        regExForPath: '\/())',
    },
    {
        id: 'user-promotor-share-red',
        pathId: '/user/promotor/share/red',
        path: '/user/promotor/share/red',
        exact: true,
        component: UserPromotorRedShareInfoScreen,
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: true,
        session: false,
        root: false,
        onlyUser: true,
        regExForPath: '\/())',
    },
    {
        id: 'user-promotor-bookings-own',
        pathId: '/user/promotor/bookings/own',
        path: '/user/promotor/bookings/own',
        exact: true,
        component: UserPromotorBookingsInfoScreen,
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: true,
        session: false,
        root: false,
        onlyUser: true,
    },
    {
        id: 'user-promotor-bookings-red',
        pathId: '/user/promotor/bookings/red',
        path: '/user/promotor/bookings/red',
        exact: true,
        component: UserPromotorRedBookingsInfoScreen,
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: true,
        session: false,
        root: false,
        onlyUser: true,
    },
    {
        id: 'user-promotor-commissions-own',
        pathId: '/user/promotor/commissions/own',
        path: '/user/promotor/commissions/own',
        exact: true,
        component: UserPromotorCommissionsInfoScreen,
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: true,
        session: false,
        root: false,
        onlyUser: true,
    },
    {
        id: 'user-promotor-commissions-red',
        pathId: '/user/promotor/commissions/red',
        path: '/user/promotor/commissions/red',
        exact: true,
        component: UserPromotorRedCommissionsInfoScreen,
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: true,
        session: false,
        root: false,
        onlyUser: true,
    },
    {
        id: 'user-wallet',
        pathId: '/user/wallet',
        path: '/user/wallet',
        className: 'user-wallet-screen',
        exact: true,
        component: UserWalletInfoScreen,
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: true,
        session: false,
        root: false,
        onlyUser: true,
    },
    {
        id: 'user-wallet-details',
        pathId: '/user/wallet/details',
        path: '/user/wallet/details/:id',
        className: 'user-wallet-screen',
        exact: true,
        component: UserWalletDetailsScreen,
        topBar: true,
        bottomBar: true,
        sideBar: true,
        auth: true,
        session: false,
        root: false,
        onlyUser: true,
    },
]

export default routes


