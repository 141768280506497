import RestClient from 'http/RestClient'

export default class ExperienceMediaRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(
            undefined,
            useCredentials,
            refreshContext,
        )
    }
    
    getExperienceMedias(params) {
        const url = `${this.baseURL}/secured/experiencias/${params.experienceId}/medios`
        
        return this.instance.get(url);
    }

    updateExperienceMedias(params) {
        const url = `${this.baseURL}/secured/experiencias/${params.experienceId}/medios`
        
        return this.instance.put(url, {
            medios: params.media
        })
    }
}
