const base64Lib = require('base-64')
const utf8 = require('utf8')
export default class JWT {
    /**
     *  Decode jwt token.
     *
     *  @param {string} token - The token to parse.
     *  @returns {Object} - The decoded token.
     */
    static parseToken(token) {

        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        
        return JSON.parse(utf8.decode(base64Lib.decode(base64)));
    }
}
