const defaultState = {
    loading: false,
    error: null,

    selectedDay: "",

    bookingType: null,
    hourOption: null,
    peopleQuantity: 0,
    finalPrice: 0,

    savedDate: null,
    clientName: "",
    clientEmail: "",
    clientPhone: "",
    clientAdditionalComments: "",
    whereToPickup: "",
    whereToGo: "",
    flightNumber: "",

    loadingClientInfo: false,
    loadingCreateBooking: false,
    successCreateBooking: false,
    bookingId: null,
    bookingDetails: {},
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case "BOOKING_SET_STATE":
            return Object.assign({}, state, {
                [action.payload.name]: action.payload.value,
            });

        case "BOOKING_CLIENT_FETCH_INFO_START":
            return Object.assign({}, state, {
                loadingClientInfo: true,
            });

        case "BOOKING_CLIENT_FETCH_INFO_SUCCESS":
            return Object.assign({}, state, {
                loadingClientInfo: false,
                clientName: action.payload.clientName,
                clientEmail: action.payload.clientEmail,
                clientPhone: action.payload.clientPhone,
                clientAdditionalComments: action.payload.clientAdditionalComments,
            });

        case "BOOKING_CLIENT_FETCH_INFO_FAILURE":
            return Object.assign({}, state, {
                loadingClientInfo: false,
            });

        case "BOOKING_CREATE_BOOKING_START":
            return Object.assign({}, state, {
                loadingCreateBooking: true,
                successCreateBooking: false,
                error: null,
            });

        case "BOOKING_CREATE_BOOKING_SUCCESS":
            return Object.assign({}, state, {
                loadingCreateBooking: false,
                successCreateBooking: true,
                bookingId: action.payload.bookingId,
            });

        case "BOOKING_CREATE_BOOKING_FAILURE":
            return Object.assign({}, state, {
                loadingCreateBooking: false,
                successCreateBooking: false,
                error: action.payload.error,
            });

        case "BOOKING_GET_BOOKING_STATUS_START":
            return Object.assign({}, state, {
                loading: true,
            });

        case "BOOKING_GET_BOOKING_STATUS_SUCCESS":
            return Object.assign({}, state, {
                loading: false,
                bookingDetails: action.payload.bookingDetails,
            });

        case "BOOKING_GET_BOOKING_STATUS_FAILURE":
            return Object.assign({}, state, {
                loading: false,
            });

        case "BOOKING_RESET":
            return Object.assign({}, defaultState);

        default:
            return state;
    }
}
