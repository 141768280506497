import { FavoriteRestClient } from 'http/resources'
import ClientRestClient from 'http/resources/ClientRestClient'
import UserRestClient from 'http/resources/UserRestClient'
import ErrorManager from 'services/error-manager'

function getFavorites(user, ignoreFingerprint) {
	return async (dispatch, getState) => {
        try {
            const {
                dataFingerprint
            } = getState().favorites
            
            if (ignoreFingerprint || !dataFingerprint || dataFingerprint?.userId !== user?.sub) {
                dispatch({
                    type: "FAVORITES_FETCH_FAVORITES_START",
                    payload: {
                        dataFingerprint: {
                            userId: user?.sub
                        }
                    }
                })

                const response = user?.role === 'Usuario' || user?.role === 'Cliente'
                    ? await new FavoriteRestClient(true).getFavoritesList()
                    : await new FavoriteRestClient().getFavoritesList()
                
                dispatch({
                    type: "FAVORITES_FETCH_FAVORITES_SUCCESS",
                    payload: {
                        favorites: response.data.items.map((item) => ({
                            id: item.id,
                            title: item.nombre,
                            details: item.detalles,
                            detailsEn: item.detallesEn,
                            price: item.precio,
                            rating: item.rating,
                            ranking: item.ranking,
                            isLiked: item.favorito,
                            isPublic: item.publicada,
                            images: item.mediosUrls,
                            externalURL: item.urlExterna,
                        })),
                        pageCount: response.data.pageCount,
                        totalItems: response.data.totalItems,
                        page: response.data.page,
                        hasMore: !(response.data.items.length <= response.data.pageCount)
                    }
                })
            }
        } catch (error) {
            dispatch({
                type: 'FAVORITES_FETCH_FAVORITES_FAILURE'
            })
        }
	}
}

function getMoreFavorites(user, page) {
	return async dispatch => {
        try {
            dispatch({
                type: "FAVORITES_FETCH_MORE_FAVORITES_START",
            })

            const response = user?.role === 'Usuario' || user?.role === 'Cliente'
                ? await new FavoriteRestClient(true).getFavoritesList({ page })
                : await new FavoriteRestClient().getFavoritesList({ page })
            
            dispatch({
                type: "FAVORITES_FETCH_MORE_FAVORITES_SUCCESS",
                payload: {
                    favorites: response.data.items.map((item) => ({
                        id: item.id,
                        title: item.nombre,
                        details: item.detalles,
                        detailsEn: item.detallesEn,
                        price: item.precio,
                        rating: item.rating,
                        ranking: item.ranking,
                        isLiked: item.favorito,
                        isPublic: item.publicada,
                        images: item.mediosUrls,
                        externalURL: item.urlExterna,
                    })),
                    pageCount: response.data.pageCount,
                    totalItems: response.data.totalItems,
                    page: response.data.page,
                    hasMore: !(response.data.items.length < 1
                        || response.data.page === (page - 1))
                }
            })
        } catch (error) {
            dispatch({
                type: 'FAVORITES_FETCH_MORE_FAVORITES_FAILURE'
            })
        }
	}
}

// TODO
function likeExperience(typeUser, experienceId) {
	return async dispatch => {
        try {
            dispatch({
                type: 'LIKED_EXPERIENCE_START',
                payload: {
                    id: experienceId,
                }
            })
            
            typeUser === 'Usuario' || typeUser === 'Cliente'
                ? await new FavoriteRestClient(true).likeExperience(experienceId)
                : await new FavoriteRestClient().likeExperience(experienceId)

                dispatch({
                    type: 'UI_ALERT_SET_LIKED',
                })
                
                dispatch({
                    type: 'LIKED_EXPERIENCE_SUCCESS',
                    payload: {
                        id: experienceId
                    }
                })
        } catch (error) {
            dispatch({
                type: 'LIKED_EXPERIENCE_FAILURE'
            })

            dispatch({
                type: 'UI_ALERT_SET_ERROR',
                payload: {
                    error: ErrorManager.toMessage(error)
                }
            })
        } finally {
            dispatch({
                type: 'LIKED_EXPERIENCE_RESET'
            })
        }
	}
}

// TODO
function unlikeExperience(typeUser, experienceId) {
	return async dispatch => {
        try {
            dispatch({
                type: 'UNLIKED_EXPERIENCE_START',
                payload: {
                    id: experienceId,
                }
            })

            typeUser === 'Usuario' || typeUser === 'Cliente'
                ? await new FavoriteRestClient(true).unlikeExperience(experienceId)
                : await new FavoriteRestClient().unlikeExperience(experienceId)

            dispatch({
                type: 'UNLIKED_EXPERIENCE_SUCCESS',
                payload: {
                    id: experienceId
                }
            })
        } catch (error) {
            dispatch({
                type: 'UNLIKED_EXPERIENCE_FAILURE'
            })

            dispatch({
                type: 'UI_ALERT_SET_ERROR',
                payload: {
                    error: ErrorManager.toMessage(error)
                }
            })
        } finally {
            dispatch({
                type: 'UNLIKED_EXPERIENCE_RESET'
            })
        }
	}
}

function reset() {
	return {
		type: 'FAVORITES_RESET'
	}
}

export const FavoritesActions = {
	getFavorites,
	getMoreFavorites,
	likeExperience,
	unlikeExperience,
	reset,
}