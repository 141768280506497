import React, { useCallback, useEffect, useState } from 'react'
import { Form, Col, Row, Radio, Typography, Divider } from 'antd';
import { Loader } from 'components'
import { AnModal, AnButtonGradient, AnButton, AnInput, AnLabelForm } from 'components/an'

const UbicationFiltersModal = React.memo((props) => {
    const {
        onApplySearchLocationCriteria,
        onOpen,

        loadingLocalities,
        localitiesOptions,
        localitiesFavoritesOptions,

        getLocalities,

        currentLocality
    } = props;

    const [selectedLocality, setSelectedLocality] = useState(currentLocality ? currentLocality.id : 'all')
    const [localitiesFrequentlyOptions, setLocalitiesFrequentlyOptions] = useState([])
    const [inputSearch, setInputSearch] = useState(null)
    const [hasSearch, setHasSearch] = useState(false)

    useEffect(() => {
        onOpen()
    }, [onOpen])

    useEffect(() => {

        if (localitiesFavoritesOptions.length > 0) {
            setLocalitiesFrequentlyOptions(() => {
                let counter = [...localitiesFavoritesOptions]
                if (currentLocality) {
                    if (!(localitiesFavoritesOptions.filter(e => e.id === currentLocality?.id).length > 0)) {
                        counter.unshift({ id: currentLocality.id, name: currentLocality.nombre })
                    }
                    //reorder array and move current locality to first place
                    counter.sort((x, y) => { return x.id == currentLocality.id ? -1 : y.id == currentLocality.id ? 1 : 0 })
                }

                return counter
            })
        }
    }, [localitiesFavoritesOptions])

    const handleSelectLocality = useCallback((e) => {
        let { value } = e.target
        setSelectedLocality(value)
    })

    const handleOnSearchByText = useCallback((e) => {
        let { value } = e.target
        setInputSearch(value)
        if (value.length > 2) {
            getLocalities(null, {
                name: value,
                inactive: false
            })
            setHasSearch(true)
        } else {
            setHasSearch(false)
        }
    })

    const radioStyle = {
        display: 'block',
        width: '100%',
        border: '1px solid rgb(240, 240, 240)',
        borderRadius: '2em',
        padding: 10,
        marginBottom: 10,
    }

    return (
        <AnModal
            headerTitle="Ubicación"
            headerButtons={(
                <AnButtonGradient
                    type='primary'
                    shape='round'
                    size='large'
                    onClick={() => {
                        onApplySearchLocationCriteria(selectedLocality)
                    }}
                >
                    Aplicar
                </AnButtonGradient>
            )}
        >

            <Form
                size="large"
                layout="vertical"
                onFinish={() => { }}
            >
                <Row
                    xs={24}
                    gutter={[16, 20]}
                >
                    <Col xs={24}>
                        <AnInput
                            className="input primary right-space"
                            placeholder='Buscar localidad ...'
                            onChange={handleOnSearchByText}
                            value={inputSearch}
                        />
                    </Col>
                </Row>
                {loadingLocalities ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flex: 1,
                            height: '100%',
                        }}
                    >
                        <Loader loading={loadingLocalities} />
                    </div>
                ) : (
                        <>
                            {hasSearch &&
                                <>
                                    <Row
                                        xs={24}
                                        style={{
                                            background: '#fff',
                                        }}
                                    >
                                        <Col xs={24}>
                                            <AnLabelForm
                                                label='Resultados'
                                            />

                                            <Form.Item
                                                name="locality"
                                                valuePropName="value"
                                                noStyle
                                            >

                                                {(localitiesOptions.length > 0) ?
                                                    <Radio.Group
                                                        onChange={handleSelectLocality}
                                                        value={selectedLocality}
                                                    >
                                                        <Col xs={24}>
                                                            <Row>
                                                                {localitiesOptions.map(locality => {
                                                                    return (
                                                                        <Radio
                                                                            key={locality.id}
                                                                            style={radioStyle}
                                                                            value={locality.id}
                                                                            name={locality.name}
                                                                        >
                                                                            {locality.name}
                                                                        </Radio>
                                                                    )
                                                                })}
                                                            </Row>
                                                        </Col>
                                                    </Radio.Group>
                                                    :
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            marginBottom: 15
                                                        }}>
                                                        <Typography.Text type='secondary'>No hay resultados para esta busqueda</Typography.Text>
                                                    </div>
                                                }
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Divider
                                        style={{
                                            marginTop: 12
                                        }}
                                    />
                                </>}

                            <Row
                                xs={24}
                                style={{
                                    background: '#fff',
                                }}
                            >
                                <Col xs={24}>
                                    <Form.Item
                                        name="locality"
                                        valuePropName="value"
                                        noStyle
                                        initialValue={selectedLocality}
                                    >
                                        <Radio.Group
                                            onChange={handleSelectLocality}
                                            value={selectedLocality}
                                        >
                                            <Col xs={24}>
                                                <Row>
                                                    <Radio
                                                        style={radioStyle}
                                                        value='all'
                                                    >
                                                        Todos los lugares
                                                    </Radio>

                                                    {localitiesFrequentlyOptions.map(locality => {
                                                        return (
                                                            <Radio
                                                                key={locality.id}
                                                                style={radioStyle}
                                                                value={locality.id}
                                                                name={locality.name}
                                                            >
                                                                {locality.name}
                                                            </Radio>)
                                                    })}
                                                </Row>
                                            </Col>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>

                            </Row>
                        </>)}
            </Form>

        </AnModal >
    )
})

export default UbicationFiltersModal