import React, { useState, useCallback } from 'react'
import { Slider, Typography } from 'antd'
import moment from 'moment'

const AnSlider = React.memo((props) => {
    const {
        className,
        minLabel,
        maxLabel,
        value,
        onChange,
    } = props
    const valueToHumanizeDuration = useCallback((value) => {
        if (!value) {
            return '0 horas'
        }

        return moment.duration(value, 'h').format('d [days], h [hours] m [minutes]', {
            trunc: true,
            trim: "both",
            minValue: 0
        })
            .replace('days', 'días')
            .replace('day', 'día')
            .replace('hours', 'horas')
            .replace('hour', 'hora')
            .replace('minutes', 'minutos')
            .replace('minute', 'minuto')
    }, [moment])

    const [humanizeDuration, setHumanizeDuration] = useState(valueToHumanizeDuration(value))

    const onChangeDurationSlider = useCallback((value) => {
        onChange(value)
    }, [onChange])

    React.useEffect(() => {
        setHumanizeDuration(valueToHumanizeDuration(value))
    }, [value, setHumanizeDuration])

    React.useEffect(() => {
        if(!value && props.id==="createExperience_tolerance" ) {
            onChange(12)
        }
    },[])

    return (
        <div className={`an-slider ${className}`}>
            <div className="an-slider-value-label">
                <Typography.Text
                    type="secondary"
                >
                    {humanizeDuration}
                </Typography.Text>
            </div>

            <div className="an-slider-control-container">
                <Typography.Text
                    type='secondary'
                >
                    {minLabel}
                </Typography.Text>

                <Slider
                    onChange={onChangeDurationSlider}
                    value={value}
                    max={24}
                    tooltipVisible={false}
                    step={0.25}
                    marks={{
                        12: '',
                    }}
                />

                <Typography.Text
                    type='secondary'
                >
                    {maxLabel}
                </Typography.Text>
            </div>
        </div>
    )
})

AnSlider.defaultProps = {
    className: 'an-slider',
    minLabel: '0h',
    maxLabel: '1d',
    onChange: () => { }
}

export default AnSlider