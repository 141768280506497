import React, { useEffect, useCallback } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { withQueryParams, NumberParam, withDefault } from "use-query-params";
import { ExperienceActions, ExperienceEventsActions, BookingActions } from "redux/actions";
import { PageHeader, Loader, RequiredSessionMessage } from "components";

import BookingStepCalendar from "screens/BookingScreen/components/BookingStepA-Calendar";
import BookingStepSummary from "screens/BookingScreen/components/BookingStepB-Summary";
import BookingStepConfirmation from "screens/BookingScreen/components/BookingStepC-Confirmation";

const BookingScreen = React.memo((props) => {
    const {
        user,

        getExperience,
        loadingExperience,
        experience,

        getExperienceEvents,
        loadingExperienceEvents,
        experienceEvents,

        setQuery,
        query,

        setSelectedDay,
        selectedDay,

        setHourOption,
        hourOption,

        resetExperience,
        resetExperienceEvents,
        resetBooking,
    } = props;

    const location = useLocation();
    const history = useHistory();
    const experienceId = useParams().id;

    if (location.state) {
        if (location.state.selectedDay) {
            if (selectedDay !== location.state.selectedDay) {
                setSelectedDay(location.state.selectedDay);
            }
        }

        if (location.state.selectedHour) {
            if (hourOption !== location.state.selectedHour) {
                setHourOption(location.state.selectedHour);
            }
        }
    }

    /* ----------------
        Callbacks
    ------------------------ */

    // Handle next step
    const nextStep = useCallback(() => {
        setQuery({ step: query.step + 1 });
    }, [setQuery, query.step]);

    // Handle previous step
    const prevStep = useCallback(() => {
        history.goBack();
    }, [history]);

    // Handle next step
    const jumpStep = useCallback(
        (step) => {
            setQuery({ step: step });
        },
        [setQuery, query.step]
    );

    /* ----------------
        Effects
    ------------------------ */

    useEffect(() => {
        /*   if (user && user.role !== "Anonimous") { */
        if (experienceId) {
            getExperience(user.role, experienceId);
            getExperienceEvents(experienceId);
        }
        /*  } */

        //nothing to do, just clear state on unmount
        return () => {
            resetExperience();
            resetExperienceEvents();
            resetBooking();
        };
    }, [user, experienceId, getExperience, getExperienceEvents, resetExperience, resetExperienceEvents, resetBooking]);

    return (
        <>
            <div className="main">
                <PageHeader title="Reservas" subtitle="Flujo de reserva" />

                {/*  {user?.role !== 'Anonimous' ? ( */}
                <>
                    <Loader loading={loadingExperience || loadingExperienceEvents} />

                    {experience.id && (
                        <>
                            {query.step === 1 && (
                                <BookingStepCalendar
                                    experience={experience}
                                    experienceEvents={experienceEvents}
                                    nextStep={nextStep}
                                />
                            )}

                            {query.step === 2 && (
                                <BookingStepSummary
                                    experience={experience}
                                    jumpStep={jumpStep}
                                    prevStep={prevStep}
                                    nextStep={nextStep}
                                />
                            )}

                            {query.step === 3 && (
                                <BookingStepConfirmation experience={experience} jumpStep={jumpStep} />
                            )}
                        </>
                    )}
                </>
                {/*  ) : (
                    <RequiredSessionMessage
                        description="Para realizar una reserva tienes que iniciar sesión"
                    />
                )} */}
            </div>
        </>
    );
});

const mapStateToProps = (state) => ({
    user: state.context.user,
    experience: state.experience.data,
    experienceEvents: state.experienceEvents.experienceEvents,
    loadingExperience: state.experience.loading,
    loadingExperienceEvents: state.experience.loading,
    selectedDay: state.booking.selectedDay,
    hourOption: state.booking.hourOption,
});

const mapDispatchToProps = (dispatch) => ({
    getExperience: (typeUser, experienceId) => dispatch(ExperienceActions.getExperience(typeUser, experienceId)),
    getExperienceEvents: (experienceId) => dispatch(ExperienceEventsActions.getEvents(experienceId)),
    setSelectedDay: (selectedDay) => dispatch(BookingActions.setSelectedDay(selectedDay)),
    setHourOption: (hourOption) => dispatch(BookingActions.setHourOption(hourOption)),
    resetExperience: () => dispatch(ExperienceActions.reset()),
    resetExperienceEvents: () => dispatch(ExperienceEventsActions.reset()),
    resetBooking: () => dispatch(BookingActions.reset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withQueryParams(
        {
            step: withDefault(NumberParam, 0), // The current step
        },
        BookingScreen
    )
);
