import React, { useEffect, useState } from 'react'
import { ScrollToTop, Loader } from 'components'
import { AnButton } from 'components/an'
import {
    GoogleOutlined,
    FacebookFilled,
    MailFilled,
} from '@ant-design/icons'
import { Typography } from 'antd'

import Animate from 'rc-animate'

const LoginUserSignIn = React.memo((props) => {
    const {
        isMobile,
        image,
        loginUser,
        loadingList,
        history
    } = props

    const [show, setShow] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, isMobile ? 500 : 10)
    }, [setShow])

    const anButtonStyles = {
        justifyContent: 'center',
        margin: 8,
    }

    return (
        <>
            <ScrollToTop behavior="auto" />

            <Loader loading={!show} />

            <Animate
                transitionName="zoom"
                transitionAppear
            >
                {(show && (
                    <div className="login-screen-iwrap">
                        <div className='login-img'>
                            <img
                                src={image}
                                alt='login background spiderweb'
                            />
                        </div>
                        
                        <div className="login-comp-container">
                            <h1
                                style={{
                                    margin: 0
                                }}
                            >
                                Ingresar
                            </h1>

                            <Typography.Text
                                type='secondary'
                                style={{
                                    marginBottom: 15,
                                    textAlign: 'center'
                                }}
                            >
                                Debes tener una cuenta usuario aprobada (proveedor o promotor)
                            </Typography.Text>

                            <AnButton
                                block
                                ghost
                                type="primary"
                                shape="round"
                                size="large"
                                icon={<MailFilled />}
                                style={anButtonStyles}
                                disabled={loadingList.google || loadingList.facebook}
                                onClick={() => history.push(`/login/user/signin/email`)}
                            >
                                Ingresar con email
                            </AnButton>

                            <AnButton
                                block
                                ghost
                                type="primary"
                                shape="round"
                                size="large"
                                icon={<GoogleOutlined />}
                                style={anButtonStyles}
                                disabled={loadingList.facebook}
                                loading={loadingList.google}
                                onClick={() => loginUser('google')}
                            >
                                Ingresar con Google
                            </AnButton>
                            
                            <AnButton
                                block
                                ghost
                                type="primary"
                                shape="round"
                                size="large"
                                icon={<FacebookFilled />}
                                style={anButtonStyles}
                                loading={loadingList.facebook}
                                disabled={loadingList.google}
                                onClick={() => loginUser('facebook')}
                            >
                                Ingresar con Facebook
                            </AnButton>
                        </div>
                    </div>
                ))}
            </Animate>
        </>
    )
})

export default LoginUserSignIn