import React, { useEffect } from 'react'
import {
	Switch,
	Route,
	Redirect,
	useHistory,
} from "react-router-dom"

import { connect } from 'react-redux'
import { RegisterActions } from 'redux/actions'
import { isMobile } from 'react-device-detect'

import PostulationUserStepOne from 'screens/PostulationScreen/components/PostulationUserStepOne'
import PostulationUserNewPostulate from 'screens/PostulationScreen/components/PostulationUserNewPostulate'
import PostulationHasConfirm from 'screens/PostulationScreen/components/PostulationHasConfirm'
import PostulationConfirmForm from 'screens/PostulationScreen/components/PostulationConfirmForm'

import bkg from 'assets/images/login-bkg.png'

const PostulationScreen = React.memo((props) => {
	const {
		user,
		registerUserWithEmailAndPass,
		clearRegisterState,
		sendNewPostulation,
		loadingPostulation,
		postulationSuccess,
		startRegisterUser,
		registerLoadingList,

		setCodeInput,

		getPostulationDetails,
		postulation,
	} = props

	const history = useHistory()

	useEffect(() => {
		if (user.role === 'Usuario') {
			history.push('/search')
		}
	}, [user])

	useEffect(() => {
		/* Nothing to do.. */
		return () => {
			clearRegisterState()
		}
	}, [clearRegisterState]);

	return (
		<div className="main full">
			<div className="login-screen-container">
				<Switch>

					<Route
						exact
						path={`/postulation/user/confirm`}
					>
						<PostulationHasConfirm
							isMobile={isMobile}
							image={bkg}
						/>
					</Route>

					<Route
						exact
						path={`/postulation/confirm`}
					>
						<PostulationConfirmForm
							isMobile={isMobile}
							image={bkg}
							postulation={postulation}
							getPostulationDetails={getPostulationDetails}
							startRegisterUser={startRegisterUser}
							loadingList={registerLoadingList}
							history={history}
							setCodeInput={setCodeInput}
							registerUserWithEmailAndPass={registerUserWithEmailAndPass}
						/>
					</Route>

					<Route
						exact
						path={`/postulation/user/postulation`}
					>
						<PostulationUserNewPostulate
							user={user}
							isMobile={isMobile}
							image={bkg}
							history={history}
							sendNewPostulation={sendNewPostulation}
							loadingPostulation={loadingPostulation}
							postulationSuccess={postulationSuccess}
						/>
					</Route>


					<Route
						exact
						path={`/postulation`}
					>
						<PostulationUserStepOne
							isMobile={isMobile}
							history={history}
						/>
					</Route>

					<Redirect from='/postulation/*' to="/postulation" />
				</Switch>
			</div>
		</div>
	)
})


const mapStateToProps = state => ({
	user: state.context.user,
	loadingPostulation: state.register.loadingPostulation,
	postulationSuccess: state.register.postulationSuccess,
	registerLoadingList: state.register.loadingList,

	postulation: state.register.postulation,
})

const mapDispatchToProps = dispatch => ({
	sendNewPostulation: (values, type) => dispatch(RegisterActions.sendNewPostulation(values, type)),
	startRegisterUser: (provider, code) => dispatch(RegisterActions.startRegisterUser(provider, code)),
	registerUserWithEmailAndPass: (values) => dispatch(RegisterActions.registerUserWithEmailAndPass(values)),
	clearRegisterState: () => dispatch(RegisterActions.clearRegisterState()),
	getPostulationDetails: (id) => dispatch(RegisterActions.getPostulationDetails(id)),
	setCodeInput: (code) => dispatch(RegisterActions.setCodeInput(code)),

})

export default connect(mapStateToProps, mapDispatchToProps)(PostulationScreen)

