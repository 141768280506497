import { LocalityRestClient } from 'http/resources'
import axios from 'axios'

function getLocalities(params, criteria) {
    return dispatch => {
        dispatch({
            type: 'LOCALITIES_FETCH_LOCALITIES_START'
        });

        new LocalityRestClient().getLocalities(params, criteria)
            .then(response => {
                if (criteria.favorite) {
                    dispatch({
                        type: 'LOCALITIES_FETCH_LOCALITIES_FAVORITES_SUCCESS',
                        payload: {
                            localities: response.data.items.map((locality) => ({
                                id: locality.id,
                                name: locality.nombre,
                                inactive: locality.inactivo,
                                favorite: locality.favorito
                            }))
                        }
                    });
                } else {
                    dispatch({
                        type: 'LOCALITIES_FETCH_LOCALITIES_SUCCESS',
                        payload: {
                            localities: response.data.items.map((locality) => ({
                                id: locality.id,
                                name: locality.nombre,
                                inactive: locality.inactivo,
                                favorite: locality.favorito
                            }))
                        }
                    })
                }
            })
            .catch(error => {
                if (error instanceof axios.Cancel) {
                    // Nothing to do ...
                } else {
                    dispatch({
                        type: 'LOCALITIES_FETCH_LOCALITIES_FAILURE',
                    });
                }
            })
    }
}

export const LocalitiesActions = {
    getLocalities,
}