import React, { useState, useEffect, useCallback } from 'react'

import { Row, Form, Typography } from 'antd'
import { ScrollToTop, Loader } from 'components'
import { AnButton, AnButtonGradient, AnInput, AnLabelForm } from 'components/an'
import Animate from 'rc-animate'
import { withQueryParams, StringParam, withDefault } from 'use-query-params'
import {
    GoogleOutlined,
    FacebookFilled,
    MailFilled,
} from '@ant-design/icons'

import { Link } from 'react-router-dom'


const PostulationConfirmForm = React.memo((props) => {
    const {
        isMobile,
        image,
        query,
        getPostulationDetails,
        savePostulationCode,
        postulation,
        startRegisterUser,
        loadingList,
        setCodeInput,
        registerUserWithEmailAndPass,
        history,
    } = props

    const [show, setShow] = useState(isMobile ? false : true)
    const [showEmailForm, setShowEmailForm] = useState(false)
    const [hasNotPostulationId, setHasNotPostulationId] = useState(false)

    useEffect(() => {
        if (query.id) {
            getPostulationDetails(query.id)
        } else {
            setHasNotPostulationId(true)
        }
    }, [query])

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 500)
    }, [setShow])

    useEffect(() => {
        if (postulation.success) {
            setTimeout(() => {
                history.push('/search')
            }, 500)
        }

        if (postulation.error) {
            setHasNotPostulationId(true)
        }
    }, [postulation])

    const finishForm = useCallback((values) => {
        registerUserWithEmailAndPass(values)
    }, [query, savePostulationCode])

    const anButtonStyles = {
        width: '100%',
        justifyContent: 'center',
        margin: 8,
    }

    return (
        <>
            <ScrollToTop behavior="auto" />
            {postulation.loading ?

                <Loader loading={postulation.loading} />

                :
                hasNotPostulationId ?
                    <Animate
                        transitionName="zoom"
                        transitionAppear
                    >
                        {(show && (
                            <div className="login-screen-iwrap">
                                <div className='login-img'>
                                    <img
                                        src={image}
                                        alt='Login Spiderweb'
                                    />
                                </div>

                                <div className="login-comp-container">
                                    <h1
                                        style={{
                                            margin: 0
                                        }}
                                    >
                                        Finalizar postulación
                                </h1>

                                    <Typography.Text
                                        type='secondary'
                                        style={{
                                            marginBottom: 15,
                                            textAlign: 'center'
                                        }}

                                    >
                                        La postulación se encuentra en espera. Revisa tu correo electrónico, te enviaremos un link cuando tu postulación esté aprobada
                                </Typography.Text>

                                    <Link
                                        to='/postulation'
                                    >
                                        <AnButtonGradient
                                            type="primary"
                                            shape='round'
                                            size='large'

                                        >
                                            De acuerdo!
                                         </AnButtonGradient>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </Animate>
                    :
                    <Animate
                        transitionName="zoom"
                        transitionAppear
                    >
                        {(show && (
                            <div className="login-screen-iwrap">
                                <div className='login-img'>
                                    <img
                                        src={image}
                                        alt='Login Spiderweb'
                                    />
                                </div>

                                <div className="login-comp-container">
                                    <h1
                                        style={{
                                            margin: 0
                                        }}
                                    >
                                        Finalizar postulación
                                </h1>

                                    <Typography.Text
                                        type='secondary'
                                        style={{
                                            marginBottom: 15,
                                            textAlign: 'center'
                                        }}

                                    >
                                        Para finalizar la postulación necesitamos que nos confirmes el código de promotor que prefieras y te registres. Te brindamos una recomendación, pero podés cambiarla
                                </Typography.Text>

                                    <AnLabelForm
                                        label='Detalles de postulación:'
                                    />

                                    <div className="summary-items-container">

                                        <div className="summary-item-container">
                                            <div className="summary-title-container">
                                                <Typography.Text className="title">
                                                    Nombre:
                                            </Typography.Text>
                                            </div>

                                            <div className="summary-value-container">
                                                <Typography.Text
                                                    type='secondary'
                                                    ellipsis
                                                >
                                                    {postulation.data.name}
                                                </Typography.Text>
                                            </div>
                                        </div>

                                        <div className="summary-item-container">
                                            <div className="summary-title-container">
                                                <Typography.Text className="title">
                                                    Apellido:
                                            </Typography.Text>
                                            </div>

                                            <div className="summary-value-container">
                                                <Typography.Text
                                                    type='secondary'
                                                    ellipsis
                                                >
                                                    {postulation.data.surname}
                                                </Typography.Text>
                                            </div>
                                        </div>

                                        <div className="summary-item-container">
                                            <div className="summary-title-container">
                                                <Typography.Text className="title">
                                                    Email:
                                            </Typography.Text>
                                            </div>

                                            <div className="summary-value-container">
                                                <Typography.Text
                                                    type='secondary'
                                                    ellipsis
                                                >
                                                    {postulation.data.email}
                                                </Typography.Text>
                                            </div>
                                        </div>

                                    </div>
                                    <form
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '100%',
                                            marginBottom: 14
                                        }}
                                    >
                                        <AnLabelForm
                                            label='Código de promotor:'
                                        />

                                        <AnInput
                                            className="an-input round"
                                            placeholder='Ingresa un código de promotor'
                                            size='large'
                                            onChange={(e) => {
                                                let ALPHANUMBER_REGEX = /^[A-Za-z0-9]*$/
                                                if (ALPHANUMBER_REGEX.test(e.target.value)) {
                                                    setCodeInput(e.target.value)
                                                }
                                            }}
                                            value={postulation.data.code}
                                            style={{
                                                margin: 10
                                            }}
                                        />

                                        {!showEmailForm ?
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                    paddingTop: 30,
                                                }}
                                            >
                                                <AnButton
                                                    onClick={() => setShowEmailForm(true)}
                                                    type="primary"
                                                    ghost
                                                    shape="round"
                                                    icon={<MailFilled />}
                                                    style={anButtonStyles}
                                                    disabled={loadingList.google || loadingList.facebook || !(postulation?.data?.code?.length > 0)}
                                                    size="large"
                                                >
                                                    Registrarse con Email
                                                </AnButton>

                                                <AnButton
                                                    onClick={() => startRegisterUser('google')}
                                                    type="primary"
                                                    ghost
                                                    shape="round"
                                                    icon={<GoogleOutlined />}
                                                    size="large"
                                                    loading={loadingList.google}
                                                    disabled={loadingList.facebook || !(postulation?.data?.code?.length > 0)}
                                                    style={anButtonStyles}
                                                >
                                                    Registrarse con Google
                                                </AnButton>

                                                <AnButton
                                                    onClick={() => startRegisterUser('facebook')}
                                                    type="primary"
                                                    ghost
                                                    shape="round"
                                                    loading={loadingList.facebook}
                                                    disabled={loadingList.google || !(postulation?.data?.code?.length > 0)}
                                                    icon={<FacebookFilled />}
                                                    size="large"
                                                    style={anButtonStyles}
                                                >
                                                    Registrarse con Facebook
                                                </AnButton>
                                            </div>
                                            :
                                            <Form
                                                name="register"
                                                onFinish={finishForm}
                                                scrollToFirstError
                                                size='large'
                                                style={{
                                                    width: '100%',
                                                    paddingTop: 30,
                                                }}
                                            >
                                                <Form.Item
                                                    name="email"
                                                    initialValue={postulation.data.email}
                                                    rules={[
                                                        {
                                                            type: "email",
                                                            message: "No es un correo válido"
                                                        },
                                                        {
                                                            required: true,
                                                            message: "Ingresa un correo válido"
                                                        }
                                                    ]}
                                                >
                                                    <AnInput
                                                        placeholder='Email'
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name="password"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Ingresá una contraseña válida"
                                                        }
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <AnInput
                                                        placeholder='Contraseña'
                                                        type='password'
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name="confirm"
                                                    dependencies={["password"]}
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Ingresá una contraseña válida"
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(rule, value) {
                                                                if (!value || getFieldValue("password") === value) {
                                                                    return Promise.resolve();
                                                                }

                                                                return Promise.reject(
                                                                    "Las contraseñas que escribiste no coinciden"
                                                                );
                                                            }
                                                        })
                                                    ]}
                                                >
                                                    <AnInput
                                                        placeholder='Repetir contraseña'
                                                        type='password'
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    noStyle
                                                >
                                                    <Row
                                                        justify="center"
                                                    >
                                                        <AnButtonGradient
                                                            block
                                                            type="primary"
                                                            shape='round'
                                                            htmlType="submit"
                                                            loading={loadingList.email}
                                                            disabled={loadingList.email || !(postulation?.data?.code?.length > 0)}
                                                            style={{
                                                                maxWidth: '80%',
                                                                marginTop: 10,
                                                                margin: '0 auto'
                                                            }}
                                                        >
                                                            Registrarme
                                                        </AnButtonGradient>
                                                    </Row>
                                                    <Row
                                                        justify="center"
                                                    >

                                                        <AnButton
                                                            onClick={() => setShowEmailForm(false)}
                                                            type="primary"
                                                            ghost
                                                            shape="round"
                                                            icon={<ion-icon name="arrow-back-outline"></ion-icon>}
                                                            size="large"
                                                            style={{
                                                                ...anButtonStyles,
                                                                maxWidth: '80%'
                                                            }}
                                                        >
                                                            Volver a las opciones
                                                        </AnButton>

                                                    </Row>
                                                </Form.Item>
                                            </Form>}

                                    </form>
                                </div>
                            </div>
                        ))}
                    </Animate>
            }
        </>
    )
})

export default withQueryParams({
    id: withDefault(StringParam, ''), // postulation ID
}, PostulationConfirmForm)