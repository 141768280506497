import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { BookingActions, ClientBookingsActions, ExperienceActions, UIActions } from 'redux/actions'
import { AnButton } from 'components/an'
import { BottomDrawer, ExperienceListItem } from 'components'
import { Alert, Divider, Typography, Input, Tag } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { RatingIcon } from 'components/icons'
import { ScrollToTop, PageHeader, Loader, ExperienceImageCarousel } from 'components'
import { BookingStatus } from 'constants/index'
import moment from 'moment'

const BookingDetailsScreen = React.memo((props) => {
    const {
        user,
        getExperience,
        experience,
        loading,
        toggleBottomDrawer,
        getBookingStatus,
        bookingDetails,
        loadingExperience,
        cancelBooking,
        loadingCancel,
        successCancel,
        clearBookingState,
        clearExperienceState
    } = props

    const { id } = useParams()
    const history = useHistory()

    const [cancelReason, setCancelReason] = useState('')

    useEffect(() => {
        if (id) {
            getBookingStatus(id)
        }
    }, [id, getBookingStatus])

    useEffect(() => {
        if (bookingDetails?.experiencia) {
            getExperience(user?.role, bookingDetails?.experiencia.id)
        }
    }, [bookingDetails])

    useEffect(() => {
        if (successCancel) {
            history.goBack()
        }
        return () => {
            toggleBottomDrawer(false)
            clearBookingState()
            clearExperienceState()
        }
    }, [successCancel])

    //handlers

    const handleCancelBooking = () => {
        cancelBooking(id, cancelReason)
    }

    const handleCancelReason = (e) => {
        setCancelReason(e.target.value)
    }

    return (
        <>
            <ScrollToTop />

            <div className="main">
                <Loader
                    loading={loading || loadingExperience}
                />

                {(!loading && !loadingExperience) && (
                    <>
                        <PageHeader
                            title={experience.title}
                            subtitle={experience.provider.name}
                        />

                        {bookingDetails?.estado === 5 && (
                            <Alert
                                message="Aviso:"
                                description="Esta reserva se encuentra cancelada"
                                type="error"
                                showIcon
                                style={{
                                    marginTop: 15,
                                    borderRadius: 10,
                                    margin: 10
                                }}
                            />
                        )}

                        <ExperienceListItem
                            isBookingView
                            user={user}
                            isExperienceView={true}
                            experience={experience}
                            shareExperienceUrl={`${window.location.protocol}//${window.location.hostname}${window.location.port !== 80 && (window.location.port.length > 0) ? `:${window.location.port}` : ``}/experience/:id/share${user?.role === 'Usuario' ? `?promoter-nic=${user?.nic}` : ``}`}
                        />

                        <div className="experience-information-container">
                            <div className="experience-price">
                                <Typography.Title
                                    level={1}
                                    strong
                                    style={{
                                        fontFamily: 'Righteous',
                                        margin: 0
                                    }}
                                >
                                    {`$${experience.price}`}
                                </Typography.Title>

                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography.Text
                                        style={{
                                            padding: 5,
                                            fontFamily: 'Roboto',
                                            fontSize: 18,
                                        }}
                                    >
                                        {experience.rating}
                                    </Typography.Text>

                                    <RatingIcon />
                                </div>
                            </div>

                            <Divider />

                            <article className="experience-booking-details-list">
                                <Typography.Title
                                    className="experience-information-item-title"
                                    level={3}
                                >
                                    Detalles de reserva
                                </Typography.Title>

                                <div className="experience-booking-details-item">
                                    <ion-icon name="person-outline" />

                                    <Typography.Text strong>
                                        {bookingDetails?.cliente?.nombre}
                                    </Typography.Text>
                                </div>

                                <div className="experience-booking-details-item">
                                    <ion-icon name="create-outline" />

                                    <Typography.Text strong>
                                        {`Creada el día ${moment(moment.utc(bookingDetails.creado).toDate()).format("DD/MM/yyyy")} a las ${moment(moment.utc(bookingDetails.creado).toDate()).format("HH:mm")}`}
                                    </Typography.Text>

                                </div>

                                <div className="experience-booking-details-item">
                                    <ion-icon name="today-outline" />

                                    <Typography.Text strong>
                                        {`El día  ${moment.utc(bookingDetails.fecha).format('DD/MM/yyyy')}`}
                                    </Typography.Text>
                                </div>

                                <div className="experience-booking-details-item">
                                    <ion-icon name="time-outline" />

                                    <Typography.Text strong>
                                        {`A las ${moment.utc(bookingDetails.fecha).format('HH:mm')}`}
                                    </Typography.Text>
                                </div>

                                <div className="experience-booking-details-item">
                                    <ion-icon name="people-outline" />

                                    <Typography.Text strong>
                                        {`Hasta ${experience.peopleQuantity * bookingDetails.cantidad} personas`}
                                    </Typography.Text>
                                </div>

                                <div className="experience-booking-details-item">
                                    <ion-icon name="checkmark-circle-outline" />

                                    {bookingDetails?.estado > 0 ? (
                                        <Tag
                                            color={BookingStatus[bookingDetails.estado].color}
                                            style={{
                                                borderRadius: 12
                                            }}
                                        >
                                            {BookingStatus[bookingDetails.estado].label}
                                        </Tag>
                                    ) : (
                                            <Tag
                                                color={BookingStatus[1].color}
                                                style={{
                                                    borderRadius: 12
                                                }}
                                            >
                                                No hay status
                                            </Tag>
                                        )}
                                </div>

                                <div className="experience-booking-details-item">
                                    <ion-icon name="cash-outline" />

                                    <Typography.Text strong>
                                        {`Total: $${bookingDetails.total}`}
                                    </Typography.Text>
                                </div>
                            </article>

                            <Divider />

                            <div className="experience-details">
                                <Typography.Title
                                    className="experience-information-item-title"
                                    level={3}
                                >
                                    Detalles
                                </Typography.Title>

                                <div className="experience-details-list">
                                    {!!experience?.duration && moment.duration(experience.duration).asHours() > 0 && (
                                        <div className="experience-details-item">
                                            <ion-icon name="hourglass-outline" />

                                            <Typography.Text strong>
                                                {
                                                    moment.duration(
                                                        moment.duration(experience.duration).asHours(), 'h'
                                                    ).format('d [days], h [hours] m [minutes]', {
                                                        trunc: true,
                                                        trim: "both",
                                                        minValue: 0
                                                    })
                                                        .replace('days', 'días')
                                                        .replace('day', 'día')
                                                        .replace('hours', 'horas')
                                                        .replace('hour', 'hora')
                                                        .replace('minutes', 'minutos')
                                                        .replace('minute', 'minuto')
                                                }
                                            </Typography.Text>
                                        </div>
                                    )}

                                    {!!experience?.language?.name && (
                                        <div className="experience-details-item">
                                            <ion-icon name="language-outline" />

                                            <Typography.Text strong>
                                                {experience.language.name}
                                            </Typography.Text>
                                        </div>
                                    )}

                                    {!!experience?.peopleQuantity && (
                                        <div className="experience-details-item">
                                            <ion-icon name="people-outline" />

                                            <Typography.Text strong>
                                                {experience.peopleQuantity === 1
                                                    ? `1 persona`
                                                    : `${experience.peopleQuantity} personas`
                                                }
                                            </Typography.Text>
                                        </div>
                                    )}

                                    {!!experience?.direction?.locate && !!experience?.direction?.locate?.provincia && (
                                        <div className="experience-details-item">
                                            <ion-icon name="earth-outline"></ion-icon>

                                            <Typography.Text strong>
                                                {experience.direction?.locate
                                                    ? `${experience.direction?.locate?.nombre}, ${experience.direction?.locate?.provincia?.nombre}`
                                                    : `No hay detalles`
                                                }
                                            </Typography.Text>
                                        </div>
                                    )}

                                    {!!experience?.direction?.street && (
                                        <div className="experience-details-item">
                                            <ion-icon name="location-outline" />

                                            <Typography.Text strong>
                                                {`${experience.direction.street} ${!!experience?.direction?.number ? ` ${experience.direction.number}` : ''} ${!!experience?.direction?.depto ? ` ${experience.direction.depto}` : ''}`}
                                            </Typography.Text>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <Divider />

                            <div className="experience-description">
                                <Typography.Title
                                    className="experience-information-item-title"
                                    level={3}
                                >
                                    Descripción
                                </Typography.Title>

                                <Typography.Paragraph
                                    ellipsis={{
                                        rows: 5,
                                        expandable: true,
                                        symbol: 'ver más'
                                    }}
                                    style={{
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    {experience?.details
                                        ? experience.details
                                        : 'No hay detalles'
                                    }
                                </Typography.Paragraph>
                            </div>


                            {(experience.detailsEn && (
                                <>
                                    <Divider />

                                    <div className="experience-description">
                                        <Typography.Title
                                            className="experience-information-item-title"
                                            level={3}
                                        >
                                            Description (en)
                                        </Typography.Title>

                                        <Typography.Paragraph
                                            ellipsis={{
                                                rows: 5,
                                                expandable: true,
                                                symbol: 'ver más'
                                            }}
                                            style={{
                                                fontFamily: 'Roboto'
                                            }}
                                        >
                                            {experience.detailsEn}
                                        </Typography.Paragraph>
                                    </div>
                                </>
                            ))}

                            <Divider />

                            <article className="experience-result-details">
                                <Typography.Title
                                    level={3}
                                >
                                    Detalle de pago
                                </Typography.Title>

                                <div>
                                    {bookingDetails.tipoReserva === 1 && (
                                        <Alert
                                            message="Aviso:"
                                            description={`Esta reserva se realizó con pago completo por un total de $${bookingDetails?.pago?.montos?.total}`}
                                            type="warning"
                                            showIcon
                                            style={{
                                                marginTop: 15,
                                                borderRadius: 10
                                            }}
                                        />
                                    )}

                                    {bookingDetails.tipoReserva === 2 && (
                                        <Alert
                                            message="Aviso:"
                                            description={`Esta reserva se realizó con pago parcial de $${bookingDetails?.pago?.montos?.total}, restan abonar $${bookingDetails?.total - bookingDetails?.pago?.montos?.total} en el lugar de la experiencia`}
                                            type="warning"
                                            showIcon
                                            style={{
                                                marginTop: 15,
                                                borderRadius: 10
                                            }}
                                        />
                                    )}

                                    {bookingDetails.tipoReserva === 3 && (
                                        <Alert
                                            message="Aviso:"
                                            description="Esta reserva se realizó sin pago, lo que significa que este se realizará en el lugar de la experiencia"
                                            type="warning"
                                            showIcon
                                            style={{
                                                marginTop: 15,
                                                borderRadius: 10
                                            }}
                                        />
                                    )}
                                </div>
                            </article>

                            {bookingDetails?.estado === 2 && (
                                <>
                                    <Divider />

                                    <div className="experience-chat-button-container">
                                        <AnButton
                                            ghost
                                            type="primary"
                                            shape="round"
                                            size='large'
                                            onClick={() => toggleBottomDrawer(true)}
                                            style={{
                                                margin: 5
                                            }}
                                        >
                                            Cancelar reserva
                                        </AnButton>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}

                <BottomDrawer
                    height={340}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center'
                        }}
                    >
                        <Typography.Title
                            level={4}
                        >
                            ¿Está seguro de cancelar la reserva?
                        </Typography.Title>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '10px 0',
                                width: '100%'
                            }}
                        >
                            <Typography.Text
                                type="secondary"
                                style={{
                                    margin: 5
                                }}
                            >
                                ¿Quisieras agregar un motivo?
                            </Typography.Text>

                            <Input.TextArea
                                rows={3}
                                onChange={handleCancelReason}
                                value={cancelReason}
                                placeholder='Detalle su motivo de cancelación..'
                                disabled={loadingCancel}
                                style={{
                                    borderRadius: 8,
                                    padding: '8px 11px'
                                }}
                            />

                            <AnButton
                                ghost
                                type="primary"
                                shape="round"
                                size='large'
                                loading={loadingCancel}
                                onClick={handleCancelBooking}
                                style={{
                                    margin: 10
                                }}
                            >
                                Cancelar reserva
                            </AnButton>

                            {/*  {bookingDetails.tipoReserva !== 3 && (
                                <Typography.Text
                                    type="secondary"
                                >
                                    Esta reserva se puede cancelar hasta el día 29/11/2020
                                </Typography.Text>
                            )} */}
                        </div>
                    </div>
                </BottomDrawer>
            </div>
        </>
    )
})

const mapStateToProps = (state) => ({
    user: state.context.user,
    loading: state.experience.loading,
    experienceId: state.clientBookings.experienceId,
    loadingCancel: state.clientBookings.loadingCancel,
    successCancel: state.clientBookings.successCancel,
    bookingDetails: state.booking.bookingDetails,

    loadingExperience: state.experience.loading,
    experience: state.experience.data,
})

const mapDispatchToProps = (dispatch) => ({
    getBookingStatus: (id) => dispatch(BookingActions.getBookingStatus(id)),
    getExperience: (typeUser, experienceId) => dispatch(ExperienceActions.getExperience(typeUser, experienceId)),
    cancelBooking: (bookingId, cancelReason) => dispatch(ClientBookingsActions.cancelBooking(bookingId, cancelReason)),
    clearBookingState: () => dispatch(ClientBookingsActions.clearState()),
    clearExperienceState: () => dispatch(ExperienceActions.reset()),
    toggleBottomDrawer: (bool) => dispatch(UIActions.toggleBottomDrawer(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailsScreen);