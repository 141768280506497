import React, { useState, useEffect } from 'react'
import { Form, Typography } from 'antd'
import { AnButtonGradient, AnInput } from 'components/an'
import { ScrollToTop, Loader } from 'components'
import Animate from 'rc-animate'

const LoginResetPassword = React.memo((props) => {
	const {
		isMobile,
		image,
		history,
		confirmPasswordReset,
		resetPassword,
		oobCode,
	} = props

	const [show, setShow] = useState(isMobile ? false : true)

	useEffect(() => {
		if (resetPassword.success) {
			setTimeout(() => {
				history.push('/login/user/signin/email')
			}, 1000)
		}
	}, [resetPassword.success])

	useEffect(() => {
		setTimeout(() => {
			setShow(true)
		}, 500)
	}, [setShow])

	const onFinish = values => {
		confirmPasswordReset(oobCode, values.newPasswordConfirm)
	}

	return (
		<>
			<ScrollToTop behavior="auto" />

			<Loader loading={!show} />

			<Animate
				transitionName="zoom"
				transitionAppear
			>
				{(show && (
					<div className="login-screen-iwrap">
						<div className='login-img'>
							<img
								src={image}
								alt='Login Spiderweb'
							/>
						</div>

						<div className="login-comp-container">
							<h1
								style={{
									margin: 0
								}}
							>
								Ingresar
                            </h1>

							<Typography.Text
								type='secondary'
								style={{
									marginBottom: 15,
									textAlign: 'center'
								}}
							>
								Recupera tu contraseña
                            </Typography.Text>

							<Form
								block
								name="register"
								onFinish={onFinish}
								scrollToFirstError
								size='large'
								style={{
									width: '100%'
								}}
							>
								<Form.Item
									name='newPassword'
									rules={[
										{
											required: true,
											message: "Ingresá una contraseña válida"
										},
									]}
									hasFeedback
								>
									<AnInput
										placeholder='Contraseña nueva'
										type='password'
									/>
								</Form.Item>

								<Form.Item
									name='newPasswordConfirm'
									rules={[
										{
											required: true,
											message: "Ingresá una contraseña válida"
										},
										({ getFieldValue }) => ({
											validator(rule, value) {
												if (!value || getFieldValue("newPassword") === value) {
													return Promise.resolve();
												}

												return Promise.reject(
													"Las contraseñas no coinciden"
												);
											}
										})
									]}
									hasFeedback
								>
									<AnInput
										placeholder='Repetir contraseña nueva'
										type='password'
									/>
								</Form.Item>

								<Form.Item
									noStyle
								>
									<AnButtonGradient
										block
										type="primary"
										shape='round'
										loading={resetPassword.loading}
										htmlType="submit"
									>
										Cambiar contraseña
                                    </AnButtonGradient>

								</Form.Item>

							</Form>
						</div>
					</div>
				))}
			</Animate>
		</>
	)
})

export default LoginResetPassword