const defaultState = {
    page: 0,
    pageCount: 10,
    loading: false,
    loadingMoreResults: false,
    error: false,
    data: [],
    totalItems: 0,
    hasMore: true,
    
    // ...
    loadingCancel: false,
    successCancel: false
};


export default function reducer(state = defaultState, action) {
    switch (action.type) {

        case 'CLIENT_BOOKINGS_FETCH_BOOKINGS_START':
            return Object.assign({}, state, {
                loading: true,
                error: false,
            })

        case 'CLIENT_BOOKINGS_FETCH_BOOKINGS_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                data: [].concat(action.payload.bookings),
                pageCount: action.payload.pageCount,
                totalItems: action.payload.totalItems,
                page: action.payload.page,
                hasMore: action.payload.hasMore,
            })

        case 'CLIENT_BOOKINGS_FETCH_BOOKINGS_FAILURE':
            return Object.assign({}, state, {
                loading: false,
                error: true,
            })

        
        case 'CLIENT_BOOKINGS_BOOKINGS_FETCH_MORE_BOOKINGS_START':
            return Object.assign({}, state, {
                loadingMoreResults: true,
            })


        case 'CLIENT_BOOKINGS_BOOKINGS_FETCH_MORE_BOOKINGS_SUCCESS':
            return Object.assign({}, state, {
                data: [].concat(state.data).concat(action.payload.bookings),
                pageCount: action.payload.pageCount,
                totalItems: action.payload.totalItems,
                page: action.payload.page,
                loadingMoreResults: false,
                hasMore: action.payload.hasMore,
            })

        case 'CLIENT_BOOKINGS_BOOKINGS_FETCH_MORE_BOOKINGS_FAILURE':
            return Object.assign({}, state, {
                loadingMoreResults: false,
                error: true,
            })

        
        case 'CLIENT_BOOKINGS_CANCEL_START':
            return Object.assign({}, state, {
                loadingCancel: true,
                successCancel: false,
            })

        case 'CLIENT_BOOKINGS_CANCEL_SUCCESS':
            return Object.assign({}, state, {
                loadingCancel: false,
                successCancel: true,

            })

        case 'CLIENT_BOOKINGS_CANCEL_FAILURE':
            return Object.assign({}, state, {
                loadingCancel: false,
                successCancel: false,
            })

        case 'CLIENT_BOOKINGS_CLEAR_STATE':
            return Object.assign({}, defaultState)


        default:
            return state;
    }
}
