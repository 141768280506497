import UserRestClient from 'http/resources/UserRestClient'
import ErrorManager from 'services/error-manager'
import auth from 'session/api'
import JWT from 'services/jwt';
import { functions } from 'config/firebase'
import Axios from 'axios';

function getAccessToken(dispatch) {
	new UserRestClient(false).getAccessToken()
		.then(res => {
			// Store the access token
			localStorage.setItem('accessToken', res.data.value)

			dispatch({
				type: 'REGISTER_COMPLETE_REGISTER_SUCCESS'
			})

			dispatch({
				type: 'CONTEXT_SET',
				payload: {
					user: JWT.parseToken(res.data.value)
				}
			})

		})
		.catch(err => {
			dispatch({
				type: 'REGISTER_COMPLETE_REGISTER_FAILURE'
			})
			dispatch({
				type: 'UI_ALERT_SET_ERROR',
				payload: {
					error: ErrorManager.toMessage(err)
				}
			})
		})
}

function verifyUserEmail(email, dispatch, getState) {
	new UserRestClient().verifyUserEmail({
		email
	})
		.then(res => {
			dispatch({
				type: 'REGISTER_USER_ALREADY_EXISTS',
			})
			dispatch({
				type: 'UI_ALERT_SET_ERROR',
				payload: {
					error: 'El usuario ya existe'
				}
			})
		})
		.catch(err => {
			//if response's 404 user can register
			completeRegisterNewUser(dispatch, getState)
		})
}

function completeRegisterNewUser(dispatch, getState) {

	const { code } = getState().register.postulation.data
	new UserRestClient(false).registerNewUser(code)
		.then(res => {
			dispatch({
				type: 'REGISTER_COMPLETE_REGISTER_SUCCESS'
			})
			dispatch({
				type: 'UI_ALERT_SET_SUCCESS',
				payload: {
					message: 'Se registró correctamente'
				}
			})
			getAccessToken(dispatch)
		})
		.catch(err => {
			auth.currentUser().delete()
				.then((res) => {
					dispatch({
						type: 'REGISTER_COMPLETE_REGISTER_FAILURE',
					})
					dispatch({
						type: 'UI_ALERT_SET_ERROR',
						payload: {
							error: ErrorManager.toMessage(err)
						}
					})
				})
				.catch((error) => {
					dispatch({
						type: 'REGISTER_COMPLETE_REGISTER_FAILURE',
					})
					dispatch({
						type: 'UI_ALERT_SET_ERROR',
						payload: {
							error: ErrorManager.toMessage(new Error('Ocurrió un problema al registrar. Intenta nuevamente o contacta a soporte'))
						}
					})
				});

		})

}

function registerUserWithEmailAndPass(values) {
	return async (dispatch, getState) => {
		dispatch({
			type: 'REGISTER_SET_LOADING',
			payload: {
				loadingList: {
					email: true,
				}
			}
		})
		auth.signUpWithEmailAndPass(values.email, values.password)
			.then(res => {
				//Here we set emailVerified prop to 'true' in Firebase User Object
				return Axios.post(
					`${process.env.REACT_APP_FIREBASE_FUNCTIONS_HTTP_URL}/verify-email`,
					{
						uid: res.user.uid
					})
			})
			.then(() => {
				verifyUserEmail(values.email, dispatch, getState)
			})
			.catch(err => {
				if (auth.currentUser()) {
					auth.currentUser().delete()
						.then((res) => {
							dispatch({
								type: 'REGISTER_FAILURE',
							})
							dispatch({
								type: 'UI_ALERT_SET_ERROR',
								payload: {
									error: ErrorManager.toMessage(err)
								}
							})
						})
						.catch(err => {
							dispatch({
								type: 'REGISTER_FAILURE',
							})
							dispatch({
								type: 'UI_ALERT_SET_ERROR',
								payload: {
									error: ErrorManager.toMessage(new Error('Ocurrió un problema al registrar. Intenta nuevamente o contacta a soporte'))
								}
							})
						})
				} else {
					dispatch({
						type: 'REGISTER_FAILURE',
					})
					dispatch({
						type: 'UI_ALERT_SET_ERROR',
						payload: {
							error: ErrorManager.toMessage(err)
						}
					})
				}
			})
	}
}

function startRegisterUser(provider) {
	return async (dispatch, getState) => {

		if (provider === 'facebook') {
			dispatch({
				type: 'REGISTER_SET_LOADING',
				payload: {
					loadingList: {
						facebook: true,
					}
				}
			})
			//handle register with Facebook provider

			auth.signInWithFacebook()
				.then(res => {
					verifyUserEmail(res.user.email, dispatch, getState)
				})
				.catch(err => {
					dispatch({
						type: 'REGISTER_FAILURE',
					})
					dispatch({
						type: 'UI_ALERT_SET_ERROR',
						payload: {
							error: ErrorManager.toMessage(err)
						}
					})
				})
		} else if (provider === 'google') {
			//handle register with Google provider
			dispatch({
				type: 'REGISTER_SET_LOADING',
				payload: {
					loadingList: {
						google: true,
					}
				}
			})
			await auth.signInWithGoogle()
				.then(res => {
					verifyUserEmail(res.user.email, dispatch, getState)
				})
				.catch(err => {
					dispatch({
						type: 'REGISTER_FAILURE',
					})
					dispatch({
						type: 'UI_ALERT_SET_ERROR',
						payload: {
							error: ErrorManager.toMessage(err)
						}
					})
				})
		}
	}
}

function sendNewPostulation(values, type) {
	return dispatch => {
		dispatch({
			type: 'NEW_POSTULATION_LOADING'
		})
		new UserRestClient().sendNewPostulation(
			{
				email: values.email,
				name: values.name,
				surname: values.surname,
				phone: values.phone ? values.phone.toString() : null,
				parentCode: values.parentCode,
				moreInfo: values.moreInfo,
				type
			})
			.then(() => {
				dispatch({
					type: 'NEW_POSTULATION_SUCCESS'
				})
			})
			.catch(err => {
				dispatch({
					type: 'NEW_POSTULATION_FAILURE'
				})
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(err)
					}
				})
			})
	}
}

function getPostulationDetails(id) {
	return dispatch => {
		dispatch({
			type: 'POSTULATION_FETCH_POSTULATION_START'
		})
		new UserRestClient().getPostulationById(id)
			.then((response) => {
				dispatch({
					type: 'POSTULATION_FETCH_POSTULATION_SUCCESS',
					payload: {
						id,
						postulation: response.data
					}
				})
			})
			.catch(err => {
				dispatch({
					type: 'POSTULATION_FETCH_POSTULATION_FAILED'
				})
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(err)
					}
				})
			})
	}
}

function setCodeInput(code) {
	return {
		type: 'POSTULATION_SET_CODE_INPUT',
		payload: {
			code
		}
	}
}

function clearRegisterState() {
	return {
		type: 'REGISTER_CLEAR_STATE'
	}
}

export const RegisterActions = {
	startRegisterUser,
	registerUserWithEmailAndPass,
	sendNewPostulation,
	clearRegisterState,
	getPostulationDetails,
	setCodeInput,
}
