import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { connect } from 'react-redux'
import { Spin, Typography, Input, Button } from 'antd'
import { MessagesActions } from 'redux/actions'
import { withQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params'
import RenderChatMessages from 'components/chat/RenderChatMessages'
import { usePrevious } from 'hooks/usePrevious'
import { PageHeader, RequiredSessionMessage } from 'components'
import { useInterval } from 'hooks/useInterval'

const MessageScreen = React.memo((props) => {
    const {
        context,
        user,
        getMessages,
        loading,
        messagesList,
        query,
        sendMessage,
        sending,
        setConversationRead,
        clearState,
        sendingSuccess,
        getMoreMessages,
        loadingMoreResults,
        hasMore,
        page,

        visibilityWindow,
    } = props

    const prevSending = usePrevious(sending)

    const [messageInfo, setMessageInfo] = useState(null)
    const [messageInput, setMessageInput] = useState(null)

    //updaters
    useEffect(() => {
        if (user?.role !== 'Anonimous') {
            getMessages(true, query.e, query.p, query.u, query.t)
        }
        return () => {
            clearState()
        }
    }, [query, user])

    useEffect(() => {
        if (messagesList.length > 0 && !loading) {
            setMessageInfo(messagesList[0])
        }
    }, [messagesList, loading])

    useEffect(() => {
        if (messageInfo) {
            if (messageInfo?.destinatario?.id == user.sub && !messageInfo?.leido) {
                setConversationRead(messageInfo.id, user.role)
            }
        }
    }, [messageInfo])

    useEffect(() => {
        if (!(prevSending == undefined) &&
            prevSending !== sending &&
            !sending) {
            getMessages(false, query.e, query.p, query.u, query.t)
        }
    }, [query, sending])

    useEffect(() => {
        if (sendingSuccess) {
            setMessageInput(null)
        }
    }, [sendingSuccess])

    //polling messages
    useInterval(() => {
        if (user?.role !== 'Anonimous' && visibilityWindow) {
            getMessages(false, query.e, query.p, query.u, query.t)
        }
    }, 7000)

    //handlers

    const handleMessageInput = (e) => {
        setMessageInput(e.target.value)
    }

    const pressSendMessage = () => {
        let chatData = messagesList[0]
        let addressee = {
            id: chatData.destinatario.id === user.sub ? chatData.remitente.id : chatData.destinatario.id,
            type: chatData.destinatario.id === user.sub ? chatData.remitente.tipo : chatData.destinatario.tipo,
        }

        sendMessage(query.e, addressee, messageInput)
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}
        >{user?.role !== 'Anonimous' ?
            (<>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            padding: 10,
                            borderBottom: '1px solid #ddd',
                            position: 'sticky',
                            zIndex: 100,
                            top: 0,
                            backgroundColor: '#fff',
                        }}>
                        <Typography.Title
                            style={{
                                fontSize: 16,
                                margin: 0
                            }}
                            ellipsis>
                            {messageInfo ?
                                messageInfo.experiencia?.nombre
                                :
                                'Cargando..'}
                        </Typography.Title>
                        <Typography.Text
                            type="secondary"
                            ellipsis
                            style={{
                                fontSize: 11,
                                margin: 0
                            }}>
                            {messageInfo ?
                                messageInfo.destinatario.id !== user.sub ?
                                    messageInfo.destinatario?.nombre
                                    :
                                    messageInfo.remitente?.nombre
                                :
                                'Cargando..'}
                        </Typography.Text>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        flex: 1,
                        padding: '20px 0px',
                        background: '#fff',
                    }}
                >
                    {loading ?
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%',
                            }}
                        >
                            <Spin
                                size='large'
                                style={{
                                    padding: 20
                                }}
                            />
                        </div >
                        :
                        <RenderChatMessages
                            user={user}
                            messagesList={messagesList}
                            getMoreMessages={getMoreMessages}
                            loadingMoreResults={loadingMoreResults}
                            hasMore={hasMore}
                            page={page}
                            experienceId={query.e}
                            userProviderId={query.p}
                            senderId={query.u}
                            type={query.t}
                        />}
                </div>

                <div
                    style={{
                        width: 'inherit',
                        maxWidth: 'inherit',
                        backgroundColor: '#F4F4F4',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 10,
                    }}
                >
                    {(user.role === 'Cliente' ||
                        (user.role === 'Usuario' && context?.impersonated)) ?
                        <>
                            <Input.TextArea
                                value={messageInput}
                                onChange={handleMessageInput}
                                placeholder='Escribe tu mensaje..'
                                style={{
                                    borderRadius: 8,
                                    padding: '8px 11px'
                                }}
                                autoSize={{ minRows: 1, maxRows: 4 }}
                            />

                            <Button
                                type="link"
                                onClick={pressSendMessage}
                                loading={sending}
                                disabled={!messageInput || sending || loading}
                            >
                                Enviar
						        </Button>
                        </>
                        :
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column'
                            }}>

                            <Typography.Title
                                style={{
                                    fontSize: 16,
                                    textAlign: 'center',
                                    margin: 0
                                }}
                                ellipsis
                            >
                                Este chat está en modo sólo lectura
                                </Typography.Title>

                            <Typography.Text
                                type="secondary"
                                ellipsis
                                style={{
                                    fontSize: 11,
                                    textAlign: 'center',
                                    margin: 0
                                }}
                            >
                                No podrás responder ni consultar
                                </Typography.Text>
                        </div>}
                </div>
            </>)
            :
            <>
                <PageHeader
                    title="Mensaje"
                    subtitle="Leer conversación"
                />
                <RequiredSessionMessage
                    description="Para ver esta conversación tienes que iniciar sesión"
                />
            </>}  </div>
    )
})

const mapStateToProps = (state) => ({
    context: state.context,
    user: state.context.user,
    loading: state.messages.loading,
    messagesList: state.messages.messagesList,
    sending: state.messages.sending,
    sendingSuccess: state.messages.sendingSuccess,
    loadingMoreResults: state.messages.loadingMoreResults,
    page: state.messages.page,
    hasMore: state.messages.hasMore,

    //ui
    visibilityWindow: state.ui.visibilityWindow,
})

const mapDispatchToProps = (dispatch) => ({
    getConversationsList: () => dispatch(MessagesActions.getConversationsList()),
    getMessages: (withLoading, experienceId, userProviderId, senderId, type) => dispatch(MessagesActions.getMessages(withLoading, experienceId, userProviderId, senderId, type)),
    sendMessage: (experienceId, addressee, message) => dispatch(MessagesActions.sendMessage(experienceId, addressee, message)),
    setConversationRead: (messageId) => dispatch(MessagesActions.setConversationRead(messageId)),
    clearState: () => dispatch(MessagesActions.clearState()),
    getMoreMessages: (experienceId, userProviderId, senderId, type, page) => dispatch(MessagesActions.getMoreMessages(experienceId, userProviderId, senderId, type, page)),
});


export default connect(mapStateToProps, mapDispatchToProps)(
    withQueryParams({
        e: withDefault(StringParam, ""), // The experience ID 
        p: withDefault(StringParam, ""), // The provider user ID
        u: withDefault(StringParam, ""), // The user who start conversation ID
        t: withDefault(NumberParam, "") // Type of user { 1 or 2 }
    }, MessageScreen)
)