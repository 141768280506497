import React, { useEffect, useRef } from 'react'
import { Input } from 'antd'

const AnInput = React.memo((props) => {
    const {
        className,
        shape,
        placeholder,
        onChange,
        value,
        size,
        type,
        style,
        name,
        disabled,
        onPressEnter,
        addonBefore,
        prefix,
        noPaste,
    } = props

    const ref = useRef()

    useEffect(() => {
        if (noPaste) {
            ref.current.input.onpaste = e => {
                e.preventDefault()
                return false
            }
        }

    }, [ref, noPaste])

    if (type === 'password') {
        return (
            <Input.Password
                ref={ref}
                className={`${className} ${shape}`}
                shape="round"
                placeholder={placeholder}
                name={name}
                size={size}
                onChange={onChange}
                value={value}
                disabled={disabled}
                onPressEnter={onPressEnter}
                style={{
                    borderRadius: '6em',

                    ...style
                }}

                {...props}
            />
        )
    } else if (type === 'number') {
        return (
            <Input
                ref={ref}
                className={`${className} round`}
                shape={'round'}
                placeholder={placeholder}
                name={name}
                size={size}
                onChange={onChange}
                value={value}
                disabled={disabled}
                addonBefore={addonBefore}
                type={type}
                prefix={prefix}
                style={{
                    borderRadius: '6em',

                    ...style
                }}

                {...props}
            />
        )
    } else {
        return (
            <Input
                ref={ref}
                className={`${className} ${shape}`}
                placeholder={placeholder}
                name={name}
                size={size}
                onChange={onChange}
                value={value}
                disabled={disabled}
                addonBefore={addonBefore}
                type={type}
                prefix={prefix}
                style={{
                    borderRadius: '6em',

                    ...style
                }}

                {...props}
            />
        )
    }
})

AnInput.defaultProps = {
    className: 'an-input',
    noPaste: false
    // shape: '',
}

export default AnInput