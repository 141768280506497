import React, { useEffect, useState } from 'react'
import { Badge, Tag, Typography } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'
import ContentLoader from 'react-content-loader'

const ConversationItem = React.forwardRef((props, ref) => {

	const {
		user,
		loading,
		details = {},
	} = props

	if (loading) {
		return (
			<ContentLoader
				height={100}
				style={{
					width: '100%'
				}}>
				<rect x="0" y="0" rx='8px' ry='8px' width="100%" height="100px" />
			</ContentLoader>)
	} else {
		return (
			<Link
				ref={ref}
				id={details?.id}
				to={`messages/${details?.id}?e=${details?.experiencia.id}&p=${details?.usuarioProveedor?.id}&u=${details?.usuario?.id}&t=${details?.usuario.tipo}`}
				style={{
					marginBottom: 5,
					height: 100,
					width: '100%',
					display: 'flex',
					padding: 10,
					borderRadius: 8,
					border: '1px solid #ddd',
				}}
			>
				<div
					style={{
						width: '75%',
						paddingRight: 5
					}}
				>
					<Typography.Title
						level={5}
						style={{
							margin: 0,
						}}
						ellipsis={{ rows: 1, expandable: false, symbol: '..' }}
					>
						{details?.experiencia?.nombre || 'No disponible'}
					</Typography.Title>
					<Typography.Title
						level={5}
						style={{
							margin: 0,
							fontSize: 10,
							color: '#C3145B',
						}}
						ellipsis={{ rows: 1, expandable: false, symbol: '..' }}
					>
						{details ?
							details?.usuario?.id === user.sub ?
								details?.usuarioProveedor?.nombre
								:
								details?.usuario?.nombre
							:
							'No disponible'}
					</Typography.Title>
					<Typography.Paragraph
						type="secondary"
						ellipsis={{ rows: 1, expandable: false, symbol: '..' }}
						style={{
							paddingTop: 5
						}}
					>
						{details?.ultimoMensaje}
					</Typography.Paragraph>
				</div>

				<div
					style={{
						width: '25%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-end',
						justifyContent: 'space-between'
					}}
				>

					<Typography.Text
						style={{
							fontSize: 11,
							paddingTop: 5
						}}
						type='secondary'
					>
						{moment(moment.utc(details?.fechaUltimoMensaje).toDate()).format('DD/MM/YYYY')}
					</Typography.Text>
					{details?.mensajesNoLeidos > 0 &&
						details?.userIdUltimoMensaje !== user?.sub &&
						<Badge
							count={details?.mensajesNoLeidos}
							overflowCount={9}
							style={{ backgroundColor: "#c3125a" }}
						/>
					}
				</div>

			</Link>)
	}
})

export default ConversationItem