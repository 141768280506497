import UserRestClient from 'http/resources/UserRestClient'
import ErrorManager from 'services/error-manager'
import auth from 'session/api'
import firebase from "firebase/app"
import "firebase/auth"

function getUserInfo(userId) {

	return dispatch => {
		dispatch({
			type: "USER_FETCH_PROFILE_INFO_START",
		})

		new UserRestClient(true).getUserInfo({ userId })
			.then((res) => {
				dispatch({
					type: "USER_FETCH_PROFILE_INFO_SUCCESS",
					payload: {
						userInfo: res.data,
					}
				})
			})
			.catch((error) => {
				dispatch({
					type: "USER_FETCH_PROFILE_INFO_FAILURE",
				})
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(error)
					}
				})
			})

	}
}
function sendInvitation(email, url) {

	return dispatch => {
		dispatch({
			type: "USER_SEND_INVITATION_START",
		})

		new UserRestClient(true).sendInvitation({
			email,
			url
		})
			.then((res) => {
				dispatch({
					type: "USER_SEND_INVITATION_SUCCESS",
				})
				dispatch({
					type: 'UI_ALERT_SET_SUCCESS',
					payload: {
						message: `Invitacion enviada correctamente a ${email}`
					}
				})
			})
			.catch((error) => {
				dispatch({
					type: "USER_SEND_INVITATION_FAILURE",
				})
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(error)
					}
				})
			})

	}
}

function confirmNewPassword(actualPassword, newPassword) {
	return dispatch => {
		dispatch({
			type: 'USER_CHANGE_PASSWORD_START'
		})

		const user = auth.currentUser()

		const credential = firebase.auth.EmailAuthProvider.credential(
			user.email,
			actualPassword
		)

		auth.reauthenticateWithCredential(credential)
			.then(() => {
				return auth.updatePassword(newPassword)
			})
			.then(() => {
				dispatch({
					type: 'USER_CHANGE_PASSWORD_SUCCESS'
				})
				dispatch({
					type: 'UI_ALERT_SET_SUCCESS',
					payload: {
						message: 'Cambió la contraseña correctamente'
					}
				})
			})
			.catch((error) => {
				dispatch({
					type: 'USER_CHANGE_PASSWORD_FAILURE'
				})
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(error)
					}
				})
			});


	}
}


export const UserProfileActions = {
	getUserInfo,
	sendInvitation,
	confirmNewPassword,
}