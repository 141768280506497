import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const AuthRoute = ({ component: Component, user, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) =>
				user?.role === 'Usuario' || user?.role === 'Cliente' ?
					<Component {...props} />
					:
					<Redirect to="/search" />

			}
		/>
	)
}


export default connect(state => ({ user: state.context.user }))(AuthRoute);