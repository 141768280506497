import React, { useState, useEffect } from 'react'
import { Popover, Typography } from 'antd'
import Icon, { CopyOutlined, CloseOutlined } from '@ant-design/icons'
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TwitterShareButton,
    TwitterIcon,
} from "react-share"
import Animate from 'rc-animate'

const ShareSvg = () => <ion-icon name="share-social-outline"></ion-icon>
const ShareIcon = props => (<Icon component={ShareSvg} {...props} />)

const ExperienceShare = (props) => {
    const {
        shareUrl,
        showShareOptions,
        setshowShareOptions
    } = props

    const [showPopoverCopy, setshowPopoverCopy] = useState(false)

    useEffect(() => {
        if (showPopoverCopy) {
            setTimeout(() => {
                setshowPopoverCopy(false)
            }, 1500);
        }
    }, [showPopoverCopy])

    return (
        <div className="share-buttons-container">

            <Animate
                transitionName="fade"
                transitionAppear
                transitionLeave
            >
                {showShareOptions &&

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <EmailShareButton
                            url={shareUrl}
                            quote={''}
                            className="share-button"
                        >

                            <EmailIcon size={35}
                                bgStyle={{
                                    fill: '#224080'
                                }}
                                round
                            />

                        </EmailShareButton>

                        <WhatsappShareButton
                            url={shareUrl}
                            quote={''}
                            className="share-button"
                            windowWidth={1080}
                            windowHeight={720}
                        >

                            <WhatsappIcon size={35} round />

                        </WhatsappShareButton>

                        <FacebookShareButton
                            url={shareUrl}
                            quote={''}
                            className="share-button"
                            windowWidth={1080}
                            windowHeight={720}
                        >

                            <FacebookIcon size={35} round />

                        </FacebookShareButton>

                        <TwitterShareButton
                            url={shareUrl}
                            quote={''}
                            className="share-button"
                            windowWidth={1080}
                            windowHeight={720}
                        >
                            <TwitterIcon size={35} round />

                        </TwitterShareButton>

                        <Popover
                            content={<Typography>Link copiado!</Typography>}
                            trigger="click"
                            visible={showPopoverCopy}
                        >
                            <div
                                className='share-button copy'
                                onClick={() => {
                                    navigator.clipboard.writeText(shareUrl)
                                    setshowPopoverCopy(true)
                                }}>
                                <CopyOutlined
                                    style={{
                                        color: '#fff'
                                    }}
                                    size={39} />
                            </div>
                        </Popover>
                    </div>}
            </Animate>

            <div
                className='share-button share'
                onClick={() => {
                    setshowShareOptions((bool) => !bool)
                }}>
                {showShareOptions ?
                    <CloseOutlined
                        style={{
                            fontSize: 21,
                            color: '#C3125A',
                        }}
                    />
                    :
                    <ShareIcon
                        style={{
                            fontSize: 21,
                            color: '#C3125A',
                        }}
                    />
                }
            </div>

        </div>
    )
}

export default ExperienceShare