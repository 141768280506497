let date = new Date()

const defaultState = {
	dateFrom: new Date(date.getFullYear(), date.getMonth(), 1),
	dateTo: new Date(date.getFullYear(), date.getMonth() + 1, 0),
	loading: true,
	promotorStatsList: {},
	proveedorStatsList: [],
	pageCount: 0,
	totalItems: 0,
	page: 0,
	hasMore: true,
	loadingMoreResults: false,
	data: [],
	movement: {
		loading: false,
		error: false,
		data: {
			id: null,
			details: null,
			quantity: null,
			commissionPlatformPay: null,
			commissionUserPay: null,
			commissionPlatform: null,
			commissionPromotor: null,
			created: null,
			state: null,
			mount: null,
			bookingId: null,
			bookingDate: null,
			bookingState: null,
			bookingType: null,
			balance: null,
			subTotalProvider: null,
			type: null,
			total: null,
			experience: {
				id: null,
				name: null
			},
			user: {
				id: null,
				name: null
			},
			providerUser: {
				id: null,
				name: null
			},
		}
	}
};

export default function reducer(state = defaultState, action) {
	switch (action.type) {

		case 'USER_GET_PROMOTOR_STATS_START':
			return Object.assign({}, state, {
				loading: true,
			})

		case 'USER_GET_PROMOTOR_STATS_SUCCESS':
			return Object.assign({}, state, {
				loading: false,
				promotorStatsList: action.payload.promotorStatsList
			})

		case 'USER_GET_PROMOTOR_STATS_FAILURE':
			return Object.assign({}, state, {
				loading: true,
			})

		case 'USER_GET_PROVEEDOR_STATS_START':
			return Object.assign({}, state, {
				loading: true,
			})

		case 'USER_GET_PROVEEDOR_STATS_SUCCESS':
			return Object.assign({}, state, {
				loading: false,
				proveedorStatsList: action.payload.proveedorStatsList
			})

		case 'USER_GET_PROVEEDOR_STATS_FAILURE':
			return Object.assign({}, state, {
				loading: true,
			})

		case 'USER_GET_INFO_STATS_START':
			return Object.assign({}, state, {
				loading: true,
			})

		case 'USER_GET_INFO_STATS_SUCCESS':
			return Object.assign({}, state, {
				loading: false,
				data: action.payload.data,
				page: action.payload.page,
				pageCount: action.payload.pageCount,
				totalItems: action.payload.totalItems,
			})

		case 'USER_GET_INFO_STATS_FAILURE':
			return Object.assign({}, state, {
				loading: false,
			})

		case 'USER_STATS_CLEAR_STATE':
			return Object.assign({}, state, {
				...defaultState
			})
	
		case 'USER_STATS_SET_DATE_FROM':
			return Object.assign({}, state, {
				dateFrom: action.payload.dateFrom,
			})

		case 'USER_STATS_SET_DATE_TO':
			return Object.assign({}, state, {
				dateTo: action.payload.dateTo,
			})

		case 'USER_GET_WALLET_MOVEMENT_START':
			return Object.assign({}, state, {
				movement: Object.assign({}, state.movement, {
					loading: true,
				})
			})

		case 'USER_GET_WALLET_MOVEMENT_SUCCESS':
			return Object.assign({}, state, {
				movement: Object.assign({}, state.movement, {
					loading: false,
					error: false,
					data: {
						id: action.payload.movement.id,
						details: action.payload.movement.detalle,
						quantity: action.payload.movement.cantidad,
						commissionPlatformPay: action.payload.movement.comisionPagoPlataforma,
						commissionUserPay: action.payload.movement.comisionPagoUsuario,
						commissionPlatform: action.payload.movement.comisionPlataforma,
						commissionPromotor: action.payload.movement.comisionPromotores,
						created: action.payload.movement.creado,
						state: action.payload.movement.estado,
						mount: action.payload.movement.monto,
						bookingId: action.payload.movement.reservaId,
						bookingDate: action.payload.movement.reservaFecha,
						bookingState: action.payload.movement.estadoReserva,
						bookingType: action.payload.movement.tipoReserva,
						balance: action.payload.movement.saldo,
						subTotalProvider: action.payload.movement.subTotalProveedor,
						type: action.payload.movement.tipo,
						total: action.payload.movement.total,
						experience: {
							id: action.payload.movement.experiencia?.id,
							name: action.payload.movement.experiencia?.nombre
						},
						user: {
							id: action.payload.movement.usuario?.id,
							name: action.payload.movement.usuario?.nombre
						},
						providerUser: {
							id: action.payload.movement.usuarioProveedor?.id,
							name: action.payload.movement.usuarioProveedor?.nombre
						},
					}
				})
			})

		case 'USER_GET_WALLET_MOVEMENT_FAILURE':
			return Object.assign({}, state, {
				movement: Object.assign({}, state.movement, {
					loading: false,
					error: true
				})
			})


		default:
			return state;
	}
}
