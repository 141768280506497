import React from 'react'
import { Row, Col } from 'antd'
import { AnLabelForm, AnInput, AnTextArea } from 'components/an'

const BookingAdditionalDataForm = React.memo((props) => {
    const {
        clientName,
        setClientName,
        clientEmail,
        setClientEmail,
        clientPhone,
        setClientPhone,
        clientAdditionalComments,
        setClientAdditionalComments,
    } = props
    return (
        <>
            <Row
                gutter={[0, 15]}
            >
                <Col span={24}>
                    <AnLabelForm
                        label="Nombre y Apellido *"
                    />
                    
                    <AnInput
                        placeholder=""
                        value={clientName}
                        onChange={setClientName}
                    />
                </Col>

                <Col span={24}>
                    <AnLabelForm
                        label="Email *"
                    />
                    
                    <AnInput
                        placeholder=""
                        value={clientEmail}
                        onChange={setClientEmail}
                    />
                </Col>

                <Col span={24}>
                    <AnLabelForm
                        label="Teléfono *"
                    />
                    
                    <AnInput
                        placeholder=""
                        value={clientPhone}
                        onChange={setClientPhone}
                    />
                </Col>

                <Col span={24}>
                    <AnLabelForm
                        label="Comentarios Adicionales"
                    />
                    
                    <AnTextArea
                        className="an-textarea round"
                        autoSize={{
                            minRows: 2,
                        }}
                        placeholder='Hotel donde te alojás, teléfono, contacto alternativo'
                        value={clientAdditionalComments}
                        onChange={setClientAdditionalComments}
                    />
                </Col>
            </Row>
        </>
    )
})

export default BookingAdditionalDataForm