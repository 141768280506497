import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const AuthSessionRoute = ({ component: Component, user, ...rest }) => (
	<Route {...rest}
        
        render={(props) => {
            const {
                location,
            } = props

            console.warn('location pathname', location.pathname)
            
            return (
                user?.role === "Usuario" || user?.role === 'Cliente'
                    ? (
                        <Redirect
                            to={{
                                pathname: "/search",
                                state: { blocked: true, blockedPath: location.pathname }
                            }}
                        />
                    )
                    : (
                        <Component {...props} />
                    )
            )
        }}
	/>
)


export default connect(state => ({ user: state.context.user }))(AuthSessionRoute);