import React, { useEffect, useState, useCallback } from 'react'
import { Table } from 'antd'
import { connect } from 'react-redux'
import { UserStatsActions } from 'redux/actions'
import moment from 'moment'
import { PageHeader } from 'components'
import { AnDayPicker } from 'components/an'

const UserPromotorRedShareInfoScreen = React.memo((props) => {
	const {
		user,
		loading,
		data,
		dateTo,
		setDateTo,
		dateFrom,
		setDateFrom,
		getPromotorShareList,
		clearState,

		page,
		pageCount,
		totalItems,
	} = props

	const [currentPage, setCurrentPage] = useState(0)
	const [dataSource, setDataSource] = useState([])

	useEffect(() => {
		setCurrentPage(page)
	}, [page])

	useEffect(() => {
		if (user) {
			getPromotorShareList(true, currentPage)
		}
	}, [currentPage, dateTo, dateFrom])

	useEffect(() => {
		//Nothing to do. Only clear state when unmount
		return () => {
			clearState()
		}
	}, [])

	const columns = [
		{
			title: 'Fecha',
			dataIndex: 'date',
			key: 'date',
		},
		{
			title: 'Experiencia',
			dataIndex: 'name',
			key: 'name',
			ellipsis: {
				showTitle: false,
			},
		},
		{
			title: 'Promotor',
			dataIndex: 'promotor',
			key: 'promotor',
			ellipsis: {
				showTitle: false,
			},
		},

	]

	//updaters

	useEffect(() => {
		if (data && data.length > 0) {
			setDataSource(() => {
				return data.map((share, index) => {
					return {
						key: index,
						name: share.experiencia ? share.experiencia.nombre : "No hay información",
						date: moment(share.creado).format('DD/MM/yyyy'),
						promotor: share.promotor ? share.promotor.nombre
							:
							"No hay información"
					}
				})
			})
		} else {
			setDataSource([])
		}
	}, [data])

	//handlers

	const handleDateFromSelect = useCallback((e) => {
		setDateFrom(new Date(e))
		setCurrentPage(0)
	}, [setDateFrom])

	const handleDateToSelect = useCallback((e) => {
		setDateTo(new Date(e))
		setCurrentPage(0)
	}, [setDateTo])

	const handleOnChangePage = useCallback((page) => {
		setCurrentPage(page - 1)
	})

	return (
		<>
			<div className="main">
				<PageHeader
					title="Compartidas"
					subtitle="Experiencias compartidas por tu red"
				/>

				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						margin: '10px 15px 0px'
					}}
				>
					<AnDayPicker
						className='stats-day-picker'
						title='Seleccionar fecha "Desde"'
						value={dateFrom}
						withIcon={false}
						onChange={handleDateFromSelect}
						dayPickerProps={{
							disabledDays: [
								{
									after: new Date(dateTo)
								}
							]
						}}
					/>
                    -
                    <AnDayPicker
						className='stats-day-picker'
						title='Seleccionar fecha "Hasta"'
						value={dateTo}
						withIcon={false}
						onChange={handleDateToSelect}
						dayPickerProps={{
							disabledDays: [
								{
									before: new Date(dateFrom),
									after: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
								}
							]
						}}
					/>
				</div>

				<div
					style={{
						marginTop: 10
					}}>
					<Table
						loading={loading}
						dataSource={dataSource}
						columns={columns}
						pagination={{
							position: ['bottomCenter'],
							pageSize: pageCount,
							current: currentPage + 1,
							total: totalItems,
							onChange: handleOnChangePage,
							showSizeChanger: false
						}}
						locale={{ emptyText: 'No hay registros entre las fechas seleccionadas' }}
					/>
				</div>
			</div>
		</>
	)
})

const mapStateToProps = (state) => ({
	user: state.context.user,
	data: state.userStats.data,
	loading: state.userStats.loading,
	dateFrom: state.userStats.dateFrom,
	dateTo: state.userStats.dateTo,

	page: state.userStats.page,
	pageCount: state.userStats.pageCount,
	totalItems: state.userStats.totalItems,
})

const mapDispatchToProps = (dispatch) => ({
	getPromotorShareList: (isRed, page) => dispatch(UserStatsActions.getPromotorShareList(isRed, page)),
	setDateFrom: dateFrom => dispatch(UserStatsActions.setDateFrom(dateFrom)),
	setDateTo: dateTo => dispatch(UserStatsActions.setDateTo(dateTo)),
	clearState: () => dispatch(UserStatsActions.clearState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPromotorRedShareInfoScreen);
