import React, { useCallback, useEffect, useState } from 'react'
import { Form, Col, Row, Checkbox, Alert } from 'antd';
import { EmptyList, Loader } from 'components'
import { AnModal, AnButtonGradient } from 'components/an'

const TagsFiltersModal = React.memo((props) => {
	const {
		onApplySearchTagsCriteria,
		onOpen,

		loading,
		tagsOptions,

		currentTags,
		errorTags
	} = props;

	const [selectedTags, setSelectedTags] = useState(currentTags.length > 0 ? currentTags : ['all'])

	useEffect(() => {
		onOpen()
	}, [onOpen])

	const handleCloseErrorAlert = useCallback(() => {
		onOpen()
	}, [onOpen])

	const handleSelect = useCallback((list) => {
		if (selectedTags[0] === 'all') {
			setSelectedTags(() => list.filter(option => option !== 'all'))
		} else if (list.find(option => option === 'all')) {
			setSelectedTags(['all'])
		} else {
			setSelectedTags(list)
		}
	})

	const radioStyle = {
		display: 'block',
		width: '100%',
		border: '1px solid rgb(240, 240, 240)',
		borderRadius: '2em',
		padding: 10,
		paddingLeft: 14,
		margin: 0,
		marginBottom: 10,
	}

	return (
		<AnModal
			headerTitle="Tags"
			headerButtons={(
				<AnButtonGradient
					type='primary'
					shape='round'
					size='large'
					onClick={() => {
						onApplySearchTagsCriteria(selectedTags)
					}}
				>
					Aplicar
				</AnButtonGradient>
			)}
		>
			{loading ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flex: 1,
						height: '100%',
					}}
				>
					<Loader loading={loading} />
				</div>)
				:
				errorTags ?
					(
						<Alert
							message="Ups!"
							description="Ha ocurrido un error al intentar obtener las etiquetas"
							type="error"
							showIcon
							closeText="Reintentar"
							onClose={handleCloseErrorAlert}
						/>
					)
					:
					tagsOptions.length > 0 ?
						(
							<Row
								xs={24}
								style={{
									background: '#fff',
								}}
							>
								<Col xs={24}>
									<Checkbox.Group
										style={{ width: '100%' }}
										defaultValue={selectedTags}
										onChange={handleSelect}
										value={selectedTags}
									>
										<Col xs={24}>
											<Row>

												<Col xs={24}>
													<Row>
														<Checkbox
															style={radioStyle}
															value='all'
															name="Todos los tags"
														>
															Todos los tags
																</Checkbox>

														{tagsOptions.map(tag => {
															return (
																<Checkbox
																	key={tag.id}
																	style={radioStyle}
																	value={tag.id}
																	name={tag.name}
																	disabled={tag.inactive}
																>
																	{tag.name}
																</Checkbox>
															)
														})}
													</Row>
												</Col>
											</Row>
										</Col>
									</Checkbox.Group>
								</Col>
							</Row>
						)
						:
						(<EmptyList
							description="No hay etiquetas creadas"
							image={(
								<ion-icon name="pricetags-outline"></ion-icon>
							)}
						/>)
			}
		</AnModal >
	)
})

export default TagsFiltersModal