import React, { useRef, useEffect, useCallback } from 'react'
import { Spin } from 'antd'
import ChatMessage from 'components/chat/ChatMessage'

const RenderChatMessages = React.memo((props) => {

	const { user,
		messagesList,
		getMoreMessages,
		loadingMoreResults,
		page,
		hasMore,
		experienceId,
		userProviderId,
		senderId,
		type
	} = props

	const messagesEndRef = useRef(null)

	const scrollToBottom = () => {
		messagesEndRef.current.scrollIntoView({ block: 'end', behavior: 'auto' })
	}

	useEffect(scrollToBottom, [])

	const observer = useRef()

	const lastItemRef = useCallback(node => {
		if (loadingMoreResults) return
		if (observer.current) observer.current.disconnect()
		observer.current = new IntersectionObserver(entries => {
			if (entries[0].isIntersecting && hasMore) {
				let nextPage = page + 1
				getMoreMessages(experienceId, userProviderId, senderId, type, nextPage)
			}
		})
		if (node) observer.current.observe(node)
	}, [loadingMoreResults, page, hasMore, getMoreMessages])

	return (
		<div
			ref={messagesEndRef}
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column-reverse',
				padding: '0 15px',
				overflowY: 'scroll',
				height: '100%',
				background: '#FFF'
			}}
		>

			{(messagesList) &&
				messagesList.map((message, index) => {
					if (messagesList.length === index + 1) {
						return (<ChatMessage
							ref={lastItemRef}
							key={index}
							message={message.contenido}
							me={message?.remitente?.id === user.sub}
							time={message.fechaCreado}
						/>)
					}
					return (<ChatMessage
						key={index}
						message={message.contenido}
						me={message?.remitente?.id === user.sub}
						time={message.fechaCreado}
					/>)
				})
			}
			{loadingMoreResults &&
				<div
					style={{
						width: '100%',
						padding: 15,
						display: 'flex',
						justifyContent: 'center'
					}}
				>
					<Spin />
				</div>}
		</div>
	)
})

export default RenderChatMessages