import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { Button, Divider, Tag, Typography } from "antd";
import moment from "moment";
import { BookingStatus } from "constants/index";
import noImg from "assets/images/no-image-placeholder.png";
import errorImg from "assets/images/error-image-placeholder.png";
import { useImageError } from "hooks/useImageError";

function Image({ src }) {
    const [setImg, hasError] = useImageError();
    const style = {
        width: 80,
        objectFit: "cover",
        height: 80,
        borderRadius: 8,
    };
    if (hasError) {
        return <img src={errorImg} alt="reserva spider-web" style={style} />;
    }
    return <img ref={setImg} src={src} style={style} />;
}

const BookingResultComp = React.forwardRef((props, ref) => {
    const { loading, bookingDetails = {}, openModalMessage } = props;
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);


    if (isLoading) {
        return (
            <ContentLoader
                height={250}
                style={{
                    width: "100%",
                }}
            >
                <rect x="0" y="0" rx="8px" ry="8px" width="100%" height="250px" />
            </ContentLoader>
        );
    } else {
        return (
            <div className="booking-list-item ctn" ref={ref}>
                <div className="booking-list-item-head ">
                    {bookingDetails.experiencia?.mediosUrls?.length > 0 ? (
                        <Image src={bookingDetails.experiencia.mediosUrls[0]} />
                    ) : (
                        <img
                            src={noImg}
                            alt=""
                            style={{
                                width: 80,
                                objectFit: "cover",
                                height: 80,
                                borderRadius: 8,
                            }}
                        />
                    )}

                    <div className="booking-list-item-status">
                        {typeof bookingDetails?.tipoReserva === "number" && bookingDetails?.tipoReserva > 0 ? (
                            <Tag
                                color={BookingStatus[bookingDetails.estado].color}
                                style={{
                                    borderRadius: 12,
                                    margin: 0,
                                }}
                            >
                                {BookingStatus[bookingDetails.estado].label}
                            </Tag>
                        ) : (
                            <Tag
                                color="#ffe58f"
                                style={{
                                    borderRadius: 12,
                                    margin: 0,
                                }}
                            >
                                No definido
                            </Tag>
                        )}

                        {typeof openModalMessage === "function" && (
                            <Button
                                size="middle"
                                type="ghost"
                                icon={<ion-icon name="chatbubbles-outline" />}
                                onClick={() => openModalMessage(bookingDetails.experiencia, bookingDetails.cliente)}
                            >
                                Enviar mensaje
                            </Button>
                        )}
                    </div>
                </div>
                <div className="user-booking-result-body">
                    <Typography.Title
                        ellipsis={{ rows: 1, expandable: false, symbol: ".." }}
                        level={5}
                        style={{
                            maxWidth: " 100%",
                            margin: 0,
                            marginBottom: 4,
                            marginLeft: 3,
                        }}
                    >
                        {bookingDetails.experiencia?.nombre || "Nombre no disponible"}
                    </Typography.Title>

                    <div className="user-booking-result-client-info">
                        <div>
                            <ion-icon
                                name="person-circle-outline"
                                style={{
                                    color: "#C3125A",
                                    fontSize: 14,
                                    paddingRight: 4,
                                }}
                            />
                            <Typography.Text type="secondary" ellipsis={{ rows: 1, expandable: false, symbol: ".." }}>
                                {bookingDetails.cliente?.nombre || "No hay información"}
                            </Typography.Text>
                        </div>
                        <div>
                            <ion-icon
                                name="mail-outline"
                                style={{
                                    color: "#C3125A",
                                    fontSize: 14,
                                    paddingRight: 4,
                                }}
                            />
                            <Typography.Text type="secondary" ellipsis={{ rows: 1, expandable: false, symbol: ".." }}>
                                {bookingDetails.cliente?.email || "No hay información"}
                            </Typography.Text>
                        </div>
                        <div>
                            <ion-icon
                                name="call-outline"
                                style={{
                                    color: "#C3125A",
                                    fontSize: 14,
                                    paddingRight: 4,
                                }}
                            />
                            <Typography.Text type="secondary" ellipsis={{ rows: 1, expandable: false, symbol: ".." }}>
                                {bookingDetails.cliente?.telefono || "No hay información"}
                            </Typography.Text>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <ion-icon
                                name="document-text-outline"
                                style={{
                                    color: "#C3125A",
                                    fontSize: 14,
                                    paddingTop: 2,
                                    paddingRight: 4,
                                    alignSelf: "flex-start",
                                }}
                            />
                            <Typography.Paragraph
                                type="secondary"
                                style={{
                                    width: "100%",
                                    margin: 0,
                                }}
                                ellipsis={{ rows: 1, expandable: true, symbol: "ver más" }}
                            >
                                {bookingDetails.datosContacto || "No hay detalles"}
                            </Typography.Paragraph>
                        </div>

                        {!!bookingDetails.dondeRecoger?.length && (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <ion-icon
                                    name="pin-outline"
                                    style={{
                                        color: "#C3125A",
                                        fontSize: 14,
                                        paddingTop: 2,
                                        paddingRight: 4,
                                        alignSelf: "flex-start",
                                    }}
                                />
                                <Typography.Paragraph
                                    type="secondary"
                                    style={{
                                        width: "100%",
                                        margin: 0,
                                    }}
                                    ellipsis={{ rows: 1, expandable: true, symbol: "ver más" }}
                                >
                                    {bookingDetails.dondeRecoger || "No hay detalles"}
                                </Typography.Paragraph>
                            </div>
                        )}

                        {!!bookingDetails.dondeLlevar?.length && (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <ion-icon
                                    name="navigate-outline"
                                    style={{
                                        color: "#C3125A",
                                        fontSize: 14,
                                        paddingTop: 2,
                                        paddingRight: 4,
                                        alignSelf: "flex-start",
                                    }}
                                />
                                <Typography.Paragraph
                                    type="secondary"
                                    style={{
                                        width: "100%",
                                        margin: 0,
                                    }}
                                    ellipsis={{ rows: 1, expandable: true, symbol: "ver más" }}
                                >
                                    {bookingDetails.dondeLlevar || "No hay detalles"}
                                </Typography.Paragraph>
                            </div>
                        )}
                        {!!bookingDetails.nroVuelo?.length && (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <ion-icon
                                    name="airplane-outline"
                                    style={{
                                        color: "#C3125A",
                                        fontSize: 14,
                                        paddingTop: 2,
                                        paddingRight: 4,
                                        alignSelf: "flex-start",
                                    }}
                                />
                                <Typography.Paragraph
                                    type="secondary"
                                    style={{
                                        width: "100%",
                                        margin: 0,
                                    }}
                                    ellipsis={{ rows: 1, expandable: true, symbol: "ver más" }}
                                >
                                    {bookingDetails.nroVuelo || "No hay detalles"}
                                </Typography.Paragraph>
                            </div>
                        )}
                    </div>

                    <div className="user-booking-result-body-info">
                        <div>
                            <ion-icon
                                name="today-outline"
                                style={{
                                    color: "#C3125A",
                                    fontSize: 16,
                                    paddingRight: 4,
                                }}
                            />
                            <Typography.Text>{moment.utc(bookingDetails.fecha).format("DD/MM/yyyy")}</Typography.Text>
                        </div>

                        <div>
                            <ion-icon
                                name="time-outline"
                                style={{
                                    color: "#C3125A",
                                    fontSize: 16,
                                    paddingRight: 4,
                                }}
                            ></ion-icon>
                            <Typography.Text>{moment.utc(bookingDetails.fecha).format("HH:mm")}</Typography.Text>
                        </div>

                        <div className="booking-list-item-price">
                            <ion-icon
                                name="cash-outline"
                                style={{
                                    color: "#FFF",
                                    fontSize: 16,
                                    paddingRight: 4,
                                }}
                            ></ion-icon>
                            <Typography.Text
                                style={{
                                    color: "#fff",
                                }}
                                strong
                            >
                                {typeof bookingDetails.total === "number"
                                    ? new Intl.NumberFormat("es-AR", {
                                          maximumFractionDigits: 2,
                                          style: "currency",
                                          currency: "ARS",
                                      }).format(bookingDetails.total)
                                    : "No hay información"}
                            </Typography.Text>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});

export default BookingResultComp;
