import RestClient from "http/RestClient";
import axios from "axios";
import moment from "moment";
import { ExperiencesConfig } from "constants/index";

const qs = require("query-string");

const CancelToken = axios.CancelToken;

let cancel;

export default class ExperienceRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(undefined, useCredentials, refreshContext);
    }

    getSearchResult(params, criteria) {
        if (typeof cancel === "function") cancel();

        const url = `${this.baseURL}/${this.secured ? "secured" : "public"}/experiencias`;

        return this.instance.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
            params: {
                page: params?.page,
                pageCount: ExperiencesConfig.pageCount,
                nombre: criteria?.name || null,
                fechaDesde: criteria?.fromDate || null,
                fechaHasta: criteria?.toDate ? moment(criteria.toDate).add(1, "day").format("yyyy-MM-DD") : null, // We add 1 day to include experience of to date
                categoriaId: criteria?.categories || null,
                cantPersonas: criteria?.peopleQuantity || null,
                idiomaId: criteria?.languages || null,
                duracionHasta: criteria?.duration
                    ? moment.duration(criteria.duration, "h").days() === 0
                        ? moment.duration(criteria.duration, "h").hours() === 0
                            ? moment.duration(criteria.duration, "h").format("0[.]00[:]mm[:]ss")
                            : moment.duration(criteria.duration, "h").format("0[.]hh[:]mm[:]ss")
                        : moment.duration(criteria.duration, "h").format("d[.]hh[:]mm[:]ss")
                    : null,
                precioHasta: criteria?.maxPrice || null,
                tagId: criteria?.tags || null,
                localidadId: criteria?.localityId || null,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "index", skipNull: true });
            },
        });
    }

    getExperiencesByUser(params, criteria) {
        if (typeof cancel === "function") cancel();

        const url = `${this.baseURL}/secured/experiencias/proveedor`;

        return this.instance.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
            params: {
                page: params?.page,
                nombre: criteria?.name,
            },
        });
    }

    getExperience(params) {
        const url = `${this.baseURL}/${this.secured ? "secured" : "public"}/experiencias/${params.experienceId}`;

        return this.instance.get(url);
    }

    createExperience(params) {
        const url = `${this.baseURL}/secured/experiencias`;

        return this.instance.post(url, {
            nombre: params.title,
            tiposReserva: {
                pagoCompleto: params.bookingType.complete,
                reservaConPago: params.bookingType.withPay,
                reservaSinPago: params.bookingType.withoutPay,
            },
            direccion: {
                calle: params.direction.street,
                numero: params.direction.number,
                pisoDepto: params.direction.depto,
                localidadId: params.direction.locateId,
            },
            urlExterna: params.externalUrl,
            detalles: params.details,
            detallesEn: params.detailsEn,
            precio: params.price,
            cantPersonas: params.peopleQuantity,
            duracion: params.duration
                ? moment.duration(params.duration, "h").days() === 0
                    ? moment.duration(params.duration, "h").hours() === 0
                        ? moment.duration(params.duration, "h").format("0[.]00[:]mm[:]ss")
                        : moment.duration(params.duration, "h").format("0[.]hh[:]mm[:]ss")
                    : moment.duration(params.duration, "h").format("d[.]hh[:]mm[:]ss")
                : "00:00:00",
            tolerancia: params.tolerance
                ? moment.duration(params.tolerance, "h").days() === 0
                    ? moment.duration(params.tolerance, "h").hours() === 0
                        ? moment.duration(params.tolerance, "h").format("0[.]00[:]mm[:]ss")
                        : moment.duration(params.tolerance, "h").format("0[.]hh[:]mm[:]ss")
                    : moment.duration(params.tolerance, "h").format("d[.]hh[:]mm[:]ss")
                : "00:00:00",
            idiomasIds: (params.languages || []).map((language) => language.id),
            categoriaId: params.categoryId || undefined,
            tagsIds: (params.tags || []).map((tag) => tag.id),
            timeZoneId: params.timeZoneId,
            horariosFlexibles: params.flexibleHours,
            requerirDondeRecoger: params.bookingRequires.requireWhereToPickup,
            requerirDondeLlevar: params.bookingRequires.requireWhereToGo,
            requerirNroVuelo: params.bookingRequires.requireFlightNumber,
        });
    }

    patchExperience(experienceId, params) {
        const url = `${this.baseURL}/secured/experiencias/${experienceId}`;

        return this.instance.patch(url, {
            publicada: params.isPublic,
        });
    }

    updateExperience(params) {
        const url = `${this.baseURL}/secured/experiencias/${params.experienceId}`;

        return this.instance.put(url, {
            nombre: params.title,
            tiposReserva: {
                pagoCompleto: params.bookingType.complete,
                reservaConPago: params.bookingType.withPay,
                reservaSinPago: params.bookingType.withoutPay,
            },
            direccion: {
                calle: params.direction.street,
                numero: params.direction.number,
                pisoDepto: params.direction.depto,
                localidadId: params.direction.locateId,
            },
            urlExterna: params.externalUrl,
            detalles: params.details,
            detallesEn: params.detailsEn,
            precio: params.price,
            cantPersonas: params.peopleQuantity,
            duracion: params.duration
                ? moment.duration(params.duration, "h").days() === 0
                    ? moment.duration(params.duration, "h").hours() === 0
                        ? moment.duration(params.duration, "h").format("0[.]00[:]mm[:]ss")
                        : moment.duration(params.duration, "h").format("0[.]hh[:]mm[:]ss")
                    : moment.duration(params.duration, "h").format("d[.]hh[:]mm[:]ss")
                : "00:00:00",
            tolerancia: params.tolerance
                ? moment.duration(params.tolerance, "h").days() === 0
                    ? moment.duration(params.tolerance, "h").hours() === 0
                        ? moment.duration(params.tolerance, "h").format("0[.]00[:]mm[:]ss")
                        : moment.duration(params.tolerance, "h").format("0[.]hh[:]mm[:]ss")
                    : moment.duration(params.tolerance, "h").format("d[.]hh[:]mm[:]ss")
                : "00:00:00",
            idiomasIds: (params.languages || []).map((language) => language.id),
            categoriaId: params.categoryId,
            tagsIds: (params.tags || []).map((tag) => tag.id),
            timeZoneId: params.timeZoneId,
            horariosFlexibles: params.flexibleHours,
            requerirDondeRecoger: params.bookingRequires.requireWhereToPickup,
            requerirDondeLlevar: params.bookingRequires.requireWhereToGo,
            requerirNroVuelo: params.bookingRequires.requireFlightNumber,
        });
    }

    markExperienceAsViewed(params) {
        const url = `${this.baseURL}/${this.secured ? "secured" : "public"}/vistas`;

        return this.instance.post(url, {
            experienciaId: params?.experienceId,
            promotorCodigo: params?.promotorNic,
        });
    }
}
