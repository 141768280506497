import RestClient from 'http/RestClient'
import axios from 'axios'

export default class MessageRestClient extends RestClient {
	constructor(useCredentials, refreshContext) {
		super(
			undefined,
			useCredentials,
			refreshContext,
		)
	}

	getMessagesBadge() {
		const url = `${this.baseURL}/secured/mensajes/noleidos`

		return this.instance.get(url, {})
	}

}
