import React from 'react'

const PageHeader = React.memo((props) => {
    const {
        title,
        subtitle,
    } = props
    
    return (
        <div className="page-header-container">
            <h1 className="page-header-title">
                {title}
            </h1>

            <h1 className="page-header-subtitle">
                {subtitle}
            </h1>
        </div>
    )
})

PageHeader.defaultProps = {
    title: 'Title',
    subtitle: 'This is the Subtitle'
}

export default PageHeader