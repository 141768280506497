import Reactotron from 'reactotron-react-js'
import { reactotronRedux } from 'reactotron-redux'

const reactotron = Reactotron
    .configure({ name: 'Spiderweb' })
    .use(reactotronRedux())

    if (process.env.NODE_ENV === 'development') {
        reactotron.connect()
    }
  
export default reactotron