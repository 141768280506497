import { FirebaseErrorsMap } from 'constants/index'

const defaultErrorMessage = (
	'Hubo un problema, intente nuevamente'
);

export default class ErrorManager {
	static toMessage(error) {

		if (error instanceof Error) {
			try {
				if (error?.message === 'IMPERSONATED_SESSION_EXPIRED') {
					return ''
				}
	
				if (error.response &&
					error.response.data &&
					error.response.data.isFaulted) {
					return error.response.data.errors[0].message
				}
	
				if (error.code) {
					if (FirebaseErrorsMap[error.code]) {
						return FirebaseErrorsMap[error.code];
					}
	
					return defaultErrorMessage;
				}
	
				return (error?.message === 'Request failed with status code 500'
					|| error?.code === 500
					|| error?.message === 'Request failed with status code 404')
					|| error?.message === 'Request failed with status code 401'
					|| error?.code === 502
					? defaultErrorMessage
					: error.response.data.errors[0].message;
			} catch (err) {
				return defaultErrorMessage
			}
		}

		return defaultErrorMessage;
	}
}
