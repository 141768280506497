function setInitialHistoryIndex(index) {
    return {
        type: 'INITIAL_HISTORY_INDEX_SET',
        payload: {
            index,
        }
    }
}

export const HistoryActions = {
    setInitialHistoryIndex,
}