import { TagRestClient } from 'http/resources'
import axios from 'axios'

function getTags(params, criteria) {
    return dispatch => {

        dispatch({
            type: 'TAGS_FETCH_TAGS_START'
        });

        new TagRestClient().getTags(params, criteria)
            .then(response => {

                dispatch({
                    type: 'TAGS_FETCH_TAGS_SUCCESS',
                    payload: {
                        tags: response.data.items.map((tag) => ({
                            id: tag.id,
                            name: tag.nombre,
                            inactive: tag.inactive,
                        }))
                    }
                });
            })
            .catch(error => {
                if (error instanceof axios.Cancel) {
                    // Nothing to do ...
                } else {

                    dispatch({
                        type: 'TAGS_FETCH_TAGS_FAILURE',
                    });

                }
            })
    }
}

export const TagsActions = {
    getTags,
}