import React from 'react'
import {
	Transition
} from 'react-transition-group'
import './style.css'

const statusTypes = {
	success: {
		color: '#5A9261',
		icon: 'checkmark-circle-outline'
	},
	info: {
		color: '#444',
		icon: 'information-circle-outline'
	},
	warning: {
		color: '#DFC33C',
		icon: 'warning-outline'
	},
	error: {
		color: '#cf346c',
		icon: 'close-circle-outline'
	},
	liked: {
		color: '#fff',
		icon: 'heart-circle-outline'
	}


}
class AnAlertBar extends React.PureComponent {
	constructor() {
		super()
		this.state = {
			show: false,
			type: 'error',
			message: "Ocurrió un problema. Intente nuevamente",
			duration: 4500,
			isDesktop: false,
			defaultStyle: {
				transition: `bottom 400ms ease-in-out`,
				position: 'fixed',
				padding: 5,
				width: '100vw',
				bottom: '-30vh',
				zIndex: 1100,
				display: 'flex',
				flexDirection: 'row-reverse'
			},
			transitionStyles: {
				entering: {
					bottom: '9vh',
				},
				entered: {
					bottom: '9vh'
				},
				exiting: {
					bottom: '-30vh',
				},
				exited: {
					bottom: '-30vh',
				},
			}
		}
		this.mediaMatch = window.matchMedia('(min-width: 760px)');
	}

	componentDidMount() {
		if (this.mediaMatch.matches) {
			this.setState({
				isDesktop: true,
				transitionStyles: {
					entering: {
						bottom: 10,
						right: 10
					},
					entered: {
						bottom: 10,
						right: 10
					},
					exiting: {
						bottom: '-30vh',
					},
					exited: {
						bottom: '-30vh',
					}
				}
			})
		} else {
			this.setState({
				isDesktop: false
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.show &&
			this.state.show !== prevState.show) {
			setTimeout(() => {
				this.handleClose()
			}, this.state.duration);
		}


	}

	show = (params) => {
		this.setState({
			show: true,
			message: params.message,
			type: params.type
		});
	}

	handleClose = () => {
		this.setState({
			show: false,
		})
	}
	render() {
		return (
			<Transition in={this.state.show} timeout={this.state.duration} >
				{ state => (
					<div style={{
						...this.state.defaultStyle,
						...this.state.transitionStyles[state]
					}}>
						<div
							className={this.state.type === 'liked' ? 'alert-bar-gradient-container' : 'alert-bar-container'}>
							<div
								className='alert-bar-icon-ctn'>
								<ion-icon
									name={statusTypes[this.state.type].icon}
									style={{
										color: statusTypes[this.state.type].color
									}}></ion-icon>
							</div>
							<div
								className='alert-bar-text-ctn'
							>
								<h3
									style={{
										color: statusTypes[this.state.type].color,
										fontSize: this.state.type === 'liked' ? 18 : 16
									}}>{this.state.type === 'liked' ?
										'Se agregó a favoritos'
										: this.state.message}</h3>
							</div>
						</div>
					</div>
				)}
			</Transition >
		)
	}
}


export default AnAlertBar