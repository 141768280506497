import firebase from "firebase/app"
import "firebase/functions"

firebase.initializeApp({
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
})

const auth = firebase.auth();
const functions = firebase.functions();
const providers = {
	google: new firebase.auth.GoogleAuthProvider(),
	facebook: new firebase.auth.FacebookAuthProvider(),
	email: new firebase.auth.EmailAuthProvider()
}
const actionCodeSettings = {
	url: `${window.location.protocol}//${window.location.host}/login/action`,
	handleCodeInApp: true
}


export { auth, providers, functions, actionCodeSettings }
export default firebase