import { CategoryRestClient } from 'http/resources'
import axios from 'axios'

function getCategories(params, criteria) {
    return dispatch => {
        dispatch({
            type: 'CATEGORIES_FETCH_CATEGORIES_START'
        });
        
        new CategoryRestClient().getCategories(params, criteria)
            .then(response => {
                dispatch({
                    type: 'CATEGORIES_FETCH_CATEGORIES_SUCCESS',
                    payload: {
                        categories: response.data.items.map((category) => ({
                            id: category.id,
                            name: category.nombre,
                            inactive: category.inactivo,
                        }))
                        // categories: res.data.items.slice(0, 5)
                    }
                });
            })
            .catch(error => {
                if (error instanceof axios.Cancel) {
                    // Nothing to do ...
                } else {
                    dispatch({
                        type: 'CATEGORIES_FETCH_CATEGORIES_FAILURE',
                    });
                }
            })
    }
}

export const CategoriesActions = {
    getCategories,
}