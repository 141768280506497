import React, { useCallback } from 'react'
import { Tag, Select, Typography, Spin, Space } from 'antd'
import { connect } from 'react-redux'
import { TagsActions } from 'redux/actions'


const AnTagSelect = React.memo((props) => {
    const {
        id,
        className,
        wrapperClassName,
        value,
        onChange,
        tags,
        fetching,
        fetchOptions,
        onBlur,
    } = props

    const onCloseTag = useCallback((removedTagId) => {
        onChange((value || []).filter(tag => tag.id !== removedTagId))
    }, [onChange, value])

    const addNewTag = useCallback((e) => {
        const alreadySelected = value && value.find( option => option.id === e.key );
        
        if (!alreadySelected) {
            onChange([].concat(value || []).concat([{ id: e.key, name: e.label }]))
        }
    }, [onChange, value])

    const handleOnSearch = useCallback((name) => {
        if (name.length > 2) {
            fetchOptions(name)
        } else if (name.length < 1) {
            fetchOptions()
        }
    }, [fetchOptions])

    const handleOnFocus = useCallback(() => {
        fetchOptions()
    }, [fetchOptions])

    return (
        <div
            id={id}
            className={wrapperClassName}
        >
            <Select
                className={className}
                showSearch
                placeholder="Seleccionar etiquetas.."
                optionFilterProp="children"
                onSearch={handleOnSearch}
                onSelect={addNewTag}
                onFocus={handleOnFocus}
                onBlur={onBlur}
                labelInValue
                value={[]}
                notFoundContent={fetching ? (
                    <Spin size="small" />
                ) : tags?.length < 1
                        ? (
                            <Typography.Text type='secondary'>No hay resultados</Typography.Text>
                        ) : (
                            <Typography.Text type='secondary'>Buscar etiquetas...</Typography.Text>
                        )
                }
            >

                {tags.id && tags.length < 1 &&
                    <Select.Option value={tags.id}>{tags.name}</Select.Option>}
                {tags.map(result => (
                    <Select.Option key={result.id} value={result.id}>{result.name}</Select.Option>
                ))}
            </Select>
            
            {((value || []).length > 0 && (
                <div
                    style={{
                        marginTop: 10,
                    }}
                >
                    {value.map((tag) => {
                        return (
                            <Tag
                                key={tag.id}
                                closable
                                onClose={() => onCloseTag(tag.id)}
                            >
                                <Space>
                                    <Typography>
                                        {tag.name}
                                    </Typography>

                                    {(tag.inactive && (
                                        <Typography.Text
                                            type="danger"
                                        >
                                            ( Inactivo )
                                        </Typography.Text>
                                    ))}
                                </Space>
                            </Tag>
                        )
                    })}
                </div>
            ))}
        </div>
    )
})

const mapStateToProps = (state) => ({
    user: state.context.user,
    tags: state.tags.data,
    fetching: state.tags.loading,
})

const mapDispatchToProps = (dispatch) => ({
    fetchOptions: (searchTerm) => dispatch(TagsActions.getTags(searchTerm))
})

export default connect(mapStateToProps, mapDispatchToProps)(AnTagSelect);
