import { ExperienceRestClient, ExperienceMediaRestClient } from "http/resources";
import ErrorManager from "services/error-manager";
import axios from "axios";
import moment from "moment";

function getExperiences(criteria) {
    return (dispatch) => {
        dispatch({
            type: "USER_EXPERIENCES_FETCH_EXPERIENCES_START",
        });

        new ExperienceRestClient(true)
            .getExperiencesByUser(null, {
                name: criteria?.name,
            })
            .then((res) => {
                dispatch({
                    type: "USER_EXPERIENCES_FETCH_EXPERIENCES_SUCCESS",
                    payload: {
                        experiences: res.data.items.map((item) => ({
                            id: item.id,
                            title: item.nombre,
                            details: item.detalles,
                            detailsEn: item.detallesEn,
                            price: item.precio,
                            rating: item.rating,
                            ranking: item.ranking,
                            isLiked: item.favorito,
                            isPublic: item.publicada,
                            images: item.mediosUrls,
                        })),
                        pageCount: res.data.pageCount,
                        totalItems: res.data.totalItems,
                        page: res.data.page,
                        hasMore: !(res.data.items.length <= res.data.pageCount),
                    },
                });
            })
            .catch((error) => {
                if (error instanceof axios.Cancel) {
                    // Nothing to do ...
                } else {
                    dispatch({
                        type: "USER_EXPERIENCES_FETCH_EXPERIENCES_FAILURE",
                    });
                    dispatch({
                        type: "UI_ALERT_SET_ERROR",
                        payload: {
                            error: ErrorManager.toMessage(error),
                        },
                    });
                }
            });
    };
}

function getMoreExperiences(page, criteria) {
    return (dispatch) => {
        dispatch({
            type: "USER_EXPERIENCES_FETCH_MORE_EXPERIENCES_START",
        });
        new ExperienceRestClient(true)
            .getExperiencesByUser(page, {
                name: criteria?.name,
            })
            .then((res) => {
                dispatch({
                    type: "USER_EXPERIENCES_FETCH_MORE_EXPERIENCES_SUCCESS",
                    payload: {
                        experiences: res.data.items.map((item) => ({
                            id: item.id,
                            title: item.nombre,
                            details: item.detalles,
                            detailsEn: item.detallesEn,
                            price: item.precio,
                            rating: item.rating,
                            ranking: item.ranking,
                            isLiked: item.favorito,
                            isPublic: item.publicada,
                            images: item.mediosUrls,
                        })),
                        pageCount: res.data.pageCount,
                        totalItems: res.data.totalItems,
                        page: res.data.page,
                        hasMore: !(res.data.items.length < 1 || res.data.page === page - 1),
                    },
                });
            })
            .catch((error) => {
                dispatch({
                    type: "USER_EXPERIENCES_FETCH_MORE_EXPERIENCES_FAILURE",
                });
                dispatch({
                    type: "UI_ALERT_SET_ERROR",
                    payload: {
                        error: ErrorManager.toMessage(error),
                    },
                });
            });
    };
}

function getExperience(experienceId) {
    return async (dispatch) => {
        try {
            dispatch({
                type: "USER_EXPERIENCES_FETCH_EXPERIENCE_START",
            });

            const response = await new ExperienceRestClient(true).getExperience({ experienceId });

            dispatch({
                type: "USER_EXPERIENCES_FETCH_EXPERIENCE_SUCCESS",
                payload: {
                    experience: {
                        id: response.data.id,
                        title: response.data.nombre,
                        provider: {
                            id: response.data.usuario.id,
                            name: response.data.usuario.nombre,
                        },
                        details: response.data.detalles,
                        detailsEn: response.data.detallesEn,
                        price: response.data.precio,
                        bookingType: [
                            response.data.tiposReserva.pagoCompleto ? "complete" : "",
                            response.data.tiposReserva.reservaConPago ? "withPay" : "",
                            response.data.tiposReserva.reservaSinPago ? "withoutPay" : "",
                        ],
                        createdDate: response.data.fechaCreada,
                        direction: {
                            street: response.data.direccion.calle,
                            number: response.data.direccion.numero,
                            depto: response.data.direccion.pisoDepto,
                            locate: response.data.direccion.localidad,
                        },
                        category: {
                            id: response.data.categoria?.id,
                            name: response.data.categoria?.nombre,
                            inactive: response.data.categoria?.inactivo,
                        },
                        peopleQuantity: response.data.cantPersonas,
                        duration: response.data.duracion ? moment.duration(response.data.duracion).asHours() : 0,
                        tolerance: response.data.tolerancia ? moment.duration(response.data.tolerancia).asHours() : 0,
                        tags: response.data.tags.map((tag) => ({
                            id: tag?.id,
                            name: tag?.nombre,
                            inactive: tag?.inactivo,
                        })),
                        languages: response.data.idiomas.map((language) => ({
                            id: language?.id || "",
                            name: language?.nombre || "",
                            inactive: language?.inactivo,
                        })),
                        externalUrl: response.data.urlExterna,
                        rating: response.data.rating,
                        isLiked: response.data.favorito || false,
                        timeZoneId: response.data.timeZoneId,
                        isPublic: response.data.publicada,
                        flexibleHours: response.data.horariosFlexibles,
                        bookingRequires: [
                            response.data.requerirDondeRecoger ? "requireWhereToPickup" : "",
                            response.data.requerirDondeLlevar ? "requireWhereToGo" : "",
                            response.data.requerirNroVuelo ? "requireFlightNumber" : "",
                        ],
                    },
                },
            });
        } catch (error) {
            dispatch({
                type: "USER_EXPERIENCES_FETCH_EXPERIENCE_FAILURE",
            });

            dispatch({
                type: "UI_ALERT_SET_ERROR",
                payload: {
                    error: ErrorManager.toMessage(error),
                },
            });
        }
    };
}

function createExperience(values) {
    return (dispatch) => {
        dispatch({
            type: "USER_EXPERIENCES_CREATE_EXPERIENCE_START",
        });

        new ExperienceRestClient(true)
            .createExperience({
                title: values.title ? values.title : "",
                bookingType: {
                    complete: values.bookingType.includes("complete"),
                    withPay: values.bookingType.includes("withPay"),
                    withoutPay: values.bookingType.includes("withoutPay"),
                },
                direction: {
                    street: values.locationStreet,
                    number: values.locationStreetNumber,
                    depto: values.locationFloor,
                    locateId: values.locationLocality.id,
                },
                externalUrl: values.externalUrl,
                categoryId: values.category?.id ? values.category.id : "",
                details: values.details ? values.details : "",
                detailsEn: values.detailsEn ? values.detailsEn : "",
                price: values.price ? parseInt(values.price) : 0,
                peopleQuantity: values.peopleQuantity ? parseInt(values.peopleQuantity) : 0,
                duration: values.duration,
                tolerance: values.tolerance,
                languages: values.languages,
                tags: values.tags,
                timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
                flexibleHours: values.flexibleHours?.length ? values.flexibleHours.includes("flexibleHours") : false,
                bookingRequires: {
                    requireWhereToPickup: values.bookingRequires?.includes("requireWhereToPickup"),
                    requireWhereToGo: values.bookingRequires?.includes("requireWhereToGo"),
                    requireFlightNumber: values.bookingRequires?.includes("requireFlightNumber"),
                },
            })
            .then((res) => {
                dispatch({
                    type: "USER_EXPERIENCES_CREATE_EXPERIENCE_SUCCESS",
                    payload: {
                        experienceId: res.request.response,
                    },
                });
                dispatch({
                    type: "UI_ALERT_SET_SUCCESS",
                    payload: {
                        message: "Se creó correctamente",
                    },
                });
            })
            .catch((err) => {
                dispatch({
                    type: "USER_EXPERIENCES_CREATE_EXPERIENCE_FAILURE",
                });
                dispatch({
                    type: "UI_ALERT_SET_ERROR",
                    payload: {
                        error: ErrorManager.toMessage(err),
                    },
                });
            })
            .finally(() => {
                dispatch({
                    type: "USER_EXPERIENCES_CREATE_EXPERIENCE_RESET",
                });
            });
    };
}

function updateExperienceDetails(values) {
    return (dispatch) => {
        dispatch({
            type: "USER_EXPERIENCES_UPDATE_EXPERIENCE_START",
        });

        new ExperienceRestClient(true)
            .updateExperience({
                experienceId: values.id,
                title: values.title ? values.title : "",
                bookingType: {
                    complete: values.bookingType.includes("complete"),
                    withPay: values.bookingType.includes("withPay"),
                    withoutPay: values.bookingType.includes("withoutPay"),
                },
                direction: {
                    street: values.locationStreet,
                    number: values.locationStreetNumber,
                    depto: values.locationFloor,
                    locateId: values.locationLocality.id,
                },
                externalUrl: values.externalUrl,
                categoryId: values.category?.id ? values.category.id : "",
                details: values.details ? values.details : "",
                detailsEn: values.detailsEn ? values.detailsEn : "",
                price: values.price ? parseInt(values.price) : 0,
                peopleQuantity: values.peopleQuantity ? parseInt(values.peopleQuantity) : 0,
                duration: values.duration,
                tolerance: values.tolerance,
                languages: values.languages,
                tags: values.tags,
                timeZoneId: values.timeZoneId,
                flexibleHours: values.flexibleHours?.length ? values.flexibleHours.includes("flexibleHours") : false,
                bookingRequires: {
                    requireWhereToPickup: values.bookingRequires?.includes("requireWhereToPickup"),
                    requireWhereToGo: values.bookingRequires?.includes("requireWhereToGo"),
                    requireFlightNumber: values.bookingRequires?.includes("requireFlightNumber"),
                },
            })
            .then((res) => {
                dispatch({
                    type: "USER_EXPERIENCES_UPDATE_EXPERIENCE_SUCCESS",
                });
                dispatch({
                    type: "UI_ALERT_SET_SUCCESS",
                    payload: {
                        message: "Se guardó correctamente",
                    },
                });
            })
            .catch((err) => {
                dispatch({
                    type: "USER_EXPERIENCES_UPDATE_EXPERIENCE_FAILURE",
                });
                dispatch({
                    type: "UI_ALERT_SET_ERROR",
                    payload: {
                        error: ErrorManager.toMessage(err),
                    },
                });
            })
            .finally(() => {
                dispatch({
                    type: "USER_EXPERIENCES_UPDATE_EXPERIENCE_RESET",
                });
            });
    };
}

function getExperienceMedia(experienceId) {
    return (dispatch) => {
        dispatch({
            type: "USER_EXPERIENCES_FETCH_EXPERIENCE_MEDIA_START",
        });
        new ExperienceMediaRestClient(true)
            .getExperienceMedias({
                experienceId,
            })
            .then((res) => {
                dispatch({
                    type: "USER_EXPERIENCES_FETCH_EXPERIENCE_MEDIA_SUCCESS",
                    payload: {
                        mediaList: res.data || [],
                    },
                });
            })

            .catch((err) => {
                dispatch({
                    type: "UI_ALERT_SET_ERROR",
                    payload: {
                        error: ErrorManager.toMessage(err),
                    },
                });
            });
    };
}

function pushNewExperienceMedia(experienceId, file, mediaList) {
    return (dispatch) => {
        let updateMedia = mediaList.concat(file);

        return new ExperienceMediaRestClient(true)
            .updateExperienceMedias({
                experienceId,
                media: updateMedia,
            })
            .then((res) => {
                dispatch({
                    type: "UI_ALERT_SET_SUCCESS",
                    payload: {
                        message: "Se agregó correctamente",
                    },
                });
            })
            .catch((err) => {
                throw err;
            });
    };
}

function deleteExperienceMedia(experienceId, fileId, mediaList) {
    return async (dispatch) => {
        let updateMedia = mediaList.filter((item) => item.id !== fileId);

        await new ExperienceMediaRestClient(true)
            .updateExperienceMedias({
                experienceId,
                media: updateMedia,
            })
            .then((res) => {
                dispatch({
                    type: "UI_ALERT_SET_SUCCESS",
                    payload: {
                        message: "Se eliminó correctamente",
                    },
                });
            })
            .catch((err) => {
                throw err;
            });
    };
}

function setExperienceToPublic(experienceId, isPublic) {
    return (dispatch) => {
        dispatch({
            type: "SET_EXPERIENCE_TO_PUBLIC_LOADING",
        });
        new ExperienceRestClient(true)
            .patchExperience(experienceId, {
                isPublic,
            })
            .then((res) => {
                dispatch({
                    type: "SET_EXPERIENCE_TO_PUBLIC_SUCCESS",
                    payload: {
                        checkIsPublic: isPublic,
                    },
                });
            })
            .catch((err) => {
                dispatch({
                    type: "SET_EXPERIENCE_TO_PUBLIC_FAILURE",
                });
                dispatch({
                    type: "UI_ALERT_SET_ERROR",
                    payload: {
                        error: ErrorManager.toMessage(err),
                    },
                });
            });
    };
}

function updateDetailsFormFailed() {
    return {
        type: "UI_ALERT_SET_ERROR",
        payload: {
            error: "El formulario no está completo. Puedes revisar los errores",
        },
    };
}

function resetExperience() {
    return {
        type: "USER_EXPERIENCES_EXPERIENCE_RESET",
    };
}

function reset() {
    return {
        type: "USER_EXPERIENCES_RESET",
    };
}

export const UserExperiencesActions = {
    getExperiences,
    getMoreExperiences,
    getExperience,
    createExperience,
    updateExperienceDetails,
    updateDetailsFormFailed,
    pushNewExperienceMedia,
    deleteExperienceMedia,
    setExperienceToPublic,
    getExperienceMedia,
    resetExperience,
    reset,
};
